import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardButton from "views/components/DashboardButtton";
// import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./components/ConnectWisePendingCustomersTable/ConnectWisePendingCustomersDataTableData";
import { auth } from "../../../../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  listConnectWiseAllClients,
  clearConnectWiseAllClients,
  activateConnectWiseClient,
  updateConnectWiseClient,
} from "features/company/clientActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import ConnectWisePendingCustomersTable from "./components/ConnectWisePendingCustomersTable/ConnectWisePendingCustomersTable";
import { showMsg } from "utils/general";
const ImportConnectWiseCustomers = (props) => {
  const { objUser } = props;
  const [isLoadingMorePendingCustomers, setIsLoadingMorePendingCustomers] =
    useState(false);
  const [isActivatingClient, setIsActivatingClient] = useState(false);
  const [objSelectedClient, setObjSelectedClient] = useState({});
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const objSelectedClientRef = useRef(objSelectedClient);
  useEffect(() => {
    objSelectedClientRef.current = objSelectedClient;
  }, [objSelectedClient]);
  const navigate = useNavigate();
  useEffect(() => {
    loadMoreConnectWisePendingCustomers(perPage, page);

    return () => {
      dispatch(clearConnectWiseAllClients());
    };
  }, []);

  const loadMoreConnectWisePendingCustomers = (_per_page, _page) => {
    setIsLoadingMorePendingCustomers(true);
    dispatch(listConnectWiseAllClients({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMorePendingCustomers(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        const objRows = [];
        const objPendingCustomersList = response.data.data;

        for (
          let index = 0;
          index < objPendingCustomersList.data.length;
          index++
        ) {
          const element = objPendingCustomersList.data[index];
          const objTr = {};
          objTr.customerName = element.name;
          objTr.address = element.address1;
          objTr.city = element.city;
          objTr.state = element.state;
          // objTr.zip = element.zip;
          objTr.status = element.status;
          objTr.element = element;
          objRows.push(objTr);
        }

        setTotalCount(response.data.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMorePendingCustomers(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to load ConnectWise customers. Please try again later.";
        showMsg("error", errorMessage);
        console.error("Error loading ConnectWise customers:", rejectedValueOrSerializedError);
      });
  };
  const { objListConnectWiseAllClientsData } = useSelector((state) => ({
    objListConnectWiseAllClientsData:
      state.company.client.objListConnectWiseAllClientsData,
  }));
  const activateTheClient = async (objClientData) => {
    setIsActivatingClient(true);
    setObjSelectedClient(objClientData);
    const objUserAuthResp = await dispatch(auth());

    // objUserAuth.isAuth
    // objUserAuth.user
    // console.log("objUserAuthResp.payload in AuthenticationCheck");
    // console.log("objUserAuthResp.payload in AuthenticationCheck");
    // console.log(objUserAuthResp.payload);
    const objUserAuth = objUserAuthResp.payload;
    // dispatch(auth()).then(unwrapResult)

    // alert("activateTheClient");
    const numberOfUnits = 1;

    // console.log("objListConnectWiseAllClientsData in activateTheClient");
    // console.log("objListConnectWiseAllClientsData in activateTheClient");
    // console.log("objListConnectWiseAllClientsData in activateTheClient");
    // console.log(objListConnectWiseAllClientsData);
    // alert("objNewClientData");

    // const objNewClientData = objClientData.element;
    // const objNewClientData = Object.assign({}, objClientData.element);

    // objNewClientData.status = 1;
    // console.log("objNewClientData in activateTheClient");
    // console.log("objNewClientData in activateTheClient");
    // console.log("objNewClientData in activateTheClient");
    // console.log(objNewClientData);
    // dispatch(updateConnectWiseClient(objClientData.element));
    // alert(objUser.user.company.available_units);
    // alert(objUserAuth.user.company.available_units);
    // return false;
    // if (objUser.user.company.available_units >= numberOfUnits) {
    if (objUserAuth.user.company.available_units >= numberOfUnits) {
      const objPostData = {
        id: objClientData.element.id,
      };
      dispatch(activateConnectWiseClient(objPostData))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsActivatingClient(false);
          setObjSelectedClient({});
          // setIsLoadingMorePendingCustomers(false);
          // handle result here
          // const data = response.data;
          const response = originalPromiseResult;

          if (response.data.status === "success") {
            showMsg("success", "You have successfully activate the customer.");
            // dispatch(updateConnectWiseClient(objClientData.element));
            const objNewClientData = Object.assign({}, objClientData.element);
            objNewClientData.status = 1;
            dispatch(updateConnectWiseClient(objNewClientData));
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsActivatingClient(false);
          setObjSelectedClient({});
          setIsLoadingMorePendingCustomers(false);
          const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to activate client. Please try again later.";
          showMsg("error", errorMessage);
          console.error("Error activating ConnectWise client:", rejectedValueOrSerializedError);
        });
    } else {
      setIsActivatingClient(false);
      setObjSelectedClient({});
      showMsg(
        "info",
        "You are using more than your plan allows for, please consider upgrading.."
      );
      navigate("/billing");
    }
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        {/* <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
          <DashboardButton
            component={Link}
            to="/clients/clients-management/add-new-customer"
            btnText="Add Customer"
            btnIcon="add"
            textColor="white"
            bgColor="dark"
          />
          <DashboardButton
            btnText="Import Customer"
            btnIcon="upload"
            textColor="white"
            bgColor="dark"
          />
        </MDBox> */}
        <Card>
          {isLoadingMorePendingCustomers === false &&
          objListConnectWiseAllClientsData !== undefined &&
          objListConnectWiseAllClientsData.data !== undefined &&
          objListConnectWiseAllClientsData.data.data !== undefined &&
          objListConnectWiseAllClientsData.data.data.data.length > 0 &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <ConnectWisePendingCustomersTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              loadMoreConnectWisePendingCustomers={
                loadMoreConnectWisePendingCustomers
              }
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              activateTheClient={activateTheClient}
              isActivatingClient={isActivatingClient}
              objSelectedClient={objSelectedClient}
              objSelectedClientRef={objSelectedClientRef}
              objClientsData={objListConnectWiseAllClientsData.data.data.data}
              objListConnectWiseAllClientsData={
                objListConnectWiseAllClientsData
              }
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isLoadingMorePendingCustomers ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  You don't have any customers in ConnectWise.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default ImportConnectWiseCustomers;
