import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchGamifycationStatsSAT } from "features/company/companiesActions";
import { useTheme } from "@mui/material/styles";
import "./charts.css";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import SymbolLogo from "assets/images/logos/symbol-logo.png";
import HuntressLogo from "assets/images/logos/huntress-logo.png";
import BreachSecureNowLogo from "assets/images/logos/breachsecurenow.webp";
import { borderBottom, display } from "@mui/system";
import { Icon } from "@mui/material";
import SatMetricsProgressBar from "./SatMetricsProgressBar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";

Chart.register(ArcElement, Tooltip, Legend);

const chartLabels = ["Pending", "Completed", "Overdue"];
const bgColors = ["#ffa600", "#38c273", "#e3352e"];
const target = {
  completionRate: 10,
  phishclickRate: 55,
  reportingRates: 40,
  incidentResponseTime: 30,
};

const actual = {
  completionRate: 40,
  phishclickRate: 35,
  reportingRates: 50,
  incidentResponseTime: 80,
};

const defaultChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: "white",
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var dataset = context.dataset;
          var total = dataset.data.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
          var currentValue = dataset.data[context.dataIndex];
          var percentage = ((currentValue / total) * 100).toFixed(0) + "%";
          return `${context.label}: ${currentValue} of ${total} (${percentage})`;
        },
      },
    },
  },
};

export default function SatCharts({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const [percent, setInsurancePercent] = useState(0);
  const [isMember, setIsMember] = useState(true);

  const chartOptions = useMemo(() => {
    const result = defaultChartOptions;
    result.plugins.legend.labels.color = theme.palette?.text?.main;
    return result;
  }, [theme.palette?.text?.main]);

  const dispatch = useDispatch();
  const getStats = () => {
    dispatch(fetchGamifycationStatsSAT({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        let data = originalPromiseResult.data;
        setObjGamifycationStats(data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <>
        {objGamifycationStats.breachSecureNow != null && (
          <MDBox mb={2}>
            {/* charts */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Card>
                <MDBox display="flex" px={0} pt={0}>
                  <MDBox
                    mt={0}
                    pt={2}
                    pl={2}
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography fontWeight="bold" variant="h5" color="dark">
                      Security Awareness Training by Breach Secure Now{" "}
                    </MDTypography>
                    <img
                      alt="Symbol"
                      style={{ maxWidth: "80px", marginLeft: "1rem" }}
                      src={BreachSecureNowLogo}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="space-around">
                  <MDBox xs={12} sm={4} p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Assignments Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.breachSecureNow.pendingAssignments,
                              objGamifycationStats.breachSecureNow
                                .completedAssignments,
                              objGamifycationStats.breachSecureNow.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </MDBox>

                  <MDBox xs={12} sm={8} p={2}>
                    <MDTypography
                      fontWeight="bold"
                      variant="body2"
                      color="dark"
                      textAlign="center"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Phishing Stats
                    </MDTypography>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {/* Emails Sent */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Emails Sent
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.breachSecureNow.emailsSent}
                        </MDTypography>
                      </Grid>

                      {/* Phish Clicks */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Phish Clicks
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.breachSecureNow.phishClicks}
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Shared Data */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Compromise Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.breachSecureNow.compromiseRate}%
                          {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Rate */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.breachSecureNow.clickRate}%
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Factor */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Open Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.breachSecureNow.openRate}%
                          {/* <sup
                            style={{
                              color:
                                objGamifycationStats.breachSecureNow.riskStatus ===
                                "FAIR"
                                  ? "green"
                                  : "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            {objGamifycationStats.breachSecureNow.riskStatus}
                          </sup> */}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <MDBox
                      display="flex"
                      mt={3}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#ffa600",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Pending -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.breachSecureNow.pendingAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.breachSecureNow.pendingAssignments /
                            objGamifycationStats.breachSecureNow.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#38c273",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Completed -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.breachSecureNow.completedAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.breachSecureNow.completedAssignments /
                            objGamifycationStats.breachSecureNow.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#e3352e",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Overdue -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.breachSecureNow.overdueAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.breachSecureNow.overdueAssignments /
                            objGamifycationStats.breachSecureNow.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                {/* {objGamifycationStats.satMetrics != null && (
                  <MDBox>
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Metrics & KPIs
                    </MDTypography>

                    <Grid container justifyContent="space-around" mt={2} mb={3}>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Completion Rate"
                          actualValue={
                            objGamifycationStats.breachSecureNow.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Phishing Click Rate"
                          actualValue={
                            objGamifycationStats.breachSecureNow.phishClickRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.phishing_click_rates
                          }
                          isLowerBetter={true}
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Quiz/Assessment Scores"
                          actualValue={
                            objGamifycationStats.breachSecureNow.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Reporting Rates"
                          actualValue={
                            objGamifycationStats.breachSecureNow.reportingRates
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.reporting_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Incident Response Times"
                          actualValue={
                            objGamifycationStats.breachSecureNow.incidentResponseTime
                          }
                          targetValue={
                            objGamifycationStats.satMetrics
                              .incident_report_times
                          }
                          isLowerBetter={true}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                )} */}
              </Card>
            )}
          </MDBox>
        )}
      </>
      <>
        {objGamifycationStats.huntress != null && (
          <MDBox mb={2}>
            {/* charts */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Card>
                <MDBox display="flex" px={0} pt={0}>
                  <MDBox
                    mt={0}
                    pt={2}
                    pl={2}
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography fontWeight="bold" variant="h5" color="dark">
                      Security Awareness Training by Huntress{" "}
                    </MDTypography>
                    <img
                      alt="Symbol"
                      style={{ maxWidth: "80px", marginLeft: "1rem" }}
                      src={HuntressLogo}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="space-around">
                  <MDBox xs={12} sm={4} p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Assignments Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.huntress.pendingAssignments,
                              objGamifycationStats.huntress
                                .completedAssignments,
                              objGamifycationStats.huntress.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </MDBox>

                  <MDBox xs={12} sm={8} p={2}>
                    <MDTypography
                      fontWeight="bold"
                      variant="body2"
                      color="dark"
                      textAlign="center"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Phishing Campaign Stats
                    </MDTypography>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {/* Emails Sent */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Emails Sent
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.emailsSent}
                        </MDTypography>
                      </Grid>

                      {/* Phish Clicks */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Phish Clicks
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.phishClicks}
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Shared Data */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Compromise Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.compromiseRate}%
                          {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Rate */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.clickRate}%
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Factor */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Open Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.huntress.openRate}%
                          {/* <sup
                            style={{
                              color:
                                objGamifycationStats.huntress.riskStatus ===
                                "FAIR"
                                  ? "green"
                                  : "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            {objGamifycationStats.huntress.riskStatus}
                          </sup> */}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <MDBox
                      display="flex"
                      mt={3}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#ffa600",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Pending -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.pendingAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.pendingAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#38c273",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Completed -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.completedAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.completedAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#e3352e",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Overdue -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.huntress.overdueAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.huntress.overdueAssignments /
                            objGamifycationStats.huntress.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                {objGamifycationStats.satMetrics != null && (
                  <MDBox>
                    <Link to={"/sat-metrics"}>
                      <MDBox display="flex" justifyContent="center" alignItems="center">
                        <MDTypography
                          // sx={{
                          //   marginBottom: "0.5rem",
                          // }}
                          fontWeight="bold"
                          variant="body2"
                          textAlign="center"
                          color="dark"
                        >
                          Metrics & KPIs
                        </MDTypography>
                        <Icon color="dark">link</Icon>
                      </MDBox>
                    </Link>

                    <Grid container justifyContent="space-around" mt={2}>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Completion Rate"
                          actualValue={
                            objGamifycationStats.huntress.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Phishing Click Rate"
                          actualValue={
                            objGamifycationStats.huntress.phishClickRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.phishing_click_rates
                          }
                          isLowerBetter={true}
                        />
                      </Grid>
                      {/* <Grid item>
                        <SatMetricsProgressBar
                          label="Quiz/Assessment Scores"
                          actualValue={
                            objGamifycationStats.huntress.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid> */}
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Reporting Rates"
                          actualValue={
                            objGamifycationStats.huntress.reportingRates
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.reporting_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Incident Response Times"
                          actualValue={
                            objGamifycationStats.huntress.incidentResponseTime
                          }
                          targetValue={
                            objGamifycationStats.satMetrics
                              .incident_report_times
                          }
                          isLowerBetter={true}
                        />
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="space-around" mb={3}>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>Policy Imported</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics.has_sat_policy ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>Policy Authorized</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics
                              .policy_authorized ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>Policy Adopted</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics.policy_adopted ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>SOP Imported</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics.has_sat_sop ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>SOP Authorized</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics.sop_authorized ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <MDBox display="flex" alignItems="center" mb={1}>
                          <MDBox>
                            <MDTypography
                              variant="body2"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ width: "max-content" }}
                            >
                              <b>SOP Adopted</b>
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            {objGamifycationStats.satMetrics.sop_adopted ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <>
                                <CancelIcon color="error" />
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </Card>
            )}
          </MDBox>
        )}
      </>
      <>
        {objGamifycationStats.symbol != null && (
          <MDBox mb={2}>
            {/* charts */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Card>
                <MDBox display="flex" px={0} pt={0}>
                  <MDBox
                    mt={0}
                    pt={2}
                    pl={2}
                    display="flex"
                    alignItems="center"
                  >
                    <MDTypography fontWeight="bold" variant="h5" color="dark">
                      Security Awareness Training by Symbol{" "}
                    </MDTypography>
                    <img
                      alt="Symbol"
                      style={{ maxWidth: "70px", marginLeft: "1rem" }}
                      src={SymbolLogo}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="space-around">
                  <MDBox xs={12} sm={4} p={2}>
                    {/* <MDTypography
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                    fontWeight="bold"
                    variant="h5"
                    textAlign="center"
                    color="dark"
                  >
                    Symbol{" "}
                  </MDTypography> */}
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Training Overview
                    </MDTypography>
                    <Doughnut
                      data={{
                        labels: chartLabels,
                        datasets: [
                          {
                            data: [
                              objGamifycationStats.symbol.pendingAssignments,
                              objGamifycationStats.symbol.completedAssignments,
                              objGamifycationStats.symbol.overdueAssignments,
                            ],
                            backgroundColor: bgColors,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </MDBox>

                  <MDBox xs={12} sm={8} p={2}>
                    <MDTypography
                      fontWeight="bold"
                      variant="body2"
                      color="dark"
                      textAlign="center"
                      sx={{ marginBottom: "1rem" }}
                    >
                      Phishing Simulation Stats
                    </MDTypography>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      {/* Emails Sent */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Emails Sent
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.emailsSent}
                        </MDTypography>
                      </Grid>

                      {/* Phish Clicks */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Phish Clicks
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.phishClicks}
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Shared Data */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Shared Data
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.sharedData}
                          {/* <sup
                            style={{
                              color: "green",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↓
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Rate */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Rate
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.clickRate}%
                          {/* <sup
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            ↑
                          </sup> */}
                        </MDTypography>
                      </Grid>

                      {/* Click Factor */}
                      <Grid item>
                        <MDTypography variant="caption" color="dark">
                          Click Factor
                        </MDTypography>
                        <MDTypography
                          fontWeight="bold"
                          variant="h4"
                          textAlign="center"
                          color="dark"
                        >
                          {objGamifycationStats.symbol.clickFactor}x
                          <sup
                            style={{
                              color:
                                objGamifycationStats.symbol.riskStatus ===
                                "FAIR"
                                  ? "green"
                                  : "red",
                              fontSize: "12px",
                              marginLeft: "3px",
                            }}
                          >
                            {objGamifycationStats.symbol.riskStatus}
                          </sup>
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <MDBox
                      display="flex"
                      mt={3}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#ffa600",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Pending -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.pendingAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.pendingAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#38c273",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Completed -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.completedAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.completedAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      mt={1}
                      justifyContent="space-between"
                      sx={{ borderBottom: "1px solid #e0e0e0" }}
                    >
                      <MDTypography display="flex" alignItems="center">
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: "#e3352e",
                            marginRight: "5px",
                          }}
                        >
                          circle
                        </Icon>
                        Overdue -
                        <strong style={{ marginLeft: "2px" }}>
                          {objGamifycationStats.symbol.overdueAssignments}
                        </strong>{" "}
                      </MDTypography>
                      <MDTypography fontWeight="bold">
                        {(
                          (objGamifycationStats.symbol.overdueAssignments /
                            objGamifycationStats.symbol.totalAssignments) *
                          100
                        ).toFixed(0)}
                        %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>

                {objGamifycationStats.satMetrics != null && (
                  <MDBox sx={{ display: "none" }}>
                    <MDTypography
                      // sx={{
                      //   marginBottom: "0.5rem",
                      // }}
                      fontWeight="bold"
                      variant="body2"
                      textAlign="center"
                      color="dark"
                    >
                      Metrics & KPIs
                    </MDTypography>

                    <Grid container justifyContent="space-around" mt={2} mb={3}>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Completion Rate"
                          actualValue={
                            objGamifycationStats.symbol.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid>
                      <Grid item>
                        <SatMetricsProgressBar
                          label="Phishing Click Rate"
                          actualValue={parseInt(
                            objGamifycationStats.symbol.clickRate
                          )}
                          targetValue={
                            objGamifycationStats.satMetrics.phishing_click_rates
                          }
                          isLowerBetter={true}
                        />
                      </Grid>
                      {/* <Grid item>
                        <SatMetricsProgressBar
                          label="Quiz/Assessment Scores"
                          actualValue={
                            objGamifycationStats.huntress.completionRate
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.completion_rates
                          }
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <SatMetricsProgressBar
                          label="Reporting Rates"
                          actualValue={
                            objGamifycationStats.huntress.reportingRates
                          }
                          targetValue={
                            objGamifycationStats.satMetrics.reporting_rates
                          }
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <SatMetricsProgressBar
                          label="Incident Response Times"
                          actualValue={
                            objGamifycationStats.huntress.incidentResponseTime
                          }
                          targetValue={
                            objGamifycationStats.satMetrics
                              .incident_report_times
                          }
                          isLowerBetter={true}
                        />
                      </Grid> */}
                    </Grid>
                  </MDBox>
                )}
              </Card>
            )}
          </MDBox>
        )}
      </>
    </>
  );
}
