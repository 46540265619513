import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  FormControlLabel,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import {
  getTaskListDetails,
  updateTaskList,
} from "features/company/taskListActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { standardDateFormat } from "utils/general";
import MDTypography from "components/MDTypography";

const TaskListFormSchema = Yup.object().shape({
  title: Yup.string().required("The Title is required."),
  notes: Yup.string().nullable(),
  due_date: Yup.date().nullable(),
});

const ViewOrEditTaskListDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objData = {
      id: taskList?.id,
      title: values.title,
      notes: values.notes,
      due_date: values.due_date,
    };

    setIsLoading(true);
    dispatch(updateTaskList({ objUser, objData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          onSubmit(response.data);
        } else {
          showMsg("error", `Failed to update project: ${response.data.message}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to update project. Please try again.";
        showMsg("error", errorMessage);
        console.error(rejectedValueOrSerializedError);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {editMode && "Edit"} {taskList?.title}
          <MDBox
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <MDTypography fontWeight="bold">View</MDTypography>
            <FormControlLabel
              control={
                <Switch
                  checked={editMode}
                  onChange={() => setEditMode((prev) => !prev)}
                  color="primary"
                />
              }
              label="Edit"
            />
          </MDBox>
        </MDBox>
      </DialogTitle>
      <Formik
        initialValues={{
          title: taskList?.title || "",
          notes: taskList?.notes || "",
          due_date: taskList?.due_date || "",
        }}
        validationSchema={TaskListFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form id="ProjectForm" autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Title"
                    name="title"
                    value={values.title}
                    placeholder="Project Title"
                    error={errors.title && touched.title}
                    success={values.title?.length > 0 && !errors.title}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Notes"
                    name="notes"
                    value={values.notes}
                    placeholder="Project Notes"
                    error={errors.notes && touched.notes}
                    success={values.notes?.length > 0 && !errors.notes}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    label="Due Date"
                    name="due_date"
                    value={values.due_date}
                    error={errors.due_date && touched.due_date}
                    success={values.due_date?.length > 0 && !errors.due_date}
                    disabled={!editMode}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  {editMode ? (
                    <MDButton
                      type="submit"
                      color="success"
                      disabled={!editMode}
                    >
                      Update
                    </MDButton>
                  ) : null}
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                  >
                    {editMode ? "Cancel" : "Close"}
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner size="lg" />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ViewOrEditTaskListDialog;
