import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {
  getCompaniesGroupedByPeerSubscriptions,
  addToPeerGroupSubscription,
  removeFromPeerGroupSubscription,
} from "features/company/superAdminActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Radio, FormControlLabel, Icon, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";

const PeerGroupSubscriptions = () => {
  const dispatch = useDispatch();
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
  const [companiesWithoutSubscription, setCompaniesWithoutSubscription] =
    useState([]);
  const [companiesWithSubscription, setCompaniesWithSubscription] = useState(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchWithout, setSearchWithout] = useState("");
  const [searchWith, setSearchWith] = useState("");

  // Fetch companies data on component mount
  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = useCallback(async () => {
    try {
      setIsFetchingCompanies(true);
      const result = await dispatch(
        getCompaniesGroupedByPeerSubscriptions()
      ).then(unwrapResult);
      if (result) {
        setCompaniesWithoutSubscription(
          result.data?.companiesGroupedByPeerGroupSubscription
            .without_subscription
        );
        setCompaniesWithSubscription(
          result.data?.companiesGroupedByPeerGroupSubscription.with_subscription
        );
        setIsFetchingCompanies(false);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      showMsg("error", "Error fetching companies");
    }
  }, [dispatch]);

  // Handle adding a company to the peer group subscription
  const handleAddToSubscription = async (companyId) => {
    try {
      const result = await dispatch(
        addToPeerGroupSubscription({ company_id: companyId })
      ).then(unwrapResult);
      if (result?.status === 200) {
        showMsg("success", "Company added to peer group subscription");
        // Update the state to move the company from "without" to "with" subscription
        const company = companiesWithoutSubscription.find(
          (c) => c.id === companyId
        );
        if (company) {
          setCompaniesWithoutSubscription((prev) =>
            prev.filter((c) => c.id !== companyId)
          );
          setCompaniesWithSubscription((prev) => [...prev, company]);
          setSelectedCompany(null); // Clear selection
        }
      }
    } catch (error) {
      console.error("Error adding company to peer group subscription:", error);
      showMsg("error", "Error adding company to peer group subscription");
    }
  };

  // Handle removing a company from the peer group subscription
  const handleRemoveFromSubscription = async (companyId) => {
    try {
      const result = await dispatch(
        removeFromPeerGroupSubscription({ company_id: companyId })
      ).then(unwrapResult);
      if (result?.status === 200) {
        showMsg("success", "Company removed from peer group subscription");
        // Update the state to move the company from "with" to "without" subscription
        const company = companiesWithSubscription.find(
          (c) => c.id === companyId
        );
        if (company) {
          setCompaniesWithSubscription((prev) =>
            prev.filter((c) => c.id !== companyId)
          );
          setCompaniesWithoutSubscription((prev) => [...prev, company]);
          setSelectedCompany(null); // Clear selection
        }
      }
    } catch (error) {
      console.error(
        "Error removing company from peer group subscription:",
        error
      );
      showMsg("error", "Error removing company from peer group subscription");
    }
  };

  // Handle company selection
  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
  };

  // Handle moving a company between lists
  const handleMoveCompany = () => {
    if (!selectedCompany) return;

    const isInWithoutSubscription = companiesWithoutSubscription.some(
      (c) => c.id === selectedCompany.id
    );

    if (isInWithoutSubscription) {
      handleAddToSubscription(selectedCompany.id);
    } else {
      handleRemoveFromSubscription(selectedCompany.id);
    }

    fetchCompanies();
  };

  // Handle clearing the selection
  const handleClearSelection = () => {
    setSelectedCompany(null);
  };

  // Filter companies based on search query
  const filteredCompaniesWithout = companiesWithoutSubscription.filter(
    (company) =>
      company.name.toLowerCase().includes(searchWithout.toLowerCase())
  );

  const filteredCompaniesWith = companiesWithSubscription.filter((company) =>
    company.name.toLowerCase().includes(searchWith.toLowerCase())
  );

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      <MDBox>
        {isFetchingCompanies ? (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        ) : (
          <MDBox
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "stretch",
              height: "70vh",
            }}
          >
            {/* Left Side: Companies without subscriptions */}
            <MDBox
              sx={{
                flex: 1,
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                overflowY: "auto",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <MDTypography variant="h4" gutterBottom>
                  Companies Without Subscription
                </MDTypography>
                <MDInput
                  placeholder="Search..."
                  value={searchWithout}
                  onChange={(e) => setSearchWithout(e.target.value)}
                  sx={{ width: "200px", ml: 2 }}
                />
              </MDBox>
              {filteredCompaniesWithout.map((company) => (
                <FormControlLabel
                  key={company.id}
                  control={
                    <Radio
                      checked={selectedCompany?.id === company.id}
                      onChange={() => handleSelectCompany(company)}
                    />
                  }
                  label={company.name}
                  sx={{ display: "block", mb: 1 }}
                />
              ))}
            </MDBox>

            {/* Arrow Icon */}
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100px",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {selectedCompany ? (
                <>
                  <MDButton
                    onClick={handleMoveCompany}
                    color="info"
                    variant="outlined"
                    sx={{ p: 2 }}
                    title={
                      companiesWithoutSubscription.some(
                        (c) => c.id === selectedCompany.id
                      )
                        ? "Add to Peer Group Subscription"
                        : "Remove from Peer Group Subscription"
                    }
                  >
                    {companiesWithoutSubscription.some(
                      (c) => c.id === selectedCompany.id
                    ) ? (
                      <ArrowForwardIcon fontSize="large" />
                    ) : (
                      <ArrowBackIcon fontSize="large" />
                    )}
                  </MDButton>
                  <MDButton
                    onClick={handleClearSelection}
                    color="error"
                    variant="outlined"
                    sx={{ p: 2 }}
                    title="Clear Selection"
                  >
                    <ClearIcon fontSize="large" />
                  </MDButton>
                </>
              ) : (
                <MDBox
                  sx={{
                    backgroundColor: "#e0e0e0",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Select a company to move">
                    <Icon sx={{ color: "text.disabled", fontSize: "4rem" }}>
                      ↔
                    </Icon>
                  </Tooltip>
                </MDBox>
              )}
            </MDBox>

            {/* Right Side: Companies with subscriptions */}
            <MDBox
              sx={{
                flex: 1,
                backgroundColor: "#e0f7fa",
                borderRadius: "8px",
                padding: "16px",
                overflowY: "auto",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <MDTypography variant="h4" gutterBottom>
                  Companies With Subscription
                </MDTypography>
                <MDInput
                  placeholder="Search..."
                  value={searchWith}
                  onChange={(e) => setSearchWith(e.target.value)}
                  sx={{ width: "200px", ml: 2 }}
                />
              </MDBox>
              {filteredCompaniesWith.map((company) => (
                <FormControlLabel
                  key={company.id}
                  control={
                    <Radio
                      checked={selectedCompany?.id === company.id}
                      onChange={() => handleSelectCompany(company)}
                      disabled={company?.manually_added === "no"}
                    />
                  }
                  label={
                    <>
                      {company?.manually_added === "no" ? (
                        <Tooltip title="This company was not manually added and cannot be moved">
                          {company.name}{" "}
                        </Tooltip>
                      ) : (
                        company.name
                      )}
                      {company?.manually_added === "yes" && (
                        <MDTypography
                          variant="caption"
                          color="textSecondary"
                          sx={{ ml: 1 }}
                        >
                          (Manually Added)
                        </MDTypography>
                      )}
                    </>
                  }
                  sx={{ display: "block", mb: 1 }}
                />
              ))}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default PeerGroupSubscriptions;
