/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import Card from "@mui/material/Card";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  authGetCompany,
  authUpdateCompany,
} from "features/company/companiesActions";
import {
  fetchConnectWiseClientById,
  updateConnectWiseClientById,
} from "features/company/clientActions";

import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import AccountInfoFormFormikWrapper from "./AccountInfoFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { objAllCountries } from "helpers/CountryHelpers/CountryHelpers";
import { displayExceptionErrorMessage } from "utils/general";

const AccountInfoFormJWTSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required."),
  address1: Yup.string().required("Address 1 is required."),
  city: Yup.string().required("City is required."),
  country: Yup.string().required("Country is required."),
  zip: Yup.string()
    .required("Zip code is required.")
    .min(4, "Please enter a valid zip code."),
});
const AccountInfoForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [objStates, setObjStates] = useState(objUsStates);
  const [objCountries, setObjCountries] = useState(objAllCountries);
  const [objInitialValues, setObjInitialValues] = useState({
    name: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  });

  const [objAccountInfoFormErrors, setObjAccountInfoFormErrors] = useState({});
  const onSubmitForm = async (values) => {
    if (values.country !== "United States of America") {
      values.state = "";
    } else if (
      values.country === "United States of America" &&
      values.state === ""
    ) {
      showMsg("error", "State is required.");
      return;
    }
    const objPostData = {
      name: values.name,
      address_1: values.address1,
      address_2: values.address2,
      country: values.country,
      city: values.city,
      state: values.state,
      zip: values.zip,
      objUser: props.objUser,
    };
    setObjAccountInfoFormErrors({});
    setIsLoading(true);
    if (
      objUser.user.role.name === "company_user" ||
      objUser.user.role.name === "admin"
    ) {
      dispatch(authUpdateCompany({ objPostData, objUser }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoading(false);
          const response = originalPromiseResult;
          if (response.status === 200) {
            showMsg(
              "success",
              "Your Company Details have been successfully saved."
            );
          } else {
            throw new Error("An error occurred while saving your company details. Try again.");
          }
          
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoading(false);
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        });
    } else {
      objPostData.id = objUser.user.list_of_clients.id;
      dispatch(updateConnectWiseClientById({ objPostData, objUser }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoading(false);
          const response = originalPromiseResult;
          if (response.status === 200) {
            showMsg(
              "success",
              "Your Company Details have been successfully saved."
            );
          } else {
            throw new Error("Failed to update company details. Check your input and try again");
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoading(false);
          const objErrors = rejectedValueOrSerializedError.response.errors;
          if (typeof objErrors !== "undefined" && objErrors !== null) {
            setObjAccountInfoFormErrors(objErrors);
            showMsg("error", "There are errors in your submission. Review and correct them.");
          } else {
            showMsg("error", "An unexpected error occurred. Try again later.");
          }
        });
    }
  };

  useEffect(() => {
    fetchAcountInfo();
  }, []);

  const fetchAcountInfo = async () => {
    if (
      objUser.user.role.name === "company_user" ||
      objUser.user.role.name === "admin"
    ) {
      dispatch(authGetCompany({ objUser }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const response = originalPromiseResult;
          let _objInitialValues = {
            name: response.data.name,
            address1: response.data.address_1,
            address2: response.data.address_2,
            country: response.data.country,
            city: response.data.city,
            state: response.data.state,
            zip: response.data.zip,
          };
          setObjInitialValues(_objInitialValues);
          setIsLoadingKeys(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoadingKeys(false);
        });
    } else {
      dispatch(
        fetchConnectWiseClientById({
          id: objUser.user.list_of_clients.id,
          objUser,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const response = originalPromiseResult;
          let _objInitialValues = {
            name: response.data.name,
            address1: response.data.address_1,
            address2: response.data.address_2,
            country: response.data.country,
            city: response.data.city,
            state: response.data.state,
            zip: response.data.zip,
          };
          setObjInitialValues(_objInitialValues);
          setIsLoadingKeys(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoadingKeys(false);
        });
    }
  };

  return (
    <>
      {isLoadingKeys === false ? (
        <Formik
          initialValues={objInitialValues}
          validationSchema={AccountInfoFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"AccountInfoForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <AccountInfoFormFormikWrapper
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    objStates={objStates}
                    objCountries={objCountries}
                    objUser={objUser}
                  />

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isLoading === false ? (
                      <MDButton
                        type="submit"
                        color="success"
                        sx={{ padding: "12px 82px" }}
                      >
                        Save
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      ) : (
        <MDBox>
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
          <MDTypography sx={{ textAlign: "center" }}>Loading Info</MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default AccountInfoForm;
