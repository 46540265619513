import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateCurrentClientSelected } from "features/company/companyUsersActions";
import { resendPolicyReviewerEmail } from "features/company/policiesActions";
import { showMsg } from "utils/general";

const OtherReviewsActionBtnTr = (props) => {
  const { row, objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // const btnGoToReviewPage = (data) => {
  //   if (id === objUser.user.current_client_id) {
  //     navigate(`/policies/${data.policy_id}/review/${data.id}`, {
  //       state: { routeDictionary },
  //     });
  //   } else {
  //     setIsSwitchingClient(true);
  //     dispatch(resendPolicyReviewerEmail({ value }))
  //       .then(unwrapResult)
  //       .then((originalPromiseResult) => {
  //         window.location = `/policies/${data.policy_id}/review/${data.id}`;
  //       })
  //       .catch((rejectedValueOrSerializedError) => {
  //         setIsSwitchingClient(false);
  //         console.log(rejectedValueOrSerializedError);
  //       });
  //   }
  // };

  const resendEmail = async (id) => {
    const objPostData = {
      policy_review_id: id,
    };

    setIsLoading(true);
    dispatch(resendPolicyReviewerEmail({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Email resent successfully");
        } else {
          showMsg("error", `Failed to resend email. Server responded with status: ${response.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            `Failed to resend email. Please try again.`
        );
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner subClass="text-center" color={"#fff"} size="lg" />
      ) : (
        <MDButton color="success" onClick={() => resendEmail(row.original.id)}>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="white"
            sx={{ lineHeight: 0, color: "white" }}
          >
            <Fragment>Resend</Fragment>
          </MDTypography>
        </MDButton>
      )}
    </Fragment>
  );
};

export default OtherReviewsActionBtnTr;
