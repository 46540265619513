import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { WindowVirtualizer } from "virtua";

import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchAssessmentEvent,
  updateEvent,
} from "features/company/assessmentsActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import tinycolor from "tinycolor2";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import AssessmentQuestion from "./components/AssessmentQuestion";
import { checkConfiguredLockers } from "features/company/evidenceLockerActions";
import { displayExceptionErrorMessage } from "utils/general";
import { fetchMergeTagValues } from "features/company/mergeTagsActions";

const reponsibility = {
  msp: "MSP Responsibility",
  company: "Company Responsibility",
  shared: "Shared Responsibility",
  tool: "Vendor/Tool Responsibility",
};

const questionFilledPredicate = (question) => {
  if (question.max_points === true) {
    return (
      question.answer !== null &&
      question.evidence_location !== null &&
      question.evidence_location.length > 2 &&
      question.implementation_statement !== null &&
      question.implementation_statement.length > 2
    );
  } else {
    return question.answer !== null;
  }
};

const questionUnansweredPredicate = (question) => {
  return (
    question.answer === null &&
    (question.evidence_location === null ||
      question.evidence_location.length < 2) &&
    (question.implementation_statement === null ||
      question.implementation_statement.length < 2) &&
    (question.policy_statements === null ||
      question.policy_statements.length === 0) &&
    (question.procedure_statements === null ||
      question.procedure_statements.length === 0)
  );
};

const questionNotNA = (question, itemScores) => {
  if (question.answer === null) {
    return true;
  }

  const answer = question.options.find((q) => q.id === question.answer);
  const itemScore = itemScores[answer?.score ?? 0] ?? null;

  return (
    answer === null ||
    itemScore === null ||
    (itemScore?.name?.toLowerCase() !== "not applicable" &&
      itemScore?.name?.replaceAll(/\W/g, "").toLowerCase() !== "na")
  );
};

const statusFilterOptions = [
  { id: "all", label: "All" },
  { id: "unanswered", label: "Unanswered" },
  { id: "partial", label: "Partially Answered" },
  { id: "completed", label: "Completed" },
  { id: "not_na", label: "Exclude N/A" },
];

function TakeTheAssessment(props) {
  const { objUser } = props;
  const { version_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [previousVersionResponses, setPreviousVersionResponses] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [filterAssigned, setFilterAssigned] = useState(false);
  const [itemScores, setItemScores] = useState({});
  const [userList, setUserList] = useState([]);
  const [mergeTags, setMergeTags] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [, setSaveQuestionDebounceTimeout] = useState(null);
  const [evidenceLockers, setEvidenceLockers] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const nextCategory = useMemo(() => {
    const categories = Object.keys(assessmentData.category ?? []);
    const activeCategoryIndex = categories.findIndex(
      (category) => category === activeCategory
    );
    if (activeCategoryIndex === -1) {
      return categories[0];
    } else if (activeCategoryIndex === categories.length - 1) {
      return null;
    } else {
      return categories[activeCategoryIndex + 1];
    }
  }, [activeCategory, assessmentData.category]);

  const prevCategory = useMemo(() => {
    const categories = Object.keys(assessmentData.category ?? []);
    const activeCategoryIndex = categories.findIndex(
      (category) => category === activeCategory
    );
    if (activeCategoryIndex === -1) {
      return categories[0];
    } else if (activeCategoryIndex === 0) {
      return null;
    } else {
      return categories[activeCategoryIndex - 1];
    }
  }, [activeCategory, assessmentData.category]);

  const nextCategoryCallback = useCallback(() => {
    setActiveCategory(nextCategory);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [nextCategory]);

  const prevCategoryCallback = useCallback(() => {
    setActiveCategory(prevCategory);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [prevCategory]);

  const filteredQuestions = useMemo(() => {
    if (!assessmentData.category || !assessmentData.category[activeCategory]) {
      return [];
    }

    return assessmentData.category[activeCategory]
      ?.filter((question) => {
        if (statusFilter === "all") {
          return true;
        } else if (statusFilter === "unanswered") {
          return questionUnansweredPredicate(question);
        } else if (statusFilter === "partial") {
          return (
            !questionUnansweredPredicate(question) &&
            !questionFilledPredicate(question)
          );
        } else if (statusFilter === "completed") {
          return questionFilledPredicate(question);
        } else if (statusFilter === "not_na") {
          return questionNotNA(question, itemScores);
        }
        return true;
      })
      .filter((question) => {
        if (filterAssigned) {
          return question.assigned_to_client;
        }
        return true;
      });
  }, [activeCategory, assessmentData, filterAssigned, statusFilter]);

  const completedCategories = useMemo(() => {
    return Object.keys(assessmentData.category ?? []).map((category) => {
      return assessmentData.category[category].every(questionFilledPredicate);
    });
  }, [assessmentData]);

  const allQuestionsFilled = useMemo(() => {
    return completedCategories?.every((x) => x);
  }, [completedCategories]);

  const clientUser = objUser?.user?.role?.name === "client_user";

  //gilbert added logic remove null
  const sortQuestionsIntoCategories = (
    objTemplate,
    itemScores = null,
    scores = null
  ) => {
    objTemplate.category = {};
    objTemplate.questions.sort((a, b) => a.sequence - b.sequence);
    for (let index = 0; index < objTemplate.questions.length; index++) {
      const element = objTemplate.questions[index];
      element.options.sort((a, b) => a.sequence - b.sequence);

      //trimming down policy and procedure statements to just the ids
      element.policy_statements = element.policy_statements?.map((record) => {
        return record.policy;
      });

      element.procedure_statements = element.procedure_statements?.map(
        (record) => {
          return record.procedure;
        }
      );

      //gilbert added logic (this is updated to true when the answer to the question is totally satisfactory)
      element.max_points = maxPointsOptionSelected(
        element.options,
        element.answer,
        itemScores,
        scores
      );
      if (typeof objTemplate.category[element.category] === "undefined") {
        objTemplate.category[element.category] = [];
      }

      objTemplate.category[element.category].push(element);
      //gilbert added logic
      const currentIndex = objTemplate.category[element.category].length - 1;
      objTemplate.category[element.category][currentIndex].max_points =
        maxPointsOptionSelected(
          element.options,
          element.answer,
          itemScores,
          scores
        );
    }
    setActiveCategory(objTemplate.questions[0].category);

    return objTemplate;
  };

  useEffect(() => {
    const fetchAssessment = (eventId) => {
      setIsLoading(true);
      dispatch(fetchAssessmentEvent({ objUser, eventId }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const response = originalPromiseResult;
          //maybe

          const scores = {};
          response.data?.itemScores?.forEach((itemScore) => {
            const color = tinycolor(itemScore.color);
            scores[itemScore.code] = {
              color: color.toString(),
              text_color: itemScore.text_color,
              name: itemScore.name,
              value: itemScore.value,
              possible_points: itemScore.possible_points,
            };
          });
          setUserList([
            { id: "", name: "Not Applicable" },
            ...response.data?.userList,
          ]);
          setPolicyList([...response.data?.policies]);
          setItemScores(scores);
          setPreviousVersionResponses(response.data?.previousVersionResponses);
          const objTemplate = sortQuestionsIntoCategories(
            response.data?.event,
            response.data?.itemScores,
            scores
          );
          setAssessmentData(objTemplate);
        })
        .catch((rejectedValueOrSerializedError) => {
          const objErrorRsp = rejectedValueOrSerializedError.response;
          console.log(rejectedValueOrSerializedError);
          if (objErrorRsp.status === 403) {
            showMsg("error", "You are not authorized to view this assessment");
          } else {
            displayExceptionErrorMessage(rejectedValueOrSerializedError);
          }
          navigate("/assessments/events/", { state: routeDictionary });
        })
        .finally(() => {
          getTags();
        });
    };

    const getTags = () => {
      dispatch(fetchMergeTagValues({ objUser }))
        .then(unwrapResult)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Get marge tags request failed.");
          }
          let tags = response?.data.filter((tag) => {
            return tag.value != null;
          });
          setMergeTags(tags);
        })
        .catch((rejectedValueOrSerializedError) => {
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        })
        .finally(() => {
          setIsLoading(false);
          // setIsFetchingMergeTags(false);
        });
    };

    fetchAssessment(version_id);
    checkLockers();
  }, []);

  const checkLockers = async () => {
    dispatch(checkConfiguredLockers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setEvidenceLockers(response.data);
      })
      .catch((err) => {
        displayExceptionErrorMessage(err);
      });
  };

  const saveQuestion = useCallback(
    (question) => {
      setIsSaving(true);
      const objPostData = {
        status: "in_progress",
        questions: [question],
      };
      dispatch(updateEvent({ objUser, objPostData, eventId: version_id }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const response = originalPromiseResult;
        })
        .catch((rejectedValueOrSerializedError) => {
          const objErrorRsp = rejectedValueOrSerializedError.response;
          console.log(rejectedValueOrSerializedError);
          if (objErrorRsp.status === 403) {
            showMsg("error", "You are not authorized to view this assessment");
          } else {
            displayExceptionErrorMessage(rejectedValueOrSerializedError);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [version_id]
  );

  const saveAssessment = useCallback(
    (assessmentData) => {
      setIsSaving(true);
      const objPostData = {
        status: assessmentData.status,
        questions: assessmentData.questions,
      };
      dispatch(updateEvent({ objUser, objPostData, eventId: version_id }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          const response = originalPromiseResult;
          const objAssessment = sortQuestionsIntoCategories(response.data);
          setAssessmentData(objAssessment);
        })
        .catch((rejectedValueOrSerializedError) => {
          const objErrorRsp = rejectedValueOrSerializedError.response;
          console.log(rejectedValueOrSerializedError);
          if (objErrorRsp.status === 403) {
            showMsg("error", "You are not authorized to view this assessment");
          } else {
            displayExceptionErrorMessage(rejectedValueOrSerializedError);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [version_id]
  );

  //gilbert added logic
  const maxPointsOptionSelected = useCallback(
    (
      question_options,
      selected_option_id,
      item_scores = null,
      scores = null
    ) => {
      const itemScoresArray = Array.isArray(item_scores)
        ? item_scores
        : Object.values(scores ? scores : itemScores);

      const maxPossiblePoints = itemScoresArray.reduce((max, itemScore) => {
        const currentPossiblePoints = parseFloat(itemScore.possible_points);
        return Math.max(max, currentPossiblePoints);
      }, -Infinity);

      const selectedAnswerForQuestionScore = question_options.find((option) => {
        return option.id === selected_option_id;
      })?.score;

      const selectedOptionScore = scores
        ? scores[selectedAnswerForQuestionScore]
        : itemScores[selectedAnswerForQuestionScore];

      return selectedOptionScore?.value >= maxPossiblePoints;
    },
    [itemScores]
  );

  const handleChange = useCallback(
    (question_id, option_id) => {
      setAssessmentData((prevState) => {
        const questionIndex = prevState.questions.findIndex(
          (question) => question.question_id === question_id
        );
        prevState.questions[questionIndex].answer = option_id;
        //gilbert added logic (this is updated to true when the answer to the question is totally satisfactory)
        const maxPoints = maxPointsOptionSelected(
          prevState.questions[questionIndex].options,
          option_id,
          itemScores
        );
        prevState.questions[questionIndex].max_points = maxPoints;
        saveQuestion(prevState.questions[questionIndex]);
        return { ...prevState, questions: prevState.questions };
      });
    },
    [maxPointsOptionSelected, saveQuestion]
  );

  const handleChangeAssign = useCallback(
    (question_id, assigned) => {
      setAssessmentData((prevState) => {
        const questionIndex = prevState.questions.findIndex(
          (question) => question.question_id === question_id
        );
        prevState.questions[questionIndex].assigned_to_client = assigned;
        saveQuestion(prevState.questions[questionIndex]);
        return { ...prevState, questions: prevState.questions };
      });
    },
    [saveQuestion]
  );

  const handleChangeSharedResponsibility = useCallback(
    (question_id, responsibility, vendor = null, tool = null) => {
      setAssessmentData((prevState) => {
        const questionIndex = prevState.questions.findIndex(
          (question) => question.question_id === question_id
        );

        prevState.questions[questionIndex].responsibility = responsibility;
        prevState.questions[questionIndex].vendor = vendor;
        prevState.questions[questionIndex].tool = tool;

        saveQuestion(prevState.questions[questionIndex]);
        return { ...prevState, questions: prevState.questions };
      });
    },
    [saveQuestion]
  );

  const handleChangeRACI = useCallback(
    (question_id, raciData) => {
      setAssessmentData((prevState) => {
        const questionIndex = prevState.questions.findIndex(
          (question) => question.question_id === question_id
        );

        prevState.questions[questionIndex].responsible_type =
          raciData.responsible?.type || null;
        prevState.questions[questionIndex].responsible_value =
          raciData.responsible?.value || null;
        prevState.questions[questionIndex].accountable_type =
          raciData.accountable?.type || null;
        prevState.questions[questionIndex].accountable_value =
          raciData.accountable?.value || null;
        prevState.questions[questionIndex].consulted_type =
          raciData.consulted?.type || null;
        prevState.questions[questionIndex].consulted_value =
          raciData.consulted?.value || null;
        prevState.questions[questionIndex].informed_type =
          raciData.informed?.type || null;
        prevState.questions[questionIndex].informed_value =
          raciData.informed?.value || null;
        // Save the updated question
        saveQuestion(prevState.questions[questionIndex]);

        return { ...prevState, questions: prevState.questions };
      });
    },
    [saveQuestion]
  );

  const handleChangeStatements = useCallback(
    (question_id, statement, value) => {
      console.log("statement", statement, "value", value);
      setAssessmentData((prevState) => {
        const questionIndex = prevState.questions.findIndex(
          (question) => question.question_id === question_id
        );

        prevState.questions[questionIndex][statement] = value;

        setSaveQuestionDebounceTimeout((prevTimeout) => {
          if (prevTimeout) {
            clearTimeout(prevTimeout);
          }
          return setTimeout(() => {
            saveQuestion(prevState.questions[questionIndex]);
            setSaveQuestionDebounceTimeout(null);
          }, 1000);
        });

        return { ...prevState, questions: prevState.questions };
      });
    },
    [saveQuestion]
  );

  const handleAssignAll = () => {
    assessmentData.questions.forEach((question) => {
      question.assigned_to_client = true;
    });
    setAssessmentData({
      ...assessmentData,
      questions: assessmentData.questions,
    });

    saveAssessment(assessmentData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!allQuestionsFilled) {
      return;
    }
    assessmentData.status = "completed";

    setAssessmentData({ ...assessmentData, status: "completed" });
    saveAssessment(assessmentData);
  };

  if (assessmentData.status === "completed" && !isSaving && !isLoading) {
    return (
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <Card>
          <CardMedia
            sx={{
              height: 40,
              backgroundColor: assessmentData?.risk_level?.color,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              margin: 0,
            }}
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          />
          <CardHeader
            title={assessmentData.name + " v" + assessmentData.version + ".0"}
            subheader={assessmentData.description}
          />
          <CardContent>
            <MDTypography variant="h5" textAlign="center">
              Score:{" "}
              {(
                (assessmentData.score / assessmentData.possible_score) *
                100.0
              ).toFixed(2)}{" "}
              %
            </MDTypography>
            <MDTypography variant="h5" textAlign="center">
              Risk Level: {assessmentData?.risk_level?.name}
            </MDTypography>
            {Object.entries(assessmentData.category ?? []).map(
              ([category, questions], catIndex) => (
                <Fragment key={category}>
                  <MDTypography variant="h5" mt={1}>
                    {category}
                  </MDTypography>
                  {questions.map((question) => {
                    const seletedOption = question.options.find(
                      (option) => option.id === question.answer
                    );
                    return (
                      <Fragment key={question.question_id}>
                        {/* <MDTypography variant="h6">
                          {question.control_id
                            ? `${question.control_id} - ${question.item}`
                            : question.item}
                        </MDTypography> */}
                        <MDBox mt={1} mb={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.3rem",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              flexGrow: 1,
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              component="div"
                              id={question.id + "_enunciate"}
                              color="link"
                            >
                              Control ID {question.control_id}
                            </MDTypography>
                            <MDTypography
                              variant="h6"
                              component="div"
                              id={question.id + "_enunciate"}
                            >
                              {question.item}
                            </MDTypography>
                          </MDBox>

                          {question.control_objective_id && (
                            <MDBox
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.3rem",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                flexGrow: 1,
                                marginLeft: "0.5rem",
                              }}
                            >
                              <MDTypography
                                variant="h6"
                                component="div"
                                id={question.id + "_enunciate"}
                                color="link"
                              >
                                Control ID {question.control_objective_id}
                              </MDTypography>
                              <MDTypography
                                variant="h6"
                                component="div"
                                id={question.id + "_enunciate"}
                              >
                                {question.control_objective_item}
                              </MDTypography>
                            </MDBox>
                          )}
                        </MDBox>
                        <Chip
                          label={itemScores[seletedOption.score].name}
                          variant="outlined"
                          sx={{
                            backgroundColor:
                              itemScores[seletedOption.score].color,
                            color: itemScores[seletedOption.score].text_color,
                          }}
                        />
                        <MDTypography variant="body2" fontWeight="regular">
                          {seletedOption.option}
                        </MDTypography>
                        <MDTypography variant="body2">
                          {question.responsibility
                            ? reponsibility[question.responsibility]
                            : ""}
                        </MDTypography>
                        <MDTypography variant="body2">
                          {!!question.vendor && (
                            <p>Vendor: {question.vendor}</p>
                          )}{" "}
                          {!!question.tool && <p>Tool: {question.tool}</p>}
                        </MDTypography>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Fragment>
              )
            )}
          </CardContent>
        </Card>
      </DashboardLayout>
    );
  } else {
    return (
      <DashboardLayout>
        <DashboardBreadcrumbs />
        {isLoading || Object.keys(assessmentData).length === 0 ? (
          <LoadingSpinner />
        ) : (
          <MDBox p={2}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "0.5rem",
              }}
            >
              <MDBox>
                <MDTypography variant="h2">
                  {assessmentData.name + " v" + assessmentData.version + ".0"}
                </MDTypography>
                <MDTypography variant="h6">
                  {assessmentData.description}
                </MDTypography>
              </MDBox>
              <MDButton
                color="success"
                onClick={handleAssignAll}
                disabled={isSaving || clientUser}
              >
                Open Entire Assessment to Client
              </MDButton>
            </MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  disableClearable
                  value={statusFilter}
                  options={statusFilterOptions}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) =>
                    option?.label ??
                    statusFilterOptions.find((op) => op.id === option)?.label ??
                    option
                  }
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      className="larger-input-label"
                      variant="standard"
                      label="Item Status"
                      size="large"
                      fullWidth
                    />
                  )}
                  onChange={(e, value) => setStatusFilter(value.id)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filterAssigned}
                      onChange={(e) => setFilterAssigned(e.target.checked)}
                    />
                  }
                  label="Only show assigned questions"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  disableClearable
                  value={activeCategory}
                  options={Object.keys(assessmentData.category ?? [""])}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      className="larger-input-label"
                      variant="standard"
                      label="Category"
                      size="large"
                      fullWidth
                    />
                  )}
                  onChange={(e, value) => setActiveCategory(value)}
                />
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit}>
              <MDBox mb={3} mt={2}>
                <MDTypography variant="h4" mb={1}>
                  {activeCategory}
                </MDTypography>
                <MDBox>
                  {filteredQuestions.length === 0 ? (
                    <MDTypography variant="h6" textAlign="center">
                      No questions match the selected filters
                    </MDTypography>
                  ) : (
                    <WindowVirtualizer>
                      {filteredQuestions.map((question) => (
                        <AssessmentQuestion
                          objUser={objUser}
                          previousVersionResponses={previousVersionResponses}
                          key={question.question_id}
                          event_question_id={question.id}
                          question_id={question.question_id}
                          item={question.item}
                          control_id={question.control_id}
                          control_objective_id={question.control_objective_id}
                          control_objective_item={
                            question.control_objective_item
                          }
                          mergeTags={mergeTags}
                          explanation={question.explanation}
                          scoring_instructions={question.scoring_instructions}
                          options={question.options}
                          answer={question.answer}
                          assigned_to_client={question.assigned_to_client}
                          responsibility={question.responsibility}
                          responsible_type={question.responsible_type}
                          responsible_value={question.responsible_value}
                          accountable_type={question.accountable_type}
                          accountable_value={question.accountable_value}
                          consulted_type={question.consulted_type}
                          consulted_value={question.consulted_value}
                          informed_type={question.informed_type}
                          informed_value={question.informed_value}
                          vendor={question.vendor}
                          tool={question.tool}
                          evidence_location={question.evidence_location}
                          evidence_location_type={
                            question.evidence_location_type
                          }
                          question_notes={question.question_notes}
                          implementation_statement={
                            question.implementation_statement
                          }
                          policy_statements={question.policy_statements}
                          procedure_statements={question.procedure_statements}
                          control_owner_id={question.control_owner_id}
                          control_evidence_source={
                            question.control_evidence_source
                          }
                          control_evidence_location={
                            question.control_evidence_location
                          }
                          sprs_score={question.sprs_score}
                          max_points={question.max_points ?? false}
                          itemScores={itemScores}
                          onChange={handleChange}
                          onChangeAssign={handleChangeAssign}
                          onChangeSharedResponsibility={
                            handleChangeSharedResponsibility
                          }
                          onChangeRACI={handleChangeRACI}
                          onChangeStatements={handleChangeStatements}
                          userList={userList}
                          policyList={policyList}
                          disabled={clientUser && !question.assigned_to_client}
                          evidenceLockers={evidenceLockers}
                          examine={question?.examine}
                          interview={question?.interview}
                          test={question?.test}
                        />
                      ))}
                    </WindowVirtualizer>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                mb={3}
                mt={2}
                display="flex"
                justifyContent="space-between"
              >
                <MDButton
                  onClick={prevCategoryCallback}
                  disabled={isSaving || prevCategory === null}
                >
                  Previous Category
                </MDButton>
                <MDButton
                  color="success"
                  onClick={nextCategoryCallback}
                  disabled={isSaving || nextCategory === null}
                >
                  Next Category
                </MDButton>
              </MDBox>

              <MDButton
                color="success"
                onClick={() => window.scrollTo(0, 0)}
                sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
                aria-label="Scroll to top"
                title="Scroll to top"
              >
                <Icon>keyboard_arrow_up</Icon>
              </MDButton>

              {isSaving ? (
                <LoadingSpinner />
              ) : (
                <MDButton
                  type="submit"
                  color="success"
                  onClick={handleSubmit}
                  disabled={!allQuestionsFilled}
                >
                  Complete Assessment Event
                </MDButton>
              )}
            </form>
          </MDBox>
        )}
      </DashboardLayout>
    );
  }
}

export default TakeTheAssessment;
