import List from "@mui/material/List";
import MDBox from "components/MDBox";
import VendorToolResponsibilityRadio from "./VendorToolResponsibilityRadio";
import ResponsibilityRadio from "./ResponsibilityRadio";
import Grid from "@mui/material/Grid";

export default function QuestionSettings({
  question_id,
  assigned_to_client,
  responsibility,
  vendor,
  tool,
  onChangeAssign,
  onChangeSharedResponsibility,
}) {
  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List>
            <ResponsibilityRadio
              question_id={question_id}
              responsibility={responsibility}
              onChangeResponsibility={onChangeSharedResponsibility}
              vendor={vendor}
              tool={tool}
              value="msp"
              description="MSP Responsibility"
            />
            <ResponsibilityRadio
              question_id={question_id}
              responsibility={responsibility}
              onChangeResponsibility={onChangeSharedResponsibility}
              vendor={vendor}
              tool={tool}
              value="company"
              description="Company Responsibility"
            />
            <ResponsibilityRadio
              question_id={question_id}
              responsibility={responsibility}
              onChangeResponsibility={onChangeSharedResponsibility}
              vendor={vendor}
              tool={tool}
              value="shared"
              description="Shared Responsibility"
            />
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <VendorToolResponsibilityRadio
            question_id={question_id}
            responsibility={responsibility}
            vendor={vendor}
            tool={tool}
            onChangeResponsibility={onChangeSharedResponsibility}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
