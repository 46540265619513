/* eslint-disable */
import Grid from "@mui/material/Grid";
import { Fragment, useState, useEffect, useMemo } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import "./CreatePolicyDocumentStepAuthorized.css";
import AuthorizationsTable from "../AuthorizationsTable";

import PublishDocumentDialog from "../../PublishDocumentDialog";
import { documentUpdateState } from "features/company/policiesActions";
// /* eslint-enable */

const CreatePolicyDocumentStepAuthorized = (props) => {
  const { objUser, objPolicyDocument, loadPolicyDocument, setNextDisabled } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryOfChanges, setSummaryOfChanges] = useState("");
  const [openPublishDocumentDialog, setOpenPublishDocumentDialog] =
    useState(false);
  const allowedStatuses = ["Authorized", "Published"];
  let allowedStep = false;

  if (
    typeof objPolicyDocument.state !== "undefined" &&
    allowedStatuses.includes(objPolicyDocument.state)
  ) {
    allowedStep = true;
  }

  useEffect(() => {
    const nextStates = ["Published"];
    if (
      typeof objPolicyDocument.state === "undefined" ||
      !nextStates.includes(objPolicyDocument.state) ||
      objPolicyDocument.is_max_major_version == false
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, []);

  const [tableData, approvers] = useMemo(() => {
    const tableData = [];
    const approvers = [];

    if (
      typeof objPolicyDocument.state !== "undefined" &&
      objPolicyDocument.state !== "In Review" &&
      typeof objPolicyDocument.reviewers !== "undefined"
    ) {
      if (
        typeof objPolicyDocument.reviewers.approval !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.approval) &&
        objPolicyDocument.reviewers.approval.length > 0
      ) {
        objPolicyDocument.reviewers.approval.forEach((element) => {
          let tmpObj = {};
          tmpObj.id = element.id;
          tmpObj.name = element.label;
          tmpObj.status = element.status;
          tmpObj.status_color = element.status_color;
          tmpObj.reason = element.reason;
          tmpObj.show_reason = element.show_reason;
          tmpObj.type = "Approver";
          approvers.push(tmpObj);
        });
      }

      if (
        typeof objPolicyDocument.reviewers.review !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.review) &&
        objPolicyDocument.reviewers.review.length > 0
      ) {
        objPolicyDocument.reviewers.review.forEach((element) => {
          let tmpObj = {};
          tmpObj.id = element.id;
          tmpObj.name = element.label;
          tmpObj.status = element.status;
          tmpObj.status_color = element.status_color;
          tmpObj.reason = element.reason;
          tmpObj.show_reason = element.show_reason;
          tmpObj.type = "Reviewer";
          tableData.push(tmpObj);
        });
      }

      if (
        typeof objPolicyDocument.reviewers.legal !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.legal) &&
        objPolicyDocument.reviewers.legal.length > 0
      ) {
        objPolicyDocument.reviewers.legal.forEach((element) => {
          let tmpObj = {};
          tmpObj.id = element.id;
          tmpObj.name = element.label;
          tmpObj.status = element.status;
          tmpObj.status_color = element.status_color;
          tmpObj.reason = element.reason;
          tmpObj.show_reason = element.show_reason;
          tmpObj.type = "Legal Reviewer";
          tableData.push(tmpObj);
        });
      }
    }
    return [tableData, approvers];
  }, [objPolicyDocument]);

  const onClickPublish = () => {
    setOpenPublishDocumentDialog(true);
  };

  const onClosePublishDialog = () => {
    setOpenPublishDocumentDialog(false);
  };

  //
  const submitPublishDocument = () => {
    setIsLoading(true);
    const params = {
      client_id: objPolicyDocument.policy.client_id ?? "",
      policy_id: objPolicyDocument.policy.id ?? "",
      policy_document_id: objPolicyDocument.id ?? "",
      state: "Published",
    };
    dispatch(documentUpdateState({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setOpenPublishDocumentDialog(false);
        setIsLoading(false);

        const response = originalPromiseResult;

        if (response.status === 200) {
          loadPolicyDocument();
        } else {
          showMsg(
            "error",
            `Failed to publish document. Server responded with status: ${response.status}`
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setOpenPublishDocumentDialog(false);
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            "Failed to publish document. Please try again."
        );
      });
  };

  return (
    <MDBox>
      <PublishDocumentDialog
        open={openPublishDocumentDialog}
        onClose={onClosePublishDialog}
        submitPublishDocument={submitPublishDocument}
        summaryOfChanges={summaryOfChanges}
        setSummaryOfChanges={setSummaryOfChanges}
      />
      {isLoading === false ? (
        <Fragment>
          {allowedStep === true ? (
            <Fragment>
              <MDBox
                className="step-content-wrapper"
                textAlign="left"
                mx="auto"
                mt={4}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  columnSpacing={2}
                >
                  <Grid item xs={12} pt={0}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography variant="h5" fontWeight="bold">
                          {objPolicyDocument.policy.policy_type == "asset"
                            ? "Asset List"
                            : "Policy"}{" "}
                          document authorized
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        All reviewers have approved the document.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox className="step-content-wrapper" textAlign="left" mt={3}>
                <AuthorizationsTable tableData={tableData} />
                <AuthorizationsTable tableData={approvers} />
                <Fragment>
                  <Grid container justifyContent="start" mt={2} spacing={2}>
                    <Grid item xs={12} lg={12} pt={0}>
                      <MDBox mb={0}>
                        <MDTypography variant="body2" color="text">
                          This{" "}
                          {objPolicyDocument.policy.policy_type == "asset"
                            ? "asset list"
                            : "policy"}{" "}
                          document is publishable.
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12} pt={0}>
                      <MDBox mb={0}>
                        <MDButton
                          onClick={onClickPublish}
                          // onClick={submitPublishDocument}
                          disabled={
                            objPolicyDocument.is_max_major_version == false
                          }
                          type="button"
                          // variant="gradient"
                          color="success"
                          sx={{ padding: "12px 20px" }}
                        >
                          PUBLISH
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Fragment>
              </MDBox>
            </Fragment>
          ) : (
            <MDBox px={5} textAlign="left" mx="auto" mt={4}>
              <Grid container justifyContent="space-between" columnSpacing={2}>
                <Grid item xs={12} pt={0}>
                  <MDBox mb={0}>
                    <MDBox mb={0}>
                      <MDTypography variant="h5" fontWeight="bold">
                        {objPolicyDocument.policy.policy_type == "asset"
                          ? "Asset List"
                          : "Policy"}{" "}
                        document authorized
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="body2" color="text">
                      Waiting for all reviewers to approve the document
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </Fragment>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </MDBox>
  );
};

export default CreatePolicyDocumentStepAuthorized;
