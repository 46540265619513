// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import * as Yup from "yup";

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
import axios from "axios";
// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import contactUploadFormat from "assets/images/contacts-upload-format.png";
import polygonLogo from "assets/images/icons/logo.png";
import CreateClientContactByClientIdFormFormikWrapper from "./CreateClientContactByClientIdFormFormikWrapper";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import CreateClientContactByClientIdFormErrorsBlock from "./CreateClientContactByClientIdFormErrorsBlock";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
// import { storeAddCustomerClient } from "features/company/clientActions";
import {
  storeClientContactByClientId,
  importClientContactsByClientId,
} from "features/company/clientContactsActions";
import { ImportContacts } from "@mui/icons-material";
import { useEffect } from "react";
import MDAlert from "components/MDAlert";
const objInitialValues = {
  txtFirstName: "",
  txtLastName: "",
  txtEmail: "",
  txtTitle: "",
  txtPhone: "",
};
const CreateClientContactByClientIdFormJWTSchema = Yup.object().shape({
  txtFirstName: Yup.string().required("First Name is required."),
  txtLastName: Yup.string().required("Last Name is required."),
  txtEmail: Yup.string().email("Invalid email").required("Email is required"),
  txtTitle: Yup.string(),
  txtPhone: Yup.string().min(
    6,
    "Phone number is too short!(min 6 characters are required)"
  ),
});
const CreateClientContactByClientIdForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const [duplicateContactsList, setDuplicateContactsList] = useState([]);
  const [
    objCreateClientContactByClientIdFormErrors,
    setObjCreateClientContactByClientIdFormErrors,
  ] = useState({});
  const [file, setFile] = useState();
  const [importArray, setImportArray] = useState([]);
  const fileReader = new FileReader();
  const onSubmitForm = async (values) => {
    const objPostData = {
      first_name: values.txtFirstName,
      last_name: values.txtLastName,
      email: values.txtEmail,
      title: values.txtTitle,
      phone: values.txtPhone,
    };
    setObjCreateClientContactByClientIdFormErrors({});
    setIsLoading(true);
    dispatch(storeClientContactByClientId({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 201) {
          showMsg("success", "Your Contact has been added successfully.");
          // navigate(`/clients/${id}`, { state: { routeDictionary } });
          navigate(`/contacts`, { state: { routeDictionary } });
        } else {
          showMsg("error", "Failed to add contact. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to add contact. Please try again.";
        showMsg("error", errorMessage);
        if (rejectedValueOrSerializedError?.response?.status === 404) {
          navigate(`/clients/edit`, { state: { routeDictionary } });
        } else if (rejectedValueOrSerializedError?.response?.data?.errors) {
          setObjCreateClientContactByClientIdFormErrors(rejectedValueOrSerializedError.response.data.errors);
        }
        // handle result here
      });
  };

  /**upload contacts csv***
   * ****
   */
  function handleOnFileChange(e) {
    setFile(e.target.files[0]);
  }

  useEffect(() => {
    parseCSV();
  }, [file]);

  function parseCSV() {
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
        //console.log(event.target.result);
      };
      fileReader.readAsText(file);
    }
  }

  function csvFileToArray(string) {
    const csvHeaderWithOutLineBreaks = string.slice(0, string.indexOf("\r"));
    const csvHeader = csvHeaderWithOutLineBreaks
      .slice(0, string.indexOf("\n"))
      .split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    array.pop(); //remove last line with null values which is auto added after parsing csv
    setImportArray(array);
  }

  async function uploadContactsToAPI() {
    // console.log(importArray);
    if (!file) {
      showMsg("error", "Please choose a CSV file to import.");
      return;
    }
    setIsImportLoading(true);
    dispatch(importClientContactsByClientId({ objUser, importArray }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsImportLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 201) {
          showMsg("success", "Your contacts have been successfully imported.");
          navigate(`/contacts`, { state: { routeDictionary } });
          if (response.data.duplicateContacts.length > 0) {
            setDuplicateContacts(true);
            setDuplicateContactsList(response.data.duplicateContacts);
          }
        } else {
          showMsg("error", "Failed to import contacts. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsImportLoading(false);
        const error = rejectedValueOrSerializedError?.response?.data;
        if (error) {
          if (error.message) {
            showMsg("error", error.message);
          } else if (error.errors) {
            setObjCreateClientContactByClientIdFormErrors(error.errors);
            for (const [key, value] of Object.entries(error.errors)) {
              showMsg("error", `${key}: ${value}`);
            }
          } else {
            showMsg("error", "An unexpected error occurred.");
          }
        } else {
          showMsg("error", "An unexpected error occurred.");
        }
        // handle result here
      });
  }

  return (
    <>
      <Formik
        initialValues={objInitialValues}
        validationSchema={CreateClientContactByClientIdFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id={"CreateClientContactByClientIdForm"} autoComplete="off">
            <Card sx={{ height: "100%" }}>
              <MDBox>
                <CreateClientContactByClientIdFormFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={objStates}
                />

                {objCreateClientContactByClientIdFormErrors !== undefined &&
                Object.keys(objCreateClientContactByClientIdFormErrors).length >
                  0 ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    pl={5}
                    pr={5}
                    pt={0}
                    pb={5}
                  >
                    <CreateClientContactByClientIdFormErrorsBlock
                      objCreateClientContactByClientIdFormErrors={
                        objCreateClientContactByClientIdFormErrors
                      }
                    />
                  </Grid>
                ) : null}

                <MDBox
                  sx={{ margin: "0 0 40px -40px" }}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {isLoading === false ? (
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="success"
                      sx={{ padding: "12px 82px" }}
                    >
                      Add
                    </MDButton>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Form>
        )}
      </Formik>

      <MDBox mt={3} textAlign="left">
        <Card sx={{ height: "100%", padding: "0.5em", paddingTop: "1em" }}>
          <MDTypography px={5} textAlign="left" variant="h4" fontWeight="bold">
            Import Client Contacts (CSV)
          </MDTypography>
          <MDBox mb={-4}>
            <MDTypography
              px={5}
              textAlign="left"
              variant="h6"
              fontWeight="bold"
              mt={3}
            >
              Format for upload:
            </MDTypography>
            <MDBox
              sx={{ padding: "0 2em 2em", marginTop: "10px", width: "40%" }}
            >
              <img
                style={{ width: "100%" }}
                src={contactUploadFormat}
                alt="Contact upload format"
              />
            </MDBox>
          </MDBox>

          {duplicateContacts ? (
            <MDBox
              sx={{ padding: "0 2em" }}
              mt={2}
              mb={2}
              width="100%"
              color="warning"
            >
              <h6>NOTICES: Duplicate contacts were found during import: </h6>
              <h6>{duplicateContactsList.join(", ")}</h6>
            </MDBox>
          ) : (
            ""
          )}

          <MDBox
            sx={{ padding: "0 2em 2em", marginTop: "0" }}
            width="100%"
            display="flex"
          >
            <MDInput
              type={"file"}
              onChange={(e) => handleOnFileChange(e)}
              accept={".csv"}
              label="CSV File"
              variant={"standard"}
            />
            {isImportLoading == false ? (
              <MDButton
                // variant="gradient"
                color="success"
                sx={{ padding: "12px 68px", marginLeft: "auto" }}
                onClick={() => uploadContactsToAPI()}
              >
                Import
              </MDButton>
            ) : (
              <MDBox sx={{ marginLeft: "auto" }}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
};

export default CreateClientContactByClientIdForm;
