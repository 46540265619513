import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { saveRMFChanges } from "features/company/CompanyConfigActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";

const AddNewCategoryFormJWTSchema = Yup.object().shape({
  txtRMFName: Yup.string().required("The framework name is required."),
});

function EditRMFDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  clientId,
  editRMFDialogData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  //const [initValues, setInitValues] = useState(objInitialValues);
  const objInitialValues = {
    txtRMFId: editRMFDialogData.rmfId,
    txtRMFName: editRMFDialogData.rmfName,
  };

  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtRMFName,
      id: values.txtRMFId,
    };

    setIsLoading(true);
    dispatch(saveRMFChanges({ objPostData, objUser, clientId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "Changes saved successfully");
          onSubmit(response);
        } else {
          throw new Error("error", `Failed to save changes. Server responded with status code: ${response.status}.`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage =
          rejectedValueOrSerializedError?.message ||
          rejectedValueOrSerializedError?.response?.data?.message ||
          `Failed to save changes. Server responded with status code: ${rejectedValueOrSerializedError?.response?.status}.`;
        showMsg("error", errorMessage);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Edit Risk Management Framework</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewCategoryFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="EditRMFForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Risk Management Framework Name"
                  name="txtRMFName"
                  value={values.txtRMFName}
                  placeholder={"Name"}
                  error={errors.txtRMFName && touched.txtRMFName}
                  success={values.txtRMFName.length > 0 && !errors.txtRMFName}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtRMFName" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Save Changes
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default EditRMFDialog;
