import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";

import { useDispatch } from "react-redux";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import {
  Checkbox,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useCookies } from "react-cookie";
import DashboardActionButton from "views/components/DashboardActionButton";
import { fetchAssessmentTemplate } from "features/company/assessmentsActions";
import { getGlobalAssessmentTemplates } from "features/company/superAdminActions";
import { addQuestionsToExistingAssessmentEventsAndTemplates } from "features/company/superAdminActions";
import MDInput from "components/MDInput";
import debounce from "lodash/debounce";
import moment from "moment";
const AssessmentEventFix = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingNewVersionInfo, setIsUpdatingNewVersionInfo] =
    useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [totalCount, setTotalCount] = useState(0);
  const [isFixing, setIsFixing] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [currentCategory, setCurrentCategory] = useState({});
  const [keyword, setKeyword] = useState("");
  const [createdAfter, setCreatedAfter] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedQuestionsIds, setSelectedQuestionsIds] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const loadTemplates = () => {
    setIsLoading(true);
    dispatch(getGlobalAssessmentTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.data.templates.length > 0) {
          response.data.templates = response.data.templates.filter(
            (template) => {
              return template.is_global_template === 1;
            }
          );
          if (response.data.templates.length > 0) {
            setAssessments(response.data.templates);

            setCurrentAssessment(response.data.templates[0]);
            setTotalCount(response.data.templates.length);
            fetchTemplate(response.data.templates[0].id);
          } else {
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load assessment templates. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchTemplate = (template_id) => {
    setIsLoading(true);
    dispatch(fetchAssessmentTemplate({ objUser, templateId: template_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;

        const categories = [
          ...new Set(
            response.data?.template.questions.map((item) => item.category)
          ),
        ];
        setSelectedQuestions([]);
        setCurrentCategory("All");
        setKeyword("");
        setCategories(categories);
        setQuestions(response.data?.template.questions);
        setDisplayedQuestions(response.data?.template.questions);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        showMsg("error", objErrorRsp?.data?.message || "Failed to load assessment template. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addQuestions = (category) => {
    setIsLoading(true);

    let objPostData = {
      assessmentTemplateId: currentAssessment.id,
      newQuestionIdsToAddToExistingQuestions: selectedQuestions.map(
        (question) => question.id
      ),
    };
    dispatch(
      addQuestionsToExistingAssessmentEventsAndTemplates({
        objPostData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        showMsg(
          "success",
          "Updates deployed to all events and templates successfully."
        );
        navigate("/super-admin/toolkit");
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to add questions to assessment events and templates. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (value) => {
    setSelectedQuestions([]);
    setCurrentAssessment(value);
    fetchTemplate(value.id);
  };

  const handleQuestionsCheckBoxToggle = (value) => () => {
    if (selectedQuestionsIds.includes(value.id)) {
      setSelectedQuestions(
        selectedQuestions.filter((item) => item.id !== value.id)
      );
      setSelectedQuestionsIds(
        selectedQuestionsIds.filter((item) => item !== value.id)
      );
    } else {
      setSelectedQuestions([...selectedQuestions, value]);
      setSelectedQuestionsIds([...selectedQuestionsIds, value.id]);
    }
  };

  const handleSelectedQuestionsCheckBoxToggle = (value) => () => {
    if (selectedQuestionsIds.includes(value.id)) {
      setSelectedQuestions(
        selectedQuestions.filter((item) => item.id !== value.id)
      );
      setSelectedQuestionsIds(
        selectedQuestionsIds.filter((item) => item !== value.id)
      );
    }
  };

  const handleCategoryChange = (value) => {
    setCurrentCategory(value);
  };

  const handleKeyWordDelayedChange = useCallback(
    debounce((value) => {
      setKeyword(value);
    }, 1000),
    []
  );

  const handleKeyWordChange = (value) => {
    setKeyword(value);
  };

  const handleCreatedAfterDelayedChange = useCallback(
    debounce((value) => {
      setCreatedAfter(value);
    }, 1500),
    []
  );
  const handleCreatedAfterChange = (value) => {
    handleCreatedAfterDelayedChange(value);
  };

  const filterQuestions = (curQuestions = []) => {
    const filteredQuestions = curQuestions.filter(
      (q) =>
        (q.item.toLowerCase() ?? "").includes(keyword.toLowerCase()) &&
        (currentCategory === "All" || q.category === currentCategory) &&
        (createdAfter === "" ||
          moment(q.created_at ?? "").isAfter(createdAfter))
    );
    return filteredQuestions;
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    if (!objUser.user.email.includes("@gtnllc.com")) {
      navigate(`/super-admin/toolkit`, {
        state: { routeDictionary },
      });
    }
    loadTemplates();
  }, []);

  useEffect(() => {
    if (questions.length == 0) {
      return;
    }

    const curQuestions = [...questions];
    const filteredQuestions = filterQuestions(curQuestions);
    setDisplayedQuestions(filteredQuestions);
  }, [keyword, createdAfter, currentCategory]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      {isLoading ? (
        <Card>
          <LoadingSpinner color="success" size="lg" />
        </Card>
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={12}>
            {assessments.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Select an Assessment Template :
                    </MDTypography>
                  </MDBox>

                  {/* select template */}
                  <Select
                    value={currentAssessment}
                    sx={{
                      minWidth: "150px",
                      marginLeft: "10px",
                      backgroundColor: "white",
                      padding: "8px",
                      borderWidth: "4px",
                    }}
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Select a Template
                    </MenuItem>
                    {assessments.map((event) => (
                      <MenuItem key={event.id} value={event}>
                        {event.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <UnfoldMoreIcon
                    sx={{
                      position: "relative",
                      right: "20px",
                      top: "3.5px",
                      pointerEvents: "none",
                    }}
                  />

                  {/* Filter by Category */}
                  <Select
                    value={currentCategory}
                    // p={1}
                    sx={{
                      minWidth: "150px",
                      marginLeft: "10px",
                      backgroundColor: "white",
                      padding: "8px",
                      borderWidth: "4px",
                    }}
                    onChange={(e) => handleCategoryChange(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Select a Category
                    </MenuItem>
                    <MenuItem key="all" value="All">
                      All Categories
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  <UnfoldMoreIcon
                    sx={{
                      position: "relative",
                      right: "20px",
                      top: "3.5px",
                      pointerEvents: "none",
                    }}
                  />

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={keyword ?? ""}
                    onChange={(e) => handleKeyWordChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />

                  <MDBox sx={{ minWidth: "max-content", marginLeft: "10px" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Created After :
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Created After */}
                  <MDInput
                    type="date"
                    value={createdAfter ?? ""}
                    onChange={(e) => handleCreatedAfterChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{
                      marginLeft: "10px",
                    }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={7}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {displayedQuestions.map((question, i) => {
                    return (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleQuestionsCheckBoxToggle(question)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedQuestionsIds.includes(
                                question.id
                              )}
                              tabIndex={-1}
                              disableRipple
                              disabled={question.duplicate}
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={question.item}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={5}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <MDTypography variant="h6" fontWeight="bold">
                  Selected Questions
                </MDTypography>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 620,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {selectedQuestions.map((question, i) => {
                    return (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleSelectedQuestionsCheckBoxToggle(
                            question
                          )}
                          dense
                        >
                          <ListItemIcon>
                            <Icon sx={{ fontWeight: "bold", color: "red" }}>
                              remove_circle
                            </Icon>
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={question.item}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                {selectedQuestionsIds.length == 0 ? (
                  "No Items selected"
                ) : (
                  <>
                    {isFixing ? (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="sm"
                      />
                    ) : (
                      <DashboardActionButton
                        action={addQuestions}
                        btnText="Deploy updates"
                        btnIcon="build"
                        textColor="white"
                        bgColor="success"
                        sx={{ marginTop: "0px !important" }}
                      />
                    )}
                  </>
                )}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default AssessmentEventFix;
