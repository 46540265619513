import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const ClientProfitCalculator = (props) => {
  const { objUser } = props;

  // client calculator
  const [numberOfClients, setNumberOfClients] = useState(2);
  const [laborHoursFirst, setLaborHoursFirst] = useState(27);
  const [laborHoursSubsequent, setLaborHoursSubsequent] = useState(5.4);
  const [platformCostFirst, setPlatformCostFirst] = useState(null);
  const [platformCostSubsequent, setPlatformCostSubsequent] = useState(null);
  const [MSPHourlyRate, setMSPHourlyRate] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [clientList, setClientList] = useState([
    2, 5, 10, 15, 20, 25, 30, 40, 50, 60,
  ]);
  const [platformCosts, setPlatFormCosts] = useState([
    125, 98, 90, 84, 78, 72, 69, 63, 56, 50, 50, 50, 50, 50,
  ]);

  // client calculator
  const marksClients = clientList.map((number, i) => {
    return {
      value: i,
      label: number,
    };
  });

  return (
    <>
      {/* client profit calculator */}

      <Grid item xs={11}>
        <Card sx={{ height: "100%" }}>
          <MDBox
            className="card-header"
            sx={{
              backgroundColor: "#808080",
              borderTopLeftRadius: "0.75rem",
              borderTopRightRadius: "0.75rem",
            }}
            px={2}
            py={1}
          >
            <MDTypography sx={{ color: "#fff" }} variant="h5" fontWeight="bold">
              Client Profit Calculator
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={12} md={4}>
              <MDBox mb={4} mt={3}>
                <Card
                  className="custom-card custom-product-card hl-border-dark bg-light hl-shadow"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                >
                  <MDBox className="card-header bg-success" px={2} py={1}>
                    <MDTypography
                      sx={{ color: "#FFFFFF" }}
                      variant="h5"
                      fontWeight="bold"
                    >
                      {numberOfClients} Clients
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    mt={2}
                  >
                    <MDBox className="custom-slider hl-border bg-light">
                      <MDBox
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        mb={0}
                        pt={2}
                      >
                        <MDBox className="slider-container">
                          <Slider
                            color="secondary"
                            aria-label="Choose your plan"
                            onChange={(_, value) => {
                              setNumberOfClients(clientList[value]);
                              setPlatformCostFirst(platformCosts[value]);
                              setPlatformCostSubsequent(platformCosts[value]);
                            }}
                            step={null}
                            marks={marksClients}
                            max={marksClients.length - 1}
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox p={2}>
                    <MDInput
                      label="Hourly Rate $"
                      value={MSPHourlyRate}
                      onChange={(e) => {
                        setMSPHourlyRate(e.target.value);
                      }}
                      fullWidth={true}
                      variant={"standard"}
                      sx={{
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                      type="number"
                    />
                    <MDInput
                      label="Profit Margin %"
                      value={profitMargin}
                      onChange={(e) => {
                        setProfitMargin(e.target.value);
                      }}
                      fullWidth={true}
                      variant={"standard"}
                      sx={{
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                      type="number"
                    />
                  </MDBox>

                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Sell it for cost + margin
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontSize="14px">
                            {platformCostFirst && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostFirst *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate
                                ).toLocaleString()}{" "}
                                ($
                                {Math.round(
                                  (platformCostFirst *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate) /
                                    12
                                ).toLocaleString()}
                                /mo)
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={4} mt={3}>
                <Card
                  className="custom-card custom-product-card hl-border-dark bg-light hl-shadow"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                >
                  <MDBox className="card-header bg-success" px={2} py={1}>
                    <MDTypography
                      sx={{ color: "#FFFFFF" }}
                      variant="h5"
                      fontWeight="bold"
                    >
                      First Client
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Labor hours
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>{laborHoursFirst}</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox bgColor="">
                          <MDTypography fontWeight="bold">
                            Labor cost
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox bgColor="">
                          <MDTypography>
                            {MSPHourlyRate
                              ? "$" +
                                Math.round(
                                  laborHoursFirst * MSPHourlyRate
                                ).toLocaleString()
                              : "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Platform cost
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>
                            ${Math.round(platformCostFirst).toLocaleString()}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox bgColor="">
                          <MDTypography fontWeight="bold">
                            Annual Platform cost to MSP
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox bgColor="">
                          <MDTypography>
                            $
                            {Math.round(
                              platformCostFirst * 12
                            ).toLocaleString()}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Total cost
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>
                            {platformCostFirst && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostFirst * 12 +
                                    laborHoursFirst * MSPHourlyRate
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox>
                          <MDTypography fontWeight="bold">
                            Annual Profit
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox>
                          <MDTypography fontWeight="bold">
                            {platformCostFirst && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostFirst *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate -
                                    (platformCostFirst * 12 +
                                      laborHoursFirst * MSPHourlyRate)
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={4} mt={3}>
                <Card
                  className="custom-card custom-product-card hl-border-dark bg-light hl-shadow"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                >
                  <MDBox className="card-header bg-success" px={2} py={1}>
                    <MDTypography
                      sx={{ color: "#FFFFFF" }}
                      variant="h5"
                      qAAAA
                      fontWeight="bold"
                    >
                      Subsequent Clients
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Labor hours
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>{laborHoursSubsequent}</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox bgColor="">
                          <MDTypography fontWeight="bold">
                            Labor cost
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox bgColor="">
                          <MDTypography>
                            {MSPHourlyRate
                              ? "$" +
                                Math.round(
                                  laborHoursSubsequent * MSPHourlyRate
                                ).toLocaleString()
                              : "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Platform cost
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>
                            $
                            {Math.round(
                              platformCostSubsequent
                            ).toLocaleString()}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox bgColor="">
                          <MDTypography fontWeight="bold">
                            Annual Platform cost to MSP/Client
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox bgColor="">
                          <MDTypography>
                            $
                            {Math.round(
                              platformCostSubsequent * 12
                            ).toLocaleString()}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={8}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography fontWeight="bold">
                            Total cost to MSP/Client
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox sx={{ backgroundColor: "#f3f3f3" }}>
                          <MDTypography>
                            {platformCostSubsequent && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostSubsequent * 12 +
                                    laborHoursSubsequent * MSPHourlyRate
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      {/* <Grid item xs={8}>
                        <MDBox bgColor="">
                          <MDTypography fontWeight="bold">
                            Sell it for cost + margin (Monthly)
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox bgColor="">
                          <MDTypography>
                            {platformCostSubsequent && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  (platformCostSubsequent *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate) /
                                    12
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid> */}
                      <Grid item xs={8}>
                        <MDBox>
                          <MDTypography fontWeight="bold">
                            Annual Profit Per client
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox>
                          <MDTypography fontWeight="bold">
                            {platformCostSubsequent && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostSubsequent *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate -
                                    (platformCostSubsequent * 12 +
                                      laborHoursSubsequent * MSPHourlyRate)
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox
                    className="card-body"
                    // pr={2}
                    sx={{ textAlign: "right" }}
                  >
                    <span className="price-per-client">
                      {platformCostFirst && MSPHourlyRate ? (
                        <>
                          <ArrowDropUpIcon
                            color="success"
                            fontSize="large"
                            sx={{ position: "relative", top: "6px" }}
                          />
                          $
                          {Math.round(
                            platformCostSubsequent *
                              12 *
                              (1 + profitMargin / 100) +
                              laborHoursFirst * MSPHourlyRate -
                              (platformCostSubsequent * 12 +
                                laborHoursSubsequent * MSPHourlyRate) -
                              (platformCostFirst *
                                12 *
                                (1 + profitMargin / 100) +
                                laborHoursFirst * MSPHourlyRate -
                                (platformCostFirst * 12 +
                                  laborHoursFirst * MSPHourlyRate))
                          ).toLocaleString()}{" "}
                        </>
                      ) : (
                        "$0"
                      )}{" "}
                    </span>

                    <span style={{ color: "green" }}>
                      {platformCostFirst && MSPHourlyRate ? (
                        <>
                          {/* <ArrowDropUpIcon
                            color="success"
                            sx={{ position: "relative", top: "2px" }}
                          /> */}
                          {"("}
                          {(
                            ((platformCostSubsequent *
                              12 *
                              (1 + profitMargin / 100) +
                              laborHoursFirst * MSPHourlyRate -
                              (platformCostSubsequent * 12 +
                                laborHoursSubsequent * MSPHourlyRate)) /
                              (platformCostFirst *
                                12 *
                                (1 + profitMargin / 100) +
                                laborHoursFirst * MSPHourlyRate -
                                (platformCostFirst * 12 +
                                  laborHoursFirst * MSPHourlyRate))) *
                            100
                          ).toFixed(2)}
                          {"%)"}
                        </>
                      ) : (
                        "0%"
                      )}
                      {""}
                    </span>
                    <span className="price-client-label">
                      {" "}
                      Profit on subsequent clients
                    </span>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDBox
                          mt={2}
                          sx={{
                            backgroundColor: "#f3f3f3",
                            textAlign: "left",
                          }}
                        >
                          <MDTypography fontWeight="bold">
                            Total Annual Profit for {numberOfClients} Clients
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox
                          mt={2}
                          sx={{
                            backgroundColor: "#f3f3f3",
                            textAlign: "left",
                          }}
                        >
                          <MDTypography fontWeight="bold">
                            {platformCostSubsequent && MSPHourlyRate ? (
                              <>
                                $
                                {Math.round(
                                  platformCostFirst *
                                    12 *
                                    (1 + profitMargin / 100) +
                                    laborHoursFirst * MSPHourlyRate -
                                    (platformCostFirst * 12 +
                                      laborHoursFirst * MSPHourlyRate) +
                                    (platformCostSubsequent *
                                      12 *
                                      (1 + profitMargin / 100) +
                                      laborHoursFirst * MSPHourlyRate -
                                      (platformCostSubsequent * 12 +
                                        laborHoursSubsequent * MSPHourlyRate)) *
                                      (numberOfClients - 1)
                                ).toLocaleString()}
                              </>
                            ) : (
                              "-"
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default ClientProfitCalculator;
