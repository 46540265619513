import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPDF } from "features/ssp/sspActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function useWispPDFDownloader() {
  const dispatch = useDispatch();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const downloadPDF = (objUser = null, sspId, params) => {
    setIsLoadingPDF(true);
    dispatch(getPDF({ objUser, sspId, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const exportType = params?.exportType ?? "pdf";

          // Check if the export type is PDF
          if (exportType === "pdf") {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${sspId}.pdf`);
            document.body.appendChild(link);
            link.click();
          } else if (exportType === "word") {
            // Handle docx URL download
            const fileUrl = response.data?.fileUrl; // Assuming response.data contains the file URL
            if (fileUrl) {
              let link = document.createElement("a");
              link.href = fileUrl;
              link.setAttribute("download", `${sspId}.docx`);
              document.body.appendChild(link);
              link.click();
            } else {
              showMsg("error", "No download URL for the word file");
            }
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPDF(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      });
  };

  return { downloadPDF, isLoadingPDF };
}

export default useWispPDFDownloader;
