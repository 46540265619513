// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import {
  saveApiKeys,
  getConnectorApiKeys,
  getConnectorClients,
  saveClientMapping,
  removeClientMapping,
  removeApiKeys,
} from "features/huntress/actions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import SaveApiKeysFormErrorsBlock from "./SaveApiKeysFormErrorsBlock";
import SaveApiKeysFormFormikWrapper from "./SaveApiKeysFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { Box, textAlign } from "@mui/system";
import assetImageLogo from "assets/images/asset-preview.png";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RouteIcon from "@mui/icons-material/Route";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, TextField, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";

const SaveApiKeysFormJWTSchema = Yup.object().shape({
  client_id: Yup.string().required("Client ID is required"),
  secret: Yup.string().required("Client Secret is required"),
});

const connector = "Huntress";

const SaveApiKeysForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [isFetchingClients, setIsFetchingClients] = useState(false);
  const [isMappingClient, setIsMappingClient] = useState("");
  const [isRemovingMapping, setIsRemovingMapping] = useState("");
  const company_id = props.objUser.user.company.id;

  const [objInitialValues, setObjInitialValues] = useState({
    client_id: "",
    secret: "",
  });
  const [openPolygonClientsDialog, setOpenPolygonClientsDialog] =
    useState(false);
  const [clientsResponseData, setClientsResponseData] = useState([]);
  const [clientsResponseDataBackup, setClientsResponseDataBackup] = useState(
    []
  );
  const [existingPolygonClients, setExistingPolygonClients] = useState([]);
  const [existingPolygonMappings, setExistingPolygonMappings] = useState([]);
  const [currentMappings, setCurrentMappings] = useState([]);
  const [selectedConnectorClientToMap, setSelectedConnectorClientToMap] =
    useState(null);

  const confirm = useConfirm();

  //clients search
  const [searchPhrase, setSearchPhrase] = useState("");

  const [objSaveApiKeysFormErrors, setObjSaveApiKeysFormErrors] = useState({});
  const onSubmitForm = async (values) => {
    setObjSaveApiKeysFormErrors({});
    setIsLoading(true);
    dispatch(saveApiKeys({ values, company_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 200) {
          let _objInitialValues = {
            client_id: response.data.client_id ?? "",
            secret: response.data.secret ?? "",
          };
          setObjInitialValues(_objInitialValues);
          setClientsResponseData([]);
          fetchConnectorClients();
          showMsg(
            "success",
            "Your " + connector + " Api Keys have been successfully saved."
          );
          // navigate("/clients");
        } else {
          console.error(connector + " API Keys:", response);
          showMsg("error", `Failed to save ${connector} API keys. Server responded with status: ${response.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.error(connector + " API Keys:", rejectedValueOrSerializedError);
        const objErrors = rejectedValueOrSerializedError.response.errors;
        if (typeof objErrors !== "undefined" && objErrors !== null) {
          setObjSaveApiKeysFormErrors(objErrors);
          showMsg("error", "Please correct the errors.");
        } else {
          showMsg("error", "Failed to save Huntress API keys. Please check the provided API keys and try again.");
        }
        // handle result here
      });
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    dispatch(getConnectorApiKeys())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        let _objInitialValues = {
          client_id: response.data.client_id ?? "",
          secret: response.data.secret ?? "",
        };
        setObjInitialValues(_objInitialValues);
        setIsLoadingKeys(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
      });
  };

  /**Also fetches current list of polygon clients */
  const fetchConnectorClients = async () => {
    setIsFetchingClients(true);
    dispatch(getConnectorClients())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const clientsResponseData = response.data.clientsToMap;
        const polygonClients = response.data.polygonClients;
        //response.data.clientsToMap.total;

        setExistingPolygonClients(polygonClients);

        const existingMappings = polygonClients.map((client) => {
          return client.huntress_mapping
            ? client.huntress_mapping.connector_client_id
            : "Not mapped";
        });

        setExistingPolygonMappings(existingMappings);

        if (clientsResponseData.length > 0) {
          const sortedClientResponseData = clientsResponseData.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
          setClientsResponseData(sortedClientResponseData);
          setClientsResponseDataBackup(sortedClientResponseData);
        } else {
          showMsg("info", "No companies were found");
        }

        setIsFetchingClients(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingClients(false);
        console.error(rejectedValueOrSerializedError);
        showMsg("error", "Error fetching companies from " + connector);
      });
  };

  const alreadyMappedClientName = (ConnectorClientId) => {
    const polyClient = existingPolygonClients.find((client) => {
      return client?.huntress_mapping?.connector_client_id == ConnectorClientId;
    });
    return polyClient.name;
  };

  //connector client search
  const handleConnectorClientSearch = (searchValue) => {
    setSearchPhrase(searchValue);

    if (searchValue.trim() === "") {
      setClientsResponseData(clientsResponseDataBackup);
    } else {
      const filterResults = clientsResponseDataBackup.filter((item) => {
        return item.Name.toLowerCase().includes(searchValue.toLowerCase());
      });
      setClientsResponseData(
        filterResults.length === 0 ? clientsResponseDataBackup : filterResults
      );
    }
  };

  const mapSelectedClient = async (existingC) => {
    setIsMappingClient(existingC.id);
    dispatch(saveClientMapping({ existingC, selectedConnectorClientToMap }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        showMsg(
          "success",
          "Mapping succesfully completed. Refreshing clients list"
        );
        setCurrentMappings((prev) => {
          return [
            ...prev,
            {
              existingC,
              selectedConnectorClientToMap,
            },
          ];
        });
        setIsMappingClient("");
        setClientsResponseData([]);
        fetchConnectorClients();
        onClose();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsMappingClient("");
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error mapping client");
        }
      });
  };

  const removeExistingMapping = async (extClient) => {
    setIsRemovingMapping(extClient.id);
    console.log(extClient);
    dispatch(removeClientMapping({ extClient }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsRemovingMapping("");
        showMsg(
          "success",
          "Mapping succesfully removed. Refreshing clients list."
        );
        setClientsResponseData([]);
        fetchConnectorClients();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsRemovingMapping("");
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error removing current mapping");
        }
      });
  };

  const openDialog = (cnctorClient) => {
    setSelectedConnectorClientToMap(cnctorClient);
    setOpenPolygonClientsDialog(true);
  };
  const onClose = () => {
    setOpenPolygonClientsDialog(false);
  };

  const formatCreatedAt = (timestamp) => {
    // Convert the timestamp from seconds to milliseconds
    return moment(timestamp).format("MMMM Do YYYY");
  };

  const disconnectConnection = async () => {
    setIsLoadingKeys(true);
    dispatch(removeApiKeys())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingKeys(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 200) {
          let _objInitialValues = {
            client_id: response.data.client_id ?? "",
            secret: response.data.secret ?? "",
          };
          setObjInitialValues(_objInitialValues);
          showMsg(
            "success",
            "Your " + connector + " connection have been successfully removed."
          );
        } else {
          showMsg(
            "error",
            `Failed to remove ${connector} API keys. Server responded with status: ${response.status}`
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
        console.log(rejectedValueOrSerializedError);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to remove Huntress API keys. Please try again.";
        showMsg("error", errorMessage);
        // handle result here
      });
  };

  const btnOnClickDisconnect = () => {
    confirm({
      description: "Your " + connector + " API keys will be removed.",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        disconnectConnection();
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      {/* List of polygon clients */}
      <Dialog
        open={openPolygonClientsDialog}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle sx={{ pb: 0 }}>
          Your current list of clients in Compliance Scorecard
        </DialogTitle>
        <DialogContent>
          <MDTypography
            textAlign="left"
            variant="p"
            color="success"
            sx={{ fontSize: "1rem" }}
          >
            What existing client will you like to map to
            {selectedConnectorClientToMap
              ? " " + selectedConnectorClientToMap?.name + " company"
              : " the selected company from " + connector}
            ?
          </MDTypography>
          <TableContainer
            sx={{
              padding: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Table mb={4} sx={{ color: "text.main" }}>
              <TableRow>
                <TableCell>
                  <b>Action</b>
                </TableCell>
                <TableCell>
                  <b>ID</b>
                </TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Address</b>
                </TableCell>
                <TableCell>
                  <b>Zip</b>
                </TableCell>
              </TableRow>
              <TableBody>
                {existingPolygonClients.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={3}>
                      You haven't added any clients yet
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {existingPolygonClients.map((existingC, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "& td, & th": {
                              color: "text.main",
                            },
                          }}
                        >
                          <TableCell>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                              onClick={() => mapSelectedClient(existingC)}
                              sx={{ padding: "12px 20px" }}
                            >
                              {isMappingClient == existingC.id ? (
                                <LoadingSpinner
                                  subClass="text-center"
                                  size="sm"
                                />
                              ) : (
                                " Select"
                              )}
                            </MDButton>
                          </TableCell>
                          <TableCell>{existingC.id}</TableCell>
                          <TableCell>{existingC.name}</TableCell>
                          <TableCell>
                            {existingC.address_1 ? existingC.address_1 : "-"},
                            {existingC?.city}, {existingC?.state}
                          </TableCell>
                          <TableCell>{existingC.zip}</TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Grid item xs={12} lg={4}>
        {isLoadingKeys === false ? (
          <Formik
            initialValues={objInitialValues}
            validationSchema={SaveApiKeysFormJWTSchema}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={"SaveApiKeysForm"} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox>
                    <SaveApiKeysFormFormikWrapper
                      connector={connector}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />

                    {Object.keys(objSaveApiKeysFormErrors).length > 0 ? (
                      <Grid
                        container
                        justifyContent="space-between"
                        pl={5}
                        pr={5}
                        pt={0}
                        pb={5}
                      >
                        <SaveApiKeysFormErrorsBlock
                          objSaveApiKeysFormErrors={objSaveApiKeysFormErrors}
                        />
                      </Grid>
                    ) : null}

                    <MDBox
                      sx={{ margin: "0 0 40px -40px" }}
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoading === false ? (
                        <>
                          {objInitialValues.client_id && (
                            <MDButton
                              type="button"
                              // variant="gradient"
                              color="error"
                              sx={{ padding: "12px 20px", margin: "0px 5px" }}
                              onClick={btnOnClickDisconnect}
                            >
                              Disconnect
                            </MDButton>
                          )}

                          <MDButton
                            type="submit"
                            color="success"
                            sx={{ padding: "12px 20px", margin: "0px 5px" }}
                          >
                            Save
                          </MDButton>
                        </>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </Card>
                {/* </MDBox> */}
              </Form>
            )}
          </Formik>
        ) : (
          <MDBox>
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
            <MDTypography sx={{ textAlign: "center" }}>
              Loading Keys
            </MDTypography>
          </MDBox>
        )}
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.5rem",
          }}
        >
          {clientsResponseData.length == 0 ? (
            <>
              {/* preview assets image and button */}
              <MDBox mb={2}>
                <img
                  src={assetImageLogo}
                  width="90px"
                  // style={{ filter: "grayscale(100%)" }}
                />
              </MDBox>
              <MDBox>
                <MDButton
                  type="submit"
                  // variant="gradient"
                  color="success"
                  disabled={isLoadingKeys}
                  onClick={fetchConnectorClients}
                  sx={{ padding: "12px 20px", margin: "0px 5px" }}
                >
                  Fetch {connector} Companies To Map
                </MDButton>
                <Tooltip
                  title={`Click this button to fetch your companies from ${connector} and link them to their corresponding companies in Compliance Scorecard.`}
                >
                  <IconButton sx={{ opacity: "0.4" }}>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
              {isFetchingClients ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                ""
              )}
              {/* preview assets image and button end*/}
            </>
          ) : (
            <>
              <MDBox
                bgColor="success"
                borderRadius="lg"
                mb={1}
                sx={{ width: "100%", padding: "0.5rem" }}
                textAlign="center"
              >
                <MDTypography variant="h6" color="white">
                  List of companies from {connector} API
                </MDTypography>
              </MDBox>
              {/* search clients */}
              <MDBox borderRadius="lg" mb={2} mt={2} sx={{ width: "100%" }}>
                <TextField
                  type="text"
                  label="Search for an company"
                  value={searchPhrase}
                  sx={{ width: "40%" }}
                  placeholder={"Start typing to search"}
                  onChange={(e) => handleConnectorClientSearch(e.target.value)}
                />
              </MDBox>
              <TableContainer sx={{ padding: "1rem" }}>
                <Table>
                  <TableRow>
                    <TableCell>
                      <b>Action</b>
                    </TableCell>
                    <TableCell>
                      <b>ID</b>
                    </TableCell>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Created On</b>
                    </TableCell>
                    {/*<TableCell>
                      <b>Is Migrated</b>
                    </TableCell>
                    */}
                  </TableRow>

                  <TableBody>
                    {clientsResponseData.map((extClient, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {existingPolygonMappings.includes(extClient.id) ? (
                              <MDButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                onClick={() => removeExistingMapping(extClient)}
                                sx={{ padding: "12px 20px" }}
                              >
                                {isRemovingMapping == extClient.id ? (
                                  <LoadingSpinner
                                    subClass="text-center"
                                    size="sm"
                                  />
                                ) : (
                                  "  Remove current mapping"
                                )}
                              </MDButton>
                            ) : (
                              <MDButton
                                type="submit"
                                variant="gradient"
                                color="dark"
                                onClick={() => openDialog(extClient)}
                                sx={{ padding: "12px 20px" }}
                              >
                                Choose client to map
                              </MDButton>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {extClient.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {extClient.name ? extClient?.name : "-"}
                            {existingPolygonMappings.includes(extClient.id) ? (
                              <p>
                                <CheckCircleIcon color="success" />
                                <i>
                                  Mapped to{" "}
                                  <u>
                                    {" "}
                                    {alreadyMappedClientName(extClient.id)}
                                  </u>
                                </i>
                              </p>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {extClient.createdAt
                              ? formatCreatedAt(extClient?.createdAt)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default SaveApiKeysForm;
