// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { showMsg } from "utils/general";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;

  const formatSharepointurl = (url) => {
    if(url !== "" && url !== undefined){
      if (!url.includes(".sharepoint.com/sites/")) {
        showMsg("error","Invalid SharePoint URL format.");
        return "";
      }
      let parts = url.split("/");

      // Count slashes by checking array length (subtracting protocol part)
      let slashCount = parts.length - 1;

      if (slashCount >= 5) {
          // Keep only the first 5 parts and append "api/fit"
          return parts.slice(0, 5).join("/") + "/_api/site/id";
      } else if (slashCount === 4) {
          let cleanUrl = url.split("?")[0]; // Remove query params if present
          return cleanUrl + "/_api/site/id";
      }else {
        showMsg("error","Invalid SharePoint URL format.");
        return "";
      }
    }
    return "";
  }

  const handleRedirect = () => {
    const updatedUrl = formatSharepointurl(values.site_url);

    if(updatedUrl !== undefined && updatedUrl !== ""){
      if (updatedUrl.startsWith("https://")) {
        window.open(updatedUrl, "_blank");
      }
    }

  };
  //console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDBox display="flex">
          <MDTypography
            px={5}
            textAlign="left"
            mb={1}
            variant="h5"
            fontWeight="bold"
            sx={{ paddingRight: "0px" }}
          >
            Configure SharePoint Evidence Locker
          </MDTypography>
        </MDBox>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your SharePoint Site Ids"
                name="site_id"
                value={values.site_id}
                placeholder={"SharePoint Site Id"}
                error={errors.site_id && touched.site_id}
                success={values.site_id.length > 0 && !errors.site_id}
              />
            </Grid>
            <Grid item xs={10} md={10} spacing={3}>
              <FormField
                type="text"
                label="Enter Your SharePoint URL To View Your ID"
                name="site_url"
                value={values.site_url}
                placeholder={"SharePoint Site URL"}
                error={errors.site_url && touched.site_url}

                // success={values.site_url.length > 0 && !errors.site_url}
              />

            </Grid>
            <Grid item xs={2} marginBlockEnd={2} spacing={2}>
              <MDButton
                onClick={handleRedirect}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color="success"
                sx={{ padding: "12px 20px", margin: "0px 5px" }}
              >
                  Get ID
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
