import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment, useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Define supported regions
const NINJA_REGIONS = [
  {
    code: "US",
    name: "United States",
    domain: "app.ninjarmm.com",
    apiUrl: "https://app.ninjarmm.com",
  },
  {
    code: "CA",
    name: "Canada",
    domain: "ca.ninjarmm.com",
    apiUrl: "https://ca.ninjarmm.com",
  },
  {
    code: "EU",
    name: "European Union",
    domain: "eu.ninjarmm.com",
    apiUrl: "https://eu.ninjarmm.com",
  },
  {
    code: "AU",
    name: "Australia",
    domain: "au.ninjarmm.com",
    apiUrl: "https://au.ninjarmm.com",
  },
  {
    code: "UK",
    name: "United Kingdom",
    domain: "uk.ninjarmm.com",
    apiUrl: "https://uk.ninjarmm.com",
  },
  {
    code: "DE",
    name: "Germany",
    domain: "de.ninjarmm.com",
    apiUrl: "https://de.ninjarmm.com",
  },
  {
    code: "CUSTOM",
    name: "Custom Domain",
    domain: "",
    apiUrl: "",
  },
];

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, connector, errors, touched, setFieldValue } = props;
  const [showCustomDomain, setShowCustomDomain] = useState(false);
  const [customDomain, setCustomDomain] = useState("");

  // Initialize region based on saved URL
  useEffect(() => {
    if (values.url) {
      const savedUrl = values.url.toLowerCase();
      const foundRegion = NINJA_REGIONS.find(
        (region) =>
          region.apiUrl && savedUrl.includes(region.domain.toLowerCase())
      );

      if (foundRegion) {
        setFieldValue("region", foundRegion.code);
        setShowCustomDomain(false);
      } else {
        // Handle custom domain case
        setFieldValue("region", "CUSTOM");
        setShowCustomDomain(true);
        // Extract domain from URL (remove https://)
        const domain = savedUrl.replace(/^https?:\/\//, "");
        setCustomDomain(domain);
      }
    } else {
      // Default to US if no URL is set
      setFieldValue("region", NINJA_REGIONS[0].code);
      setFieldValue("api_url", NINJA_REGIONS[0].apiUrl);
    }
  }, [values.url, setFieldValue]);

  const handleRegionChange = (event, newValue) => {
    if (newValue) {
      const regionCode = newValue.code;
      setFieldValue("region", regionCode);

      if (regionCode === "CUSTOM") {
        setShowCustomDomain(true);
        // Keep existing custom domain if switching back to custom
        setFieldValue("api_url", customDomain ? `https://${customDomain}` : "");
      } else {
        setShowCustomDomain(false);
        const selectedRegion = NINJA_REGIONS.find((r) => r.code === regionCode);
        if (selectedRegion) {
          setFieldValue("api_url", selectedRegion.apiUrl);
        }
      }
    }
  };

  const handleCustomDomainChange = (event) => {
    const domain = event.target.value;
    setCustomDomain(domain);
    // Only update api_url if domain is not empty
    if (domain) {
      const formattedDomain = domain.startsWith("http")
        ? domain
        : `https://${domain}`;
      setFieldValue("api_url", formattedDomain);
    } else {
      setFieldValue("api_url", "");
    }
  };

  // Find the current region object based on the selected code
  const currentRegion =
    NINJA_REGIONS.find(
      (r) => r.code === (values.region || NINJA_REGIONS[0].code)
    ) || null;

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Add Your {connector} API Keys
        </MDTypography>
        <MDBox mt={2}>
          <Grid item container xs={12} justifyContent="space-between" px={5}>
            <MDBox mb={2}>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                <a
                  target="_blank"
                  href="https://scribehow.com/shared/Add_Compliance_Scorecard_API_in_NinjaRMM__GX-WrvhkQtyRKFoaN77bcw"
                  rel="noopener noreferrer"
                >
                  How to get your ClientID and Client Secret
                </a>
              </Alert>
            </MDBox>

            {/* Region Selection */}
            <Grid item xs={12} md={12} mb={2}>
              <Autocomplete
                disableClearable
                options={NINJA_REGIONS}
                getOptionLabel={(option) =>
                  `${option.name}${option.domain ? ` (${option.domain})` : ""}`
                }
                value={currentRegion}
                onChange={handleRegionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="NinjaOne Region"
                    variant="outlined"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.code === value.code
                }
              />
            </Grid>

            {/* Custom Domain Input */}
            {showCustomDomain && (
              <Grid item xs={12} md={12} spacing={3} mb={2}>
                <FormField
                  type="text"
                  label="Enter Custom NinjaOne Domain"
                  name="custom_domain"
                  value={customDomain}
                  onChange={handleCustomDomainChange}
                  placeholder="e.g., eu.ninjarmm.com or https://eu.ninjarmm.com"
                  error={errors.custom_domain && touched.custom_domain}
                />
              </Grid>
            )}

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Client ID"
                name="client_id"
                value={values.client_id}
                placeholder={"Access key"}
                error={errors.client_id && touched.client_id}
                success={values.client_id.length > 0 && !errors.client_id}
              />
            </Grid>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your Client Secret"
                name="client_secrete"
                value={values.client_secrete}
                placeholder={"client_secrete"}
                error={errors.client_secrete && touched.client_secrete}
                success={
                  values.client_secrete.length > 0 && !errors.client_secrete
                }
              />
            </Grid>

            {/* Hidden field for API URL */}
            <input type="hidden" name="api_url" value={values.api_url || ""} />
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
