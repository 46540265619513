/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { useMemo } from "react";
import EssentialProviders from "context/essentialProviders";
import { useMaterialUIController } from "context";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";

import DT from "datatables.net-dt";
import DataTable from "datatables.net-react";
import "datatables.net-responsive-dt";
import "datatables.net-select-dt";

import "views/components/DataTablesStyle/datatables.css";

DataTable.use(DT);

function PoliciesDatatable({ table, objUser, NameBtnComponentFunction, ActionBtnComponentFunction }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        name: "title",
        data: "title",
        title: "Title",
        width: "25%",
      },
      {
        name: "category",
        data: "category",
        title: "Category",
        width: "15%",
      },
      {
        name: "current_rmfs",
        data: "current_rmfs",
        title: "Frameworks",
        width: "15%",
      },
      {
        name: "date_created",
        data: "date_created",
        title: "Date created",
        width: "30%",
      },
      {
        name: "creator_name",
        data: "creator_name",
        title: "Created By",
      },
      {
        name: "status",
        data: "status",
        title: "Status",
      },
    ];

    if (
      objUser.user.role.name === "company_user" ||
      objUser.user.role.name === "admin" ||
      (objUser.user.role.name === "client_user" &&
        objUser.user.company.can_download_pdfs === "yes")
    ) {
      return [
        ...baseColumns,
        {
          name: "action",
          data: "element",
          title: "Action",
          orderable: false,
        },
      ];
    }

    return baseColumns;
  }, [objUser.user.role.name, objUser.user.company]);

  const data = useMemo(() => table.rows, [table]);

  const datatableOptions = {
    responsive: true,
    stripeClasses: darkMode ? [] : ["odd", "even"],
  };

  const slots = {
    title: (data, row) => (
      <EssentialProviders materialUIController={controller}>
        {NameBtnComponentFunction(data,row)}
      </EssentialProviders>
    ),
    current_rmfs: (data, row) => (
      <EssentialProviders materialUIController={controller}>
        <Tooltip title={data} placement="bottom">
          <span>{data.length > 20 ? data.slice(0, 20) + "..." : data}</span>
          <IconButton
            sx={{ opacity: "0.4", fontSize: "1.2rem" }}
            pb={2}
          ></IconButton>
        </Tooltip>
      </EssentialProviders>
    ),
    action: (data, row) => (
      <EssentialProviders materialUIController={controller}>
        {ActionBtnComponentFunction(data,row)}
      </EssentialProviders>
    ),
  };

  return (
    <DataTable
      data={data}
      columns={columns}
      slots={slots}
      options={datatableOptions}
      className="display"
    />
  );
}

export default PoliciesDatatable;
