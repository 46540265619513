// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { deleteRiskMatrixItem } from "features/company/assessmentsActions";
const RiskMatrixItemDeleteBtnTr = (props) => {
  const { objUser, itemId, eventId, onDelete } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const riskMatrixItemId = itemId;

  const btnOnClickDeleteEvent = async () => {
    confirm({
      description: "Do you want to delete this item?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteItem();
      })
      .catch(() => {
        /* ... */
      });
  };

  const deleteItem = () => {
    setIsLoading(true);
    dispatch(deleteRiskMatrixItem({ objUser, riskMatrixItemId, eventId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        showMsg("success", "Item deleted successfully");
        onDelete();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
       const errorMessage =
         rejectedValueOrSerializedError.response?.data?.message ||
         "An unexpected error occurred. Try again.";
       showMsg("error", errorMessage);
       console.log(
         "rejectedValueOrSerializedError",
         rejectedValueOrSerializedError
       );
      });
  };

  return (
    <Fragment>
      {!isLoading ? (
        <MDButton
          onClick={btnOnClickDeleteEvent}
          variant="gradient"
          disabled={
            objUser.user.role.name != "company_user" &&
            objUser.user.role.name != "admin"
          }
          color="error"
        >
          <MDBox color="white" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="white"
              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            >
              Delete
            </MDTypography>
          </MDBox>
        </MDButton>
      ) : (
        <LoadingSpinner subClass="text-center" color="error" size="lg" />
      )}
    </Fragment>
  );
};

export default RiskMatrixItemDeleteBtnTr;
