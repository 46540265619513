/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { useCookies } from "react-cookie";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "views/components/Breadcrumbs";
import NotificationItem from "views/components/Items/NotificationItem";
import PostDefaultLogo from "assets/images/icons/logo-header.png";

// Custom styles for DashboardBreadcrumbs
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarMobileMenu,
  navbarRow,
} from "views/components/Navbars/DashboardBreadcrumbs/styles";

import { fetchAutoLogoutTimer } from "../../../../features/company/CompanyConfigActions";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
  setDarkMode,
} from "context";

import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth0 } from "@auth0/auth0-react";

import { getNewVersionInfo } from "features/company/clientActions";
import DOMPurify from "dompurify";

const IdlePopup = ({ open, onClose, inactiveLimit, MSPLogoutTime }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent sx={{ padding: "20px", pl: 0, maxWidth: "450px" }}>
        <Card sx={{ boxShadow: 0 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              sx={{ height: "100px", margin: "auto" }}
              image={PostDefaultLogo}
              alt=""
            />
            <CardContent sx={{ pr: 0, pb: 0 }}>
              <MDTypography gutterBottom variant="h4" component="div">
                Are you still here?
              </MDTypography>
              <MDTypography variant="body2" color="secondary">
                This tab has been idle for about{" "}
                {(MSPLogoutTime / 60000).toFixed(1)} minutes and for security
                reasons, you will be auto-logged in {inactiveLimit}
                {inactiveLimit > 1 ? " minutes" : " minute"}
              </MDTypography>
              <MDTypography
                variant="body2"
                textAlign="right"
                sx={{ marginTop: "25px", fontSize: "13px", color: "#38b54a" }}
              >
                *Perform any action to stay logged in
              </MDTypography>
            </CardContent>
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

function DashboardBreadcrumbs({ absolute, light, isMini, title }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const dispatching = useDispatch();

  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const route = location.pathname.split("/").slice(1);
  const routeDictionary = location.state?.routeDictionary ?? {};
  const [isIdle, setIsIdle] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [logOutTimer, setLogOutTimer] = useState();
  const [inactiveLimit, setInactiveLimit] = useState(5);
  const [MSPLogoutTime, setMSPLogoutTime] = useState(120000);
  const [cookies, setCookie] = useCookies(["isNewVersion"]);
  const [newVersionMessage, setNewVersionMessage] = useState("");
  const [newVersionButtonText, setNewVersionButtonText] = useState("");
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));
  const { logout } = useAuth0();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const btnOnClickLogout = () => {
    let login_type = localStorage.getItem("login_type");

    localStorage.removeItem("token");
    localStorage.removeItem("is_remember_me");
    localStorage.removeItem("login_type");
    localStorage.clear();

    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      localOnly: false,
    });
  };

  // function to check for inactivity state
  const inactivityTime = (MSPTime) => {
    let timeId;
    let timeCount;
    window.addEventListener("load", resetTimer);
    document.addEventListener("click", resetTimer);
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    function logout() {
      setIsIdle(true);
      let count = 0;
      timeCount = setInterval(() => {
        count++;
        setInactiveLimit(6 - count);
        if (count > 5) {
          clearInterval(timeCount);
          btnOnClickLogout();
        }
      }, 60000);
    }

    function resetTimer() {
      clearTimeout(timeCount);
      if (!logOutTimer) {
        setIsIdle(false);
        clearTimeout(timeId);
        timeId = setTimeout(logout, MSPTime);
        setLogOutTimer(timeId);
      }
    }
  };

  const loadAutoLogoutTime = async () => {
    dispatching(fetchAutoLogoutTimer({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setMSPLogoutTime(parseInt(response.data));
        inactivityTime(response.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const loadNewVersionInfo = async () => {
    dispatching(getNewVersionInfo())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setNewVersionMessage(
          response.data.message.value ||
            `New Version (${process.env.REACT_APP_APP_VERSION}) released, please refresh to get latest features.`
        );
        setNewVersionButtonText(response.data.btnText.value);
        setIsNewVersion(true);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError.response);
      });
  };

  useEffect(() => {
    if (objUser?.user.email_verified_at && isIdle) {
      inactivityTime(MSPLogoutTime);
    }
  }, [isIdle]);


  useEffect(() => {
    let isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (isDarkMode === null || isDarkMode === undefined) {
      localStorage.setItem("darkMode", false);
    } else {
      setDarkMode(dispatch, isDarkMode);
    }
    loadAutoLogoutTime();
    const buttonElement = document.getElementById("countButton");
    buttonElement.click();
    setNavbarType("static");

  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (process.env.REACT_APP_APP_VERSION) {
      if (
        !cookies.isNewVersion ||
        cookies.isNewVersion != process.env.REACT_APP_APP_VERSION
      ) {
        loadNewVersionInfo();
      }
    }
  }, [isNewVersion]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },

    cursor: () => {
      return "pointer";
    },
  });

  const refreshForVersion = () => {
    setCookie("isNewVersion", process.env.REACT_APP_APP_VERSION, {
      path: "/",
    });
    window.location.reload();
  };

  const sanitizedNewVersionText = (newVersionMessage) => {
    return DOMPurify.sanitize(newVersionMessage ?? "");
  };

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {isMini ? null : (

                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                
          )}
            
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_closed" : "menu_open"}
              </Icon>
            </IconButton>
            <Breadcrumbs
              icon="home"
              title={
                title.length > 0
                  ? title
                  : routeDictionary[route[route.length - 1]] !== undefined
                  ? routeDictionary[route[route.length - 1]]
                  : route[route.length - 1]
              }
              route={route}
              light={light}
            />
            
            {isIdle && (
              <IdlePopup
                inactiveLimit={inactiveLimit}
                MSPLogoutTime={MSPLogoutTime}
              />
            )}
            {/* <IdlePopup /> */}
            <button id="countButton" style={{ display: "none" }}></button>
          </MDBox>
        </Toolbar>
        {isNewVersion && (
          <MDBox
            display="flex"
            bgColor="warning"
            p={0.2}
            mx="0"
            gap={1}
            alignItems="center"
            mb={1}
            mt={1}
            borderRadius={4}
          >
            <ReportProblemIcon
              color="white"
              display="block"
              fontSize="small"
              style={{ marginLeft: "8px" }}
            />
            <MDBox
              sx={{ flexGrow: "1"}}
              bgColor={darkMode ? "dark" : "white"}
              display="flex"
              justifyContent="space-between"
              borderRadius={4}
              pr={2}
            >
              <MDTypography
                fontSize="13px"
                dangerouslySetInnerHTML={{
                  __html: sanitizedNewVersionText(newVersionMessage),
                }}
              ></MDTypography>
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                size="small"
                onClick={refreshForVersion}
              >
                {newVersionButtonText ? newVersionButtonText : "Refresh"}
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardBreadcrumbs
DashboardBreadcrumbs.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  title: "",
};

// Typechecking props for the DashboardBreadcrumbs
DashboardBreadcrumbs.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
};

export default DashboardBreadcrumbs;
