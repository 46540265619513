import { useEffect, useState, useRef, useMemo } from "react";
import { Form, Formik } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  sendDraftAutosave,
  sendSectionDraftAutosave,
  getPolicyDraftDocumentPDF,
  policyDocumentUpdateState,
  getPolicyVersion,
  getSectionVersion,
  markDocumentSectionAsComplete,
  markAllDocumentSectionsAsComplete,
} from "features/company/policiesActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { useIdleTimer } from "react-idle-timer";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import CircularProgress from "@mui/material/CircularProgress";
import CreatePolicyDocumentFormFormikWrapper from "../CreatePolicyDocumentStepDraftFormikWrapper/CreatePolicyDocumentStepDraftFormikWrapper";
import SummaryOfChangesDialog from "../../SummaryOfChangesDialog";
import usePreviewer from "../../usePreviewer";
import PreviewDocDialog from "../../PreviewDocDialog";
import "./CreatePolicyDocumentStepDraft.css";

const _objInitialValues = {
  policy_name: "",
  title: "",
  category: "",
  version: "",
  document_creator: "",
  status: "",
  state: "",
  review_expiration_time_in_days: "",
  submitted_to_review_at: "",
  submitted_to_review_by: "",
  published_at: "",
  published_by: "",
  document: "",
  policy_sections: [],
  // reviewDate: "",
  // expireDate: "",
  // referenceMaterialUrl: "",
  // policyTags: "",
  // shortDescription: "",
  // intendedAudience: "",
  // relatedDocument: "",
  // kbArticleLink: "",
  // knowledgeBase: "",
  // documentTemplate: "",
  // riskManagementFrameworks: "",
};

const CreatePolicyDocumentStepDraft = (props) => {
  const { objUser, objPolicyDocument, loadPolicyDocument, setNextDisabled } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { policyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingSection, setIsSavingSection] = useState(false);
  const [savingSectionIndex, setSavingSectionIndex] = useState(null);
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [policySectionsToSave, setPolicySectionsToSave] = useState([]);

  const [editorDocument, setEditorDocument] = useState("");
  const [objAddNewCustomerFormErrors, setObjAddNewCustomerFormErrors] =
    useState({});
  const [isSubmitingForReview, setIsSubmitingForReview] = useState(false);
  const [saveBeforeSubmittingInFlight, setSaveBeforeSubmittingInFlight] =
    useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [documentVersions, setDocumentVersions] = useState([]);
  const [documentSectionVersions, setDocumentSectionVersions] = useState([]);
  const [summaryOfChanges, setSummaryOfChanges] = useState("");
  const [openSummaryOfChangesDialog, setOpenSummaryOfChangesDialog] =
    useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [editorsToShow, setEditorsToShow] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const { previewDoc, isLoadingPreview, policyObj, setPolicyObj } =
    usePreviewer();

  const [policyDocumentId, setPolicyDocumentId] = useState(
    objPolicyDocument.id
  );
  const editorRef = useRef(null);
  const formRef = useRef();

  const readOnly =
    (objPolicyDocument.state !== "Draft" &&
      objPolicyDocument.state !== "Rejected") ||
    objPolicyDocument.is_max_major_version == false;
  const locked = objPolicyDocument.locked === 1;

  const canDownloadPDFS =
    objUser.user && objUser.user.company.can_download_pdfs == "yes"
      ? true
      : false;
  useEffect(() => {
    const __ObjInitialValues = {
      policy_name: objPolicyDocument.policy.title,
      title: objPolicyDocument.name,
      category: objPolicyDocument.policy?.category?.display_name ?? 0,
      // document_creator: objPolicyDocument?.creator?.name ?? "",
      document_creator: objPolicyDocument?.policy?.creator?.name ?? "",
      status: objPolicyDocument.status ?? "",
      state: objPolicyDocument.state ?? "",
      review_expiration_time_in_days:
        objPolicyDocument.review_expiration_time_in_days ?? "",
      submitted_to_review_at: objPolicyDocument.submitted_to_review_at ?? "",
      submitted_to_review_by: objPolicyDocument.submitted_to_review_by ?? "",
      // summary_of_changes: objPolicyDocument.summary_of_changes ?? "",
      published_at: objPolicyDocument.published_at ?? "",
      published_by: objPolicyDocument.published_by ?? "",
      document: objPolicyDocument.document ?? "",
      version: objPolicyDocument.version ?? "",
      policy_sections: objPolicyDocument.document_sections.sort(
        (a, b) => a.owning_global_section.order - b.owning_global_section.order
      ),
    };

    const nextStates = [
      "In Review",
      "Pending Approval",
      "Authorized",
      "Published",
    ];
    if (
      typeof objPolicyDocument.state === "undefined" ||
      !nextStates.includes(objPolicyDocument.state)
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    if (
      typeof objPolicyDocument.id !== "undefined" &&
      objPolicyDocument.id !== null &&
      typeof objPolicyDocument.document !== "undefined"
    ) {
      __ObjInitialValues.latestDocument = objPolicyDocument.document;
      setPolicyDocumentId(objPolicyDocument.id);
    }
    setObjInitialValues(__ObjInitialValues);
    setPolicySectionsToSave(__ObjInitialValues.policy_sections ?? []);
    setEditorDocument(objPolicyDocument.document);
    if (typeof objPolicyDocument.policy.extra.versions !== "undefined") {
      setDocumentVersions(objPolicyDocument.policy.extra.versions);
    }
    if (
      typeof objPolicyDocument.policy.extra.document_section_versions_all !==
      "undefined"
    ) {
      // console.log(objPolicyDocument.policy.extra.document_section_versions_all);
      setDocumentSectionVersions(
        objPolicyDocument.policy.extra.document_section_versions_all
      );
    }
  }, [objPolicyDocument]);

  const showEditor = (section_id) => {
    let updatedEditorsToShow = [...editorsToShow];
    const index = updatedEditorsToShow.indexOf(section_id);
    if (index !== -1) {
      updatedEditorsToShow.splice(index, 1);
    } else {
      updatedEditorsToShow.push(section_id);
    }
    setEditorsToShow(updatedEditorsToShow);
  };

  const handleUpdatePolicySectionsToSave = (field, index, newValue) => {
    let updatedSections = [...policySectionsToSave];
    updatedSections[index][field] = newValue;
    setPolicySectionsToSave(updatedSections);
    setIsEditing(true);
    // updateTheArrayOfEditorsWithUpdates(updatedSections[index]["section_id"]);
  };

  const allSectionsComplete = () => {
    let updatedSections = [...policySectionsToSave];
    const incompleteSections = updatedSections.filter((section) => {
      return section.completed === 0;
    }).length;

    return incompleteSections === 0;
  };

  const toggleSectionCompletion = (index, section_id) => {
    // Update state immutably
    const updatedSections = policySectionsToSave.map((section, i) =>
      i === index
        ? { ...section, completed: section.completed === 1 ? 0 : 1 }
        : section
    );

    // Simplify the owning_policy_document relationship (with a check for undefined)
    if (updatedSections[index]["owning_policy_document"]) {
      updatedSections[index]["owning_policy_document"] =
        updatedSections[index]["owning_policy_document"].id;
    } else {
      // Handle the case where owning_policy_document is undefined or null
      updatedSections[index]["owning_policy_document"] = null; // or some default value
    }

    const sectionToUpdate = updatedSections[index];
    setSavingSectionIndex(index);
    setIsSavingSection(true);

    dispatch(
      markDocumentSectionAsComplete({ sectionToUpdate, objUser, policyId })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          setPolicySectionsToSave(updatedSections); // Update state after successful response
          showMsg(
            "success",
            `Section marked as ${
              sectionToUpdate.completed === 1 ? "completed" : "incomplete"
            }`
          );
        } else {
          showMsg("error", "Error saving changes");
        }
        setSavingSectionIndex(null);
        setIsSavingSection(false);
        setIsEditing(false);
      })
      .catch(() => {
        showMsg("error", "Error saving changes");
        setSavingSectionIndex(null);
        setIsSavingSection(false);
      });
  };

  const markAllSectionAsComplete = (completed = 1) => {
    let updatedSections = [...policySectionsToSave];

    updatedSections.forEach((section) => {
      section["completed"] = completed;
      // section["owning_policy_document"] = section["owning_policy_document"].id;
      section["owning_policy_document"] = policyDocumentId;
    });

    let policyDocId = policyDocumentId;

    setIsLoading(true);

    dispatch(
      markAllDocumentSectionsAsComplete({
        policyDocId,
        objUser,
        policyId,
        completed,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          setPolicySectionsToSave(updatedSections);
          if (completed === 1) {
            showMsg("success", "Sections marked as completed");
          } else {
            showMsg("success", "Sections marked as incompleted");
          }
        } else {
          showMsg("error", "Error saving changes");
        }
        // setSavingSectionIndex(null);
        // setIsSavingSection(false);
        setIsLoading(false);
      })
      .catch(() => {
        showMsg("error", "Error saving changes");
        //setSavingSectionIndex(null);
        // setIsSavingSection(false);
        setIsLoading(false);
      });
  };

  /*** */
  //section versions
  const sortSectionVersionsByMostRecentFirst = (section_versions) => {
    return section_versions.sort((a, b) => {
      const timestampA = new Date(a.created_at).getTime();
      const timestampB = new Date(b.created_at).getTime();

      return timestampB - timestampA;
    });
  };

  const fetchSectionVersion = (id) => {
    const objPostData = { id };
    return dispatch(getSectionVersion({ objPostData, objUser, policyId }))
      .then(unwrapResult)
      .then((response) => response.data.section_content)
      .catch((e) => {
        showMsg("error", "Error retrieving policy. Try again later");
      });
  };

  const handleSectionAutoSave = (
    editor,
    successCallback,
    failureCallback,
    sectionId
  ) => {
    const objPostData = {
      sectionContent: editor.getContent(),
      sectionId: sectionId,
    };
    if (
      objPostData.sectionContent === "" &&
      saveBeforeSubmittingInFlight === false
    ) {
      successCallback();
      return;
    }

    if (saveBeforeSubmittingInFlight === false && isSavingSection === false) {
      //prevent autosave from running in the middle of submitting for review after changes
      dispatch(sendSectionDraftAutosave({ objPostData, objUser, policyId }))
        .then(unwrapResult)
        .then((response) => {
          if (response.status === 201) {
            successCallback();
            setDocumentSectionVersions([
              ...documentSectionVersions,
              response.data,
            ]);
          } else {
            failureCallback();
          }
        })
        .catch(() => {
          failureCallback();
        });
    }
  };

  const btnClickSectionSave = (index, section_id, section_content) => {
    if (readOnly) {
      showMsg(
        "error",
        "You must be in draft mode on the most recent version to edit this policy"
      );
    } else {
      const objPostData = {
        sectionContent: section_content,
        sectionId: section_id,
      };
      setSavingSectionIndex(index);
      setIsSavingSection(true);
      dispatch(sendSectionDraftAutosave({ objPostData, objUser, policyId }))
        .then(unwrapResult)
        .then((response) => {
          setSavingSectionIndex(null);
          setIsSavingSection(false);
          if (response.status === 201) {
            showMsg("success", "Section changes saved");
            // ;
            const currentSectionVersionsPlusNewlyAddedVersion = [
              ...documentSectionVersions,
              response.data,
            ];
            // setDocumentSectionVersions([
            //   ...documentSectionVersions,
            //   response.data,
            // ]);
            setDocumentSectionVersions(
              sortSectionVersionsByMostRecentFirst(
                currentSectionVersionsPlusNewlyAddedVersion
              )
            );
            setIsEditing(false);
          } else {
            showMsg("error", "Error saving changes");
          }
          showEditor(section_id);
        })
        .catch(() => {
          showMsg("error", "Error saving changes");
          setSavingSectionIndex(null);
          setIsSavingSection(false);
        });
    }
  };

  // const compareDocumentVersionsWorker = useMemo(
  //   () =>
  //     new Worker(
  //       new URL("../compareDocumentVersions.worker.js", import.meta.url)
  //     ),
  //   []
  // );

  // const compareDocumentVersions = (before, after) =>
  //   new Promise((resolve, reject) => {
  //     compareDocumentVersionsWorker.onmessage = (e) => {
  //       resolve(
  //         `<div class='diff'><div class='before'>${e.data}</div><div class='after'>${e.data}</div></div>`
  //       );
  //     };
  //     compareDocumentVersionsWorker.onerror = (e) => {
  //       reject(e);
  //     };
  //     compareDocumentVersionsWorker.postMessage([before, after]);
  //   });
  /*** */

  const onSubmitForm = () => {
    handleAutoSave(
      editorRef.current,
      () => showMsg("success", "Draft saved succesfully"),
      () => showMsg("error", "Error saving draft. Try again later")
    );
  };

  const handleAutoSave = (editor, successCallback, failureCallback) => {
    let updatedSections = [...policySectionsToSave];
    let updatedSectionsWithoutOwningPolicyDocumentParam = updatedSections.map(
      (section) => {
        section.owning_policy_document = null;
        return section;
      }
    );
    const objPostData = {
      policy: editor ? editor.getContent() : "-",
      updatedPolicySections: updatedSectionsWithoutOwningPolicyDocumentParam,
    };
    if (objPostData.policy === "" && saveBeforeSubmittingInFlight == false) {
      successCallback();
      return;
    }

    if (saveBeforeSubmittingInFlight == false) {
      //prevent autosave from running in the middle of submitting for review after changes
      dispatch(sendDraftAutosave({ objPostData, objUser, policyId }))
        .then(unwrapResult)
        .then((response) => {
          if (response.status === 201) {
            successCallback();
            const currentDocumentVersionsPlusNewlyAddedVersion = [
              ...documentVersions,
              response.data,
            ];

            //console.log(currentDocumentVersionsPlusNewlyAddedVersion);
            setDocumentVersions(
              sortSectionVersionsByMostRecentFirst(
                currentDocumentVersionsPlusNewlyAddedVersion
              )
            );
            // setDocumentVersions([...documentVersions, response.data]);
            setPolicyDocumentId(response.data.id);
          } else {
            failureCallback();
          }
        })
        .catch(() => {
          failureCallback();
        });
    }
  };

  const onSubmitForReview = () => {
    //save new document version before submitting for review
    setSaveBeforeSubmittingInFlight(true);
    setIsSubmitingForReview(true);
    let updatedSections = [...policySectionsToSave];
    let updatedSectionsWithoutOwningPolicyDocumentParam = updatedSections.map(
      (section) => {
        section.owning_policy_document = null;
        return section;
      }
    );
    const objPostData = {
      policy: editorRef.current ? editorRef.current.getContent() : "-",
      updatedPolicySections: updatedSectionsWithoutOwningPolicyDocumentParam,
    };

    const hasIncompleteSections = objPostData.updatedPolicySections.find(
      (section) => {
        return section.completed === 0;
      }
    );

    if (
      objPolicyDocument?.policy?.doc_type === "oscal" &&
      hasIncompleteSections
    ) {
      showMsg("info", "Please complete all sections");
      setIsSubmitingForReview(false);
      return;
    }

    if (objPostData.policy === "") {
      //successCallback();
      setIsSubmitingForReview(false);
      return;
    }

    if (editorRef.current) {
      if (editorRef.current.isDirty()) {
        dispatch(sendDraftAutosave({ objPostData, objUser, policyId }))
          .then(unwrapResult)
          .then((response) => {
            // setSaveBeforeSubmittingInFlight(false);
            if (response.status === 201) {
              // successCallback();
              setDocumentVersions([...documentVersions, response.data]);
              setPolicyDocumentId(response.data.id);
              submitLatestDocumentForReview(response.data.id);
            } else {
              showMsg("error", "Error submitting for review. Try again later.");
            }
          })
          .catch(() => {
            // setSaveBeforeSubmittingInFlight(false);
            showMsg("error", "Error submitting for review. Try again later.");
          });
      } else {
        submitLatestDocumentForReview(policyDocumentId);
      }
    } else {
      submitLatestDocumentForReview(policyDocumentId);
    }
  };

  const submitLatestDocumentForReview = (policyDocId) => {
    if (isExpired) {
      showMsg("error", "You have been checked out due to inactivity");
      navigate("/home");
      return;
    }
    // if (formRef.current.values.summary_of_changes == "") {
    //   showMsg(
    //     "error",
    //     "Please provide the summary of changes for this document"
    //   );
    //   setIsSubmitingForReview(false);
    //   return;
    // }
    // console.log(formRef.current.values.summary_of_changes);
    setIsSubmitingForReview(true);
    dispatch(
      policyDocumentUpdateState({
        objUser,
        policy_id: objPolicyDocument.policy.id,
        client_id: objPolicyDocument.policy.client_id,
        objPostData: {
          state: "In Review",
          // summary_of_changes: formRef.current.values.summary_of_changes,
          summary_of_changes: summaryOfChanges,
          // policy_document_id: policyDocumentId,
          policy_document_id: policyDocId,
          updatedPolicySections: [...policySectionsToSave],
        },
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 200) {
          // props.setActiveStep(1);
          loadPolicyDocument();
        } else {
          showMsg("Error updating policy state. Try again later");
        }
      })
      .catch((e) => {
        showMsg("Error updating policy state. Try again later");
        console.log(e);
      })
      .finally(() => {
        setIsSubmitingForReview(false);
      });
  };

  const fetchDocumentVersion = (
    major_version,
    minor_version,
    patch_version
  ) => {
    const objPostData = { major_version, minor_version, patch_version };
    return dispatch(getPolicyVersion({ objPostData, objUser, policyId }))
      .then(unwrapResult)
      .then((response) => response.data.document)
      .catch((e) => {
        console.log(e);
        showMsg("error", "Error retrieving policy. Try again later");
      });
  };

  const compareDocumentVersionsWorker = useMemo(
    () =>
      new Worker(
        new URL("../compareDocumentVersions.worker.js", import.meta.url)
      ),
    []
  );

  const compareDocumentVersions = (before, after) =>
    new Promise((resolve, reject) => {
      compareDocumentVersionsWorker.onmessage = (e) => {
        resolve(
          `<div class='diff'>
            <div class='after'>
              <p><b>Current editor content</b></p>
              ${e.data}
            </div>
            <div class='before'>
              <p><b>Selected version</b></p>
              ${e.data}
            </div>
          </div>`
        );
      };
      compareDocumentVersionsWorker.onerror = (e) => {
        reject(e);
      };
      compareDocumentVersionsWorker.postMessage([before, after]);
    });

  const idleTimer = useIdleTimer({
    timeout: 30 * 60 * 1000, // 30 min
    onIdle: () => {
      console.log("Expired");
      setIsExpired(true);
    },
    name: "idle-timer-policy-draft",
  });

  const downloadDocument = () => {
    //console.log(objPolicyDocument);
    const params = {
      policy_id: policyId,
      document_id: policyDocumentId,
    };
    downloadPolicyDocumentPDF(objUser, params);
  };

  const downloadPolicyDocumentPDF = (objUser, params) => {
    setIsLoading(true);
    dispatch(getPolicyDraftDocumentPDF({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${policyDocumentId}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
      });
  };

  const returnToPolicies = () => {
    navigate(
      objPolicyDocument.policy.policy_type == "asset" ? `/assets` : `/policies`
    );
  };

  const onClickEnterSummaryOfChanges = () => {
    setOpenSummaryOfChangesDialog(true);
  };

  const onCloseSummaryOfChangesDialog = () => {
    setOpenSummaryOfChangesDialog(false);
  };

  const onClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
    setPolicyObj(null);
  };
  return (
    <>
      <SummaryOfChangesDialog
        open={openSummaryOfChangesDialog}
        onClose={onCloseSummaryOfChangesDialog}
        onSubmitForReview={onSubmitForReview}
        summaryOfChanges={summaryOfChanges}
        setSummaryOfChanges={setSummaryOfChanges}
      />
      <PreviewDocDialog
        open={policyObj !== null}
        onClose={onClosePreviewDialog}
        objPolicy={policyObj}
      />
      <div
        style={{
          position: "fixed",
          bottom: "40px",
          color: "#fff",
          padding: "1.2rem",
          transition: "0.7s ease all",
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: 3,
          borderRadius: "10px",
        }}
        className={
          isEditing || editorsToShow.length > 0 ? "expanded" : "not-expanded"
        }
      >
        <b>
          You have unsaved changes or opened editors. Please save the
          appropriate sections before leaving
        </b>
      </div>
      {Object.keys(objInitialValues).length > 0 &&
      objInitialValues.title !== "" ? (
        <Formik
          initialValues={objInitialValues}
          innerRef={formRef}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"CreatePolicyDocumentForm"} autoComplete="off">
              <MDBox>
                <CreatePolicyDocumentFormFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={{}}
                  documentType={objPolicyDocument?.policy?.doc_type}
                  policySectionsToSave={policySectionsToSave}
                  editorDocument={editorDocument}
                  setEditorDocument={setEditorDocument}
                  handleUpdatePolicySectionsToSave={
                    handleUpdatePolicySectionsToSave
                  }
                  toggleSectionCompletion={toggleSectionCompletion}
                  savingSectionIndex={savingSectionIndex}
                  isSavingSection={isSavingSection}
                  handleAutoSave={handleAutoSave}
                  handleSectionAutoSave={handleSectionAutoSave}
                  editorRef={editorRef}
                  categories={objPolicyDocument.policy.extra.categories}
                  /**document versions */
                  documentVersions={documentVersions}
                  fetchDocumentVersion={fetchDocumentVersion}
                  fetchSectionVersion={fetchSectionVersion}
                  compareDocumentVersions={compareDocumentVersions}
                  /**section versions gilbert added */
                  documentSectionVersions={documentSectionVersions}
                  readOnly={readOnly}
                  objUser={objUser}
                  objPolicyDocument={objPolicyDocument}
                  showEditor={showEditor}
                  btnClickSectionSave={btnClickSectionSave}
                  editorsToShow={editorsToShow}
                  mergeTags={objPolicyDocument.merge_tags}
                />

                {isLoading === false ? (
                  <MDBox
                    sx={{ margin: "0" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <MDButton
                      onClick={returnToPolicies}
                      variant="outlined"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                    >
                      RETURN TO{" "}
                      {objPolicyDocument.policy.policy_type == "asset"
                        ? "ASSET LISTS"
                        : "POLICIES"}
                    </MDButton>
                    {objPolicyDocument?.policy?.doc_type === "oscal" &&
                      (allSectionsComplete() ? (
                        <MDButton
                          type="button"
                          variant="outlined"
                          style={{ marginLeft: "16px" }}
                          color="dark"
                          onClick={() => markAllSectionAsComplete(0)}
                          sx={{ padding: "12px 12px" }}
                        >
                          MARK ALL SECTIONS AS INCOMPLETE
                        </MDButton>
                      ) : (
                        <MDButton
                          type="button"
                          variant="outlined"
                          style={{ marginLeft: "16px" }}
                          color="dark"
                          onClick={() => markAllSectionAsComplete()}
                          sx={{ padding: "12px 12px" }}
                        >
                          MARK ALL SECTIONS AS COMPLETE
                        </MDButton>
                      ))}

                    <MDButton
                      onClick={() =>
                        previewDoc(objUser, policyId, policyDocumentId)
                      }
                      style={{ marginLeft: "16px" }}
                      type="button"
                      variant="outlined"
                      color="dark"
                      disabled={readOnly}
                      sx={{ padding: "12px 12px" }}
                    >
                      PREVIEW DOCUMENT
                    </MDButton>
                    {/* {canDownloadPDFS ? (
                      // previously had a download button here
                    ) : (
                      ""
                    )} */}

                    <MDButton
                      style={{ marginLeft: "16px" }}
                      type="button"
                      variant="gradient"
                      color="success"
                      disabled={readOnly || locked}
                      // onClick={onSubmitForReview}
                      onClick={onClickEnterSummaryOfChanges}
                      // onClick={() =>
                      //   console.log(formRef.current.values.summary_of_changes)
                      // }
                      sx={{ padding: "12px 12px" }}
                    >
                      {locked ? (
                        `Locked by ${objPolicyDocument.policy.locked_by}`
                      ) : isSubmitingForReview ? (
                        <MDBox pl={7} pr={7}>
                          <CircularProgress color="white" size={15} />
                        </MDBox>
                      ) : (
                        "SUBMIT FOR REVIEW"
                      )}
                    </MDButton>
                  </MDBox>
                ) : (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                )}
              </MDBox>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </>
  );
};

export default CreatePolicyDocumentStepDraft;
