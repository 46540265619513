import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
// Data
import { unwrapResult } from "@reduxjs/toolkit";
import { getPoams } from "features/company/riskRegisterActions";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import PoamTable from "./components/PoamTable/PoamTable";
import MDTypography from "components/MDTypography";
import DashboardActionButton from "views/components/DashboardActionButton";
import { getPoamsPDF } from "features/company/riskRegisterActions";
import { getPoamsCSV } from "features/company/riskRegisterActions";

const PlanOfActionandMilestones = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [riskRegisterlist, setRiskRegisterList] = useState({});
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);
  const [riskRegisterUsers, setRiskRegisterUsers] = useState([]);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [objCompletedTableData, setObjCompletedTableData] =
    useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCompletedCount, setTotalCompletedCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    loadMoreItems();
  }, []);
  const loadMoreItems = () => {
    setIsLoading(true);
    dispatch(getPoams({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        const objRows = response.data.poams.map((objEvent) => {
          const objTr = {};
          objTr.eventName = objEvent.assessment_event_name;
          objTr.itemName = objEvent.item_name;
          objTr.controlId = objEvent?.control_id ?? "";
          objTr.controlObjectiveId = objEvent?.control_objective_id ?? "";
          objTr.controlObjectiveItem = objEvent?.control_objective_item ?? "";
          objTr.implementationStatement =
            objEvent?.implementation_statement ?? "";
          objTr.start_date = objEvent.start_date;
          objTr.end_date = objEvent.end_date;
          objTr.responsibility = objEvent.responsibility;
          objTr.formattedResponsiblity = objEvent.responsibility
            ? objEvent.responsibility.name +
              " " +
              objEvent.responsibility.last_name
            : "";
          objTr.status = objEvent.status;
          objTr.cost = objEvent.cost;
          objTr.id = objEvent.id;
          return objTr;
        });

        let UncompletedItems = objRows.filter((item) => {
          return item.status != "Completed";
        });

        if (response.data.riskRegisterList) {
          setRiskRegisterList(response.data.riskRegisterList);
        }
        setRiskRegisterUsers(response.data.users);
        setTotalCount(UncompletedItems.length);
        setObjTableData({
          ...dataTableData,
          rows: UncompletedItems,
        });

        let completedItems = objRows.filter((item) => {
          return item.status == "Completed";
        });
        setTotalCompletedCount(completedItems.length);
        setObjCompletedTableData({
          ...dataTableData,
          rows: completedItems,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load POAM data. Please try again.";
        showMsg("error", errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const printPage = (output) => {
    setIsFetchingPDF(true);
    dispatch(
      output == "pdf"
        ? getPoamsPDF({
            objUser,
          })
        : getPoamsCSV({
            objUser,
          })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Plan of Action and Milestones.` + output
          ); //or any other extension
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsFetchingPDF(false);
        showMsg("error", rejectedValueOrSerializedError?.response?.data?.message || "Failed to export POAM data. Please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      <MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          {isFetchingPDF ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox display="flex" gap={2}>
              <DashboardActionButton
                action={() => printPage("csv")}
                btnText="Export to CSV"
                btnIcon="print"
                textColor="white"
                bgColor="success"
                sx={{ marginTop: "0px !important" }}
              />
              <DashboardActionButton
                action={() => printPage("pdf")}
                btnText="Export to PDF"
                btnIcon="print"
                textColor="white"
                bgColor="success"
                sx={{ marginTop: "0px !important" }}
              />
            </MDBox>
          )}
        </MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isLoading === false &&
          objTableData !== undefined &&
          objTableData.rows !== undefined &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <>
              <PoamTable
                table={objTableData}
                entriesPerPage={true}
                canSearch
                objUser={objUser}
                totalCount={totalCount}
                loadMoreItems={loadMoreItems}
                riskRegisterUsers={riskRegisterUsers}
              />
            </>
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no Plans of Action and Milestones</p>
              )}
            </Fragment>
          )}
        </Card>

        <MDBox display={"flex"} gap=".6rem" alignItems="flex-start" mb={0}>
          <MDTypography
            sx={{ paddingTop: "2rem" }}
            fontWeight="bold"
            variant="h4"
            color="dark"
          >
            Completed Items
          </MDTypography>
        </MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isLoading === false &&
          objCompletedTableData !== undefined &&
          objCompletedTableData.rows !== undefined &&
          objCompletedTableData.rows.length > 0 &&
          totalCompletedCount > 0 ? (
            <>
              <PoamTable
                completed={true}
                table={objCompletedTableData}
                entriesPerPage={true}
                canSearch
                objUser={objUser}
                totalCount={totalCompletedCount}
                riskRegisterUsers={riskRegisterUsers}
              />
            </>
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no completed items</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default PlanOfActionandMilestones;
