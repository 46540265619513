// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import SaveApiKeysFormErrorsBlock from "./SaveApiKeysFormErrorsBlock";
import SaveApiKeysFormFormikWrapper from "./SaveApiKeysFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { fetchSharePointSiteId } from "features/company/evidenceLockerActions";

const SaveApiKeysFormJWTSchema = Yup.object().shape({
  site_id: Yup.string().required("Site id is required"),
});

const SaveApiKeysForm = ({ objUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [controller] = useMaterialUIController();
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};

  const [objInitialValues, setObjInitialValues] = useState({
    site_id: "",
    site_url: ""
  });

  const [objSaveApiKeysFormErrors, setObjSaveApiKeysFormErrors] = useState({});

  const onSubmitForm = async (values) => {
    setObjSaveApiKeysFormErrors({});
    navigate(
      `/evidence-locker/ms-graph-sharepoint-connect/${objUser.user.current_client_id}/${values.site_id}`,
      {
        state: { routeDictionary },
      }
    );
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    dispatch(fetchSharePointSiteId())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        let _objInitialValues = {
          site_id: response.data.site_id ?? ""
        };
        setObjInitialValues(_objInitialValues);
        setIsLoadingKeys(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
      });
  };

  return (
    <>
      <Grid item xs={12}>
        {isLoadingKeys === false ? (
          <Formik
            initialValues={objInitialValues}
            validationSchema={SaveApiKeysFormJWTSchema}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={"SaveApiKeysForm"} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox>
                    <SaveApiKeysFormFormikWrapper
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />

                    {Object.keys(objSaveApiKeysFormErrors).length > 0 ? (
                      <Grid
                        container
                        justifyContent="space-between"
                        pl={5}
                        pr={5}
                        pt={0}
                        pb={5}
                      >
                        <SaveApiKeysFormErrorsBlock
                          objSaveApiKeysFormErrors={objSaveApiKeysFormErrors}
                        />
                      </Grid>
                    ) : null}

                    <MDBox
                      sx={{ margin: "0 0 40px -40px" }}
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <MDButton
                        component="a"
                        href="https://answers.microsoft.com/en-us/msoffice/forum/all/what-is-the-best-way-to-findout-the-share-point/7b2d4183-4188-4cd5-8441-dd93207c5a01"
                        target="_blank"
                        rel="noreferrer"
                        variant="gradient"
                        color="dark"
                        sx={{ padding: "12px 20px", margin: "0px 5px" }}
                      >
                        Learn How To Retrieve Sharepoint Site Id
                      </MDButton>
                      {isLoading === false ? (
                        <MDButton
                          type="submit"
                          // variant="gradient"
                          color="success"
                          sx={{ padding: "12px 20px", margin: "0px 5px" }}
                        >
                          Connect
                        </MDButton>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </Card>
                {/* </MDBox> */}
              </Form>
            )}
          </Formik>
        ) : (
          <MDBox>
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
            <MDTypography sx={{ textAlign: "center" }}>
              Loading Keys
            </MDTypography>
          </MDBox>
        )}
      </Grid>
    </>
  );
};

export default SaveApiKeysForm;
