import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import { getTasksReports } from "features/company/taskListActions";
import { showMsg } from "utils/general";

function TaskListReports(props) {
    const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const dispatch = useDispatch();

  const fetchReports = useCallback(() => {
    setIsLoading(true);

    return dispatch(getTasksReports({objUser}))
      .then(unwrapResult)
      .then((response) => {
        if (!response || response.status !== 200) {
          throw new Error("Get reports request failed.");
        }

        const reportsData = response.data.reports;

        // Map the backend response to the required format
        const formattedReports = [
          {
            id: 1,
            title: "Tasks Due This Week",
            count: reportsData.tasks_due_this_week_count,
            icon: "event",
          },
          {
            id: 2,
            title: "Completed Tasks",
            count: reportsData.completed_tasks_count,
            icon: "check_circle",
          },
          {
            id: 3,
            title: "High Priority Tasks",
            count: reportsData.high_priority_tasks_count,
            icon: "priority_high",
          },
        ];

        setReports(formattedReports);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || "Failed to fetch reports. Please try again.";
        console.error("Error fetching reports:", error);
        showMsg("error", errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  if (isLoading) {
    return (
      <MDBox display="flex" justifyContent="center">
        <LoadingSpinner />
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox p={3}>
        <MDTypography variant="h4" mb={3}>
          Projects Reports
        </MDTypography>
        <Grid container spacing={3}>
          {reports.map((report) => (
            <Grid item xs={12} md={4} key={report.id}>
              <Card>
                <MDBox
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium">
                      {report.title}
                    </MDTypography>
                    <MDTypography variant="h4" py={2}>
                      {report.count}
                    </MDTypography>
                  </MDBox>

                  <MDBox>
                    <Tooltip title={report.title}>
                      <Icon sx={{ fontSize: "3rem", opacity: 0.5 }}>
                        {report.icon}
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default TaskListReports;
