import { useEffect, useMemo, useState } from "react";

// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import DefaultCell from "views/components/TableCells/DefaultCell";
import PoamDropdown from "./PoamDropdown";
import PoamResponsibilityDropdown from "./PoamResponsibilityDropdown";
import PoamTextField from "./PoamTextField";
import PoamDeleteBtnTr from "./PoamDeleteBtnTr";
import { Tooltip } from "@mui/material";

function PoamTable({
  canSearch,
  entriesPerPage,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalCount,
  completed,
  takeTheAssessment,
  riskRegisterUsers,
  loadMoreItems,
  objUser,
}) {
  const objColumns = completed
    ? [
        {
          Header: "Assessment Event",
          accessor: "eventName",
          Cell: ({ value, column, row }) => (
            <DefaultCell value={value} column={column} row={row} />
          ),
        },
        {
          Header: "Control",
          accessor: "itemName",
          Cell: ({ value, column, row }) => (
            // <Tooltip title={value} placement="top-end">
            //   <DefaultCell
            //     value={value.length > 40 ? value.slice(0, 40) + "..." : value}
            //   />
            //   <></>
            // </Tooltip>
            <Tooltip
              title={
                row.original?.controlObjectiveItem
                  ? row.original?.controlObjectiveId +
                    " " +
                    row.original?.controlObjectiveItem
                  : row.original?.controlId + " " + value
              }
              placement="top-end"
            >
              <MDBox>
                <DefaultCell
                  value={
                    value.length + row.original?.controlId.length > 40
                      ? (row.original?.controlId + " " + value).slice(0, 40) +
                        "..."
                      : row.original?.controlId + " " + value
                  }
                />
              </MDBox>
              {/* <MDBox style={{ marginLeft: "8px" }}>
                <DefaultCell
                  value={
                    row.original?.controlObjectiveItem.length > 40
                      ? row.original?.controlObjectiveId +
                        " " +
                        row.original?.controlObjectiveItem.slice(0, 40) +
                        "..."
                      : row.original?.controlObjectiveId +
                        " " +
                        row.original?.controlObjectiveItem
                  }
                />
              </MDBox> */}

              {row.original?.controlObjectiveItem && (
                <MDBox style={{ marginLeft: "8px" }}>
                  <DefaultCell
                    value={
                      row.original?.controlObjectiveItem.length > 40
                        ? row.original?.controlId +
                          " " +
                          row.original?.controlObjectiveId +
                          " " +
                          row.original?.controlObjectiveItem.slice(0, 40) +
                          "..."
                        : row.original?.controlId +
                          " " +
                          row.original?.controlObjectiveId +
                          " " +
                          row.original?.controlObjectiveItem
                    }
                  />
                </MDBox>
              )}
            </Tooltip>
          ),
        },
        {
          Header: "Implementation Event",
          accessor: "implementationStatement",
          Cell: ({ value, column, row }) => (
            <Tooltip title={value} placement="top-end">
              <DefaultCell
                value={value.length > 40 ? value.slice(0, 40) + "..." : value}
                column={column}
                row={row}
              />
              <></>
            </Tooltip>
          ),
        },
        {
          Header: "Start Date",
          accessor: "start_date",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"start_date"}
                />
              )}
            </>
          ),
        },
        {
          Header: "End Date",
          accessor: "end_date",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"end_date"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Responsibility",
          accessor: "responsibility",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell
                  value={row.original.formattedResponsiblity ?? ""}
                  column={column}
                  row={row}
                />
              ) : (
                <PoamResponsibilityDropdown
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  options={riskRegisterUsers}
                  field={"responsibility"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamDropdown
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  options={["Todo", "Under Review", "In Progress", "Completed"]}
                  loadMoreItems={loadMoreItems}
                  field={"status"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Cost",
          accessor: "cost",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"cost"}
                />
              )}
            </>
          ),
        },
      ]
    : [
        {
          Header: "Assessment Event",
          accessor: "eventName",
          Cell: ({ value, column, row }) => (
            <DefaultCell value={value} column={column} row={row} />
          ),
        },
        {
          Header: "Control",
          accessor: "itemName",
          Cell: ({ value, column, row }) => (
            <Tooltip
              title={
                row.original?.controlObjectiveItem
                  ? row.original?.controlObjectiveId +
                    " " +
                    row.original?.controlObjectiveItem
                  : row.original?.controlId + " " + value
              }
              placement="top-end"
            >
              <MDBox>
                <DefaultCell
                  value={
                    value.length + row.original?.controlId.length > 40
                      ? (row.original?.controlId + " " + value).slice(0, 40) +
                        "..."
                      : row.original?.controlId + " " + value
                  }
                />
              </MDBox>

              <MDBox style={{ marginLeft: "8px" }}>
                <DefaultCell
                  value={
                    row.original?.controlObjectiveItem.length > 40
                      ? row.original?.controlObjectiveId +
                        " " +
                        row.original?.controlObjectiveItem.slice(0, 40) +
                        "..."
                      : row.original?.controlObjectiveId +
                        " " +
                        row.original?.controlObjectiveItem
                  }
                />
              </MDBox>
            </Tooltip>
          ),
        },
        {
          Header: "Implementation Event",
          accessor: "implementationStatement",
          Cell: ({ value, column, row }) => (
            <Tooltip title={value} placement="top-end">
              <DefaultCell
                value={value.length > 40 ? value.slice(0, 40) + "..." : value}
                column={column}
                row={row}
              />
              <></>
            </Tooltip>
          ),
        },
        {
          Header: "Start Date",
          accessor: "start_date",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"start_date"}
                />
              )}
            </>
          ),
        },
        {
          Header: "End Date",
          accessor: "end_date",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"end_date"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Responsibility",
          accessor: "responsibility",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell
                  value={row.original.formattedResponsiblity ?? ""}
                  column={column}
                  row={row}
                />
              ) : (
                <PoamResponsibilityDropdown
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  options={riskRegisterUsers}
                  field={"responsibility"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamDropdown
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  options={["Todo", "Under Review", "In Progress", "Completed"]}
                  loadMoreItems={loadMoreItems}
                  field={"status"}
                />
              )}
            </>
          ),
        },
        {
          Header: "Cost",
          accessor: "cost",
          Cell: ({ value, column, row }) => (
            <>
              {completed ? (
                <DefaultCell value={value ?? ""} column={column} row={row} />
              ) : (
                <PoamTextField
                  value={value ?? ""}
                  column={column}
                  row={row}
                  objUser={objUser}
                  field={"cost"}
                />
              )}
            </>
          ),
        },
        ...(objUser.user.role.name == "company_user" ||
        objUser.user.role.name == "admin"
          ? [
              {
                Header: "Action",
                Cell: ({ value, column, row }) => (
                  <PoamDeleteBtnTr
                    value={value}
                    column={column}
                    row={row}
                    objUser={objUser}
                    completed={completed}
                  />
                ),
              },
            ]
          : []),
      ];
  const columns = useMemo(() => objColumns, [table, takeTheAssessment]);
  const data = useMemo(() => table.rows, [table]);
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 25;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "40", "100"];
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  useEffect(() => setPageSize(defaultValue || 25), [defaultValue]);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const setEntriesPerPage = (value) => setPageSize(value);

  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const entriesStart = pageIndex * pageSize + 1;
  const entriesEnd = Math.min((pageIndex + 1) * pageSize, totalCount);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of PoamTable
PoamTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default PoamTable;
