import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewPolicy } from "features/company/policiesActions";
import { useMaterialUIController } from "context";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { generatePax8BillingCSV } from "features/company/superAdminActions";
import { generatePax8BillingPDF } from "features/company/superAdminActions";
const objInitialValues = {
  billingMonth: '',
};
const GenerateBillingFormJWTSchema = Yup.object().shape({
  billingMonth: Yup.string().required("Billing Month is required."),
});

function GeneratePax8BillingDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [format, setFormat] = useState("csv");
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const onSubmitForm = async (values) => {
    const objPostData = {
      billingMonth: values.billingMonth,
    };

    setIsLoading(true);
    dispatch(
      format === "csv"
        ? generatePax8BillingCSV({ objPostData, objUser })
        : generatePax8BillingPDF({ objPostData, objUser })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Pax8 Billing ${values.billingMonth}.${format}`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          onSubmit(response.data);
        } else {
          showMsg("error", `Failed to generate Pax8 billing. Server responded with status: ${response.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to generate Pax8 billing. Please try again.";
        showMsg("error", errorMessage);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0, backgroundColor: darkMode && "#1a2035" }}>
        Generate Pax8 Billing
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={GenerateBillingFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewPolicyForm" autoComplete="off">
            <DialogContent sx={{ backgroundColor: darkMode && "#1a2035" }}>
              <MDBox mt={2} px={1}>
                <DatePicker
                  label="Billing Month"
                  disablePast={false}
                  views={["year", "month"]}
                  maxDate={new Date()}
                  value={values.billingMonth}
                  onChange={(value) => {
                    setFieldValue(
                      "billingMonth",
                      value ? value.format("MMMM YYYY") : ""
                    );
                  }}
                  name="billingMonth"
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(
                        errors.billingMonth && touched.billingMonth
                      )}
                      helperText={touched.billingMonth && errors.billingMonth}
                    />
                  )}
                />
              </MDBox>
              {/* template selection */}

              <RadioGroup
                name="format"
                value={format}
                onChange={(event) => setFormat(event.target.value)}
                sx={{ marginTop: "1rem" }}
              >
                <Grid spacing={2} container sx={{ ml: 0, width: "100%" }}>
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="csv"
                      control={<Radio />}
                      sx={{ padding: "0" }}
                      label="CSV"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="pdf"
                      control={<Radio />}
                      sx={{ padding: "0" }}
                      label="PDF"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: darkMode && "#1a2035" }}>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Generate
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default GeneratePax8BillingDialog;
