import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, {  tooltipClasses } from "@mui/material/Tooltip";
import { maxWidth, minHeight } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 600,
    maxWidth: 900,
    maxHeight: 500,
    minHeight: 300,
    overflowY: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function ImageToolTip({ info }) {
  return (
    <CustomTooltip title={<>{info}</>} placement="right-end">
      <IconButton sx={{ paddingTop: "2px", paddingLeft: "0px" }}>
        <HelpOutlineIcon color="icon" fontSize="small" />
      </IconButton>
    </CustomTooltip>
  );
}

export default ImageToolTip;
