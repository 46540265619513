import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewCompanyTemplate } from "features/company/templatesActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";
const objInitialValues = {
  txtTemplateTitle: "",
  txtPolicySectionType: { name: "", description: "" },
};
const AddNewTemplateFormJWTSchema = Yup.object().shape({
  txtTemplateTitle: Yup.string().required("The Name is required."),
  txtPolicySectionType: Yup.object().shape({
    name: Yup.string(),
  }),
});

function CreateNewTemplateDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  transferTo,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtTemplateTitle,
      client_id: transferTo == "client" ? currentClient.id : null,
      sectionType: values.txtPolicySectionType.name,
    };
    setIsLoading(true);
    dispatch(createNewCompanyTemplate({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data.id);
        } else {
          throw new Error("error", `Failed to create template. Server responded with status code: ${response.status}.`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to create template. Please try again later.";
        showMsg("error", errorMessage);
      });
  };

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
  }, []);
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add New Template</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewTemplateFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewTemplateForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Name"
                  name="txtTemplateTitle"
                  value={values.txtTemplateTitle}
                  placeholder={"Template name"}
                  error={errors.txtTemplateTitle && touched.txtTemplateTitle}
                  success={
                    values.txtTemplateTitle.length > 0 &&
                    !errors.txtTemplateTitle
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtTemplateTitle" />
                  </MDTypography>
                </MDBox>
              </MDBox>

              {/* doctype */}
              <MDBox mb={0} mt={2}>
                <Autocomplete
                  disableClearable
                  value={values.txtPolicySectionType}
                  options={[
                    { name: "", description: "" },
                    {
                      name: "single_page",
                      description: "Single One Page Template",
                    },
                    { name: "oscal", description: "OSCAL Template" },
                  ]}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) =>
                    option ? option.description : ""
                  }
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Template Type"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    if (value === null) {
                      setFieldValue("txtPolicySectionType", "");
                    } else {
                      setFieldValue("txtPolicySectionType", value);
                    }
                  }}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewTemplateDialog;
