// src/components/TextEditor.js
import React, { useState, useEffect, useImperativeHandle } from 'react';
import ReactQuill from 'react-quill';  // Import ReactQuill
import 'react-quill/dist/quill.snow.css';  // Import Quill's CSS
import './editor.css';

// Function to check if the string is plain text (not containing HTML tags)
const isPlainText = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent === value;  // If the plain text is equal to the content, it's not rich text
};

// Forward ref to TextEditor
const TextEditor = React.forwardRef(({ value, onChange, question_id, field }, ref) => {
  const [editorValue, setEditorValue] = useState(value);

  // Quill editor toolbar options
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Basic formatting
      [{ 'font': [] }], // Font size (optional)
      [{ 'align': [] }], // Text alignment
    ],
  };

  // Effect to detect plain text or rich text on initial load
  useEffect(() => {
    // If the value is plain text, set it directly
    if (isPlainText(value)) {
      setEditorValue(value);
    } else {
      setEditorValue(value); // Assume it's rich text (HTML)
    }
  }, [value]);

  // Update value when the editor content changes
  const handleEditorChange = (value) => {
    setEditorValue(value); // Local state update
    onChange(value); // Call the parent's onChange callback
  };

  // Expose methods to parent through the ref (optional)
  useImperativeHandle(ref, () => ({
    getEditorValue: () => editorValue,
    setEditorValue: (newValue) => setEditorValue(newValue),
  }));

  return (
    <div className="editor-container">
      <ReactQuill
        value={editorValue}
        onChange={handleEditorChange}
        modules={modules}
        theme="snow"
      />
    </div>
  );
});

export default TextEditor;
