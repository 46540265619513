import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPolicyDraftDocumentPDF } from "features/company/policiesActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function usePolicyPDFDownloader() {
  const dispatch = useDispatch();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const downloadPDF = (objUser = null, params) => {
    setIsLoadingPDF(true);
    dispatch(getPolicyDraftDocumentPDF({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPDF(false);
        const response = originalPromiseResult;

        // if (response.status === 200) {
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   let link = document.createElement("a");
        //   link.href = url;
        //   const policyName = params?.title ?? "POLICY";
        //   const exportType = params?.pdf_display_settings?.exportType ?? "pdf";
        //   if (exportType == "pdf") {
        //     link.setAttribute("download", `${policyName}.pdf`);
        //   } else {
        //     link.setAttribute("download", `${policyName}.docx`);
        //   }

        //   document.body.appendChild(link);
        //   link.click();
        // } else {
        // }
        if (response.status === 200) {
          const exportType = params?.pdf_display_settings?.exportType ?? "pdf";
          const policyName = params?.title ?? "POLICY";

          // Check if the export type is PDF
          if (exportType === "pdf") {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${policyName}.pdf`);
            document.body.appendChild(link);
            link.click();
          } else if (exportType === "word") {
            // Handle docx URL download
            const fileUrl = response.data?.fileUrl; // Assuming response.data contains the file URL
            if (fileUrl) {
              let link = document.createElement("a");
              link.href = fileUrl;
              link.setAttribute("download", `${policyName}.docx`);
              document.body.appendChild(link);
              link.click();
            } else {
              showMsg("error", "No download URL for the word file");
            }
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPDF(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to download policy document. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  return { downloadPDF, isLoadingPDF };
}

export default usePolicyPDFDownloader;
