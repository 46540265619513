import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  BACKEND_API_URL,
  CLIENTS_SERVER,
  VERSION_SERVER,
} from "components/utils/constants/misc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import { storeClientContactByClientId } from "features/company/clientContactsActions.js";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

const RetrieveTenantIdFromMsGraphOneDrive = (props) => {
  const { objUser } = props;
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const state = JSON.parse(query.get("state"));
  const routeDictionary = state.routeDictionary;
  const target = state.target;
  // const [tenant, setTenant] = useState(query.get("tenant"));
  const [authCode, setAuthCode] = useState(query.get("code"));
  const [loading, setLoading] = useState(false);
  const [connectionSet, setConnectionSet] = useState(false);

  //console.log(objUser);

  useEffect(() => {
    if (authCode) {
      setLoading(true);
      const saveMsGraphTenantId = async () => {
        try {
          /**
           * This is not actually saving the auth code
           * Instead, we are passing the authcode to the backend, and using that to retrieve
           * and store a refresh token for all susequent calls
           */
          const res = await axios.post(
            `${BACKEND_API_URL}${VERSION_SERVER}/ms-graph/save-auth-code`,
            {
              auth_code: authCode,
              client_id: state.client_id,
              target,
              redirect_uri:
                process.env.REACT_APP_MS_GRAPH_REDIRECT_URI_FOR_ONEDRIVE,
            }
          );
          setLoading(false);
          setConnectionSet(true);
          showMsg("success", "Connection completed successfully");
        } catch (error) {
          setLoading(false);
          const errorMessage = error?.response?.data?.message || "Failed to connect to OneDrive. Please try again.";
          showMsg("error", errorMessage);
        }
      };
      saveMsGraphTenantId();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs title="Connect with Microsoft Graph" />
      <MDBox>
        <Card>
          <MDBox p={2}>
            {loading ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              <>
                {authCode && connectionSet ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      OneDrive permissions granted successfully
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <Link to={"evidence-locker"}>
                        <MDButton variant="contained" color="success">
                          Go back
                        </MDButton>
                      </Link>
                    </p>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "center", color: "red" }}>
                      Connection was not successful. Please try again
                    </p>
                  </>
                )}
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default RetrieveTenantIdFromMsGraphOneDrive;
