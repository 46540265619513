import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useLocation } from "react-router-dom";
import "./settings.css";

import ApiTokensImg from "assets/images/logos/api-tokens.png";
import AzureImg from "assets/images/logos/azure.png";
import ConnectWiseLogo from "assets/images/logos/connectwise-logo2.png";
import SymbolLogo from "assets/images/logos/symbol-logo.png";
import HuntressLogo from "assets/images/logos/huntress-logo.png";
import BreachSecureNowLogo from "assets/images/logos/breachsecurenow.webp";
import DashboardButton from "views/components/DashboardButtton";

const SecurityAwarenessTraining = (props) => {
  const { objUser } = props;
  const isClientUser = objUser.user.role.name === "client_user" ? true : false;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDTypography fontWeight="bold" variant="h5">
        Security Awareness Training Connections
      </MDTypography>

      <MDBox>
        <Grid spacing={2} container width="100%">
          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img
                        src={SymbolLogo}
                        alt="Symbol"
                        style={{ minWidth: "300px" }}
                      />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Symbol
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Sync your clients from your symbol account
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/symbol`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img
                        src={HuntressLogo}
                        alt="Symbol"
                        style={{ minWidth: "450px" }}
                      />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Huntress
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Sync your clients from your huntress account
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/huntress`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img
                        src={BreachSecureNowLogo}
                        alt="Symbol"
                        style={{ minWidth: "450px" }}
                      />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Breach Secure Now
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Sync your clients from your breach secure now account
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/breach-secure-now`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          {!isClientUser ? (
            <Grid item xs={12} lg={6}>
              <Card
                sx={{
                  marginTop: "16px",
                  minHeight: "230px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Grid item xs={12} lg={2}>
                      <img
                        src={SymbolLogo}
                        alt="Symbol"
                        style={{ minWidth: "300px" }}
                      />
                    </Grid> */}
                <MDTypography variant="h5" fontWeight="bold" mb={2}>
                  Want to see your SAT tool here?
                </MDTypography>
                <MDTypography variant="p" fontWeight="regular">
                  <a
                    href="https://featurerequests.compliancescorecard.com/"
                    style={{ color: "blue" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submit a feature request
                  </a>
                  .
                </MDTypography>
                {/* <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/connections/symbol`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography> */}
              </Card>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SecurityAwarenessTraining;
