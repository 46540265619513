import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import CreatePolicyDocumentStepReviewFormikWrapper from "./CreatePolicyDocumentStepReviewFormikWrapper/CreatePolicyDocumentStepReviewFormikWrapper";
import { policyDocumentSaveReviewers } from "features/company/policiesActions";

const objInitialValues = {
  approvers: {},
  reviewers: [{ id: null, label: null }],
  legalReviewers: {},
};

/**This schema validation is for normal policies */
const reviewFormJWTSchema = Yup.object().shape({
  approvers: Yup.object()
    .shape({
      id: Yup.string().required(),
      label: Yup.string().required(),
    })
    .required("Required."),
  reviewers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("ID is required."),
        label: Yup.string().required("Label is required."),
      })
    )
    .required("Required"),
});

/**This schema validation is for asset policies */
const assetPolicyFormJWTSchema = Yup.object().shape({
  approvers: Yup.object()
    .shape({
      id: Yup.string().required(),
      label: Yup.string().required(),
    })
    .required("Required."),
});

const CreatePolicyDocumentStepReview = (props) => {
  const { objUser, objPolicyDocument, loadPolicyDocument, setNextDisabled } =
    props;
  const dispatch = useDispatch();
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);

  const submitDisabled =
    (objPolicyDocument.state && objPolicyDocument.state != "In Review") ||
    objPolicyDocument.is_max_major_version == false;
  const isAssetPolicy =
    objPolicyDocument.policy &&
    objPolicyDocument?.policy?.policy_type === "asset";

  useEffect(() => {
    const nextStates = ["Pending Approval", "Authorized", "Published"];
    if (
      typeof objPolicyDocument.state === "undefined" ||
      !nextStates.includes(objPolicyDocument.state)
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, []);

  const onSubmitForm = async (values) => {
    const objPostData = {
      policy_document_id: objPolicyDocument.id,
      policy_type: objPolicyDocument.policy.policy_type,
      policy_id: objPolicyDocument.policy.id,
      client_id: objPolicyDocument.policy.client_id,
      approvers: values.approvers,
      reviewers: values.reviewers,
      legal_reviewers: values.legalReviewers,
    };
    if (objPostData.legal_reviewers.id === undefined) {
      objPostData.legal_reviewers = "";
    }

    setIsLoading(true);
    dispatch(policyDocumentSaveReviewers({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          // onSubmit(response.data.id);
          showMsg("success", "Reviewers updated.");
          loadPolicyDocument();
        } else {
          showMsg(
            "error",
            `Failed to update reviewers. Server responded with status: ${response.status}`
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            `Failed to update reviewers. Please try again.`
        );
      });
  };

  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={
        isAssetPolicy ? assetPolicyFormJWTSchema : reviewFormJWTSchema
      }
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"CreatePolicyDocumentStepReviewForm"} autoComplete="off">
          <MDBox>
            <CreatePolicyDocumentStepReviewFormikWrapper
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              objStates={objStates}
              objPolicyDocument={objPolicyDocument}
            />

            <MDBox
              sx={{ margin: "0 0 40px 40px" }}
              width="100%"
              display="flex"
              justifyContent="flex-start"
            >
              {isLoading === false ? (
                <MDButton
                  disabled={submitDisabled}
                  type="submit"
                  // variant="gradient"
                  color="success"
                  sx={
                    submitDisabled
                      ? { display: "none" }
                      : { padding: "12px 82px" }
                  }
                >
                  Submit {isAssetPolicy ? "Asset List" : "Policy"} For Review
                </MDButton>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePolicyDocumentStepReview;
