import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardButton from "views/components/DashboardButtton";

import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import { useDispatch, useSelector } from "react-redux";
import {
  listConnectWiseAllClients,
  clearConnectWiseAllClients,
  activateConnectWiseClient,
  deActivateConnectWiseClient,
  deleteConnectWiseClient,
  deleteConnectWiseClientfromStore,
} from "features/company/clientActions";
import { auth } from "../../../../../features/user/userActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomerManagementTable from "./components/CustomerManagementTable/CustomerManagementTable";
import { showMsg } from "utils/general";
import "./CustomerManagement.css";
import MDTypography from "components/MDTypography";
import { displayExceptionErrorMessage } from "utils/general";
const CustomersManagement = (props) => {
  const { objUser } = props;
  const [isLoadingMoreCustomers, setIsLoadingMoreCustomers] = useState(false);
  const [isActivatingClient, setIsActivatingClient] = useState(false);
  const [isDeActivatingClient, setIsDeActivatingClient] = useState(false);
  const [isDeletingClient, setIsDeletingClient] = useState(false);
  const [objSelectedClient, setObjSelectedClient] = useState({});
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [mspClientID, setMspClientID] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [canAddNewClient, setCanAddNewClient] = useState(false);
  const [subPackage, setSubPackage] = useState(null);

  useEffect(() => {
    loadMoreCustomers(perPage, page);

    return () => {
      dispatch(clearConnectWiseAllClients());
    };
  }, []);
  const objSelectedClientRef = useRef(objSelectedClient);
  useEffect(() => {
    objSelectedClientRef.current = objSelectedClient;
  }, [objSelectedClient]);
  const loadMoreCustomers = (_per_page, _page) => {
    setIsLoadingMoreCustomers(true);
    dispatch(listConnectWiseAllClients({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreCustomers(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objCustomersList = response.data.clients;
        setMspClientID(response.data.msp_client);
        if (
          typeof response.data.can_add_client !== "undefined" &&
          response.data.can_add_client === true
        ) {
          setCanAddNewClient(true);
        } else {
          setCanAddNewClient(false);
        }

        if (typeof response.data.plan !== "undefined") {
          setSubPackage(response.data.plan);
        }

        for (let index = 0; index < objCustomersList.length; index++) {
          const element = objCustomersList[index];
          const objTr = {};
          objTr.customerName = element.name;
          objTr.address = element.address_1;
          objTr.city = element.city;
          objTr.state = element.state;
          objTr.zip = element.zip;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreCustomers(false);
        setCanAddNewClient(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };
  const { objListConnectWiseAllClientsData } = useSelector((state) => ({
    objListConnectWiseAllClientsData:
      state.company.client.objListConnectWiseAllClientsData,
  }));

  const activateTheClient = async (objClientData) => {
    setIsActivatingClient(true);
    setObjSelectedClient(objClientData);

    dispatch(
      activateConnectWiseClient({ id: objClientData.element.id, objUser })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsActivatingClient(false);
        setObjSelectedClient({});
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "Client activated.");
        }
        window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsActivatingClient(false);
        setObjSelectedClient({});
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const deActivateTheClient = async (objClientData) => {
    setIsDeActivatingClient(true);
    setObjSelectedClient(objClientData);

    dispatch(
      deActivateConnectWiseClient({ id: objClientData.element.id, objUser })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeActivatingClient(false);
        setObjSelectedClient({});
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "Client deactivated.");
        }
        window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeActivatingClient(false);
        setObjSelectedClient({});
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to deactivate client. Please try again later.";
        showMsg("error", errorMessage);
      });
  };

  const deleteTheClient = async (objClientData) => {
    setIsDeletingClient(true);
    setObjSelectedClient(objClientData);

    const objUserAuthResp = await dispatch(auth());
    const objUserAuth = objUserAuthResp.payload;

    if (objUserAuth.user.company.status === "active") {
      const objPostData = {
        id: objClientData.element.id,
      };
      dispatch(deleteConnectWiseClient(objPostData))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsDeletingClient(false);
          setObjSelectedClient({});
          const response = originalPromiseResult;
          if (response.data.status === "success") {
            showMsg("success", "You have successfully deleted the client.");
            const objNewClientData = Object.assign({}, objClientData.element);
            dispatch(deleteConnectWiseClientfromStore(objNewClientData));
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsDeletingClient(false);
          setObjSelectedClient({});
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        });
    } else {
      setIsDeletingClient(false);
      setObjSelectedClient({});
      showMsg(
        "info",
        "You are using more than your plan allows for, please consider upgrading.."
      );
      navigate("/billing");
    }
  };

  const editTheClient = async (objClientData) => {
    routeDictionary[objClientData.element.id] = objClientData.element.name;
    navigate(`/clients/${objClientData.element.id}/edit`, {
      state: { routeDictionary },
    });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={0}>
          {isAdmin && (
            <>
              <Fragment>
                <DashboardButton
                  component={Link}
                  to={`/clients/add-new-client`}
                  btnText="Add Client"
                  btnIcon={"add"}
                  textColor="white"
                  bgColor="dark"
                  disabled={!canAddNewClient}
                  className="dashboard-button"
                />
              </Fragment>

              {subPackage && (
                <MDTypography sx={{ paddingTop: "1.2rem" }}>
                  You are on a <b>{subPackage?.type} </b>plan that allows for{" "}
                  <b>{subPackage?.units} </b>clients
                  <Link
                    style={{
                      backgroundColor: "#3498db",
                      padding: "0.1rem 0.3rem 0",
                      fontSize: "0.8rem",
                      marginLeft: "1rem",
                      borderRadius: "5px",
                      display: "inline-block",
                      color: "white",
                    }}
                    to="/billing"
                  >
                    UPGRADE
                  </Link>
                </MDTypography>
              )}
            </>
          )}
        </MDBox>
        {!canAddNewClient && (
          <Fragment>
            <MDBox textAlign="left">
              <MDTypography ml={1} variant="caption" color="text">
                Maximum clients per subscription plan reached.
              </MDTypography>
            </MDBox>
          </Fragment>
        )}
        <Card sx={{ marginTop: "16px" }}>
          {isLoadingMoreCustomers === false &&
          objListConnectWiseAllClientsData !== undefined &&
          objListConnectWiseAllClientsData.data !== undefined &&
          objListConnectWiseAllClientsData.data.clients !== undefined &&
          objListConnectWiseAllClientsData.data.clients.length > 0 &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <CustomerManagementTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              loadMoreCustomers={loadMoreCustomers}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              activateTheClient={activateTheClient}
              isActivatingClient={isActivatingClient}
              objSelectedClient={objSelectedClient}
              objSelectedClientRef={objSelectedClientRef}
              objClientsData={objListConnectWiseAllClientsData.data.clients}
              objListConnectWiseAllClientsData={
                objListConnectWiseAllClientsData
              }
              mspClientID={mspClientID}
              setObjTableData={setObjTableData}
              deActivateTheClient={deActivateTheClient}
              isDeActivatingClient={isDeActivatingClient}
              setIsDeActivatingClient={setIsDeActivatingClient}
              editTheClient={editTheClient}
              deleteTheClient={deleteTheClient}
              isDeletingClient={isDeletingClient}
              setIsDeletingClient={setIsDeletingClient}
            />
          ) : (
            <Fragment>
              {isLoadingMoreCustomers ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>Please add some clients first</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default CustomersManagement;
