import { updateRiskRegisterItemState } from "features/company/riskRegisterActions";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import DoubleConfirmationDialog from "./DoubleConfirmationDialog";
import ImageToolTip from "./ImageToolTip";
import RiskRegisterDecidedActionTooltipTable from "./RiskRegisterDecidedActionTooltipTable";
// import Legend from "assets/images/risk-register-actions.png";

const RiskRegisterItemActionBtnTr = (props) => {
  const { row, objUser, loadMoreItems, loadMoreNoActionItems, listId } = props;
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(row.original.will_take_action);
  const [tempAction, setTempAction] = useState("");
  const [openDoubleConfirmationDialog, setOpenDoubleConfirmationDialog] =
    useState(false);
  const dispatch = useDispatch();
  const actions = ["Mitigate", "Transfer", "Avoid", "Accept", "Defer"];

  const onCloseDoubleConfirmationDialog = () => {
    setOpenDoubleConfirmationDialog(false);
  };

  const onSubmitNewNoActionItem = (policy) => {
    setOpenDoubleConfirmationDialog(false);
    loadMoreItems();
  };

  const updateActionState = (value, reason) => {
    if (!value) {
      return;
    }
    if (
      (value == "Defer" || value == "Avoid" || value == "Accept") &&
      !openDoubleConfirmationDialog
    ) {
      setOpenDoubleConfirmationDialog(true);
      setTempAction(value);
      return;
    }
    setTempAction("");
    setOpenDoubleConfirmationDialog(false);
    setLoading(true);
    let itemId = row.original.id;
    const objPostData = {
      action: value,
      reason,
    };

    dispatch(updateRiskRegisterItemState({ objUser, objPostData, itemId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setAction(value);
        if (response.status === 201) {
          setLoading(false);
          showMsg("success", "Item updated successfully");
          loadMoreItems(listId);
          loadMoreNoActionItems(listId);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  function assignColor(condition) {
    switch (condition) {
      case "Mitigate":
        return "#28A745";
      case "Transfer":
        return "#FFC107";
      case "Avoid":
        return "#DC3545";
      case "Accept":
        return "#17A2B8";
      case "Defer":
        return "#6C757D";
      default:
        return "";
    }
  }

  return (
    <Fragment>
      <DoubleConfirmationDialog
        open={openDoubleConfirmationDialog}
        onClose={onCloseDoubleConfirmationDialog}
        onSubmit={updateActionState}
        setAction={setAction}
        tempAction={tempAction}
      />
      {objUser.user.role.name !== "admin" ||
      objUser.user.role.name !== "company_user" ||
      objUser.user?.user_permissions?.risk_register_access == 1 ? (
        <>
          {loading ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox display="flex">
              <MDBox
                display="flex"
                sx={{ maxWidth: "400px", flexWrap: "wrap" }}
                gap={1}
              >
                {actions.map((action) => (
                  <MDButton
                    size="small"
                    sx={{
                      background: assignColor(action),
                      width: "60px",
                      padding: 0,
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: assignColor(action),
                      },
                      textTransform: "capitalize",
                    }}
                    onClick={() => updateActionState(action)}
                  >
                    {action}
                  </MDButton>
                ))}
              </MDBox>
            </MDBox>
          )}
        </>
      ) : (
        <>
          <ImageToolTip info={<RiskRegisterDecidedActionTooltipTable />} /> {action}
        </>
      )}
    </Fragment>
  );
};

export default RiskRegisterItemActionBtnTr;
