import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAdoptionCampaigns,
  _deleteAdoptionCampaign,
} from "features/company/adoptionCampaignActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";
import borders from "assets/theme/base/borders";
import { useConfirm } from "material-ui-confirm";
import DashboardActionButton from "views/components/DashboardActionButton";
import CampaignsManagementTable from "./components/CampaignsTable/ManagementTable";
import dataTableData from "./components/CampaignsTable/DataTableData";
import { displayExceptionErrorMessage } from "utils/general";

const Index = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  // const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [isDeletingObj, setIsDeletingObj] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadMoreAdoptionCampaigns();
  }, []);

  const loadMoreAdoptionCampaigns = (_per_page, _page) => {
    setIsLoadingData(true);
    dispatch(getAdoptionCampaigns({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingData(false);
        populateTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingData(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const populateTableData = (response) => {
    const objRows = [];
    const objList = response.data.campaigns;

    for (let index = 0; index < objList.length; index++) {
      const element = objList[index];
      const objTr = {};
      // objTr.id = element.id_pk;
      objTr.campaign_id = element.campaign_id;
      objTr.name = element.campaign_name;
      objTr.policy_names = element?.policies?.map((policy) => policy.title);
      objTr.deleted = element?.policies.some((policy) => policy.deleted === 'yes') ? 'yes' : 'no';
      objTr.audience = element.audience;
      objTr.status = element.adoption_status;
      objTr.created_at = standardDateFormat(element.created_at);
      objRows.push(objTr);
    }
    setTotalCount(response.data.campaigns.length);
    setObjTableData({
      rows: objRows,
    });
  };

  const deleteObj = async (dataFromActionBtn) => {
    setIsLoadingData(true);
    dispatch(_deleteAdoptionCampaign({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingData(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Campaign deleted");
          loadMoreAdoptionCampaigns();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingData(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const navigateToCreateNewCampaign = () => {
    navigate(`/adoption-campaigns/new`);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={2}
            >
              <MDTypography variant="h4" sx={{ paddingTop: "1.2rem" }}>
                Policy Adoption Campaigns
              </MDTypography>
              <DashboardActionButton
                action={navigateToCreateNewCampaign}
                btnText="Create New Campaign"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </MDBox>
            {/* <MDBox>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                This allows you to create WISPs for published documents. If your
                policy is using the OSCAL format, we will pull in the following
                sections: {oscalSectionsToInclude}, as well as a link to the
                Knowledge Base document. If you aren't using OSCAL format, we
                will only display a link to the Knowledge Base document.
              </Alert>
            </MDBox> */}
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingData ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalCount === 0 ? (
                    <>
                      {/* <MDBox mb={2}>
                        <img src={wispImageLogo} width="90px" alt="" />
                      </MDBox> */}
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You haven't created any adoption campaigns yet
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          type="submit"
                          // variant="gradient"
                          color="success"
                          onClick={() => navigateToCreateNewCampaign()}
                          sx={{ padding: "12px 20px", margin: "0px 5px" }}
                        >
                          Create New Campaign
                        </MDButton>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <CampaignsManagementTable
                        table={objTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalCount}
                        loadMoreObj={loadMoreAdoptionCampaigns}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        _page={page}
                        setPage={setPage}
                        setObjTableData={setObjTableData}
                        deleteObj={deleteObj}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;
