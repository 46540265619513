import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaveApiKeysForm from "./components/SaveApiKeysForm";

const Index = (props) => {
  const navigate = useNavigate();
  const { objUser } = props;
  const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    if (
      objUser.user.role.name != "admin" &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid
        my={3}
        container
        // justifyContent="center"
        // alignItems="center"
        width="100%"
        spacing={2}
      >
        <SaveApiKeysForm id={id} objUser={objUser} />
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default Index;
