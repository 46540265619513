// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { exportAssessmentTemplate } from "features/company/assessmentsActions";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

const TemplateExportBtnTr = (props) => {
  const {row, reloadFunc, objUser } = props;
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();

  const exportTemplateAction = () => {
    setIsExporting(true);
    dispatch(exportAssessmentTemplate(
      {
        objUser,
        templateId: row.original.id
      }))
    .then(unwrapResult)
    .then((originalPromiseResult) => {
      setIsExporting(false);
      const response = originalPromiseResult;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${row.original.name} assessmentTemplate.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((rejectedValueOrSerializedError) => {
      setIsExporting(false);
      showMsg("error", rejectedValueOrSerializedError.response?.data?.message || "An error occurred while trying to export assessment csv. Try again.");
    });
  };

  return isExporting ? (
    <LoadingSpinner subClass="text-center" color="success" size="sm" />
  ) : (
    <MDButton
      onClick={exportTemplateAction}
      variant="gradient"
      sx={{ marginLeft: "1rem" }}
    >
      <MDBox color="gray" display="flex" alignItems="center">
        <Icon sx={{ fontWeight: "bold" }}>download</Icon>
      </MDBox>
    </MDButton>
  );
};

export default TemplateExportBtnTr;
