import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Papa from "papaparse";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { importCSVTaskListTemplate } from "features/company/taskListActions";
import FileUpload from "./FileUpload";

const CSVTemplateImportDialog = ({ open, onClose, objUser, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [importArray, setImportArray] = useState([]);
  const [csvColumns, setCSVColumns] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();

  function parseCSV(file) {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const errors = [];
          const validatedData = results.data
            .map((item, index) => {
              if (!item.title || item.title.trim() === "") {
                errors.push(
                  `Row ${index + 1}: Missing required field "title".`
                );
                return null; // Exclude invalid rows
              }

              if (!item.item_title || item.item_title.trim() === "") {
                errors.push(
                  `Row ${index + 1}: Missing required field "item_title".`
                );
                return null; // Exclude invalid rows
              }

              return {
                title: item.title.trim(),
                notes: item.notes.trim(),
                item_description: item.item_description?.trim() || null,
                item_title: item.item_title?.trim(),
                item_start_date: item.item_start_date?.trim() || null,
                item_end_date: item.item_end_date?.trim() || null,
                item_priority_level:
                  item.item_priority_level?.trim() || "medium",
                item_notes: item.item_notes?.trim() || null,
                estimated_level_of_effort:
                  parseFloat(item.estimated_level_of_effort?.trim()) || null,
                item_status: item.item_status?.trim() || "ToDo",
              };
            })
            .filter((row) => row !== null); // Remove invalid rows

          if (errors.length > 0) {
            console.error("Validation Errors:", errors.join("\n"));
            errors.forEach((error) => {
              showMsg("error", error);
            });
          }

          setImportArray(validatedData);
        },
        error: function (err, file, inputElem, reason) {
          console.error("Error parsing CSV:", err, reason);
        },
      });
    }
  }

  const onSubmitForm = async (values) => {
    if (importArray.length === 0) {
      showMsg("error", "Please upload a CSV file before importing.");
      return;
    }

    const template = {
      scope: "global",
      import_array: importArray,
    };

    setIsLoading(true);
    dispatch(importCSVTaskListTemplate({ objUser, template }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "Template imported successfully.");
          onSubmit(response.data);
        } else {
          showMsg("error", "Failed to import template.");
        }
      })
      .catch((error) => {
        showMsg("error", error.message || "An unexpected error occurred.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload CSV Template</DialogTitle>
      <Formik
        initialValues={{
          txtTitle: "",
          txtNotes: "",
        }}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="ImportTaskListCSVForm" autoComplete="off">
            <DialogContent>
              <MDBox>
                <FileUpload onFileSelect={(file) => parseCSV(file)} />
              </MDBox>
            </DialogContent>

            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Import
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CSVTemplateImportDialog;
