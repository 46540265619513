import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { Checkbox } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { bulkImportTemplates } from "features/company/templatesActions";
import TermsandConditions from "../../../SignUpFunnel/SignUpSelectOrganizationType/components/TermsandConditions";

function AgreeToTermsForBulkImportDialog({
  objUser,
  open,
  onClose,
  globalTemplateIds,
  onSubmit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [isMSPWide, setIsMSPWide] = useState(null);
  const [isCopyRightAccepted, setIsCopyRightAccepted] = useState(false);
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);
  const [termsAndConditionsDisplay, setTermsAndConditionsDisplay] =
    useState("none");
  const dispatch = useDispatch();
  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  const proceedToCloneTemplate = () => {
    if (isMSPWide == null) {
      showMsg("error", "Please specify document scope");
      return;
    }
    if (isTosAccepted && isCopyRightAccepted && isDisclaimerAccepted) {
      // onClose();
      // openCloneTemplateDialog();
      const objPostData = {
        global_template_ids: globalTemplateIds,
        agreed_to_terms: true,
        acknowledged_copyright: true,
        acknowledged_disclaimer: true,
        client_id: isMSPWide ? null : currentClient.id,
      };
      setIsLoading(true);
      dispatch(bulkImportTemplates({ objUser, objPostData }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          showMsg("success", "Templates imported successfully!");
          onClose();
          setIsLoading(false);
          onSubmit();
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoading(false);
          const objErrorRsp = rejectedValueOrSerializedError.response;
          console.log(rejectedValueOrSerializedError);
          showMsg(
            "error",
            rejectedValueOrSerializedError?.message || "Failed to import templates. Please try again later."
          );
        });
    } else {
      showMsg("error", "Please agree to all terms and conditions to proceed");
    }
  };

  const handleTermsAndConditionsToggle = () => {
    if (termsAndConditionsDisplay == "none") {
      setTermsAndConditionsDisplay("block");
    } else {
      setTermsAndConditionsDisplay("none");
    }
  };

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Agree To Terms Last Update:{" "}
        <b style={{ color: "red" }}>March 19, 2024</b>
      </DialogTitle>
      <DialogContent>
        <Checkbox
          checked={isTosAccepted}
          onChange={(event) => {
            setIsTosAccepted(event.target.checked);
          }}
        />

        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
          }}
        >
          &nbsp;&nbsp;I accept the{" "}
          <a
            href="https://trust.compliancescorecard.com"
            target="_blank"
            // onClick={handleTermsAndConditionsToggle}
          >
            terms and conditions
          </a>
          &nbsp;
        </MDTypography>

        <MDTypography
          sx={{
            display: `${termsAndConditionsDisplay}`,
            margin: "0 !important",
          }}
        >
          <TermsandConditions />
        </MDTypography>
        <br />
        <Checkbox
          checked={isCopyRightAccepted}
          onChange={(event) => {
            setIsCopyRightAccepted(event.target.checked);
          }}
        />

        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Acknowledge copyright&nbsp;
        </MDTypography>
        <br />
        <Checkbox
          checked={isDisclaimerAccepted}
          onChange={(event) => {
            setIsDisclaimerAccepted(event.target.checked);
          }}
        />

        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Acknowledge disclaimer&nbsp;
        </MDTypography>

        <MDBox mb={0} ml={1} mt={0}>
          <Autocomplete
            disableClearable
            options={[
              { name: "MSP-Wide Templates", value: null },
              {
                name: currentClient.name + " Templates",
                value: currentClient.id,
              },
              // ...values.txtTemplate,
              // ...templates,
            ]}
            // options={templatesWithDefaultValue}
            getOptionLabel={(option) => (option ? option.name : "")}
            renderInput={(params) => (
              <MDInput
                {...params}
                variant="standard"
                label="Document Scope"
                fullWidth
              />
            )}
            onChange={(event, value) => {
              if (value.value === null) {
                setIsMSPWide(true);
              } else {
                setIsMSPWide(false);
              }
            }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        {isLoading === false ? (
          <MDButton
            onClick={proceedToCloneTemplate}
            // variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Proceed
          </MDButton>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default AgreeToTermsForBulkImportDialog;
