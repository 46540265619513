import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import {
  createNewEvent,
} from "features/company/assessmentsActions.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// formik components
import { Formik, Form } from "formik";
const objInitialValues = { cloneInfo: 1 };

function CreateNewEventDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  templateId,
  assessmentName,
}) {
  const [isSubmittingEvent, setIsSubmittingEvent] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      templateId: templateId,
      cloneRecentEventInformation: values.cloneInfo,
    };
    setIsSubmittingEvent(true);
    dispatch(createNewEvent({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit({
            id: response.data.id,
            name: response.data.name,
            assessmentTemplateId: response.data.assessment_template_id,
          });
        } else {
          throw new Error("Failed to create a new event. Please check your input and try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized to create new event");
        } else {
           showMsg(
             "error",
             `(Error Code: ${rejectedValueOrSerializedError.response?.data?.error_code}) ${rejectedValueOrSerializedError.response?.data?.message}`
           );
        }
      })
      .finally(() => {
        setIsSubmittingEvent(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Create new version of {assessmentName}{" "}
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="CloneEventForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0} ml={2}>
                <FormControlLabel
                  control={
                    <Switch checked={values.cloneInfo === 1} name="cloneInfo" />
                  }
                  label="Clone Most Recent Event Information"
                  onChange={() => {
                    if (values.cloneInfo === 1) {
                      setFieldValue("cloneInfo", 0);
                    } else {
                      setFieldValue("cloneInfo", 1);
                    }
                  }}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isSubmittingEvent === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Ok
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewEventDialog;
