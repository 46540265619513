import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  FormControlLabel,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { standardDateFormat } from "utils/general";
import MDTypography from "components/MDTypography";
import { getPrioritySymbol } from "../util";
import { updateTaskListItem } from "features/company/taskListActions";

const TaskListItemFormSchema = Yup.object().shape({
  title: Yup.string().required("The Title is required."),
  description: Yup.string().nullable(),
  start_date: Yup.date().nullable(),
  due_date: Yup.date().nullable(),
  responsible_user_id: Yup.string().nullable(),
  is_subtask_of_id: Yup.string().nullable(),
  is_blocker_task_of_id: Yup.string().nullable(),
  link: Yup.string().nullable().url("Must be a valid URL."),
  priority_level: Yup.string()
    .oneOf(["low", "medium", "high", "highest"], "Invalid priority level")
    .required("Priority Level is required."),
  notes: Yup.string().nullable(),
});

const ViewOrEditTaskListItemDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskListItem,
  taskListItems,
  taskListItemsUsers,
  taskListId,
  isFetchingUsers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taskListItemList, setTaskListItemList] = useState(taskListItems);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taskListItems) {
      setTaskListItemList(
        taskListItems.filter((item) => item.id !== taskListItem?.id)
      );
    }
  }, [taskListItems, taskListItem]);

  const onSubmitForm = async (values) => {
    const objData = {
      id: taskListItem.id,
      title: values.title,
      start_date: values.start_date,
      due_date: values.due_date,
      responsible_user_id: values.responsible_user_id,
      is_subtask_of_id: values.is_subtask_of_id,
      is_blocker_task_of_id: values.is_blocker_task_of_id,
      link: values.link,
      priority_level: values.priority_level,
      notes: values.notes,
    };

    setIsLoading(true);
    dispatch(updateTaskListItem({ objUser, objData, taskListId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          onSubmit(response.data);
        } else {
          showMsg("error", `Failed to update task: ${response?.data?.message || "An unexpected error occurred."}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError?.response?.data?.message || "Failed to update task. Please try again");
        console.error(rejectedValueOrSerializedError);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {editMode && "Edit"} {taskListItem?.title}
          <MDBox
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <MDTypography fontWeight="bold">View</MDTypography>
            <FormControlLabel
              control={
                <Switch
                  checked={editMode}
                  onChange={() => setEditMode((prev) => !prev)}
                  color="primary"
                />
              }
              label="Edit"
            />
          </MDBox>
        </MDBox>
      </DialogTitle>
      <Formik
        initialValues={{
          title: taskListItem?.title || "",
          description: taskListItem?.description || "",
          start_date: taskListItem?.start_date || "",
          due_date: taskListItem?.due_date || "",
          responsible_user_id: taskListItem?.responsible_user_id || "",
          is_subtask_of_id: taskListItem?.is_subtask_of_id || "",
          is_blocker_task_of_id: taskListItem?.is_blocker_task_of_id || "",
          link: taskListItem?.link || "",
          priority_level: taskListItem?.priority_level || "",
          notes: taskListItem?.notes || "",
        }}
        validationSchema={TaskListItemFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form id="TaskForm" autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Title"
                    name="title"
                    value={values.title}
                    placeholder="Task Title"
                    error={errors.title && touched.title}
                    success={values.title?.length > 0 && !errors.title}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Description"
                    name="description"
                    value={values.description}
                    placeholder="Task Description"
                    error={errors.description && touched.description}
                    success={
                      values.description?.length > 0 && !errors.description
                    }
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    label="Start Date"
                    name="start_date"
                    value={values.start_date}
                    error={errors.start_date && touched.start_date}
                    success={
                      values.start_date?.length > 0 && !errors.start_date
                    }
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    label="Due Date"
                    name="due_date"
                    value={values.due_date}
                    error={errors.due_date && touched.due_date}
                    success={values.due_date?.length > 0 && !errors.due_date}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disableClearable
                    options={
                      taskListItemsUsers
                        ? taskListItemsUsers?.map((user) => ({
                            label: `${user.name} ${user.last_name}`,
                            value: user.id,
                          }))
                        : []
                    }
                    value={
                      taskListItemsUsers
                        ? taskListItemsUsers
                            .map((user) => ({
                              label: `${user.name} ${user.last_name}`,
                              value: user.id,
                            }))
                            .find(
                              (option) =>
                                option.value === values.responsible_user_id
                            ) || null
                        : null
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(event, newValue) =>
                      setFieldValue(
                        "responsible_user_id",
                        newValue ? newValue.value : null
                      )
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Responsible User"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disableClearable
                    options={["low", "medium", "high", "highest"].map(
                      (priorityLevel) => ({
                        label: priorityLevel,
                        value: priorityLevel,
                        icon: getPrioritySymbol(priorityLevel),
                      })
                    )}
                    value={values.priority_level}
                    onChange={(event, value) =>
                      setFieldValue("priority_level", value.value)
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Priority Level"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={option.icon}
                          alt={`${option.label} priority`}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        {option.label}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Link"
                    name="link"
                    value={values.link}
                    error={errors.link && touched.link}
                    success={values.link?.length > 0 && !errors.link}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled={!editMode}
                    disableClearable
                    options={taskListItemList?.map((filteredTaskListItem) => ({
                      label: filteredTaskListItem.title,
                      value: filteredTaskListItem.id,
                    }))}
                    value={
                      taskListItemList
                        .map((filteredTaskListItem) => ({
                          label: filteredTaskListItem.title,
                          value: filteredTaskListItem.id,
                        }))
                        .find(
                          (option) => option.value === values.is_subtask_of_id
                        ) || null
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(event, value) => {
                      setFieldValue(
                        "is_subtask_of_id",
                        value ? value.value : ""
                      );
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Is Sub Task Of"
                      />
                    )}
                  />

                  {errors.is_subtask_of_id && touched.is_subtask_of_id ? (
                    <FormHelperText error>
                      {errors.is_subtask_of_id}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled={!editMode}
                    disableClearable
                    options={taskListItemList.map((filteredTaskListItem) => ({
                      label: filteredTaskListItem.title,
                      value: filteredTaskListItem.id,
                    }))}
                    value={
                      taskListItemList
                        .map((filteredTaskListItem) => ({
                          label: filteredTaskListItem.title,
                          value: filteredTaskListItem.id,
                        }))
                        .find(
                          (option) =>
                            option.value === values.is_blocker_task_of_id
                        ) || null
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(event, value) => {
                      setFieldValue(
                        "is_blocker_task_of_id",
                        value ? value.value : ""
                      );
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Is Blocker Task Of"
                      />
                    )}
                  />
                  {errors.is_blocker_task_of_id &&
                  touched.is_blocker_task_of_id ? (
                    <FormHelperText error>
                      {errors.is_blocker_task_of_id}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Notes"
                    name="notes"
                    value={values.notes}
                    error={errors.notes && touched.notes}
                    success={values.notes?.length > 0 && !errors.notes}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Status"
                    name="status"
                    value={taskListItem?.status}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="datetime"
                    label="Created At"
                    name="created_at"
                    value={standardDateFormat(
                      taskListItem?.created_at || new Date(),
                      "add_timestamp"
                    )}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="datetime"
                    label="Updated At"
                    name="updated_at"
                    value={standardDateFormat(
                      taskListItem?.updated_at || new Date(),
                      "add_timestamp"
                    )}
                    disabled
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  {editMode ? (
                    <MDButton
                      type="submit"
                      color="success"
                      disabled={!editMode}
                    >
                      Update
                    </MDButton>
                  ) : null}
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                  >
                    {editMode ? "Cancel" : "Close"}
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner size="lg" />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ViewOrEditTaskListItemDialog;
