import { useEffect, useState, useMemo } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  getAllowedDomains,
  deleteAllowedDomainData,
  getNonExpiryKB,
  getActiveKnowledgeBaseShares,
} from "features/company/knowledgeBaseActions";
import DomainsManagementTable from "./components/DomainsTable/DomainsManagementTable";
import KnowledgeBaseSharesTable from "./components/KnowledgeBaseSharesTable/KnowledgeBaseSharesTable";
import CreateNewDomainDialog from "./components/DomainsTable/createNewDomainDialog";
import EditDomainDialog from "./components/DomainsTable/EditDomainDialog";
import { fetchClientContactsById } from "features/company/clientContactsActions";
import CreateNewKBSDialog from "./components/KnowledgeBase/CreateNewKBSDialog";
import dataTableData from "./components/ClientContactsDataByIdTable/ClientContactsDataTableData";
import ClientContactsDataByIdTable from "./components/ClientContactsDataByIdTable/ClientContactsDataByIdTable";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardLayout from "views/containers/DashboardLayout";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { showMsg } from "utils/general";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

import ToolTip from "./components/ToolTip";
import { Formik, Form, ErrorMessage, Field } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";

import * as Yup from "yup";
// formik components
import { Checkbox, TextField } from "@mui/material";
import { fetchPublicLinkPassword } from "features/company/CompanyConfigActions";
import { updatePublicLinkPassword } from "features/company/CompanyConfigActions";
import { updatePublicLinkPasswordActiveStatus } from "features/company/CompanyConfigActions";
import moment from "moment";

const objInitialValues = {
  txtKBSExpiration: null,
};

const objInitiaPasswordlValues = {
  password: "",
  passwordConfirmation: "",
};

const PublicPasswordFormJWTSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/\d/, "Password must contain at least one number.")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one !@#$%^&*(),?":{}|<>.'
    )
    ,
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .required("Password confirmation is required."),
});

const KnowledgeBase = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [nonExpiryKB, setNonExpiryKB] = useState({});
  const [openNewKBSDialog, setOpenNewKBSDialog] = useState(false);
  const [isLoadingClientContact, setIsLoadingClientContact] = useState(false);
  const [objSelectedClientContact, setObjSelectedClientContact] = useState({});
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [firstCompanyContact, setFirstCompanyContact] = useState(null);

  /**domains */
  const [isLoadingMoreDomains, setIsLoadingMoreDomains] = useState(false);
  const [isDeletingDomain, setIsDeletingDomain] = useState(false);
  const [objDomainsTableData, setObjDomainsTableData] = useState(dataTableData);
  const [totalDomainsCount, setDomainsTotalCount] = useState(0);
  const [openNewDomainDialog, setOpenNewDomainDialog] = useState(false);
  const [openEditDomainDialog, setOpenEditDomainDialog] = useState(false);
  const [editDomainDialogData, setEditDomainDialogData] = useState({});
  const [perDomainsPage, setPerDomainsPage] = useState(10);
  const [pageDomains, setPageDomains] = useState(1);
  const [link, setLink] = useState("");

  /**knowledge base shares */
  const [isLoadingMoreKbs, setIsLoadingMoreKbs] = useState(false);
  const [objKbsTableData, setObjKbsTableData] = useState(dataTableData);
  const [totalKbsCount, setKbsTotalCount] = useState(0);
  const [perKbsPage, setPerKbsPage] = useState(10);
  const [pageKbs, setPageKbs] = useState(1);

  const { objfetchClientContactsByIdData } = useSelector((state) => ({
    objfetchClientContactsByIdData:
      state.company.clientContacts.objfetchClientContactsByIdData,
  }));
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);
  const [contactsSelectedForBulkAction, setContactsSelectedForBulkAction] =
    useState([]);

  // Public Password
  const [publicLinkPassword, setPublicLinkPassword] = useState(null);
  const [publicLinkPasswordActivated, setPublicLinkPasswordActivated] =
    useState(false);
  const [isUpdatingPublicPassword, setIsUpdatingPublicPassword] =
    useState(false);

  const fetchNonExpiryKB = () => {
    setIsLoading(true);
    dispatch(getNonExpiryKB({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setNonExpiryKB(originalPromiseResult.data);
        objInitialValues.txtKBSExpiration =
          originalPromiseResult.data.expires_at;
        if (originalPromiseResult.data.expires_at) {
          loadMoreDomains(perDomainsPage, pageDomains);
          setLink(
            `<iframe src="${window.location.origin}/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${originalPromiseResult.data.id}/${originalPromiseResult.data.shared_link_id}" width="1024" height="700" frameborder="0" allowfullscreen></iframe>`
          );
        } else {
          setIsLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  const loadMoreKnowledgeBaseShares = (_per_page, _page) => {
    setIsLoadingMoreKbs(true);
    dispatch(getActiveKnowledgeBaseShares({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreKbs(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objKnowledgeBases = response.data.knowledge_base_shares;

        for (let index = 0; index < objKnowledgeBases.length; index++) {
          const element = objKnowledgeBases[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.link = `${window.location.origin}/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${element.id}/${element.shared_link_id}`;
          objTr.contacts = element.contacts;
          objTr.expiryDate = element.expires_at;
          objTr.element = element;
          objRows.push(objTr);
        }
        setKbsTotalCount(response.data.total);
        setObjKbsTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreKbs(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (objErrorRsp && objErrorRsp.status === 401 && objErrorRsp.statusText === "Unauthorized") {
          // Handle 401 Unauthorized error, e.g., redirect to login
          showMsg("error", "Your session has expired. Please log in again.");
          // For example: navigate('/login');
        } else if (objErrorRsp) {
          showMsg("error", objErrorRsp.data?.message || "Failed to load more Knowledge Base shares. Please try again.");
        } else {
          showMsg("error", "An unexpected error occurred. Please try again later.");
        }
      });
  };

  const loadMoreClientContactsById = (_per_page, _page) => {
    dispatch(fetchClientContactsById({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objClientContactsList = response.data.contacts;

        for (let index = 0; index < objClientContactsList.length; index++) {
          const element = objClientContactsList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.firstname = element.first_name;
          objTr.lastname = element.last_name;
          objTr.email = element.email;
          objTr.member =
            element.user !== null && typeof element.user !== undefined
              ? true
              : false;
          objTr.first_msp_contact =
            response.data.first_company_contact_id.contact_id;
          objTr.element = element;
          objRows.push(objTr);
        }
        setFirstCompanyContact(
          response.data.first_company_contact_id.contact_id
        );
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientContact(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (objErrorRsp && objErrorRsp.status === 401 && objErrorRsp.statusText === "Unauthorized") {
          showMsg("error", "Your session has expired. Please log in again.");
        } else if (objErrorRsp) {
          showMsg("error", objErrorRsp.data?.message || "Failed to load more client contacts. Please try again.");
        } else {
          showMsg("error", "An unexpected error occurred. Please try again later.");
        }
      });
  };

  const loadMoreDomains = (_per_page, _page) => {
    setIsLoadingMoreDomains(true);
    dispatch(getAllowedDomains({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreDomains(false);
        setIsLoading(false);
        populateDomainsTableData(originalPromiseResult);
      })
      .catch((err) => {
        setIsLoadingMoreDomains(false);
        const objErrorRsp = err.response;
        if (objErrorRsp) {
          if (objErrorRsp.status === 401 && objErrorRsp.statusText === "Unauthorized") {
            showMsg("error", "Your session has expired. Please log in again.");
          } else {
            showMsg("error", objErrorRsp.data?.message || "Failed to load more domains. Please try again.");
          }
          console.error("loadMoreDomains error:", objErrorRsp);
        } else {
          showMsg("error", "An unexpected error occurred. Please try again later.");
          console.error("loadMoreDomains error:", err);
        }
      });
  };

  const onCloseNewKBSDialog = () => {
    setOpenNewKBSDialog(false);
  };

  const onSuccessNewKBSDialog = () => {
    setOpenNewKBSDialog(false);
    showMsg("success", "Knowledge base shared successfully.");
    setContactsSelectedForBulkAction([]);
    loadMoreKnowledgeBaseShares(perKbsPage, pageKbs);
    // fetchNonExpiryKB();
  };

  const btnOnClickNewDomain = () => {
    setOpenNewDomainDialog(true);
  };

  const onCloseNewDomainDialog = () => {
    setOpenNewDomainDialog(false);
  };

  const deleteDomain = async (dataFromActionBtn) => {
    setIsDeletingDomain(true);
    dispatch(deleteAllowedDomainData({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingDomain(false);

        const response = originalPromiseResult;
        if (response.status == 200 || response.status == 201) {
          showMsg("success", "Domain deleted");
          populateDomainsTableData(response);
          // window.location.reload();
        }
      })
      .catch((err) => {
        setIsDeletingDomain(false);
        const errorMessage = err.response?.data?.message || "Failed to delete domain. Please try again.";
        showMsg("error", errorMessage);
        console.error("delete domain error:", err);
      });
  };

  const onSubmitNewDomainDialog = (response) => {
    populateDomainsTableData(response);
    setOpenNewDomainDialog(false);
  };

  const editDomain = (dataFromActionBtn) => {
    setEditDomainDialogData(dataFromActionBtn);
    setOpenEditDomainDialog(true);
  };

  const onCloseEditDomainDialog = () => {
    setOpenEditDomainDialog(false);
  };

  const onSubmitEditDomainDialog = (response) => {
    populateDomainsTableData(response);
    setOpenEditDomainDialog(false);
  };

  const populateDomainsTableData = (response) => {
    const objRows = [];
    const objDomainsList = response.data.domains;
    for (let index = 0; index < objDomainsList.length; index++) {
      const element = objDomainsList[index];
      const objTr = {};
      objTr.domainName = element.domain;
      objTr.domainId = element.id;
      objRows.push(objTr);
    }
    setDomainsTotalCount(response.data.total);
    setObjDomainsTableData({
      ...objDomainsTableData,
      rows: objRows,
    });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    showMsg("info", "Embed link copied successfully.");
  };

  const loadPubilcLinkPassword = () => {
    setIsUpdatingPublicPassword(true);
    dispatch(fetchPublicLinkPassword({ objUser }))
      .then(unwrapResult)
      .then((response) => {
        setPublicLinkPassword(response.data.publicLinkPassword);
        if (response.data.publicLinkPassword?.is_active == 1) {
          setPublicLinkPasswordActivated(true);
        } else {
          setPublicLinkPasswordActivated(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "Fetch public link password",
          rejectedValueOrSerializedError
        );
        showMsg("error", "Error fetching public link password");
      })
      .finally(() => {
        setIsUpdatingPublicPassword(false);
      });
  };

  const updatePublicPassword = (values) => {
    const objPostData = {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    };
    setIsUpdatingPublicPassword(true);
    dispatch(updatePublicLinkPassword({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        loadPubilcLinkPassword();
        const response = originalPromiseResult;
        showMsg("success", "Password updated successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingPublicPassword(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  const updatePasswordActiveStatus = () => {
    const objPostData = {
      is_active: publicLinkPasswordActivated ? 0 : 1,
    };
    setIsUpdatingPublicPassword(true);
    dispatch(updatePublicLinkPasswordActiveStatus({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        loadPubilcLinkPassword();
        const response = originalPromiseResult;
        showMsg(
          "success",
          `Password ${
            objPostData.is_active == 1 ? "activated" : "deactivated"
          } successfully`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingPublicPassword(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchNonExpiryKB();
    loadPubilcLinkPassword();
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CreateNewKBSDialog
          open={openNewKBSDialog}
          onClose={onCloseNewKBSDialog}
          onSubmit={onSuccessNewKBSDialog}
          contacts={contactsSelectedForBulkAction}
          objUser={props.objUser}
        />
      </LocalizationProvider>

      <CreateNewDomainDialog
        open={openNewDomainDialog}
        onClose={onCloseNewDomainDialog}
        onSubmit={onSubmitNewDomainDialog}
        objUser={props.objUser}
      />
      <EditDomainDialog
        open={openEditDomainDialog}
        onClose={onCloseEditDomainDialog}
        onSubmit={onSubmitEditDomainDialog}
        objUser={props.objUser}
        editDomainDialogData={editDomainDialogData}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container>
            <Grid item xs={6}>
              <MDBox textAlign="left">
                <MDTypography variant="h2"> Knowledge Base</MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={6} textAlign="right">
              <Link
                to={`/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${nonExpiryKB.id}/${nonExpiryKB.shared_link_id}`}
                target="_blank"
              >
                <MDButton
                  type="button"
                  color="success"
                  sx={{ padding: "12px 60px" }}
                >
                  View Knowledge Base
                </MDButton>
              </Link>
            </Grid>
          </Grid>
          <Card sx={{ marginTop: "20px", display: "none" }}>
            {isLoadingClientContact === false &&
            objfetchClientContactsByIdData !== undefined &&
            objfetchClientContactsByIdData.data !== undefined &&
            objfetchClientContactsByIdData.data.contacts !== undefined &&
            objfetchClientContactsByIdData.data.contacts.length > 0 &&
            objTableData.rows.length > 0 &&
            totalCount > 0 ? (
              <>
                <MDBox
                  display="flex"
                  gap=".6rem"
                  alignItems="flex-start"
                  p={2}
                  pb={0}
                >
                  {isBulkActionLoading ? (
                    <MDBox sx={{ paddingTop: "2.5rem" }}>
                      <LoadingSpinner color="success" size="lg" />
                    </MDBox>
                  ) : (
                    <>
                      <DashboardActionButton
                        action={() => setOpenNewKBSDialog(true)}
                        btnText={`Share Knowledge Base With ${
                          contactsSelectedForBulkAction.length > 0
                            ? contactsSelectedForBulkAction.length
                            : "All"
                        } Contacts`}
                        btnIcon="share"
                        textColor="white"
                        bgColor="success"
                      />
                    </>
                  )}
                </MDBox>

                <ClientContactsDataByIdTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalCount}
                  loadMoreClientContactsById={loadMoreClientContactsById}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  objClientsContactsData={
                    objfetchClientContactsByIdData.data.contacts
                  }
                  objfetchClientContactsByIdData={
                    objfetchClientContactsByIdData
                  }
                  setObjTableData={setObjTableData}
                  objSelectedClientContact={objSelectedClientContact}
                  setObjSelectedClientContact={setObjSelectedClientContact}
                  setContactsSelectedForBulkAction={
                    setContactsSelectedForBulkAction
                  }
                  contactsSelectedForBulkAction={contactsSelectedForBulkAction}
                  firstCompanyContact={firstCompanyContact}
                />
              </>
            ) : (
              <Fragment>
                {isLoadingClientContact ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    Client does not have any contacts.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
          <MDBox sx={{ marginTop: "40px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <MDBox p={3}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <MDTypography
                        variant="h5"
                        fontWeight="bold"
                        display="flex"
                        alignItems="flex-start"
                      >
                        Allowed Domains
                      </MDTypography>

                      <Fragment>
                        <DashboardActionButton
                          action={btnOnClickNewDomain}
                          btnText="Add Domain"
                          btnIcon="add"
                          textColor="white"
                          bgColor="success"
                          marginTop="0px"
                        />
                      </Fragment>
                    </MDBox>
                    {isLoadingMoreDomains === false &&
                    objDomainsTableData.rows.length > 0 &&
                    totalDomainsCount > 0 &&
                    isDeletingDomain == false ? (
                      <DomainsManagementTable
                        table={objDomainsTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalDomainsCount}
                        loadMoreDomains={loadMoreDomains}
                        perPage={perDomainsPage}
                        setPerPage={setPerDomainsPage}
                        _page={pageDomains}
                        setPage={setPageDomains}
                        setObjTableData={setObjDomainsTableData}
                        deleteDomain={deleteDomain}
                        editDomain={editDomain}
                      />
                    ) : (
                      <Fragment>
                        {isLoadingMoreDomains || isDeletingDomain == true ? (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        ) : (
                          <p>Please add some domains first.</p>
                        )}
                      </Fragment>
                    )}
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <MDBox></MDBox>
                <Card>
                  <MDBox p={3}>
                    <MDTypography
                      variant="h5"
                      fontWeight="bold"
                      display="flex"
                      alignItems="flex-start"
                    >
                      Knowledge Base Embed Code
                    </MDTypography>
                    <MDBox
                      mt={4}
                      onClick={copyLink}
                      position="relative"
                      sx={{ cursor: "pointer" }}
                    >
                      <TextField
                        label="Code"
                        value={link}
                        multiline
                        sx={{
                          marginTop: "0.3rem",
                          width: "100%",
                          pointerEvents: "none",
                        }}
                      />
                      <MDBox
                        textAlign={"center"}
                        position="absolute"
                        sx={{ top: "0", right: "0" }}
                      >
                        <MDButton type="button">
                          <Icon fontSize="large">copy</Icon>
                          <MDTypography
                            fontWeight="bold"
                            sx={{ marginLeft: "0.75rem" }}
                            variant="button"
                            color="text.dark"
                          >
                            Copy
                          </MDTypography>
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>

                <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                  {isUpdatingPublicPassword ? (
                    <LoadingSpinner subClass="text-center" size="lg" />
                  ) : (
                    <Grid container width="100%">
                      <Grid item xs={12}>
                        <MDTypography
                          fontWeight="bold"
                          variant="h5"
                          display="flex"
                          alignItems="flex-start"
                        >
                          Public Link Password
                          <ToolTip
                            info={
                              "Password protect all content accessible with a public link."
                            }
                          />
                        </MDTypography>
                      </Grid>

                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          <Formik
                            initialValues={objInitiaPasswordlValues}
                            validationSchema={PublicPasswordFormJWTSchema}
                            onSubmit={updatePublicPassword}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setFieldValue,
                            }) => (
                              <Form id="AddNewPostForm" autoComplete="off">
                                <MDBox display="flex" alignItems="center">
                                  <Checkbox
                                    disabled={!publicLinkPassword}
                                    checked={publicLinkPasswordActivated}
                                    onChange={(event) => {
                                      updatePasswordActiveStatus(
                                        event.target.checked
                                      );
                                    }}
                                  />
                                  <MDTypography variant="button">
                                    Require password
                                  </MDTypography>
                                </MDBox>

                                <Grid container spacing={3}>
                                  <Grid item xs={6}>
                                    <FormField
                                      type="password"
                                      label="Enter New Password"
                                      name="password"
                                      placeholder={"***********"}
                                      error={
                                        touched.password && errors.password
                                      }
                                      success={
                                        values.password.length > 0 && touched.password &&
                                        !errors.password
                                      }
                                      variant="standard"
                                      fullWidth
                                      autoFocus
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormField
                                      type="password"
                                      label="Confirm New Password"
                                      name="passwordConfirmation"
                                      placeholder={"***********"}
                                      error={
                                        touched.passwordConfirmation &&
                                        errors.passwordConfirmation
                                      }
                                      success={
                                        values.passwordConfirmation.length >
                                          0 && touched.passwordConfirmation && !errors.passwordConfirmation
                                      }
                                      variant="standard"
                                      fullWidth
                                  
                                    />
                                  </Grid>
                                </Grid>
                                <DashboardActionButton
                                  type="submit"
                                  btnText="Save Changes"
                                  btnIcon="save"
                                  textColor="white"
                                  bgColor="success"
                                />
                              </Form>
                            )}
                          </Formik>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {publicLinkPassword && (
                            <MDTypography variant="button">
                              Last Updated by{" "}
                              {publicLinkPassword.updatedBy.name}{" "}
                              {publicLinkPassword.updatedBy.last_name} {" on "}
                              {moment(
                                publicLinkPassword?.formatted_updated_at
                              ).format("lll")}
                            </MDTypography>
                          )}
                        </MDBox>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Card sx={{ marginTop: "20px", display: "none" }}>
            {isLoadingMoreKbs === false &&
            objKbsTableData.rows.length > 0 &&
            totalKbsCount > 0 ? (
              <>
                <MDBox
                  display="flex"
                  gap=".6rem"
                  alignItems="flex-start"
                  p={2}
                  pb={0}
                >
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    display="flex"
                    alignItems="flex-start"
                  >
                    Shared Knowledge Base Links
                  </MDTypography>
                </MDBox>

                <KnowledgeBaseSharesTable
                  table={objKbsTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalKbsCount}
                  perPage={perKbsPage}
                  setPerPage={setPerKbsPage}
                  _page={pageKbs}
                  setPage={setPageKbs}
                  loadMoreDomains={loadMoreDomains}
                />
              </>
            ) : (
              <Fragment>
                {isLoadingMoreKbs ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    No Knowledge Base Share Links available.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        </>
      )}
    </DashboardLayout>
  );
};

export default KnowledgeBase;
