import React from "react";
import { useState, useEffect, useRef } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";

import { useDispatch } from "react-redux";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import {
  Checkbox,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import { useCookies } from "react-cookie";
import DashboardActionButton from "views/components/DashboardActionButton";
import { getTemplatesToImport } from "features/company/superAdminActions";
import MDInput from "components/MDInput";
import {
  importExternalTemplate,
  reassignUserToCompany,
} from "features/company/superAdminActions";

const ImportTemplateFromStaging = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [isImporting, setIsImporting] = useState(false);
  const [policyTemplates, setPolicyTemplates] = useState([]);
  const [assessmentTemplates, setAssessmentTemplates] = useState([]);
  const [filteredAssessmentTemplates, setFilteredAssessmentTemplates] =
    useState([]);
  const [filteredPolicyTemplates, setFilteredPolicyTemplates] = useState([]);
  const [assessmentKeyword, setAssessmentKeyword] = useState("");
  const [policyKeyword, setPolicyKeyword] = useState("");
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const loadTemplates = () => {
    setIsLoading(true);
    dispatch(getTemplatesToImport({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setPolicyTemplates(response.data.templates.policyTemplates);
        setAssessmentTemplates(response.data.templates.assessmentTemplates);
        setFilteredAssessmentTemplates(
          response.data.templates.assessmentTemplates
        );
        setFilteredPolicyTemplates(response.data.templates.policyTemplates);
        setFilteredAssessmentTemplates(
          response.data.templates.assessmentTemplates
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load templates. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const importTemplates = () => {
    setIsImporting(true);
    let objPostData = {
      policyTemplates: selectedPolicies,
      assessmentTemplates: selectedAssessments,
    };
    dispatch(
      importExternalTemplate({
        objPostData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        loadTemplates();
        setSelectedAssessments([]);
        setSelectedPolicies([]);
        showMsg("success", "Templates imported successfully.");
        // navigate("/super-admin/toolkit");
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      })
      .finally(() => {
        setIsImporting(false);
      });
  };

  const checkIfAssessmentIsSelected = (templateId) => {
    let selectedTemplatesArray = [...selectedAssessments];
    const userSelected = selectedTemplatesArray.find(
      (user) => user.id === templateId
    );

    return userSelected === undefined ? false : true;
  };

  const checkIfPolicyIsSelected = (templateId) => {
    let selectedTemplatesArray = [...selectedPolicies];
    const userSelected = selectedTemplatesArray.find(
      (user) => user.id === templateId
    );

    return userSelected === undefined ? false : true;
  };

  const handleAssessmentChecked = (assessment) => () => {
    let selectedTemplatesArray = [...selectedAssessments];

    // Check if the assessment is already in the array
    const index = selectedTemplatesArray.findIndex(
      (a) => a.id === assessment.id
    );

    if (index !== -1) {
      // If it exists, remove it
      selectedTemplatesArray.splice(index, 1);
    } else {
      // If it doesn't exist, add it
      selectedTemplatesArray.push(assessment);
    }

    setSelectedAssessments(selectedTemplatesArray);
  };

  const handlePolicyChecked = (assessment) => () => {
    let selectedTemplatesArray = [...selectedPolicies];

    // Check if the assessment is already in the array
    const index = selectedTemplatesArray.findIndex(
      (a) => a.id === assessment.id
    );

    if (index !== -1) {
      // If it exists, remove it
      selectedTemplatesArray.splice(index, 1);
    } else {
      // If it doesn't exist, add it
      selectedTemplatesArray.push(assessment);
    }

    setSelectedPolicies(selectedTemplatesArray);
  };

  const handleAssessmentKeyWordChange = (value) => {
    setAssessmentKeyword(value);
  };

  const handlePolicyKeyWordChange = (value) => {
    setPolicyKeyword(value);
  };

  const filterAssessmentTemplates = (curAssessmentTemplates = []) => {
    const filteredAssessmentTemplates = curAssessmentTemplates.filter((q) => {
      return (q?.name?.toLowerCase() ?? "").includes(
        assessmentKeyword.toLowerCase()
      );
    });
    return filteredAssessmentTemplates;
  };

  const filterPolicyTemplates = (curPolicyTemplates = []) => {
    const filteredPolicyTemplates = curPolicyTemplates.filter((q) => {
      return (q?.name?.toLowerCase() ?? "").includes(
        policyKeyword.toLowerCase()
      );
    });
    return filteredPolicyTemplates;
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadTemplates();
  }, []);

  useEffect(() => {
    if (policyTemplates.length == 0 && assessmentTemplates.length == 0) {
      return;
    }
    const curPolicyTemplates = [...policyTemplates];
    const curAssessmentTemplates = [...assessmentTemplates];
    const filteredAssessmentTemplates = filterAssessmentTemplates(
      curAssessmentTemplates
    );
    const filteredPolicyTemplates = filterPolicyTemplates(curPolicyTemplates);
    setFilteredAssessmentTemplates(filteredAssessmentTemplates);
    setFilteredPolicyTemplates(filteredPolicyTemplates);
  }, [assessmentKeyword, policyKeyword]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      {isLoading ? (
        <Card>
          <LoadingSpinner color="success" size="lg" />
        </Card>
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={4}>
            {assessmentTemplates.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Search Assessment Template:
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={assessmentKeyword ?? ""}
                    onChange={(e) =>
                      handleAssessmentKeyWordChange(e.target.value)
                    }
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            {policyTemplates.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Search Policy Template:
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={policyKeyword ?? ""}
                    onChange={(e) => handlePolicyKeyWordChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {filteredAssessmentTemplates.map((assessment, i) => {
                    return (
                      <ListItem
                        key={assessment.id}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleAssessmentChecked(assessment)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checkIfAssessmentIsSelected(
                                assessment.id
                              )}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={`${assessment.name}`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {filteredPolicyTemplates.map((policy, i) => {
                    return (
                      <ListItem
                        key={policy.id}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handlePolicyChecked(policy)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checkIfPolicyIsSelected(policy.id)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={`${policy.name}`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                {selectedAssessments.length > 0 && (
                  <>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Selected Assessments
                    </MDTypography>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 620,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {selectedAssessments.map((assessment, i) => {
                        return (
                          <ListItem key={i} disablePadding>
                            <ListItemButton role={undefined} dense>
                              <ListItemText
                                id={i}
                                primary={`${assessment.name}`}
                                sx={{ color: "text.main" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                )}

                {selectedPolicies.length > 0 && (
                  <>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textAlign="left"
                      mt={2}
                    >
                      Selected Policies
                    </MDTypography>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 620,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      {selectedPolicies.map((policy, i) => {
                        return (
                          <ListItem disablePadding>
                            <ListItemButton role={undefined} dense>
                              <ListItemText
                                primary={`${policy?.name}`}
                                sx={{ color: "text.main" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                )}

                {selectedAssessments.length == 0 && selectedPolicies == 0 ? (
                  <MDBox>No Templates selected</MDBox>
                ) : (
                  <>
                    {isImporting ? (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="sm"
                      />
                    ) : (
                      <DashboardActionButton
                        action={importTemplates}
                        btnText="Import"
                        btnIcon="download"
                        textColor="white"
                        bgColor="success"
                        sx={{ marginTop: "0px !important" }}
                      />
                    )}
                  </>
                )}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default ImportTemplateFromStaging;
