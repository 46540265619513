import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import dataTableData from "views/pages/Dashboard/Companies/data/dataTableData";
import { useDispatch } from "react-redux";
import { fetchAllContracts } from "features/company/superAdminActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CompaniesManagementTable from "./components/CompaniesManagementTable/CompaniesManagementTable";
import { showMsg } from "utils/general";
import "./Companies.css";
import MDTypography from "components/MDTypography";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { useCookies } from "react-cookie";
import { displayExceptionErrorMessage } from "utils/general";
import ExportMSPContractsDialog from "./components/ExportMSPContractsDialog/ExportMSPContractsDialog";
import DashboardActionButton from "views/components/DashboardActionButton";
import { MenuItem, Select } from "@mui/material";
import moment from "moment/moment";
import { getContractsCSV } from "features/company/superAdminActions";

const ContractStats = (props) => {
  const { objUser } = props;
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [openNewPolicyDialog, setOpenNewPolicyDialog] = useState(false);
  const [excludeFromReports, setExcludeFromReports] = useState([]);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(2);
  const [page, setPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [expiresIn, setExpiresIn] = useState("90");
  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchContracts(perPage, page, expiresIn);
  }, []);

  const formatPhone = (inputValue) => {
    let previousValue = inputValue;

    if (inputValue) {
      const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

      if (numericValue.length === 10) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`;
      } else if (numericValue.length === 11) {
        return `(${numericValue[0]}) ${numericValue.slice(
          1,
          4
        )}-${numericValue.slice(4, 7)}-${numericValue.slice(7)}`;
      } else if (numericValue.length > 11) {
        return previousValue;
      } else {
        return numericValue;
      }
    } else {
      return inputValue;
    }
  };

  const fetchContracts = (_per_page, _page, expiresIn) => {
    setIsFetchingCompanies(true);
    dispatch(fetchAllContracts({ _per_page, _page, expiresIn, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingCompanies(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objCompaniesList = response.data.contracts;
        const excludeArray = objCompaniesList
          .filter((company) => company.exclude_from_reports === 1)
          .map((company) => company.id);
        setExcludeFromReports(excludeArray);
        for (let index = 0; index < objCompaniesList.length; index++) {
          const element = objCompaniesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.companyName = element.name;
          objTr.companyEmail = element.email;
          objTr.renewalDate = element.current_subscription_cycle_end_date;
          objTr.expiresIn = moment(
            element.current_subscription_cycle_end_date
          ).diff(moment(), "days");
          objTr.paymentMedium = element.default_payment_medium;
          // objTr.status =
          //   element.status === "PENDING_SUBSCRIPTION"
          //     ? "Signed up"
          //     : "Has billing";
          objTr.plan = element.subscription_package;
          objTr.maxClients = element.units;
          objTr.companyPhone =
            element.users.length > 0
              ? formatPhone(element.users[0].phone)
              : "-";
          objTr.numberOfClients = element.clients_count;
          objTr.numberOfUsers = element.users_count;
          objTr.numberOfPolicies = element.policies_count;
          objTr.riskRegistryCount = element.risk_register_items_count;
          objTr.poamCount = 0;
          objTr.completedPoamCount = 0;
          Object.entries(element?.poamStatus ?? {}).forEach(([key, value]) => {
            if (key.toLowerCase() === "completed") {
              objTr.completedPoamCount += value;
            }
            objTr.poamCount += value;
          });
          objTr.assessmentMostUsedTemplate = element.assessmentMostUsedTemplate;
          objTr.assessmentNotStarted = element.assessmentNotStarted;
          objTr.assessmentInProgress = element.assessmentInProgress;
          objTr.assessmentCompleted = element.assessmentCompleted;
          objTr.dateJoined = element.formatted_created_at;
          objTr.lastLogin = element.last_login;
          objTr.exclude_from_reports = element.exclude_from_reports;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingCompanies(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const btnOnClickNewPolicy = () => {
    setOpenNewPolicyDialog(true);
  };

  const onCloseNewPolicyDialog = () => {
    setOpenNewPolicyDialog(false);
  };

  const onSubmitNewPolicyDialog = () => {
    setOpenNewPolicyDialog(false);
  };

  const exportContracts = () => {
    setIsExporting(true);
    dispatch(getContractsCSV({ expiresIn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsExporting(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `Contracts.csv`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsExporting(false);
        const errorMessage = rejectedValueOrSerializedError?.data?.message || "Failed to export contracts csv. Try again later"
        showMsg("error", errorMessage);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <MDTypography variant="h3">Contract Stats</MDTypography>
          <MDBox display={"flex"} alignItems={"center"}>
            <MDBox display={"flex"} alignItems={"baseline"}>
              <MDTypography variant="body2" fontWeight={"bold"}>
                Renewal Within:
              </MDTypography>
              <Select
                value={expiresIn}
                // p={1}
                sx={{
                  minWidth: "100px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "white",
                  padding: "8px",
                  borderWidth: "4px",
                }}
                onChange={(e) => {
                  setExpiresIn(e.target.value);
                  fetchContracts(perPage, page, e.target.value);
                }}
              >
                <MenuItem value="90">90 days</MenuItem>
                <MenuItem value="60">60 days</MenuItem>
                <MenuItem value="30">30 days</MenuItem>
              </Select>
              <UnfoldMoreIcon
                sx={{
                  position: "relative",
                  right: "30px",
                  top: "3.5px",
                  pointerEvents: "none",
                }}
              />
            </MDBox>
            {isExporting ? (
              <LoadingSpinner color="success" size="md" />
            ) : (
              <DashboardActionButton
                action={() => exportContracts()}
                btnText="Export"
                btnIcon="save"
                textColor="white"
                bgColor="success"
              />
            )}
          </MDBox>
        </MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isFetchingCompanies === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <CompaniesManagementTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchCompanies={fetchContracts}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
              excludeFromReports={excludeFromReports}
              setExcludeFromReports={setExcludeFromReports}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isFetchingCompanies ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No companies data</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default ContractStats;
