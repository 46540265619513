import { Icon, LinearProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

export default function SatMetricsProgressBar({
  label,
  actualValue,
  targetValue,
  isLowerBetter = false,
}) {
  // Ensure values are numeric
  const numericActual = Number(actualValue) || 0;
  const numericTarget = Number(targetValue) || 1; // Prevent division by zero

  // Calculate percentage and cap at 100%
  const percentage = Math.min((numericActual / numericTarget) * 100, 100);

  // Determine if the target is met
  const isTargetMet = isLowerBetter
    ? numericActual <= numericTarget
    : numericActual >= numericTarget;

  // Choose color based on result
  const barColor = isTargetMet ? "success" : "error";

  return (
    <>
      {targetValue ? (
        <MDBox mb={3}>
          {/* Label with values */}
          <MDTypography variant="caption" color="dark" fontWeight="bold">
            {label === "Incident Response Times" ? (
              <>
                {actualValue == null ? (
                  <>{label}: Not Reported Yet </>
                ) : (
                  <>
                    {label}: {numericActual} days ({numericTarget} days Target)
                  </>
                )}
              </>
            ) : (
              <>
                {label}: {numericActual}% ({numericTarget}% Target)
              </>
            )}
          </MDTypography>

          {/* Progress Bar with value label */}
          <MDBox display="flex" alignItems="center" gap={1}>
            {actualValue != null && (
              <>
                <MDBox position="relative" width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={isNaN(percentage) ? 0 : percentage} // Ensure it's a valid number
                    color={barColor}
                    sx={{
                      height: 15,
                      borderRadius: 0,
                    }}
                  />
                </MDBox>

                {/* Status Icon */}
                {isTargetMet ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </>
            )}
          </MDBox>
        </MDBox>
      ) : (
        <Link to={"/sat-metrics"}>
          <MDBox mb={3} display="flex" alignItems="center" gap={1}>
            {/* Label with values */}
            <MDTypography variant="caption" color="dark" fontWeight="bold">
              {label} target not set
            </MDTypography>
            <Icon color="dark">link</Icon>
          </MDBox>
        </Link>
      )}
    </>
  );
}
