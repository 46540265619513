import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createNewPolicy } from "features/company/policiesActions";
import { useMaterialUIController } from "context";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";
const objInitialValues = {
  txtPolicyTitle: "",
  txtTemplate: { id: "", name: "No template", document: "" },
  txtPolicySectionType: { name: "", description: "" },
};
const AddNewPolicyFormJWTSchema = Yup.object().shape({
  txtPolicyTitle: Yup.string().required("The Title is required."),
  txtTemplate: Yup.object().shape({
    id: Yup.string(),
  }),
  txtPolicySectionType: Yup.object().shape({
    name: Yup.string(),
  }),
});

function CreateNewPolicyDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  templates,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const onSubmitForm = async (values) => {
    const objPostData = {
      title: values.txtPolicyTitle,
      template: values.txtTemplate.id,
      sectionType: values.txtPolicySectionType.name,
    };

    //console.log(objPostData);
    setIsLoading(true);
    dispatch(createNewPolicy({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data);
        } else {
          showMsg(
            "error",
            `Failed to create new policy. Server responded with status: ${response.status}`
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            "Failed to create new policy. Please check your inputs and try again."
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0, backgroundColor: darkMode && "#1a2035" }}>
        Add New Policy
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewPolicyFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewPolicyForm" autoComplete="off">
            <DialogContent sx={{ backgroundColor: darkMode && "#1a2035" }}>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Title"
                  name="txtPolicyTitle"
                  value={values.txtPolicyTitle}
                  placeholder={"Policy Title"}
                  error={errors.txtPolicyTitle && touched.txtPolicyTitle}
                  success={
                    values.txtPolicyTitle.length > 0 && !errors.txtPolicyTitle
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtPolicyTitle" />
                  </MDTypography>
                </MDBox>
              </MDBox>

              {/* template selection */}

              <MDBox mb={0} mt={2}>
                <Autocomplete
                  disableClearable
                  // value={values.txtTemplate}
                  value={values.txtTemplate}
                  options={[
                    { id: "", name: "No template", document: "" },
                    // ...values.txtTemplate,
                    ...templates,
                  ]}
                  // options={templatesWithDefaultValue}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="standard"
                      label="Template"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    if (value === null) {
                      setFieldValue("txtTemplate", "");
                    } else {
                      setFieldValue("txtTemplate", value);
                    }
                  }}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtTemplate" />
                  </MDTypography>
                </MDBox>
              </MDBox>

              {/* doctype section */}
              {values.txtTemplate.id === "" ? (
                <MDBox mb={0} mt={3}>
                  <Autocomplete
                    disableClearable
                    value={values.txtPolicySectionType}
                    options={[
                      { name: "", description: "" },
                      {
                        name: "single_page",
                        description: "Single One Page Template",
                      },
                      { name: "oscal", description: "OSCAL Template" },
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) =>
                      option ? option.description : ""
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Policy Type"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("txtPolicySectionType", "");
                      } else {
                        setFieldValue("txtPolicySectionType", value);
                      }
                    }}
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage name="txtPolicySectionType" />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: darkMode && "#1a2035" }}>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewPolicyDialog;
