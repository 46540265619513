/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { useEffect, useState } from "react";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import useSuperAdminAuth from "views/pages/Dashboard/SuperAdmin/utilHooks/useSuperAdminAuth";
import {
  listMSPsToImpersonate,
  impersonate,
  stopImpersonating,
} from "features/user/impersonateActions";
import { displayExceptionErrorMessage, showMsg } from "utils/general";

const Impersonate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [msps, setMsps] = useState([]);
  const dispatch = useDispatch();
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));
  const isImpersonating = objUser.user?.is_impersonating;
  useSuperAdminAuth(objUser);

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      return;
    }
    // fetch MSPs
    dispatch(listMSPsToImpersonate())
      .then(unwrapResult)
      .then((response) => {
        setMsps(response.data);
      })
      .catch((error) => {
        showMsg(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, objUser.user.is_super_admin]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const submit = () => {
    if (!selectedValue) {
      console.log("No MSP selected");
      showMsg("error","Please select an MSP to impersonate");
      return;
    }
    setIsSaving(true);
    dispatch(impersonate({ mspId: selectedValue }))
      .then(unwrapResult)
      .then((response) => {
        showMsg("success", response?.data?.message ?? "Impersonation successful");
      })
      .catch((error) => {
        displayExceptionErrorMessage(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onStopImpersonating = () => {
    setIsSaving(true);
    dispatch(stopImpersonating({ mspId: null }))
      .then(unwrapResult)
      .then((response) => {
        showMsg("success", response?.data?.message ?? "Stopped impersonating");
      })
      .catch((error) => {
        displayExceptionErrorMessage(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const impersonateSelect = isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="MSP">MSP to impersonate</InputLabel>
        <Select
          sx={{ height: "40px" }}
          labelId="MSP"
          label="MSP"
          id="msp"
          value={selectedValue}
          onChange={handleSelectChange}
        >
          {msps
            .filter((msp) => msp.id !== objUser?.user?.company?.id)
            .map((msp) => (
              <MenuItem key={msp.id} value={msp.id}>
                {msp.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <MDBox sx={{ width: "100%", marginTop: "1rem", textAlign: "center" }}>
        <MDButton
          type="submit"
          color="success"
          sx={{ padding: "12px 82px" }}
          onClick={submit}
          disabled={isSaving}
        >
          {isSaving ? <LoadingSpinner  size="sm" color="white" /> : "Impersonate"}
        </MDButton>
      </MDBox>
    </>
  );

  const stopImpersonatingButton = (
    <MDBox sx={{ width: "100%", marginTop: "1rem", textAlign: "center" }}>
      <MDButton
        type="submit"
        color="error"
        sx={{ padding: "12px 82px" }}
        onClick={onStopImpersonating}
        disabled={isSaving}
      >
        {isSaving ? <LoadingSpinner size="sm" color="white" /> : "Stop Impersonating"}
      </MDButton>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid my={3} container width="100%">
        <Grid item xs={12} lg={6} style={{ margin: "auto" }}>
          <Card sx={{ height: "100%" }}>
            <MDBox p={3}>
              <MDTypography
                textAlign="left"
                mb={3}
                variant="h4"
                fontWeight="bold"
              >
                {isImpersonating
                  ? "You are currently impersonating another MSP"
                  : "Select MSP to impersonate"}
              </MDTypography>
              <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                {isImpersonating ? stopImpersonatingButton : impersonateSelect}
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Impersonate;
