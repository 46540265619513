import React from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon"; // For adding an info icon
import Tooltip from "@mui/material/Tooltip"; // For adding a tooltip
import DashboardActionButton from "views/components/DashboardActionButton";

const FeedbackComponent = () => {
  const websiteUrl = "https://support.compliancescorecard.com"; // Website URL

  // Function to handle the click and redirect to the website
  const handleEmailClick = () => {
    window.open(websiteUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <MDBox
      display="flex"
      alignItems="center"
      bgcolor="info.light" // Light background by default
      borderRadius="md"
      sx={{
        cursor: "pointer",
      }}
      onClick={handleEmailClick}
    >
      {/* Dark icon by default */}
      <DashboardActionButton variant="h6" color="text.primary" btnIcon="info" textColor="white" bgColor="info" btnText="Submit feedback to support.compliancescorecard.com"></DashboardActionButton>
    </MDBox>
  );
};

export default FeedbackComponent;
