import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { transferDocuments } from "features/company/templatesActions";

function TransferDocumentDialog({
  objUser,
  open,
  onClose,
  clientDocumentsToTransfer,
  mspWideDocumentsToTransfer,
  onSubmit,
  transferTo,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  const proceedToTransferDocument = () => {
    const objPostData = {
      documents:
        transferTo == "client"
          ? mspWideDocumentsToTransfer
          : clientDocumentsToTransfer,
      client_id: transferTo == "client" ? currentClient.id : null,
    };
    setIsLoading(true);
    dispatch(transferDocuments({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        showMsg(
          "success",
          `Documents transferred to ${
            transferTo == "client" ? currentClient.name + " " : "MSP "
          } successfully!`
        );
        onClose();
        setIsLoading(false);
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || rejectedValueOrSerializedError?.message || "Failed to transfer documents. Please try again later.";
        showMsg("error", errorMessage);
      });
  };

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Transfer to{" "}
        {transferTo == "client"
          ? currentClient.name + " Documents "
          : "MSP-Wide Documents"}
      </DialogTitle>
      <DialogContent>
        <MDTypography variant="body2" color="secondary" mt={1}>
          {transferTo == "client"
            ? `Transferring ${
                mspWideDocumentsToTransfer.length > 1
                  ? "these doc templates"
                  : "this doc template"
              } to ${currentClient.name} Documents will make said ${
                mspWideDocumentsToTransfer.length > 1
                  ? "doc templates"
                  : "doc template"
              } visible to ${currentClient.name} exclusively`
            : `Transferring ${
                clientDocumentsToTransfer.length > 1
                  ? "these doc templates"
                  : "this doc template"
              }  to MSP-Wide Templates will make said ${
                clientDocumentsToTransfer.length > 1
                  ? "doc templates"
                  : "doc template"
              } visible to all your Client companies`}
        </MDTypography>
      </DialogContent>
      <DialogActions>
        {isLoading === false ? (
          <MDButton
            onClick={proceedToTransferDocument}
            // variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Proceed
          </MDButton>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default TransferDocumentDialog;
