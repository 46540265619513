import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { BASE_ENDPOINT } from "components/utils/constants/misc";
import { storeClientContactByClientId } from "features/company/clientContactsActions.js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

const ImportFromMsGraph = (props) => {
  const { objUser } = props;
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nextPageURL, setNextPageURL] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const state = JSON.parse(query.get("state"));
  const routeDictionary = state.routeDictionary;
  const tenant = query.get("tenant");

  const importContacts = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/contacts/import-from-ms-graph`,
        {
          tenant,
          nextPageURL,
          searchString,
        }
      );
      if (!searchString) {
        setContacts((prevContacts) => [
          ...prevContacts,
          ...res.data.contactsToImport.map((contact) => {
            contact.checked = false;
            return contact;
          }),
        ]);
      } else {
        setContacts(() => [
          ...res.data.contactsToImport.map((contact) => {
            contact.checked = false;
            return contact;
          }),
        ]);
      }
      setNextPageURL(res.data.nextPage);
      setLoading(false);
    } catch (error) {
      console.error("error importing contacts from MS Graph:", error);
      showMsg(
        "error",
        error?.response?.data?.message ||
          "Error importing contacts from MS Graph."
      );
      setLoading(false);
    }
  };

  const onSearch = () => {
    setContacts([]);
    importContacts();
  };

  useEffect(() => {
    importContacts();
  }, []);

  const handleToggle = (i) => () => {
    if (contacts[i].duplicate) return;
    contacts[i].checked = !contacts[i].checked;
    setContacts([...contacts]);
  };

   const onImport = async () => {
     const contactsToImport = contacts.filter((contact) => contact.checked);
     if (contactsToImport.length === 0) {
       showMsg("info", "Please select at least one contact to import.");
       return;
     }
     setIsSubmitting(true);
     try {
       const storePromises = contactsToImport.map((objPostData) => {
         objPostData.numberOfContactsSynced = contactsToImport.length;
         return dispatch(
           storeClientContactByClientId({ objUser, objPostData })
         );
       });
       await Promise.all(storePromises);
       showMsg("success", "Contacts imported successfully.");
     } catch (error) {
       const errorMessage = error?.response?.data?.message || error?.message || "Failed to import contacts, please try again.";
       console.error("error", error);
       showMsg("error", errorMessage);
     } finally {
       setIsSubmitting(false);
     }
   };

  const onClose = () => {
    navigate(`/contacts/ms-graph-contacts-auto-import-config`, {
      state: { routeDictionary },
    });
  };

  const selectAllCheckboxChange = (event) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) => {
        if (contact.duplicate) {
          return contact;
        }
        return {
          ...contact,
          checked: event.target.checked ? true : false,
        };
      })
    );
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardBreadcrumbs title="Import contacts from Microsoft Graph" />
        <MDBox>
          <Card>
            <MDBox p={2}>
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
              <MDTypography style={{ textAlign: "center" }}>
                Fetching contacts from MS Graph. Please wait...
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  } else {
    return (
      <DashboardLayout>
        <DashboardBreadcrumbs title="Import contacts from Microsoft Graph" />
        <MDBox>
          <MDTypography textAlign="left" mb={1} variant="h4" fontWeight="bold">
            Import contacts from MS Graph
          </MDTypography>
          <Card>
            <MDBox
              display="flex"
              alignItems="center"
              flexDirection="column"
              mb={2}
              p={5}
            >
              {/* <Card
                variant="outlined"
                sx={{ marginBottom: "1em", maxWidth: 560, width: "100%" }}
              > */}
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="20px"
                sx={{ width: "100%" }}
              >
                <MDBox sx={{ color: "text.main" }}>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    onChange={selectAllCheckboxChange}
                  />
                  <b>Displaying:</b> {contacts.length} contacts
                </MDBox>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSearch();
                  }}
                  style={{ marginLeft: "auto", display: "flex" }}
                >
                  <MDBox width="14rem" ml="auto" sx={{ position: "relative" }}>
                    <MDInput
                      placeholder="Search..."
                      size="small"
                      value={searchString}
                      fullWidth
                      onChange={({ currentTarget }) => {
                        setSearchString(currentTarget.value);
                      }}
                    />
                    <MDButton
                      variant="gradient"
                      color="success"
                      sx={{
                        position: "absolute",
                        right: "0",
                        top: "-1.5px",
                      }}
                      onClick={onSearch}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>search</Icon>
                    </MDButton>
                  </MDBox>
                  {/* Invisible submit button */}
                  <button type="submit" style={{ display: "none" }} />
                </form>
              </MDBox>

              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: 500,
                  "& ul": { padding: 0 },
                }}
                subheader={<li />}
              >
                {contacts.map((contact, i) => {
                  return (
                    <ListItem key={i} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(i)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={contact.checked}
                            tabIndex={-1}
                            disableRipple
                            disabled={contact.duplicate}
                            inputProps={{ "aria-labelledby": i }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={i}
                          primary={
                            contact.first_name +
                            " " +
                            contact.last_name +
                            " (" +
                            contact.email +
                            ")"
                          }
                          sx={{ color: "text.main" }}
                          secondary={
                            contact.duplicate ? (
                              <Typography
                                component="div"
                                variant="body2"
                                color="red"
                              >
                                This contact is already present
                              </Typography>
                            ) : (
                              contact.title
                            )
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {/* </Card> */}
              <MDBox
                display="flex"
                alignItems="end"
                sx={{ width: "100%", marginTop: "2rem" }}
                flexDirection="row-reverse"
                gap={2}
              >
                {isSubmitting ? (
                  <LoadingSpinner />
                ) : (
                  <MDButton
                    type="button"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                    onClick={onImport}
                  >
                    Import
                  </MDButton>
                )}
                {nextPageURL ? (
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="primary"
                    sx={{ padding: "12px 12px" }}
                    onClick={importContacts}
                  >
                    Load More
                  </MDButton>
                ) : (
                  ""
                )}

                <MDButton
                  type="button"
                  variant="outlined"
                  color="dark"
                  sx={{ padding: "12px 12px" }}
                  onClick={onClose}
                >
                  Back
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }
};
export default ImportFromMsGraph;
