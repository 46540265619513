// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import axios from "axios";
// import SignInFormErrorsBlock from "./SignInFormErrorsBlock";
import InviteAdminUsersFormikWrapper from "./InviteAdminUsersFormikWrapper";
import { storeCompany } from "features/user/userSignUpFunnelActions";
import { inviteUserToCompany } from "features/company/companiesActions";
// import InviteAdminUsersFormErrorsBlock from "./InviteAdminUsersFormErrorsBlock";
// import SignInFormErrorsBlock from "./SignInFormErrorsBlock";
const objInitialValues = {
  txtEmailAddress: "",
};
const FormJWTSchema = Yup.object().shape({
  txtEmailAddress: Yup.string().required("Email address is required."),
});
const InviteAdminUsersForm = (props) => {
  const { setOpenInviteAdminUsersDialog, loadMoreCompanyUsers } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [objInviteAdminUsersFormErrors, setInviteAdminUsersFormErrors] =
    useState({});

  const handleBack = () => {
    navigate("/setup-account/step-one");
  };

  const onSubmitForm = async (values) => {
    const objPostData = {
      email: values.txtEmailAddress,
      type: "company_user",
    };
    setInviteAdminUsersFormErrors({});
    setIsLoading(true);
    dispatch(inviteUserToCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg(
            "success",
            "Your company invite has been sent to the user successfully."
          );
          setOpenInviteAdminUsersDialog(false);
          loadMoreCompanyUsers(10, 1);
        } else {
          showMsg("error", `Failed to send invite. server responded with status: ${response?.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);

        const objErrors =
          rejectedValueOrSerializedError?.response?.data?.errors;
        if (objErrors) {
          showMsg("error", "Please correct the errors.");
          setInviteAdminUsersFormErrors(objErrors);
        } else {
          const errorMessage =
            rejectedValueOrSerializedError?.response?.data?.message ||
            "Failed to send invite. Please try again.";
          showMsg("error", errorMessage);
        }
        // handle result here
      });
  };
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={FormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"invite-admin-user-form"} autoComplete="off">
          <InviteAdminUsersFormikWrapper
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            handleBack={handleBack}
            objInviteAdminUsersFormErrors={objInviteAdminUsersFormErrors}
            isLoading={isLoading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InviteAdminUsersForm;
