import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const MsGraphConnect = (props) => {
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const { objUser, target, scope } = props;
  const { client_id, site_id } = useParams();

  useEffect(() => {
    const msgraphOathState = JSON.stringify({
      routeDictionary,
      client_id,
      target,
      site_id,
    });
    //console.table(msgraphOathState);
    // const msgraphOathUrl =
    //   "https://login.microsoftonline.com/common/adminconsent" +
    //   `?client_id=${process.env.REACT_APP_MS_GRAPH_APP_ID}` +
    //   `&state=${encodeURI(msgraphOathState)}` +
    //   `&redirect_uri=${process.env.REACT_APP_MS_GRAPH_REDIRECT_URI_FOR_EMAIL}`;
    // window.location.href = msgraphOathUrl;

    const msgraphOathUrl =
      "https://login.microsoftonline.us/common/oauth2/v2.0/authorize" +
      `?client_id=${process.env.REACT_APP_MS_GRAPH_APP_ID}` +
      `&response_type=code` +
      `&response_mode=query` +
      `&state=${encodeURI(msgraphOathState)}` +
      `&scope=offline_access%20${scope.join("%20")}` +
      `&redirect_uri=${process.env.REACT_APP_MS_GRAPH_REDIRECT_URI_FOR_SHAREPOINT}`;
    window.location.href = msgraphOathUrl;
  }, []);

  return <div>index</div>;
};

const MsGraphSharePointGccHighConnect = (props) => {
  return (
    <MsGraphConnect
      {...props}
      target="sharepoint-gcc"
      scope={[
        "files.read",
        "files.read.all",
        "files.readwrite",
        "files.readwrite.all",
        "UserAuthenticationMethod.read.all",
        "Sites.ReadWrite.All",
      ]}
    />
  );
};

export { MsGraphSharePointGccHighConnect };
