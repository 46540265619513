import { updatePoam } from "features/company/riskRegisterActions";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// @mui icons
import { Fragment, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";

const PoamDropdown = (props) => {
  const { row, objUser, options, field, loadMoreItems } = props;
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(row.original[field]);
  const dispatch = useDispatch();

  const update = (value) => {
    setLoading(true);
    let poamId = row.original.id;
    const objPostData = {
      field: field,
      value,
    };

    dispatch(updatePoam({ objUser, objPostData, poamId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          setLoading(false);
          showMsg("success", "Poam updated successfully");
          if (field == "status" && value == "Completed") {
            loadMoreItems();
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized ");
        } else {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {objUser.user.role.name !== "admin" ||
      objUser.user.role.name !== "company_user" ||
      objUser.user?.user_permissions?.risk_register_access == 1 ? (
        <>
          {loading ? (
            <LoadingSpinner subClass="text-center" color="success" size="sm" />
          ) : (
            <MDBox sx={{ width: "150px" }}>
              <Autocomplete
                name="value"
                disableClearable
                value={currentValue}
                options={options}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="" fullWidth />
                )}
                onChange={(event, value) => {
                  console.log(value, event.target.value);
                  setCurrentValue(value);
                  update(value);
                }}
              />
            </MDBox>
          )}
        </>
      ) : (
        <>{currentValue}</>
      )}
    </Fragment>
  );
};

export default PoamDropdown;
