import React, { useState, useEffect } from "react";
import { Modal, Autocomplete, TextField, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const RACIFramework = ({
  objUser,
  question_id,
  assigned_to_client,
  userList,
  onChangeAssign,
  onChangeRACI,
  responsible_type,
  responsible_value,
  accountable_type,
  accountable_value,
  consulted_type,
  consulted_value,
  informed_type,
  informed_value,
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [selectedRACI, setSelectedRACI] = useState({
    responsible: { type: responsible_type, value: responsible_value },
    accountable: { type: accountable_type, value: accountable_value },
    consulted: { type: consulted_type, value: consulted_value },
    informed: { type: informed_type, value: informed_value },
  });
  const [tempType, setTempType] = useState(""); // Temporary type for modal
  const [tempValue, setTempValue] = useState(""); // Temporary value for modal
  const [vendorToolData, setVendorToolData] = useState({
    vendor: "",
    vendorOther: "",
    tool: "",
  }); // Separate state for Vendor/Tool

  // Update selectedRACI when props change
  useEffect(() => {
    setSelectedRACI({
      responsible: { type: responsible_type, value: responsible_value },
      accountable: { type: accountable_type, value: accountable_value },
      consulted: { type: consulted_type, value: consulted_value },
      informed: { type: informed_type, value: informed_value },
    });
  }, [
    responsible_type,
    responsible_value,
    accountable_type,
    accountable_value,
    consulted_type,
    consulted_value,
    informed_type,
    informed_value,
  ]);

  const handleOpenModal = (type) => {
    setOpenModal(type);
    setTempType(selectedRACI[type].type); // Initialize tempType with the current selection
    setTempValue(selectedRACI[type].value); // Initialize tempValue with the current selection
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setTempType("");
    setTempValue("");
    setVendorToolData({ vendor: "", vendorOther: "", tool: "" }); // Reset Vendor/Tool data
  };

  const handleSave = () => {
    const updatedRACI = { ...selectedRACI };

    if (tempType === "Vendor/Tool") {
      updatedRACI[openModal] = {
        type: tempType,
        value: vendorToolData,
      };
    } else {
      updatedRACI[openModal] = {
        type: tempType,
        value: tempValue,
      };
    }

    setSelectedRACI(updatedRACI);
    onChangeRACI(question_id, updatedRACI); // Pass the entire RACI object
    handleCloseModal();
  };

  // Helper function to format value for display
  const formatValueForDisplay = (value) => {
    if (typeof value === "string") {
      try {
        value = JSON.parse(value);
      } catch (error) {
        console.error("Failed to parse value as JSON:", error);
        return value;
      }
    }
    if (typeof value === "object" && value !== null) {
      if (value.vendor === "Other") {
        return `Vendor: ${value.vendorOther}, Tool: ${value.tool}`;
      } else if (value.vendor) {
        return `Vendor: ${value.vendor}, Tool: ${value.tool}`;
      }
    }

    // Return as-is if it's not an object or doesn't match the expected structure
    return value;
  };

  // Helper function to get the current client name
  const getCurrentClient = () => {
    const currentClientId = objUser?.user?.current_client_id;
    const currentClient = objUser?.user?.list_of_clients?.find(
      (client) => client.id === currentClientId
    );
    return currentClient?.name || "Company"; // Fallback to "Company" if not found
  };

  // Filter users based on role
  const filterUsersByRole = (role) => {
    return userList.filter((user) => user.role === role);
  };

  // Get all users for "Shared"
  const getAllUsers = () => {
    return userList.filter((user) => user.name !== "Not Applicable");
  };

  // Get MSP company name
  const getMSPCompanyName = () => {
    return objUser?.user?.company?.name || "MSP";
  };

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center">
      {/* RACI Buttons in a 2x2 Grid */}
      <Grid container spacing={2} justifyContent="center">
        {/* Responsible Button */}
        <Grid item xs={6} display="flex" justifyContent="center">
          <MDButton
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              textAlign: "center", // Center align text
              padding: "8px 16px",
              minHeight: "auto",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #ccc",
              },
            }}
            onClick={() => handleOpenModal("responsible")}
          >
            <MDBox>
              <MDTypography variant="h6" textAlign="center">
                Responsible{" "}
                <MDTypography
                  component="span"
                  fontStyle="italic"
                  fontWeight="bold"
                >
                  (Who does the work?)
                </MDTypography>
              </MDTypography>
              <MDTypography
                variant="body2"
                fontStyle={
                  !selectedRACI.responsible.value ? "italic" : "normal"
                }
                textAlign="center"
              >
                {formatValueForDisplay(selectedRACI.responsible.value) ||
                  "Select who is responsible for work"}
              </MDTypography>
            </MDBox>
          </MDButton>
        </Grid>

        {/* Accountable Button */}
        <Grid item xs={6} display="flex" justifyContent="center">
          <MDButton
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              textAlign: "center", // Center align text
              padding: "8px 16px",
              minHeight: "auto",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #ccc",
              },
            }}
            onClick={() => handleOpenModal("accountable")}
          >
            <MDBox>
              <MDTypography variant="h6" textAlign="center">
                Accountable{" "}
                <MDTypography
                  component="span"
                  fontStyle="italic"
                  fontWeight="bold"
                >
                  (Who supervises the work?)
                </MDTypography>
              </MDTypography>
              <MDTypography
                variant="body2"
                fontStyle={
                  !selectedRACI.accountable.value ? "italic" : "normal"
                }
                textAlign="center"
              >
                {formatValueForDisplay(selectedRACI.accountable.value) ||
                  "Select who supervises the work"}
              </MDTypography>
            </MDBox>
          </MDButton>
        </Grid>

        {/* Consulted Button */}
        <Grid item xs={6} display="flex" justifyContent="center">
          <MDButton
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              textAlign: "center", // Center align text
              padding: "8px 16px",
              minHeight: "auto",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #ccc",
              },
            }}
            onClick={() => handleOpenModal("consulted")}
          >
            <MDBox>
              <MDTypography variant="h6" textAlign="center">
                Consulted{" "}
                <MDTypography
                  component="span"
                  fontStyle="italic"
                  fontWeight="bold"
                >
                  (Who offers expert advice?)
                </MDTypography>
              </MDTypography>
              <MDTypography
                variant="body2"
                fontStyle={!selectedRACI.consulted.value ? "italic" : "normal"}
                textAlign="center"
              >
                {formatValueForDisplay(selectedRACI.consulted.value) ||
                  "Select who offers expert advice"}
              </MDTypography>
            </MDBox>
          </MDButton>
        </Grid>

        {/* Informed Button */}
        <Grid item xs={6} display="flex" justifyContent="center">
          <MDButton
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              textAlign: "center", // Center align text
              padding: "8px 16px",
              minHeight: "auto",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #ccc",
              },
            }}
            onClick={() => handleOpenModal("informed")}
          >
            <MDBox>
              <MDTypography variant="h6" textAlign="center">
                Informed{" "}
                <MDTypography
                  component="span"
                  fontStyle="italic"
                  fontWeight="bold"
                >
                  (Who is the stakeholder?)
                </MDTypography>
              </MDTypography>
              <MDTypography
                variant="body2"
                fontStyle={!selectedRACI.informed.value ? "italic" : "normal"}
                textAlign="center"
              >
                {formatValueForDisplay(selectedRACI.informed.value) ||
                  "Select a stakeholder"}
              </MDTypography>
            </MDBox>
          </MDButton>
        </Grid>
      </Grid>

      {/* Modal for RACI Selection */}
      <Modal open={Boolean(openModal)} onClose={handleCloseModal}>
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: "none",
            p: 4,
            textAlign: "center", // Center align modal content
          }}
        >
          <MDTypography variant="h6" mb={2} textAlign="center">
            {openModal === "responsible" && "Who does the work?"}
            {openModal === "accountable" && "Who supervises the work?"}
            {openModal === "consulted" && "Who offers expert advice?"}
            {openModal === "informed" && "Who is a stakeholder?"}
          </MDTypography>

          <Autocomplete
            fullWidth
            value={tempType}
            onChange={(event, newValue) => {
              setTempType(newValue);
              if (newValue === "MSP") {
                setTempValue(getMSPCompanyName());
              } else if (newValue === "Company") {
                setTempValue(getCurrentClient());
              } else {
                setTempValue("");
              }
            }}
            options={[
              "MSP",
              "MSP User",
              "Company", // New option
              "Company User",
              "Shared",
              "Vendor/Tool",
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Select an option" />
            )}
          />

          {tempType === "MSP" && (
            <TextField
              fullWidth
              value={getMSPCompanyName()}
              disabled
              sx={{ mt: 2 }}
              onChange={(e) => setTempValue(getMSPCompanyName())}
            />
          )}

          {tempType === "MSP User" && (
            <Autocomplete
              fullWidth
              options={filterUsersByRole("company_user")}
              getOptionLabel={(user) => user.name}
              onChange={(event, newValue) => setTempValue(newValue?.name || "")}
              renderInput={(params) => (
                <TextField {...params} label="Select an MSP user" />
              )}
              sx={{ mt: 2 }}
            />
          )}

          {tempType === "Company" && (
            <TextField
              fullWidth
              value={getCurrentClient()}
              disabled
              sx={{ mt: 2 }}
              onChange={(e) => setTempValue(getCurrentClient())}
            />
          )}

          {tempType === "Company User" && (
            <Autocomplete
              fullWidth
              options={filterUsersByRole("client_user")}
              getOptionLabel={(user) => user.name}
              onChange={(event, newValue) => setTempValue(newValue?.name || "")}
              renderInput={(params) => (
                <TextField {...params} label="Select a company user" />
              )}
              sx={{ mt: 2 }}
            />
          )}

          {tempType === "Shared" && (
            <Autocomplete
              fullWidth
              options={getAllUsers()}
              getOptionLabel={(user) => user.name}
              onChange={(event, newValue) => setTempValue(newValue?.name || "")}
              renderInput={(params) => (
                <TextField {...params} label="Select a user" />
              )}
              sx={{ mt: 2 }}
            />
          )}

          {tempType === "Vendor/Tool" && (
            <MDBox mt={2} textAlign="center">
              <Autocomplete
                disableClearable
                value={vendorToolData.vendor || ""}
                options={[
                  "LionGard",
                  "Connectwise",
                  "Nodeware",
                  "MS Graph",
                  "ConnectSecure",
                  "Other",
                ]}
                onChange={(event, newValue) => {
                  setVendorToolData((prev) => ({
                    ...prev,
                    vendor: newValue || "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Vendor" fullWidth />
                )}
                sx={{ mb: 2 }}
              />
              {vendorToolData.vendor === "Other" && (
                <TextField
                  label="Vendor (Other)"
                  value={vendorToolData.vendorOther || ""}
                  onChange={(e) => {
                    setVendorToolData((prev) => ({
                      ...prev,
                      vendorOther: e.target.value,
                    }));
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
              <TextField
                label="Tool"
                value={vendorToolData.tool || ""}
                onChange={(e) => {
                  setVendorToolData((prev) => ({
                    ...prev,
                    tool: e.target.value,
                  }));
                }}
                fullWidth
              />
            </MDBox>
          )}

          <MDBox mt={2} display="flex" justifyContent="center">
            <MDButton onClick={handleCloseModal} sx={{ mr: 2 }}>
              Cancel
            </MDButton>
            <MDButton color="success" onClick={handleSave}>
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
    </MDBox>
  );
};

export default RACIFramework;
