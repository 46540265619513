import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";

const ClientContactActionBtnTr = (props) => {
  const {
    row,
    editTheClientContact,
    isDeletingClientContact,
    deleteTheClientContact,
    changeUserStatus,
    objSelectedClientContact,
    addToAudienceContact,
    resendInvite,
    isInvitingClientContact,
    isClientUser,
    showInviteClientDialog,
  } = props;
  const confirm = useConfirm();
  const btnOnClickAddToAudienceContact = (data) => {
    addToAudienceContact(data);
  };
  const btnOnClickEditClientContact = (data) => {
    editTheClientContact(data);
  };
  const btnOnClickResendInvite = (data) => {
    confirm({
      description: "Resend invite to this contact?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        resendInvite(data);
      })
      .catch(() => {
        /* ... */
      });
  };
  const btnOnClickDeletClientContact = (data) => {
    confirm({
      description: "Are you sure, You want to delete this contact?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteTheClientContact(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnOnClickUpdateStatus = (data, status) => {
    confirm({
      description: `Are you sure, You want to ${
        status === "Active" ? "activate" : "deactivate"
      } this contact?`,
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        changeUserStatus(data, status);
      })
      .catch(() => {
        /* ... */
      });
  };

  let inviteActionContent = "";

  if (typeof row !== "undefined" && typeof row.element !== "undefined") {
    // Already a system user?
    if (typeof row.element.user !== "undefined" && row.element.user !== null) {
      inviteActionContent = (
        <Tooltip title="User" placement="bottom">
          <span>
            <MDButton disabled>
              <MDBox color="dark" display="flex" alignItems="center">
                <Icon sx={{ fontWeight: "bold" }}>check</Icon>
              </MDBox>
            </MDButton>
          </span>
        </Tooltip>
      );
    } else if (
      typeof row.element.invites !== "undefined" &&
      Array.isArray(row.element.invites) &&
      row.element.invites.length > 0
    ) {
      inviteActionContent = (
        <Tooltip title="Resend Invite" placement="bottom">
          <MDButton
            disabled={isInvitingClientContact ? true : false}
            onClick={() => btnOnClickResendInvite(row)}
          >
            <MDBox color="dark" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>timer</Icon>
            </MDBox>
          </MDButton>
        </Tooltip>
      );
    } else {
      inviteActionContent = (
        <Tooltip title="Invite" placement="bottom">
          <MDButton
            disabled={isInvitingClientContact ? true : false}
            onClick={() => showInviteClientDialog(row)}
          >
            <MDBox color="dark" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>send</Icon>
            </MDBox>
          </MDButton>
        </Tooltip>
      );
    }
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "5px",
      }}
    >
      {!isClientUser && (
        <Fragment>
          <Tooltip title="Edit" placement="bottom">
            <MDButton onClick={() => btnOnClickEditClientContact(row)}>
              <MDBox color="dark" display="flex" alignItems="center">
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              </MDBox>
            </MDButton>
          </Tooltip>
          {row.status != null ? (
            <Tooltip
              title={row.status === "Active" ? "Deactivate" : "Activate"}
              placement="bottom"
            >
              <MDButton
                onClick={() =>
                  btnOnClickUpdateStatus(
                    row,
                    `${row.status === "Active" ? "Inactive" : "Active"}`
                  )
                }
                // disabled={row.status === "Invited"}
                // sx={{
                //   display: `${objUser.user.role.name != "admin" ? "none" : ""}`,
                //   paddingLeft: 0,
                // }}
              >
                {isDeletingClientContact &&
                row.element.id === objSelectedClientContact.element.id ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="sm"
                  />
                ) : (
                  <MDBox
                    color={row.status === "Active" ? "error" : "success"}
                    display="flex"
                    alignItems="center"
                  >
                    {row.status === "Active" ? (
                      <NoAccountsIcon />
                    ) : (
                      <AccountCircleIcon />
                    )}
                  </MDBox>
                )}
              </MDButton>
            </Tooltip>
          ) : isDeletingClientContact &&
            Object.keys(objSelectedClientContact).length > 0 &&
            row.element.id === objSelectedClientContact.element.id ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <Tooltip title="Delete" placement="bottom">
              <MDButton
                onClick={() => btnOnClickDeletClientContact(row)}
                variant="gradient"
              >
                <MDBox color="error" display="flex" alignItems="center">
                  <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                </MDBox>
              </MDButton>
            </Tooltip>
          )}
        </Fragment>
      )}

      <Tooltip title="Add to audience" placement="bottom">
        <MDButton onClick={() => btnOnClickAddToAudienceContact(row)}>
          <MDBox color="dark" display="flex" alignItems="center">
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          </MDBox>
        </MDButton>
      </Tooltip>
      {inviteActionContent}
    </div>
  );
};

export default ClientContactActionBtnTr;
