import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material";
import Card from "@mui/material/Card";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import {
  clearClientContactsById,
  deleteClientContactByContactId,
  fetchClientContactsById,
  inviteClientContact,
  resendInviteClientContact,
  /**bulk actions */
  bulkDeleteSelectedContacts,
} from "features/company/clientContactsActions";
import { updateUserStatus } from "features/company/companiesActions";
import { useConfirm } from "material-ui-confirm";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardButton from "views/components/DashboardButtton";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
// import dataTableData from "views/pages/Dashboard/Customers/ShowClientContactsById/components/ClientContactsDataByIdTable/ClientContactsDataTableData";
import AddToAudienceDialog from "./components/AddToAudienceDialog";
import BulkAddToAudienceDialog from "./components/BulkAddToAudienceDialog";
import ClientContactsDataByIdTable from "./components/ClientContactsDataByIdTable/ClientContactsDataByIdTable";
import InviteClientUserDialog from "./components/InviteClientUserDialog";
import CreateNewKBSDialog from "./components/KnowledgeBase/CreateNewKBSDialog";

const ShowClientContactsById = (props) => {
  const { objUser } = props;
  const isClientUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name === "client_user"
      : false;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [openNewKBSDialog, setOpenNewKBSDialog] = useState(false);
  const [openAddAudienceDialog, setOpenAddAudienceDialog] = useState(false);
  const [openInviteClientDialog, setOpenInviteClientDialog] = useState(false);
  const [isLoadingClientContact, setIsLoadingClientContact] = useState(false);
  const [isInvitingClientContact, setIsInvitingClientContact] = useState(false);
  const [isDeletingClientContact, setIsDeletingClientContact] = useState(false);
  const [objSelectedClientContact, setObjSelectedClientContact] = useState({});
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [controller] = useMaterialUIController();

  const [objTableData, setObjTableData] = useState({});
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [firstCompanyContact, setFirstCompanyContact] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**bulk action vars */
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);
  const [contactsSelectedForBulkAction, setContactsSelectedForBulkAction] =
    useState([]);
  const [bulkActionResultNotices, setBulkActionResultNotices] = useState([]);
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [openBulkAddToAudienceDialog, setOpenBulkAddToAudienceDialog] =
    useState(false);

  useEffect(() => {
    loadMoreClientContactsById(perPage, page);

    return () => {
      dispatch(clearClientContactsById());
    };
  }, []);

  const loadMoreClientContactsById = (_per_page, _page) => {
    setIsLoadingClientContact(true);
    dispatch(
      fetchClientContactsById({
        _per_page,
        _page,
        is_contacts_page: "true",
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientContact(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        const objRows = [];

        const objClientContactsList = response.data.contacts;

        for (let index = 0; index < objClientContactsList.length; index++) {
          const element = objClientContactsList[index];

          const objTr = {};
          objTr.id = element.id;
          objTr.firstname = element.first_name;
          objTr.lastname = element.last_name;
          objTr.email = element.email;
          objTr.status =
            element.user !== null && typeof element.user !== undefined
              ? element.user.status
              : null;
          objTr.role =
            element.user !== null && typeof element.user !== undefined
              ? element.user.role.name
              : null;
          objTr.member =
            element.user !== null && typeof element.user !== undefined
              ? true
              : false;
          objTr.first_msp_contact =
            response.data.first_company_contact_id.contact_id;
          objTr.element = element;
          objRows.push(objTr);
        }
        setFirstCompanyContact(
          response.data.first_company_contact_id.contact_id
        );
        setTotalCount(response.data.total);
        setObjTableData(objRows);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientContact(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "An error occurred. Please try again.";
        showMsg("error", errorMessage);
      });
  };
  const editTheClientContact = (objClientContact) => {
    routeDictionary[objClientContact.element.id] =
      objClientContact.element.first_name +
      " " +
      objClientContact.element.last_name;
    navigate(`/contacts/${objClientContact.element.id}`, {
      state: { routeDictionary },
    });
  };

  const openAddToAudienceDialog = (objClientContact) => {
    setObjSelectedClientContact(objClientContact);
    setOpenAddAudienceDialog(true);
  };

  const closeAddToAudienceDialog = () => {
    setObjSelectedClientContact({});
    setOpenAddAudienceDialog(false);
  };

  const showInviteClientDialog = (objClientContact) => {
    setObjSelectedClientContact(objClientContact);
    setOpenInviteClientDialog(true);
  };

  const closeInviteClientDialog = () => {
    setObjSelectedClientContact({});
    setOpenInviteClientDialog(false);
  };

  const { objfetchClientContactsByIdData } = useSelector((state) => ({
    objfetchClientContactsByIdData:
      state.company.clientContacts.objfetchClientContactsByIdData,
  }));

  const deleteTheClientContact = async (objClientContactData) => {
    setIsDeletingClientContact(true);
    setObjSelectedClientContact(objClientContactData);

    const objPostData = {
      id: objClientContactData.element.id,
      client_id: objClientContactData.element.client_id,
    };

    dispatch(deleteClientContactByContactId({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingClientContact(false);
        setObjSelectedClientContact({});
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status == 200) {
          showMsg("success", "You have successfully deleted the contact.");

          const objNewClientContactData = Object.assign(
            {},
            objClientContactData.element
          );
          loadMoreClientContactsById(perPage, page);
          return false;
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingClientContact(false);
        setObjSelectedClientContact({});
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  const changeUserStatus = async (contactData, newStatus) => {
    setIsDeletingClientContact(true);
    setObjSelectedClientContact(contactData);
    const objPostData = {
      id: contactData.element.user.id,
      newStatus: newStatus,
    };
    dispatch(updateUserStatus({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingClientContact(false);
        setObjSelectedClientContact({});
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg(
            "success",
            "You have successfully updated the user's status."
          );
          loadMoreClientContactsById(perPage, page);
          return false;
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingClientContact(false);
        setObjSelectedClientContact({});
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  const inviteContact = (objClientContact, values) => {
    // console.log("####inviteContact", objClientContact);
    setIsInvitingClientContact(true);
    dispatch(
      inviteClientContact({
        contactId: objClientContact.element.id,
        assessment_access: values.txtAssessmentAccess,
        risk_register_access: values.txtRoleRegisterAccess,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsInvitingClientContact(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Contact invited.");
        } else if (response.status === 409) {
          showMsg("info", "Contact already invited.");
        } else {
          showMsg("error", `Could not invite contact, Server responded with status: ${response.status}`);
        }
        // navigate(`/clients/${id}`);
        loadMoreClientContactsById(perPage, page);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsInvitingClientContact(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 409) {
          showMsg("info", "Contact already is a user in the system.");
        } else {
          if (rejectedValueOrSerializedError.response.data.message) {
            showMsg(
              "error",
              rejectedValueOrSerializedError.response.data.message
            );
          } else {
            showMsg("error", "Failed to invite contact. Please try again.");
          }
        }
      });
  };

  const resendInvite = (objClientContact) => {
    // console.log("####inviteContact", objClientContact);
    setIsInvitingClientContact(true);
    dispatch(
      resendInviteClientContact({
        contactId: objClientContact.element.id,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsInvitingClientContact(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Invite resend.");
        } else if (response.status === 409) {
          showMsg("info", "Contact already invited.");
        } else {
          showMsg("error", `Could not resend invite, Server responded with status: ${response.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsInvitingClientContact(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to resend invite. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  /**knowledge base */
  const btnOnClickNewKBS = () => {
    setSelectedContactId(null);
    setOpenNewKBSDialog(true);
  };

  const onCloseNewKBSDialog = () => {
    setSelectedContactId(null);
    setOpenNewKBSDialog(false);
  };

  const onSuccessNewKBSDialog = () => {
    setSelectedContactId(null);
    setOpenNewKBSDialog(false);
    showMsg("success", "Knowledge base shared successfully.");
  };

  const btnOnClickContactKBS = (contactId) => {
    setSelectedContactId(contactId);
    setOpenNewKBSDialog(true);
  };

  /**bulk actions */
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBulkActionResultNotices([]);
  };

  const btnOnClickConfirmBulkDelete = () => {
    confirm({
      description: "Do you want to archive the selected contacts? ",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteSelectedContacts();
      })
      .catch(() => {
        /* ... */
      });
  };

  const deleteSelectedContacts = async () => {
    setIsBulkActionLoading(true); // Show loading state

    try {
      // Dispatch the bulk delete action and unwrap the result
      const response = await dispatch(
        bulkDeleteSelectedContacts({ objUser, contactsSelectedForBulkAction })
      ).then(unwrapResult);

      // Handle the response
      if (response.status === 200) {
        const { not_deleted_contacts: notDeletedContacts } = response.data;

        if (notDeletedContacts.length === 0) {
          // Success: All contacts were deleted
          showMsg(
            "success",
            "You have successfully archived the selected contacts."
          );
        } else {
          // Partial success: Some contacts were not deleted
          showMsg(
            "success",
            "Some contacts have not been archived. Check action notices for details."
          );
          setBulkActionResultNotices(notDeletedContacts); // Store notices for undeleted contacts
          setContactsSelectedForBulkAction([]); // Clear selected contacts
          handleClickOpen(); // Open a modal or dialog to show notices
        }

        // Refresh the contacts list
        loadMoreClientContactsById(perPage, page);
      } else {
        // Handle unexpected status codes
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      // Handle errors gracefully
      setIsBulkActionLoading(false); // Hide loading state
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      showMsg("error", errorMessage); // Show error message to the user
    } finally {
      setIsBulkActionLoading(false); // Ensure loading state is always reset
    }
  };

  const btnOnClickShowAudienceSelectionModalForBulkAction = () => {
    setOpenBulkAddToAudienceDialog(true);
  };

  const closeAudienceSelectionModalForBulkAction = () => {
    setOpenBulkAddToAudienceDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <AddToAudienceDialog
        open={openAddAudienceDialog}
        onClose={closeAddToAudienceDialog}
        onSubmit={closeAddToAudienceDialog}
        contact={objSelectedClientContact}
        inviteContact={inviteContact}
        objUser={props.objUser}
      />
      <InviteClientUserDialog
        open={openInviteClientDialog}
        onClose={closeInviteClientDialog}
        onSubmit={closeInviteClientDialog}
        contact={objSelectedClientContact}
        inviteContact={inviteContact}
        objUser={props.objUser}
      />
      <BulkAddToAudienceDialog
        open={openBulkAddToAudienceDialog}
        onClose={closeAudienceSelectionModalForBulkAction}
        onSubmit={closeAudienceSelectionModalForBulkAction}
        setContactsSelectedForBulkAction={setContactsSelectedForBulkAction}
        contactsSelectedForBulkAction={contactsSelectedForBulkAction}
        setBulkActionResultNotices={setBulkActionResultNotices}
        handleClickOpen={handleClickOpen}
        objUser={props.objUser}
      />

      {/* Not archived/added to audience contacts report dialog */}
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }}>Action Notices:</DialogTitle>
        <DialogContent dividers>
          {bulkActionResultNotices.map((result) => {
            return <p>{result}</p>;
          })}
        </DialogContent>
      </Dialog>
      {/* // */}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CreateNewKBSDialog
          open={openNewKBSDialog}
          onClose={onCloseNewKBSDialog}
          onSubmit={onSuccessNewKBSDialog}
          objUser={props.objUser}
          contactId={selectedContactId}
        />
      </LocalizationProvider>

      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="flex-start"
          justifyContent="right"
          mb={2}
        >
          <DashboardButton
            component={Link}
            to={`/contacts/create`}
            state={{ routeDictionary }}
            btnText="Create New Contacts"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>

        <Card>
          <MDBox display="flex" gap=".6rem" alignItems="flex-start" p={2}>
            {isBulkActionLoading ? (
              <MDBox sx={{ paddingTop: "2.5rem" }}>
                <LoadingSpinner color="success" size="lg" />
              </MDBox>
            ) : (
              <>
                {contactsSelectedForBulkAction?.length > 0 && (
                  <>
                    {!isClientUser && (
                      <DashboardActionButton
                        action={btnOnClickConfirmBulkDelete}
                        btnText="Archive"
                        btnIcon="archive"
                        textColor="white"
                        bgColor="error"
                      />
                    )}
                    <DashboardActionButton
                      action={btnOnClickShowAudienceSelectionModalForBulkAction}
                      btnText="Add To Audience"
                      btnIcon="add"
                      textColor="white"
                      bgColor="info"
                    />
                  </>
                )}
              </>
            )}
          </MDBox>
          {isLoadingClientContact === false &&
          objfetchClientContactsByIdData !== undefined &&
          objfetchClientContactsByIdData.data !== undefined &&
          objfetchClientContactsByIdData.data.contacts !== undefined &&
          objfetchClientContactsByIdData.data.contacts.length > 0 &&
          objTableData?.length > 0 &&
          totalCount > 0 ? (
            <ClientContactsDataByIdTable
              objClientsContactsData={
                objfetchClientContactsByIdData.data.contacts
              }
              editTheClientContact={editTheClientContact}
              deleteTheClientContact={deleteTheClientContact}
              changeUserStatus={changeUserStatus}
              isDeletingClientContact={isDeletingClientContact}
              setIsDeletingClientContact={setIsDeletingClientContact}
              objSelectedClientContact={objSelectedClientContact}
              setObjSelectedClientContact={setObjSelectedClientContact}
              addToAudienceContact={openAddToAudienceDialog}
              inviteContact={inviteContact}
              resendInvite={resendInvite}
              isInvitingClientContact={isInvitingClientContact}
              clickContactKBS={btnOnClickContactKBS}
              setContactsSelectedForBulkAction={
                setContactsSelectedForBulkAction
              }
              isClientUser={isClientUser}
              materialUIController={controller}
              showInviteClientDialog={showInviteClientDialog}
            />
          ) : (
            <Fragment>
              {isLoadingClientContact ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Client does not have any contacts.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default ShowClientContactsById;
