import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

import { showMsg, standardDateFormat } from "utils/general";

import {
  getKBDocumentWithIdAndSecret,
  getListOfAdoptersDocumentPDF,
} from "features/company/knowledgeBaseActions";
import { useDispatch } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container
} from "@mui/material";
import brandDark from "assets/images/icons/logo.png";
import { useCookies } from "react-cookie";
import "./OpenedDocument.css";
import RelatedDocumentsDatatable from "./RelatedDocumentsDatatable";

const OpenedDocument = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);
  const [policyDocument, setPolicyDocument] = useState(null);
  const [publishedDate, setPublishedDate] = useState(null);
  const [client, setClient] = useState(null);
  const [logo, setLogo] = useState(brandDark);
  const [relatedDocuments, SetRelatedDocuments] = useState([]);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [cookies, setCookie] = useCookies(["isValidated"]);

  let {
    company_id,
    client_id,
    kbs_id,
    kbs_secret,
    policy_id,
    policy_document_id,
  } = useParams();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getKBDocumentWithIdAndSecret({
        company_id,
        client_id,
        kbs_id,
        policy_id,
        policy_document_id,
        kbs_secret,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (
          originalPromiseResult.data.isPasswordProtected &&
          cookies.isValidated != client_id
        ) {
          navigate(`/validate-password/${company_id}/${client_id}`, {
            state: { routeDictionary },
          });
        }
        let originalDocumentSections =
          originalPromiseResult.data.policy_document.document_sections;
        originalPromiseResult.data.policy_document.document_sections =
          originalDocumentSections.sort(
            (a, b) =>
              a.owning_global_section.order - b.owning_global_section.order
          );

        originalPromiseResult?.data?.policy_document?.revisions?.sort(
          (a, b) => {
            const approved_at_a = a.approved_by[0]?.approved_at;
            const approved_at_b = b.approved_by[0]?.approved_at;
            if (approved_at_a && approved_at_b) {
              return new Date(approved_at_b) - new Date(approved_at_a);
            } else {
              return 0;
            }
          }
        );
        setPolicyDocument(originalPromiseResult.data.policy_document);
        setClient(originalPromiseResult.data.client);
        setLogo(originalPromiseResult.data.logo);
        SetRelatedDocuments(
          originalPromiseResult.data.policy_document.related_docs
        );
        const dateString = standardDateFormat(
          originalPromiseResult.data.policy_document.published_at
        );
        setPublishedDate(dateString);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setPolicyDocument(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [policy_document_id, policy_id]);

  const exportListOfAdoptersToPDF = () => {
    setIsFetchingPDF(true);
    dispatch(
      getListOfAdoptersDocumentPDF({
        company_id,
        client_id,
        kbs_id,
        policy_id,
        policy_document_id,
        kbs_secret,
        policyDocument,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          // console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${policy_document_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingPDF(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to export list of adopters. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  const hasAdoptedUsers = policyDocument?.adopted_users && policyDocument?.adopted_users?.length > 0;
  const hasNotAdoptedUsers = policyDocument?.not_adopted_users && policyDocument?.not_adopted_users?.length > 0;
  const showAdoptionStatus = hasAdoptedUsers || hasNotAdoptedUsers;

  return (
    <MDBox pt={2}>
      <Container>
        <Grid>
          {/* logo and title */}
          <Grid item xs={12} lg={9}>
            <Card
              display="flex"
              sx={{
                width: "100%",
                padding: "0.7rem",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "3rem",
                background: "transparent",
                boxShadow: "none",
                border: "none",
              }}
            >
              <MDBox sx={{ width: "20%" }}>
                <img
                  src={logo}
                  style={{ maxWidth: "100%", height: "auto" }}
                  alt=""
                />
              </MDBox>
              <MDTypography
                variant="h2"
                color="white"
                sx={{
                  backgroundColor: "#4CAF50",
                  width: "80%",
                  textAlign: "center",
                  borderRadius: "12px",
                }}
                p={1}
              >
                {client ? client + "'s " : ""}Knowledge Base
              </MDTypography>
            </Card>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Link
              to={`/kbs/${company_id}/${client_id}/${kbs_id}/${kbs_secret}`}
            >
              <MDButton
                variant="gradient"
                color="dark"
                style={{
                  marginTop: "1rem",
                }}
              >
                Return to List
              </MDButton>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container>
        {isLoading ? (
          <MDBox sx={{ width: "100%" }}>
            <MDTypography style={{ textAlign: "center" }} p={1}>
              <LoadingSpinner />
            </MDTypography>
          </MDBox>
        ) : (
          <>
            {isLoading == false && policyDocument ? (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} mt={5}>
                  <MDTypography variant="h3" color="dark" p={1}>
                    {policyDocument.owner_policy.title}
                  </MDTypography>
                  <MDTypography variant="h3" color="dark" p={1}>
                    <ul className="tags">
                      {policyDocument.tags
                        ? policyDocument.tags.map((tag, i) => {
                            return (
                              <li key={i} className="tag">
                                {tag.name}
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </MDTypography>

                  {/* Revision History */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="icon" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <MDTypography variant="h3" color="dark" p={1}>
                        Revision History
                      </MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDTypography variant="p" color="dark" p={1}>
                        <b>Effective Date: </b>
                        {publishedDate}
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        style={{ display: "block" }}
                        p={1}
                      >
                        <b>Document Owner: </b> {policyDocument.owner}
                      </MDTypography>
                      <MDTypography variant="h5" color="dark" p={1}>
                        Revision History
                      </MDTypography>
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <TableRow>
                          <TableCell
                            style={{
                              width: "10%",
                            }}
                          >
                            <b> Revision</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "25%",
                            }}
                          >
                            <b> Description</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "5%",
                            }}
                          >
                            <b>Creator</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                            }}
                          >
                            <b> Review By</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                            }}
                          >
                            <b> Approved By</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "20%",
                            }}
                          >
                            <b> Approved Date</b>
                          </TableCell>
                        </TableRow>

                        <TableBody>
                          {policyDocument.revisions ? (
                            <>
                              {policyDocument.revisions.map((doc, id) => {
                                return (
                                  <TableRow
                                    key={id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        width: "10%",
                                        minWidth: "100%",
                                      }}
                                    >{`${doc.major_version}.${doc.minor_version}.${doc.patch_version}`}</TableCell>
                                    <TableCell
                                      style={{
                                        width: "25%",

                                        minWidth: "100%",
                                      }}
                                    >
                                      {doc.summary_of_changes}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "5%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {doc.creator}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "20%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {/* {policyDocument.revisions.map(
                                        (revision, i) => {
                                          return ( */}
                                      <>
                                        {doc.reviewed_by
                                          .map((rv) => {
                                            return rv.reviewer_name;
                                          })
                                          .join(",")}
                                      </>
                                      {/* );
                                        }
                                      )} */}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "20%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {/* {policyDocument.revisions.map(
                                        (revision, i) => {
                                          return ( */}
                                      <>
                                        {doc.approved_by
                                          .map((rv) => {
                                            return rv.approver_name;
                                          })
                                          .join(", ")}
                                      </>
                                      {/* );
                                        }
                                      )} */}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "20%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {/* {policyDocument.revisions.map(
                                        (revision, i) => {
                                          return ( */}
                                      <>
                                        {doc.approved_by
                                          .map((rv) => {
                                            return rv.approved_at
                                              ? standardDateFormat(
                                                  rv.approved_at
                                                )
                                              : "N/A";
                                          })
                                          .join(", ")}
                                      </>
                                      {/* );
                                        }
                                      )} */}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>

                  {/* Adoption status */}
                  {showAdoptionStatus && (
                    <Accordion sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="icon" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <MDTypography variant="h3" color="dark" p={1}>
                          Adoption Status
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MDTypography variant="h5" color="dark" p={1}>
                          Users that have adopted:
                        </MDTypography>
                        <TableContainer
                          component={Paper}
                          style={{ width: "100%", maxHeight: "300px" }}
                        >
                          <TableRow>
                            <TableCell
                              style={{
                                width: "50%",
                                minWidth: "250px",
                              }}
                            >
                              <b> Name</b>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "50%",
                                minWidth: "300px",
                              }}
                            >
                              <b> Date</b>
                            </TableCell>
                          </TableRow>

                          <TableBody>
                            {policyDocument.adopted_users ? (
                              <>
                                {policyDocument.adopted_users.map((user, id) => {
                                  return (
                                    <TableRow
                                      key={id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          width: "50%",
                                          minWidth: "250px",
                                        }}
                                      >
                                        {user.first_name} {user.last_name}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "50%",
                                          minWidth: "300px",
                                        }}
                                      >
                                        {standardDateFormat(user.adoption_date)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                          </TableBody>
                        </TableContainer>

                        <MDTypography
                          variant="h5"
                          style={{
                            marginTop: "1rem",
                          }}
                          p={1}
                          color="dark"
                        >
                          Users that have NOT adopted:
                        </MDTypography>
                        <TableContainer
                          component={Paper}
                          style={{ maxHeight: "300px" }}
                        >
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ paddingLeft: 0 }}>
                                  Name
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {policyDocument.not_adopted_users ? (
                                <>
                                  {policyDocument.not_adopted_users.map(
                                    (user, id) => {
                                      return (
                                        <TableRow
                                          key={id}
                                          sx={{
                                            "&:last-child td, &:last-child th": {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {user.first_name} {user.last_name}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {isFetchingPDF ? (
                          <LoadingSpinner mt={2} />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="dark"
                            style={{
                              marginTop: "1rem",
                            }}
                            onClick={exportListOfAdoptersToPDF}
                          >
                            Print list of adopters PDF
                          </MDButton>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {/* Article and document text */}
                  {policyDocument.owner_policy.kb_article_link ? (
                    <MDTypography
                      variant="h3"
                      style={{
                        color: "white",
                        backgroundColor: "#4CAF50",
                        textAlign: "center",
                        borderRadius: "10px",
                        marginTop: "1rem",
                      }}
                      p={1}
                    >
                      <a
                        href={policyDocument.owner_policy.kb_article_link}
                        style={{ color: "white" }}
                      >
                        KB Article
                      </a>
                    </MDTypography>
                  ) : (
                    ""
                  )}

                  {policyDocument.owner_policy.description ? (
                    <MDTypography
                      variant="h3"
                      mt={2}
                      style={{ fontSize: "1.2rem" }}
                      p={1}
                      color="dark"
                    >
                      {policyDocument.owner_policy.description}
                    </MDTypography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} lg={12} mt={3} mb={5}>
                  {/* document sections */}

                  {policyDocument.owner_policy.doc_type === "single_page" ? (
                    <MDTypography
                      textAlign="left"
                      variant="h6"
                      fontWeight="bold"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: policyDocument.document,
                        }}
                      ></span>
                    </MDTypography>
                  ) : (
                    <>
                      {policyDocument.document_sections.map((section) => {
                        return (
                          <>
                            {section.section_content == "" ||
                            section.section_content == null ? (
                              ""
                            ) : (
                              <div key={section.id}>
                                <MDTypography
                                  sx={{
                                    marginBottom: "10px",
                                    marginTop: "20px",
                                  }}
                                  variant="h3"
                                  fontWeight="bold"
                                >
                                  {section.owning_global_section.title}
                                </MDTypography>
                                <Card
                                  sx={{
                                    padding: "0.7rem",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {/* document html */}
                                  <MDBox sx={{ width: "100%" }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `<div class='dangerousHTMLDIVClass'>${section.section_content}</div>`,
                                      }}
                                    />
                                  </MDBox>
                                </Card>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </Grid>
                {relatedDocuments.length > 0 && (
                  <Grid item xs={12} lg={12} mt={3} mb={5}>
                    <MDTypography variant="h3" color="dark" p={1}>
                      Related Documents
                    </MDTypography>
                    {/* <MDBox px={0} mb={2} textAlign="left" mx="auto"> */}
                    <RelatedDocumentsDatatable
                      rows={relatedDocuments}
                      company_id={company_id}
                      client_id={client_id}
                      kbs_id={kbs_id}
                      kbs_secret={kbs_secret}
                    />
                    {/* </MDBox> */}
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} mt={5}>
                  <MDBox sx={{ width: "100%" }}>
                    <Card
                      sx={{
                        padding: "0.7rem",
                      }}
                    >
                      <MDTypography style={{ textAlign: "center" }} p={1}>
                        Document not available
                      </MDTypography>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>

      {/* end document */}
    </MDBox>
  );
};

export default OpenedDocument;
