/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import Grid from "@mui/material/Grid";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import TermsandConditions from "views/pages/SignUpFunnel/SignUpSelectOrganizationType/components/TermsandConditions";
import LandingFooterLogo from "assets/images/landing-logo-footer.png";
import "../../pages/Landing/index.css";
import "font/NexaRegular.ttf";
import "font/Nexa-Light.ttf";

function Footer() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox
      component="footer"
      minHeight="13vh"
      sx={{ background: "rgb(11,46,74)" }}
    >
      {/* Terms and conditions dialog */}
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          TERMS OF SERVICE LAST UPDATE:{" "}
          <b style={{ color: "red" }}>March 19, 2024</b>
        </DialogTitle>
        <DialogContent dividers>
          <TermsandConditions />
        </DialogContent>
      </Dialog>
      {/* terms and conditions dialog end */}
      <Grid container justifyContent="center" sx={{ minHeight: "inherit" }}>
        <Grid
          item
          xs={12}
          lg={6}
          minHeight="100%"
          height="auto"
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            flexDirection: "column",
            paddingLeft: "4.5rem",
          }}
          paddingBottom={{ xs: "2rem" }}
          paddingTop={{ xs: "2rem" }}
        >
          <a
            href="https://trust.compliancescorecard.com"
            // onClick={handleClickOpen}
            target="_blank"
            style={{
              display: "inline-block",
              cursor: "pointer",
              color: "white",
              whiteSpace: "pre-wrap",
            }}
            className="footer-info"
          >
            &copy; 2024 Compliance Scorecard {"    "}| {"    "}Terms of Service
          </a>

          <p
            className="footer-info"
            style={{ color: "white", paddingTop: "0.5rem" }}
          >
            Build and Deploy Time: {process.env.REACT_APP_VERSION}
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          flexDirection={{ xs: "column", md: "row" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
          paddingBottom={{ xs: "2rem", md: "0" }}
        >
          <MDBox
            paddingRight={{ xs: "0", md: "20px" }}
            sx={{
              paddingTop: "12px",
            }}
          >
            <img
              src={LandingFooterLogo}
              style={{ width: "195px" }}
              alt="Compliance Scorecard Logo"
            />
          </MDBox>

          <MDBox>
            <a
              target="_blank"
              href="https://compliancescorecard.com/?utm_source=home-page-go-platform-page&utm_medium=go-platform-page&utm_campaign=home-page-go-platform-page&utm_id=home-page-go-platform-page&utm_term=home-page-go-platform-page&utm_content=home-page-go-platform-page"
            >
              <span className="footer-links">Learn More</span>
            </a>
          </MDBox>
          <MDBox>
            <a
              target="_blank"
              href="https://compliancescorecard.com/contact-us/"
            >
              <span className="footer-links">Contact for Pricing</span>
            </a>
          </MDBox>
          <MDBox>
            <a
              target="_blank"
              href="https://compliancescorecard.com/live-demo?utm_source=go-platform-page&utm_medium=go-plat[…]ge&utm_term=go-platform-page&utm_content=go-platform-page"
            >
              <span className="footer-links">Join a Live Demo</span>
            </a>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Footer;
