import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DefaultCell from "views/components/TableCells/DefaultCell";

import Icon from "@mui/material/Icon";
import usePolicyPDFDownloader from "../../CreatePolicyDocument/usePolicyPDFDownloader";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import PDFDownloadSettingsDialog from "views/pages/Dashboard/CompanyConfig/components/PDFDownloadSettingsDialog";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useConfirm } from "material-ui-confirm";

const VersionNameBtnTr = (props) => {
  const { value, column, row, policyId, objPolicy } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickVersionName = (row) => {
    // console.log(row);
    if (objPolicy.policy_type == "asset") {
      navigate(`/asset-lists/${policyId}/${row.major_version}`, {
        state: { routeDictionary },
      });
    } else {
      navigate(`/policies/${policyId}/${row.major_version}`, {
        state: { routeDictionary },
      });
    }
  };

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickVersionName(row.original)}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

const EditPolicyActionsBtnTr = (props) => {
  const { value, column, row, policyId, objUser, deleteDraftVersion, isDeletingDraft } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { downloadPDF, isLoadingPDF } = usePolicyPDFDownloader();
  const confirm = useConfirm();

  const btnOnClickConfirmDeleteVersion = (policyDocumentVersion) => {
    confirm({
      description: "Do you want to delete this draft version?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteDraftVersion(policyDocumentVersion?.id);
      })
      .catch(() => {
        /* ... */
      });
  };

  const btnClickDownloadPolicyPDF = (dataObj) => {
    const params = {
      policy_id: policyId,
      document_id: dataObj.id,
      pdf_display_settings: displayPdfSettingsValue,
    };
    downloadPDF(objUser, params);
  };

  /**pdf display settings */
  const [displayPdfSettingsValue, setDisplayPdfSettingValue] = useState(
    objUser?.user?.company?.pdf_display_settings ?? null
  );
  const [openPdfSettingsDialog, setOpenPdfSettingsDialog] = useState(false);
  const onclosePdfSettingsDialog = () => {
    setOpenPdfSettingsDialog(false);
  };
  /**end pdf display settings */

  return (
    <MDBox>
      {/* download button */}
      <PDFDownloadSettingsDialog
        openPdfSettingsDialog={openPdfSettingsDialog}
        onclosePdfSettingsDialog={onclosePdfSettingsDialog}
        displayPdfSettingsValue={displayPdfSettingsValue}
        setDisplayPdfSettingValue={setDisplayPdfSettingValue}
        startDownload={btnClickDownloadPolicyPDF}
        dataObj={row.original}
        isLoadingPDF={isLoadingPDF}
      />

      <MDButton
        disabled={
          row.original.state !== "Published" ||
          objUser?.user?.role?.name == "client_user"
        }
        // onClick={() => btnClickDownloadPolicyPDF(row.original)}
        onClick={() => {
          setOpenPdfSettingsDialog(true);
        }}
      >
        <MDBox
          color="info"
          display="flex"
          alignItems="center"
          sx={{
            opacity:
              row.original.state !== "Published" ||
              objUser?.user?.role?.name == "client_user"
                ? 0.4
                : 1,
          }}
        >
          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.primary"
            sx={{ lineHeight: 0 }}
          >
            {isLoadingPDF ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              ""
            )}
          </MDTypography>
        </MDBox>
      </MDButton>
      <MDButton
        onClick={() => btnOnClickConfirmDeleteVersion(row.original)}
        sx={{ marginLeft: "5px" }}
        disabled={
          row.original.state === "Published" ||
          objUser?.user?.role?.name === "client_user"
        }
      >
        <MDBox
          color="error"
          display="flex"
          alignItems="center"
          sx={{
            opacity:
              row.original.state === "Published" ||
              objUser?.user?.role?.name === "client_user"
                ? 0.4
                : 1,
          }}
        >
          <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.primary"
            sx={{ lineHeight: 0 }}
          >
            {isDeletingDraft ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              ""
            )}
          </MDTypography>
        </MDBox>
      </MDButton>
    </MDBox>
  );
};

const VersionsDatatable = ({
  canSearch,
  isSorted,
  noEndBorder,
  deleteDraftVersion,
  policyId,
  rows,
  objPolicy,
  objUser,
}) => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    const columns = [
      {
        Header: "Version",
        accessor: "version",
        // width: "25%",
        Cell: ({ value, column, row }) => (
          <VersionNameBtnTr
            value={value}
            column={column}
            row={row}
            policyId={policyId}
            objPolicy={objPolicy}
          />
        ),
      },
      {
        Header: "State",
        accessor: "state",
        // Cell: ({ value, column, row }) => (
        //   <DefaultCell value={row.original.state} />
        // ),
        Cell: ({ value, column, row }) =>
          value == "Rejected" ? (
            <>
              <MDBox
                sx={{ color: "red", fontWeight: "bold", fontSize: "13px" }}
              >
                {value}
              </MDBox>
              <MDBox
                sx={{
                  marginLeft: "0.3rem",
                  fontSize: "13px",
                  marginTop: "0.3rem",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  maxWidth: "300px",
                }}
              >
                {row.original.reject_reason}
              </MDBox>
            </>
          ) : (
            <DefaultCell value={value} />
          ),
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
      },
      {
        Header: "Publication Date",
        accessor: "published_at",
        Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
      },
      {
        Header: "Action",
        accessor: "published_by",
        Cell: ({ value, column, row }) => (
          <EditPolicyActionsBtnTr
            value={value}
            column={column}
            row={row}
            policyId={policyId}
            objUser={objUser}
            deleteDraftVersion={deleteDraftVersion}
          />
        ),
      },
    ];
    const treatedRows = rows.map((row) => {
      const newRow = { ...row };
      newRow.version = `${row.major_version}.${row.minor_version}.${row.patch_version}`;
      newRow.published_at = row.published_at ?? "";
      return newRow;
    });
    setTableData({ columns, rows: treatedRows });
  }, [policyId, rows]);

  const tableInstance = useTable(
    {
      columns: tableData.columns,
      data: tableData.rows,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: rows.length,
    },
    useGlobalFilter,
    useSortBy
  );

  // Search input value state
  const [search, setSearch] = useState(tableInstance.globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    tableInstance.setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...tableInstance.getTableProps()}>
        <MDBox
          component="thead"
          sx={{ backgroundColor: darkMode ? "inherit" : "#f5f5f5" }}
        >
          {tableInstance.headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...tableInstance.getTableBodyProps()}>
          {tableInstance.rows.map((row, key) => {
            tableInstance.prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                sx={{
                  backgroundColor: darkMode
                    ? "inherit"
                    : key % 2 === 0
                    ? "#ffffff"
                    : "#f9f9f9", // Striped effect only if not dark mode
                  "&:hover": darkMode ? {} : { backgroundColor: "#e0e0e0" }, // Hover effect only if not dark mode
                }}
              >
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={
                      noEndBorder && tableInstance.rows.length - 1 === key
                    }
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

VersionsDatatable.defaultProps = {
  canSearch: false,
  isSorted: true,
  noEndBorder: false,
};
export default VersionsDatatable;
