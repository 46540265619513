import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import { TagsInput } from "react-tag-input-component";
import "./EditTemplateFormikWrapper.css";
import MergeTagsInfo from "views/components/MergeTagsInfo";

const EditTemplateFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    handleAutoSave,
    editorRef,
    readOnly,
    mergeTags,
  } = props;

  const mergeTagsList = [
    {
      title: "Global",
      menu:
        mergeTags?.global?.map((tag) => ({
          value: tag.name,
          title: tag.description,
        })) ?? [],
    },
  ];
  if (mergeTags?.company?.length > 0) {
    mergeTagsList.push({
      title: "Company",
      menu:
        mergeTags?.company?.map((tag) => ({
          value: tag.name,
          title: tag.description ?? tag.name,
        })) ?? [],
    });
  }

  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDBox px={5} textAlign="left" mx="auto" mt={4}>
          <Grid
            container
            justifyContent="space-between"
            columnSpacing={2}
            rowSpacing={3}
          >
            <Grid item xs={12} md={6} pt={0}>
              <MDBox mb={0}>
                <MDBox mb={0}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Edit template
                  </MDTypography>
                </MDBox>
                <MDTypography variant="body2" color="text">
                  Template details
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={0} pt={2} px={5} pb={5}>
          <Grid container justifyContent="space-between" columnSpacing={5}>
            {/* Left column */}
            <Grid container item xs={12} lg={12} rowSpacing={3}>
              <Grid item xs={12} pt={0}>
                <FormField
                  type="text"
                  label="Template Name"
                  name="template_name"
                  value={values.template_name}
                  placeholder={""}
                />
              </Grid>
            </Grid>

            {/* Right column */}
            {/* Left column */}
            <Grid container item xs={12} lg={12} rowSpacing={3}>
              <Grid item xs={12} pt={0}>
                {/* <MDBox> */}
                <label className="tags-label" htmlFor="tags">
                  Template Tags
                </label>
                <TagsInput
                  value={values.tags}
                  onChange={(value) => {
                    if (value === null) {
                      setFieldValue("tags", []);
                    } else {
                      setFieldValue("tags", value);
                    }
                  }}
                  name="tags"
                  placeHolder=""
                  className="tags-input-container"
                />
              </Grid>
            </Grid>

            <Grid
              className="no-pt-element"
              container
              mt={3}
              item
              xs={12}
              columnSpacing={8}
              pt={0}
            >
              <Grid item xs={12}>
                <div style={{ marginTop: "20px" }}>
                  <small>Template Text</small>
                  <MergeTagsInfo mergeTags={mergeTags} />
                  <Editor
                    disabled={readOnly}
                    apiKey={TINYMC_API_KEY}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={values.document}
                    init={{
                      branding: false,
                      height: 400,
                      menubar: false,
                      external_plugins: {
                        polygonAutosave:
                          process.env.PUBLIC_URL +
                          "/autoSaveMCEPlugin/autoSaveMCEPlugin.js",
                        polygonVersionBrowser:
                          process.env.PUBLIC_URL +
                          "/versionBrowserPlugin/versionBrowserPlugin.js",
                      },
                      polygonAutosave_autosaveCall: handleAutoSave,
                      polygonAutosave_interval: "30s",
                      polygonAutosave_ask_before_unload: false,
                      polygonVersionBrowser_versionsCallback:
                        props.documentVersions,
                      polygonVersionBrowser_fetchVersionCallback:
                        props.fetchDocumentVersion,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      mergetags_list: mergeTagsList,
                    }}
                    plugins={[
                      "preview",
                      "anchor",
                      "autolink",
                      "charmap",
                      "advcode",
                      "emoticons",
                      "image",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "linkchecker",
                      // "a11ychecker",
                      // "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      "typography",
                      "inlinecss",
                    ]}
                    toolbar={[
                      "preview undo redo | blocks  | " +
                        "bold italic underline strikethrough | removeformat | polygonVersionBrowser",
                      "link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | " +
                        "checklist numlist bullist indent outdent | emoticons charmap",
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default EditTemplateFormikWrapper;
