import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

import EditTemplateDraft from "./EditTemplateDraft";
import { fetchTemplate } from "features/company/templatesActions";

const EditTemplate = (props) => {
  const navigate = useNavigate();

  const { objUser } = props;
  const dispatch = useDispatch();

  let { clientId, templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [objTemplate, setObjTemplate] = useState({});

  useEffect(() => {
    loadTemplate();
  }, []);

  const loadTemplate = async () => {
    setIsLoading(true);
    dispatch(fetchTemplate({ objUser, templateId, clientId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;

        try {
          if (response.status !== 200) {
            throw new Error("Get template request failed.");
          }

          if (
            typeof response.data === "undefined" ||
            typeof response.data.id === "undefined"
          ) {
            throw new Error("Template is not valid");
          }
          setObjTemplate(response.data);
        } catch (error) {
          console.error("Fetch template failed", error);
          showMsg("error", error.message || "Failed to load template. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate(`/clients/${clientId}/templates/`);
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <Card mt={4}>
            <MDBox p={2}>
              <MDBox>
                <EditTemplateDraft
                  loadTemplate={loadTemplate}
                  objUser={objUser}
                  objTemplate={objTemplate}
                />
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default EditTemplate;
