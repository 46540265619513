import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAuditorReports,
  getUnInvitedAuditors,
  _deleteAuditorReport,
} from "features/auditorReports/auditorReportActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";

import wispImageLogo from "assets/images/wisp-preview.png";
import DashboardActionButton from "views/components/DashboardActionButton";

import ManagementTable from "./components/SspsTable/ManagementTable";
import dataTableData from "./components/SspsTable/DataTableData";

import InviteAuditorDialog from "./components/InviteAuditorDialog";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Index = (props) => {
  const navigate = useNavigate();
  const { objUser } = props;
  const dispatch = useDispatch();
  const isAuditor = objUser.user.role.name === "auditor";

  const [isDeletingWisp, setIsDeletingWisp] = useState(false);
  const [isLoadingWisps, setIsLoadingWisps] = useState(false);
  const [objSspsTableData, setobjSspsTableData] = useState(dataTableData);
  const [totalSspsCount, settotalSspsCount] = useState(0);
  const [perPageSsps, setPerPageSsps] = useState(10);
  const [PageSsp, setPageSsp] = useState(1);

  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [uninvitedAuditors, setUninvitedAuditors] = useState([]);

  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });

  useEffect(() => {
    if (objUser.user.role.name == "client_user") {
      setCurrentClient(objUser.user.list_of_clients);
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      setCurrentClient(current_client);
    }
    loadMore();
    fetchUninvitedAuditors();
  }, []);

  const fetchUninvitedAuditors = () => {
    dispatch(getUnInvitedAuditors({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setUninvitedAuditors(originalPromiseResult.data.uninvitedAuditors);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const errorMessage = rejectedValueOrSerializedError.response?.data?.message || "Failed to fetch uninvited auditors. Please check your network connection and try again. If the problem persists, please contact support.";
        showMsg("error", errorMessage);
      });
  };

  const loadMore = (_per_page, _page) => {
    setIsLoadingWisps(true);
    dispatch(getAuditorReports({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingWisps(false);
        populateSspTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingWisps(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        const errorMessage = objErrorRsp?.data?.message || `Failed to load auditor reports. Server responded with status code: ${objErrorRsp?.status}.`;
        showMsg("error", errorMessage);
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const populateSspTableData = (response) => {
    const objRows = [];
    const objList = response.data.auditorReports;

    for (let index = 0; index < objList.length; index++) {
      const element = objList[index];
      const objTr = {};
      objTr.id = element.id;
      objTr.name = element.name;
      objTr.created_by =
        element.creator.name + " " + element.creator.last_name ?? "";
      objTr.auditor = element.auditor
        ? element.auditor?.name + " " + element.auditor?.last_name
        : "N/A";
      objTr.status = capitalizeFirstLetter(
        element.audit_status.replace("_", " ")
      );
      objTr.created_at = standardDateFormat(element.created_at);
      objRows.push(objTr);
    }
    settotalSspsCount(response.data.total);
    setobjSspsTableData({
      rows: objRows,
    });
  };

  const deleteAuditorReport = async (dataFromActionBtn) => {
    setIsDeletingWisp(true);
    dispatch(_deleteAuditorReport({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingWisp(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Auditor Report deleted");
          populateSspTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingWisp(false);
        console.log(rejectedValueOrSerializedError);
        const errorMessage = rejectedValueOrSerializedError.response?.data?.message || `Failed to delete auditor report. Server responded with status code: ${rejectedValueOrSerializedError.response?.status}.`;
        showMsg("error", errorMessage);
      });
  };

  const navigateToCreateNew = () => {
    navigate(`/auditor-reports/new`);
  };

  const openInviteAuditorDialog = () => {
    setOpenInviteDialog(true);
  };

  const onCloseInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <InviteAuditorDialog
          open={openInviteDialog}
          onClose={onCloseInviteDialog}
          uninvitedAuditors={uninvitedAuditors}
          objUser={props.objUser}
        />
      </LocalizationProvider>
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={2}
            >
              <MDTypography variant="h4" sx={{ paddingTop: "1.2rem" }}>
                {currentClient.name}'s Auditor Reports
              </MDTypography>
              {isAuditor ? (
                ""
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <DashboardActionButton
                    action={openInviteAuditorDialog}
                    btnText="Invite My Auditor"
                    btnIcon="account_circle"
                    textColor="white"
                    bgColor="success"
                  />
                  <DashboardActionButton
                    action={navigateToCreateNew}
                    btnText="Generate Auditor Report"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                  />
                </MDBox>
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingWisps ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalSspsCount === 0 ? (
                    <>
                      <MDBox mb={2}>
                        <img src={wispImageLogo} width="90px" alt="" />
                      </MDBox>
                      {isAuditor ? (
                        <MDBox mb={2}>
                          <MDTypography variant="p" fontWeight="regular">
                            No Auditor Reports have been shared with you by this
                            company yet
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <>
                          <MDBox mb={2}>
                            <MDTypography variant="p" fontWeight="regular">
                              You haven't created any Auditor Reports yet
                            </MDTypography>
                          </MDBox>
                          <MDBox>
                            <MDButton
                              type="submit"
                              color="success"
                              onClick={() => navigateToCreateNew()}
                              sx={{ padding: "12px 20px", margin: "0px 5px" }}
                            >
                              Generate New Auditor Report
                            </MDButton>
                          </MDBox>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ManagementTable
                        table={objSspsTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalSspsCount}
                        loadMoreObj={loadMore}
                        perPage={perPageSsps}
                        setPerPage={setPerPageSsps}
                        _page={PageSsp}
                        setPage={setPageSsp}
                        isDeletingWisp={isDeletingWisp}
                        setobjSspsTableData={setobjSspsTableData}
                        deleteObj={deleteAuditorReport}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;
