import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import dataTableData from "./data/dataTableData";
import { useDispatch } from "react-redux";
import { listConnectWiseAllClients } from "features/company/clientActions";
import { getAllClientMappings } from "features/msgraph/actions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ManagementTable from "./components/ManagementTable";
import { showMsg } from "utils/general";
import "./Styling.css";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";

const ClientsToConfigureMsGraphFor = (props) => {
  const { objUser, target } = props;
  const { id } = useParams("id");

  /**categories */
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    loadClients(perPage, page);
  }, []);

  const loadClients = (_per_page, _page) => {
    setIsLoadingMoreData(true);
    let fetchFunction = listConnectWiseAllClients;
    if (target === "assets") {
      fetchFunction = getAllClientMappings;
    }
    dispatch(fetchFunction({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreData(false);

        const response = originalPromiseResult;
        const objRows = [];
        const objCustomersList = response.data.clients;

        for (let index = 0; index < objCustomersList.length; index++) {
          const element = objCustomersList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.customerName = element.name;
          objTr.address = element.address_1;
          objTr.city = element.city;
          if (target === "email") {
            objTr.status = element.ms_graph_refresh_token
              ? "Connected"
              : "Not Connected";
          } else {
            objTr.status =
              element?.ms_graph_mapping !== null
                ? "Connected"
                : "Not Connected";
          }
          objTr.state = element.state;
          objTr.zip = element.zip;
          objTr.target = target;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreData(false);

        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp?.status === 401 &&
          objErrorRsp?.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Failed to retrieve client data. Please check your connection and try again.");
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDTypography fontWeight="bold" variant="h5">
        Configure {target === "email" ? "Send Mail" : "Assets"} With MS Graph
      </MDTypography>

      <MDBox>
        <Grid spacing={2} container width="100%">
          <Grid item xs={12} lg={12}>
            <Card sx={{ marginTop: "16px" }}>
              {isLoadingMoreData === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 ? (
                <ManagementTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalCount}
                  loadMoreCategories={loadClients}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreData ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p>Please add some clients first.</p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

const ClientsToConfigureMsGraphEmailFor = (props) => {
  return <ClientsToConfigureMsGraphFor {...props} target="email" />;
};

const ClientsToConfigureMsGraphAssetsFor = (props) => {
  const { objUser } = props;
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const navigate = useNavigate();

  navigate(
    `/settings/ms-graph-assets-connect/${objUser.user.current_client_id}`,
    {
      state: { routeDictionary },
    }
  );
  return "";
};

export {
  ClientsToConfigureMsGraphEmailFor,
  ClientsToConfigureMsGraphAssetsFor,
};
