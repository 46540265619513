/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { useEffect, useState } from "react";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import useSuperAdminAuth from "views/pages/Dashboard/SuperAdmin/utilHooks/useSuperAdminAuth";
import { getPermissions, savePermissions } from "features/company/permissions";
import { showMsg } from "utils/general";

const Permissions = ({ objUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState({});
  const dispatch = useDispatch();
  useSuperAdminAuth(objUser);
  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      return;
    }
    // fetch roles and permissions
    dispatch(getPermissions())
      .then(unwrapResult)
      .then((response) => {
        if (response.status !== 200) {
          console.error(response);
          throw new Error("Error loading permissions");
        }
        const data = response.data;
        setRoles(data.roles);
        setPermissions(data.permissions.sort((a, b) => a.display_name.localeCompare(b.display_name)));
        setRolePermissions(data.rolePermissions);
      })
      .catch((error) => {
        showMsg("error", "Error loading permissions");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [objUser.user.is_super_admin]);

  const toggleState = (roleId, permissionId) => {
    const newRolePermissions = { ...rolePermissions };
    if (getRolePermissionsValue(roleId, permissionId)) {
      newRolePermissions[roleId] = newRolePermissions[roleId].filter(
        (id) => id !== permissionId
      );
    } else {
      newRolePermissions[roleId] = [
        ...(newRolePermissions[roleId] ?? []),
        permissionId,
      ];
    }
    setRolePermissions(newRolePermissions);
  };

  const getRolePermissionsValue = (roleId, permissionId) => {
    return (
      rolePermissions !== undefined &&
      rolePermissions[roleId] !== undefined &&
      rolePermissions[roleId].includes(permissionId)
    );
  };

  const submit = () => {
    setIsSaving(true);
    // save permissions
    dispatch(savePermissions(rolePermissions))
      .then(unwrapResult)
      .then((response) => {
        if (response.status !== 200) {
          console.error(response);
          throw new Error("Error saving permissions");
        }
        showMsg("success", "Permissions saved successfully");
      })
      .catch((error) => {
        showMsg("error", "Error saving permissions");
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Card>
        <CardHeader title="Roles and Permissions" />
        <CardContent>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
              <TableContainer sx={{ maxHeight: "50rem" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell />
                      {roles.map((role) => (
                        <TableCell key={role.id} align="center">
                          {role.display_name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions.length > 0 &&
                      permissions.map((permission) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={permission.id}
                          >
                            <TableCell align="left">
                              {permission.display_name}
                            </TableCell>
                            {roles.map((role) => {
                              return (
                                <TableCell
                                  key={role.id + "-" + permission.id}
                                  align="center"
                                >
                                  <Checkbox
                                    checked={getRolePermissionsValue(
                                      role.id,
                                      permission.id
                                    )}
                                    onChange={() => {
                                      toggleState(role.id, permission.id);
                                    }}
                                  />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <MDButton
            onClick={submit}
            color="success"
            sx={{ padding: "12px 12px" }}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Submit"}
          </MDButton>
        </CardActions>
      </Card>
    </DashboardLayout>
  );
};

export default Permissions;
