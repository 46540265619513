import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
import dataTableData from "./data/dataTableData";
import { useDispatch } from "react-redux";
import {
  getRMF,
  deleteRMFData,
  fetchTemps,
  savePolicyPackChanges,
} from "features/company/policyPackActions";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RMFManagementTable from "./components/RMFTable/RMFManagementTable";
import RMFGlobalManagementTable from "./components/RMFTable/RMFGlobalManagementTable";
import TemplatesManagementTable from "./components/TemplatesTable/ManagementTable";
import { showMsg, standardDateFormat } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";

import CreateNewRMFDialog from "./components/RMFTable/createNewRMFDialog";
import EditRMFDialog from "./components/RMFTable/EditRMFDialog";
import RMFTogglesDialog from "./components/RMFTogglesDialog";
import { useMaterialUIController } from "context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CompanyConfig = (props) => {
  const { objUser } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [currentSelectedRMF, setCurrentSelectedRMF] = useState(null);
  const [updatedTemplates, setUpdatedTemplates] = useState([]);

  /**MSP wide RMF */
  const [isLoadingMoreRMF, setIsLoadingMoreRMF] = useState(false);
  const [isDeletingRMF, setIsDeletingRMF] = useState(false);
  const [objRMFTableData, setObjRMFTableData] = useState(dataTableData);
  const [totalRMFCount, setTotalRMFCount] = useState(0);
  const [openNewRMFDialog, setOpenNewRMFDialog] = useState(false);
  const [openEditRMFDialog, setOpenEditRMFDialog] = useState(false);
  const [editRMFDialogData, setEditRMFDialogData] = useState({});
  const [perPageRMF, setPerPageRMF] = useState(10);
  const [pageRMF, setPageRMF] = useState(1);

  /**Global RMF */
  const [objGlobalRMFTableData, setObjGlobalRMFTableData] =
    useState(dataTableData);
  const [totalGlobalRMFCount, setTotalGlobalRMFCount] = useState(0);

  /**rmf toggles */
  const [openEditRMFTogglesDialog, setOpenEditRMFTogglesDialog] =
    useState(false);

  /** */
  const [isLoadingFrameworkTemplates, setIsLoadingFrameworkTemplates] =
    useState(false);
  const [isAddingToFrameworkLoading, setIsAddingToFrameworkLoading] =
    useState(false);
  const [objTemplatesTableData, setObjTemplatesTableData] =
    useState(dataTableData);
  const [totalTemplatesCount, setTotalTemplatesCount] = useState(0);

  const dispatch = useDispatch();

  /**RMFs */
  const btnOnClickNewRMF = () => {
    setOpenNewRMFDialog(true);
  };

  const onCloseNewRMFDialog = () => {
    setOpenNewRMFDialog(false);
  };

  const onSubmitNewRMFDialog = (response) => {
    populateRMFTableData(response);
    setOpenNewRMFDialog(false);
  };

  const editRMF = (dataFromActionBtn) => {
    setEditRMFDialogData(dataFromActionBtn);
    setOpenEditRMFDialog(true);
  };

  const onCloseEditRMFDialog = () => {
    setOpenEditRMFDialog(false);
  };

  const onSubmitEditRMFDialog = (response) => {
    populateRMFTableData(response);
    setOpenEditRMFDialog(false);
  };

  const loadMoreRMF = (_per_page, _page) => {
    setIsLoadingMoreRMF(true);
    dispatch(getRMF({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreRMF(false);
        populateRMFTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreRMF(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load RMFs. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  const populateRMFTableData = (response) => {
    const objRows = [];
    const objGlobalRows = [];
    const objRMFsList = response.data.rmfs;
    for (let index = 0; index < objRMFsList.length; index++) {
      const element = objRMFsList[index];
      const objTr = {};
      objTr.rmfName = element.name;
      objTr.rmfNotes = element.notes;
      objTr.rmfId = element.id;
      objTr.is_global = element.is_global == 1 ? true : false;

      if (element.is_global == 1) {
        objGlobalRows.push(objTr);
      } else {
        objRows.push(objTr);
      }
    }

    //msp wide
    setTotalRMFCount(response.data.total);
    setObjRMFTableData({
      ...dataTableData,
      rows: objRows,
    });

    //global
    setTotalGlobalRMFCount(objGlobalRows.length);
    setObjGlobalRMFTableData({
      ...dataTableData,
      rows: objGlobalRows,
    });
  };

  const deleteRMF = async (dataFromActionBtn) => {
    setIsDeletingRMF(true);
    dispatch(deleteRMFData({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingRMF(false);

        const response = originalPromiseResult;
        if (response.status == 200 || response.status == 201) {
          showMsg("success", "Policy pack deleted");
          populateRMFTableData(response);
          // window.location.reload();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingRMF(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to delete policy pack. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  /*** */
  const fetchTemplates = (rowOriginal) => {
    setIsLoadingFrameworkTemplates(true);
    dispatch(fetchTemps({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingFrameworkTemplates(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.templates;

        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.name = element.name;
          objTr.type = element.global_template_type;
          objTr.created_by = element?.created_by;
          objTr.last_modified_at = standardDateFormat(element.last_modified_at);
          objTr.status = element.status;
          objTr.current_rmfs = getCurrentRMFList(
            element.risk_management_frameworks
          );
          objTr.selected = checkIfCurrentRMFIsPreselected(
            element.policy_packs,
            rowOriginal
          );
          objTr.element = element;
          objRows.push(objTr);
        }

        setUpdatedTemplates(objRows);
        setTotalTemplatesCount(response.data.total);
        setObjTemplatesTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingFrameworkTemplates(false);
        setCurrentSelectedRMF(null);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load policy templates. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  const getCurrentRMFList = (rmfsArray) => {
    return rmfsArray
      .filter((item) => {
        return item.selected === 1;
      })
      .map((item) => {
        return item.owning_rmf.name;
      })
      .join(", ");
  };

  const checkIfCurrentRMFIsPreselected = (
    policyPacksForTemplate,
    rowOriginal
  ) => {
    const existingPack = policyPacksForTemplate.find((item) => {
      return item.risk_management_framework_id === rowOriginal.rmfId;
    });

    return existingPack === undefined ? false : true;
  };

  const saveChanges = () => {
    if (currentSelectedRMF == null) {
      showMsg("info", "Please choose a policy pack");
    } else {
      /**
       * modify updatedTemplateData
       * Goalis to solve the request entity too large json error
       */
      let modifiedTemplateData = updatedTemplates.map((item, i) => {
        return {
          id: item.id,
          selected: item.selected,
        };
      });

      setIsAddingToFrameworkLoading(true);
      dispatch(
        savePolicyPackChanges({ modifiedTemplateData, currentSelectedRMF })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsAddingToFrameworkLoading(false);

          const response = originalPromiseResult;
          showMsg("success", "Templates updated successfully");
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsAddingToFrameworkLoading(false);
          const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to update policy packs. Please try again.";
          showMsg("error", errorMessage);
        });
    }
  };

  const selectRMF = (rowOriginal) => {
    setCurrentSelectedRMF(rowOriginal);
    fetchTemplates(rowOriginal);
  };

  /**RMF Toggles */
  const btnOnClickOpenRMFTogglesDialog = () => {
    setOpenEditRMFTogglesDialog(true);
  };

  const onCloseRMFTogglesDialog = () => {
    setOpenEditRMFTogglesDialog(false);
  };

  useEffect(() => {
    loadMoreRMF(perPageRMF, pageRMF);
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      {/* RMF crud dialogs */}
      <CreateNewRMFDialog
        open={openNewRMFDialog}
        onClose={onCloseNewRMFDialog}
        onSubmit={onSubmitNewRMFDialog}
        objUser={props.objUser}
      />
      <EditRMFDialog
        open={openEditRMFDialog}
        onClose={onCloseEditRMFDialog}
        onSubmit={onSubmitEditRMFDialog}
        objUser={props.objUser}
        editRMFDialogData={editRMFDialogData}
      />

      <RMFTogglesDialog
        open={openEditRMFTogglesDialog}
        onClose={onCloseRMFTogglesDialog}
        onSubmit={onSubmitEditRMFDialog}
        objUser={props.objUser}
        editRMFDialogData={editRMFDialogData}
      />
      <MDBox>
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          <Fragment>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
                paddingLeft: "0.9em",
                paddingTop: "1em",
              }}
            >
              <DashboardActionButton
                action={btnOnClickOpenRMFTogglesDialog}
                btnText="Policy Scorecard Charts Toggles"
                btnIcon="pie_chart"
                textColor="white"
                bgColor={darkMode ? "info" : "dark"}
              />
              <DashboardActionButton
                action={btnOnClickNewRMF}
                btnText="Add New Policy Pack (RMF)"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </div>
          </Fragment>

          <Grid item xs={12} lg={5}>
            <Accordion
              defaultExpanded={false}
              style={{
                marginBottom: "25px",
                borderRadius: "5px",
                marginTop: "16px",
              }}
              sx={
                darkMode
                  ? { backgroundColor: "#f0f0f091" }
                  : { backgroundColor: "#f0f0f0" }
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MDTypography
                  variant="h6"
                  style={{
                    fontSize: "20px",
                    color: darkMode ? "white" : "#344767",
                  }}
                >
                  Global Policy Packs (RMFs)
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Card sx={{ marginTop: "16px" }}>
                  {isLoadingMoreRMF === false &&
                  objGlobalRMFTableData.rows.length > 0 &&
                  totalGlobalRMFCount > 0 &&
                  isDeletingRMF == false ? (
                    <RMFGlobalManagementTable
                      table={objGlobalRMFTableData}
                      entriesPerPage={true}
                      canSearch
                      totalCount={totalRMFCount}
                      loadMoreRMF={loadMoreRMF}
                      perPage={perPageRMF}
                      setPerPage={setPerPageRMF}
                      _page={pageRMF}
                      setPage={setPageRMF}
                      setObjRMFTableData={setObjRMFTableData}
                      selectRMF={selectRMF}
                      deleteRMF={deleteRMF}
                      editRMF={editRMF}
                    />
                  ) : (
                    <Fragment>
                      {isLoadingMoreRMF || isDeletingRMF == true ? (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      ) : (
                        <p style={{ padding: "1rem" }}>
                          Please add some Policy Packs (RMFs) first.
                        </p>
                      )}
                    </Fragment>
                  )}
                </Card>
              </AccordionDetails>
            </Accordion>

            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography variant="h5" fontWeight="bold">
                  MSP-Wide Policy Packs (RMFs)
                </MDTypography>
              </MDBox>

              {isLoadingMoreRMF === false &&
              objRMFTableData.rows.length > 0 &&
              totalRMFCount > 0 &&
              isDeletingRMF == false ? (
                <RMFManagementTable
                  table={objRMFTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalRMFCount}
                  loadMoreRMF={loadMoreRMF}
                  perPage={perPageRMF}
                  setPerPage={setPerPageRMF}
                  _page={pageRMF}
                  setPage={setPageRMF}
                  setObjRMFTableData={setObjRMFTableData}
                  selectRMF={selectRMF}
                  deleteRMF={deleteRMF}
                  editRMF={editRMF}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreRMF || isDeletingRMF == true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p style={{ padding: "1rem" }}>
                      Please add some Policy Packs (RMFs) first.
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} lg={7}>
            {currentSelectedRMF == null ? (
              <Card
                sx={{
                  marginTop: "72px",
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="p" fontWeight="bold" color="success">
                  You haven't selected a Policy Pack yet
                </MDTypography>
              </Card>
            ) : (
              <Fragment>
                <MDBox sx={{ marginTop: "8px", marginBottom: "10px" }}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Select Required Global Templates For{" "}
                    {currentSelectedRMF.rmfName} Gamifycation Score
                  </MDTypography>
                  <MDTypography sx={{ color: "#38b54a" }} variant="p">
                    Note: Selecting a template will automatically turn on the
                    Policy Pack toggle in the template, if not already on.
                  </MDTypography>
                </MDBox>
                <Card
                  sx={{
                    height: "auto",
                    minHeight: `${
                      isLoadingFrameworkTemplates ? "200px" : "400px"
                    }`,
                  }}
                >
                  <MDBox
                    display="flex"
                    p={2}
                    px={3}
                    pt={1}
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {isAddingToFrameworkLoading ? (
                      <MDBox sx={{ paddingTop: "2.5rem" }}>
                        <LoadingSpinner color="success" size="lg" />
                      </MDBox>
                    ) : (
                      <>
                        <DashboardActionButton
                          action={saveChanges}
                          btnText="Save changes"
                          btnIcon="save"
                          textColor="white"
                          bgColor="info"
                          marginTop="8px"
                        />
                      </>
                    )}
                  </MDBox>

                  {isLoadingFrameworkTemplates === false &&
                  objTemplatesTableData.rows.length > 0 &&
                  totalTemplatesCount > 0 ? (
                    <TemplatesManagementTable
                      table={objTemplatesTableData}
                      entriesPerPage={true}
                      canSearch
                      totalCount={totalRMFCount}
                      _page={pageRMF}
                      setPage={setPageRMF}
                      setUpdatedTemplates={setUpdatedTemplates}
                      updatedTemplates={updatedTemplates}
                    />
                  ) : (
                    <Fragment>
                      {isLoadingFrameworkTemplates ? (
                        <MDBox sx={{ marginTop: "1.3rem" }}>
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="lg"
                          />
                        </MDBox>
                      ) : (
                        <p style={{ padding: "1rem" }}>
                          No global templates found.
                        </p>
                      )}
                    </Fragment>
                  )}
                </Card>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default CompanyConfig;
