import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { addCategory } from "features/company/CompanyConfigActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";
const objInitialValues = {
  txtCategoryName: "",
  txtTemplate: { id: "", name: "No template", document: "" },
};
const AddNewCategoryFormJWTSchema = Yup.object().shape({
  txtCategoryName: Yup.string().required("The category name is required."),
});

function CreateNewCategoryDialog({ open, onClose, onSubmit, objUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtCategoryName,
    };

    setIsLoading(true);
    dispatch(addCategory({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "New category added successfully");
          onSubmit(response);
        } else {
          showMsg("error", `Failed to add category. Server responded with status code: ${response.status}.`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.message ||
            rejectedValueOrSerializedError?.response?.data?.message ||
            `Failed to add category. Server responded with status code: ${rejectedValueOrSerializedError?.response?.status}.`
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add New Category</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewCategoryFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="AddNewCategoryForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Category Name"
                  name="txtCategoryName"
                  value={values.txtCategoryName}
                  placeholder={"Name"}
                  error={errors.txtCategoryName && touched.txtCategoryName}
                  success={
                    values.txtCategoryName.length > 0 && !errors.txtCategoryName
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtCategoryName" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Add
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewCategoryDialog;
