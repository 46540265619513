import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import { Autocomplete, Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import { Formik, Form } from "formik";
import DashboardActionButton from "views/components/DashboardActionButton";
import {
  updateSatPolicies,
  getSatPolicies,
} from "features/company/superAdminActions";
import ToolTip from "views/components/Tooltip/ToolTip";
import { displayExceptionErrorMessage } from "utils/general";
import MDInput from "components/MDInput";
import { fetchGlobalTemplates } from "features/company/superAdminActions";

let objInitiaSatPolicyValues = {
  training_policy: "",
  training_sop: "",
};

const SatPolicyFormJWTSchema = Yup.object().shape({
  training_policy: Yup.string().required("Training Policy is required."),
  training_sop: Yup.string().required("Training SOP is required."),
});

const SATPolicies = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingSatTemplates, setIsUpdatingSatTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const updateSatTemplates = (values) => {
    const objPostData = {
      training_policy: values.training_policy,
      training_sop: values.training_sop,
    };
    setIsUpdatingSatTemplates(true);
    dispatch(updateSatPolicies({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUpdatingSatTemplates(false);
        fetchSatPolicies();
        // loadNewVersionInfo();
        showMsg("success", "SAT templates updated successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingSatTemplates(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const fetchTemplates = () => {
    setIsLoading(true);
    dispatch(fetchGlobalTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setTemplates(response.data?.templates);
        fetchSatPolicies();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage =
          rejectedValueOrSerializedError?.response?.data?.message ||
          "Failed to fetch global templates. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  const fetchSatPolicies = () => {
    setIsLoading(true);
    dispatch(getSatPolicies({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        objInitiaSatPolicyValues = {
          training_policy: response.data?.trainingPolicy?.value,
          training_sop: response.data?.trainingSop?.value,
        };
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage =
          rejectedValueOrSerializedError?.response?.data?.message ||
          "Failed to fetch current sat policies. Please try again.";
        showMsg("error", errorMessage);
      });
  };

  const trainingSatAutocompleteGetLabelFunction = useCallback(
    (option) =>
      option?.name ??
      templates.find((template) => template.id === option)?.name ??
      "",
    [templates]
  );

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchTemplates();
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <Grid spacing={2} container width="100%">
          {isLoading ? (
            <Grid item xs={12} lg={23}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <MDTypography variant="p" fontWeight="regular">
                      <LoadingSpinner />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <MDTypography
                fontWeight="bold"
                variant="h5"
                display="flex"
                alignItems="flex-start"
              >
                SAT Training Policies
                <ToolTip
                  info={
                    "Message and button text that appear when new version is realeased."
                  }
                />
              </MDTypography>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                {isUpdatingSatTemplates ? (
                  <LoadingSpinner subClass="text-center" size="lg" />
                ) : (
                  <Grid container width="100%">
                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                      <MDBox mb={2}>
                        <Formik
                          enableReinitialize
                          initialValues={objInitiaSatPolicyValues}
                          validationSchema={SatPolicyFormJWTSchema}
                          onSubmit={updateSatTemplates}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <Form id="AddNewPostForm" autoComplete="off">
                              <Grid container>
                                <Grid item xs={12} mb={2}>
                                  <Autocomplete
                                    sx={{ width: "50%" }}
                                    options={templates}
                                    getOptionLabel={
                                      trainingSatAutocompleteGetLabelFunction
                                    }
                                    value={
                                      templates.find(
                                        (t) => t.id === values.training_policy
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value === null) {
                                        setFieldValue("training_policy", "");
                                      } else {
                                        setFieldValue(
                                          "training_policy",
                                          value.id
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <MDInput
                                        {...params}
                                        variant="standard"
                                        label="Security Awareness Training Policy"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                  <Autocomplete
                                    sx={{ width: "50%" }}
                                    options={templates}
                                    getOptionLabel={
                                      trainingSatAutocompleteGetLabelFunction
                                    }
                                    value={
                                      templates.find(
                                        (t) => t.id === values.training_sop
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value === null) {
                                        setFieldValue("training_sop", "");
                                      } else {
                                        setFieldValue("training_sop", value.id);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <MDInput
                                        {...params}
                                        variant="standard"
                                        label="Security Awareness Training SOP"
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <DashboardActionButton
                                type="submit"
                                btnText="Save Changes"
                                btnIcon="save"
                                textColor="white"
                                bgColor="success"
                              />
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SATPolicies;
