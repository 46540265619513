/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { MaterialUIControllerProvider } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";

/**
 * Use this component when you need the basic providers for the app.
 * Datatables needs it to render components inside the table, for some reason
 */
const EssentialProviders = ({ children, materialUIController }) => {
    const selectedTheme = materialUIController.darkMode ? themeDark : theme;
    return (
        <MaterialUIControllerProvider initialState={materialUIController}>
        <ThemeProvider theme={selectedTheme}>
            <ConfirmProvider>
                {children}
            </ConfirmProvider>
        </ThemeProvider>
        </MaterialUIControllerProvider>
    );
}

export default EssentialProviders;