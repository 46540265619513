import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import { Grid, Radio } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../settings.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { fetchMetrics } from "features/company/SatMetricsActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import { saveMetrics } from "features/company/SatMetricsActions";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardActionButton from "views/components/DashboardActionButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AgreeToTermsDialog from "./components/AgreeToTermsDialog";

const MetricsAndKPIs = (props) => {
  const dispatch = useDispatch();
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState({});
  const isClientUser = objUser.user.role.name === "client_user" ? true : false;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const navigate = useNavigate();
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [seletecGlobalTemplateId, setSelectedGlobalTemplateId] = useState(null);

  const [objInitialSecurityValues, setObjInitialSecurityValues] = useState({
    completion_rates: "",
    phishing_click_rates: "",
    reporting_rates: "",
    quiz_assessment_score: "",
    incident_report_times: "",
  });

  const SecurityFormJWTSchema = Yup.object().shape({
    completion_rates: Yup.number()
      .required("This field is required.")
      .max(100, "You cannot exceed 100%"),
    phishing_click_rates: Yup.number()
      .required("This field is required.")
      .max(100, "You cannot exceed 100%"),
    reporting_rates: Yup.number()
      .required("This field is required.")
      .max(100, "You cannot exceed 100%"),
    quiz_assessment_score: Yup.number()
      .required("This field is required.")
      .max(100, "You cannot exceed 100%"),
    incident_report_times: Yup.number()
      .required("This field is required.")
      .max(100, "You cannot exceed 100%"),
  });

  const fetchSatMetrics = () => {
    setIsLoading(true);
    dispatch(fetchMetrics({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setMetrics(response.data?.metrics);
        setObjInitialSecurityValues({
          completion_rates: response.data?.metrics?.completion_rates ?? "",
          phishing_click_rates:
            response.data?.metrics?.phishing_click_rates ?? "",
          reporting_rates: response.data?.metrics?.reporting_rates ?? "",
          quiz_assessment_score:
            response.data?.metrics?.quiz_assessment_score ?? "",
          incident_report_times:
            response.data?.metrics?.incident_report_times ?? "",
        });
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        setIsLoading(false);
      });
  };

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    let objPostData = values;
    console.log(values);
    dispatch(saveMetrics({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setIsLoading(false);
        showMsg("success", "Metrics saved successfully.");
        // saveFormExtraInfo(generalInfo);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage =
          rejectedValueOrSerializedError?.response?.data?.message ||
          "Failed to submit the form. Please check your inputs and try again.";
        showMsg("error", errorMessage);
        const objErrors = rejectedValueOrSerializedError?.response?.errors;
        if (objErrors) {
          // setObjInfoFormErrors(objErrors);
        }
      });
  };

  const btnOnClickConnection = () => {
    navigate(`/sat-metrics/connections`);
  };

  const onSubmit = () => {
    setOpenTermsDialog(false);
    fetchSatMetrics();
  };

  const onCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  useEffect(() => {
    fetchSatMetrics();
  }, []);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <AgreeToTermsDialog
        open={openTermsDialog}
        onClose={onCloseTermsDialog}
        objUser={objUser}
        onSubmit={onSubmit}
        objTemplateId={seletecGlobalTemplateId}
      />
      <MDTypography fontWeight="bold" variant="h5">
        Scoring and Measuring Effectiveness
      </MDTypography>

      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="right"
          justifyContent="right"
          mb={2}
        >
          <DashboardActionButton
            action={btnOnClickConnection}
            btnText="Connections"
            btnIcon="link"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card sx={{ marginTop: "16px" }}>
          <MDBox p={3}>
            <Grid item xs={6} lg={6}>
              <Formik
                initialValues={objInitialSecurityValues}
                validationSchema={SecurityFormJWTSchema}
                // onSubmit={(values, { setSubmitting }) => {
                //   setSubmitting(true);
                // }}
                onSubmit={onSubmitForm}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  validateForm,
                  setFieldValue,
                }) => (
                  <Form id={"InfoForm2"} autoComplete="off">
                    <MDTypography variant="h5">
                      Security Awareness Training
                    </MDTypography>
                    <MDTypography>
                      This section will help you build out your Security
                      Awareness Training program
                    </MDTypography>
                    <MDBox px={2} mb={3}>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Do you have a formal Security Awareness Training{" "}
                            <b>Policy</b>
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.has_sat_policy ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.global_policy_id && (
                                <MDTypography
                                  variant="body2"
                                  fontWeight="bold"
                                  color="info"
                                  fontSize="small"
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedGlobalTemplateId(
                                      metrics.global_policy_id
                                    );
                                    setOpenTermsDialog(true);
                                  }}
                                >
                                  (Import Policy)
                                </MDTypography>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Has the Security Awareness Training <b>Policy</b>{" "}
                            been <b>Authorized</b>
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.policy_authorized ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.has_sat_policy && (
                                <Link
                                  to={`/policies/${metrics.has_sat_policy}/${metrics.policy_version}`}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="info"
                                    fontSize="small"
                                  >
                                    (Authorize Policy)
                                  </MDTypography>
                                </Link>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Has the Security Awareness Training <b>Policy</b>{" "}
                            been <b>Adopted</b> by all users
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.policy_adopted ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.policy_authorized && (
                                <Link
                                  to={`/policies/${metrics.has_sat_policy}/${metrics.policy_version}`}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="info"
                                    fontSize="small"
                                  >
                                    (Create Adoption Campaign)
                                  </MDTypography>
                                </Link>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Do you have a Security Awareness Training <b>SOP</b>
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.has_sat_sop ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.global_sop_id && (
                                <MDTypography
                                  variant="body2"
                                  fontWeight="bold"
                                  color="info"
                                  fontSize="small"
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedGlobalTemplateId(
                                      metrics.global_sop_id
                                    );
                                    setOpenTermsDialog(true);
                                  }}
                                >
                                  (Import SOP)
                                </MDTypography>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Has the Security Awareness Training <b>SOP</b> been
                            <b> Authorized</b>
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.sop_authorized ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.has_sat_sop && (
                                <Link
                                  to={`/policies/${metrics.has_sat_sop}/${metrics.sop_version}`}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="info"
                                    fontSize="small"
                                  >
                                    (Authorize SOP)
                                  </MDTypography>
                                </Link>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={1} mb={1}>
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            sx={{ width: "max-content" }}
                          >
                            Has the Security Awareness Training <b>SOP</b> been
                            <b> Adopted</b> by all users
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          {metrics.sop_adopted ? (
                            <CheckCircleIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <>
                              <CancelIcon color="error" fontSize="medium" />
                              {metrics.sop_authorized && (
                                <Link
                                  to={`/policies/${metrics.has_sat_sop}/${metrics.sop_version}`}
                                >
                                  <MDTypography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="info"
                                    fontSize="small"
                                  >
                                    (Create Adoption Campaign)
                                  </MDTypography>
                                </Link>
                              )}
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>

                    <MDTypography variant="h5">Metrics & KPIs</MDTypography>
                    <MDTypography>
                      A robust scoring or maturity model ensures continuous
                      improvement and demonstrates ROI. Below are recommended
                      approaches:
                    </MDTypography>
                    <MDBox px={2}>
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="regular">
                            <b>Completion Rates:</b> Track the percentage of
                            employees completing required training on time.
                            Target is typically 100% for compliance.
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <FormField
                            sx={{ width: "30%" }}
                            type="number"
                            label="What is your target %"
                            name="completion_rates"
                            value={values.completion_rates ?? ""}
                            placeholder={"What is your target %"}
                            error={
                              errors.completion_rates &&
                              touched.completion_rates
                            }
                            success={
                              values.completion_rates.length > 0 &&
                              !errors.completion_rates
                            }
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="regular">
                            <b>Phishing Click Rates:</b> Measure how many staff
                            click on simulated phishing emails. Goal is to
                            reduce this rate over time (e.g., 20% ? 10% ? 5%).
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <FormField
                            sx={{ width: "30%" }}
                            type="number"
                            label="What is your target %"
                            name="phishing_click_rates"
                            value={values.phishing_click_rates ?? ""}
                            placeholder={"What is your target %"}
                            error={
                              errors.phishing_click_rates &&
                              touched.phishing_click_rates
                            }
                            success={
                              values.phishing_click_rates.length > 0 &&
                              !errors.phishing_click_rates
                            }
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="regular">
                            <b>Reporting Rates:</b> Track how frequently
                            employees report suspicious emails instead of
                            clicking. Aim to increase this rate, fostering a
                            vigilant culture.
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <FormField
                            sx={{ width: "30%" }}
                            type="number"
                            label="What is your target %"
                            name="reporting_rates"
                            value={values.reporting_rates ?? ""}
                            placeholder={"What is your target %"}
                            error={
                              errors.reporting_rates && touched.reporting_rates
                            }
                            success={
                              values.reporting_rates.length > 0 &&
                              !errors.reporting_rates
                            }
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="regular">
                            <b>Quiz/Assessment Scores:</b> Monitor average
                            scores on training quizzes. Identify areas where
                            additional content or targeted refreshers are
                            needed.
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <FormField
                            sx={{ width: "30%" }}
                            type="number"
                            label="What is your target %"
                            name="quiz_assessment_score"
                            value={values.quiz_assessment_score ?? ""}
                            placeholder={"What is your target %"}
                            error={
                              errors.quiz_assessment_score &&
                              touched.quiz_assessment_score
                            }
                            success={
                              values.quiz_assessment_score.length > 0 &&
                              !errors.quiz_assessment_score
                            }
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="h6" fontWeight="regular">
                            <b>Incident Response Times:</b> Measure how quickly
                            incidents are reported to security teams after a
                            suspected phishing attempt.
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <FormField
                            sx={{ width: "30%" }}
                            type="number"
                            label="Target days for response"
                            name="incident_report_times"
                            value={values.incident_report_times ?? ""}
                            placeholder={"Target days for response"}
                            error={
                              errors.incident_report_times &&
                              touched.incident_report_times
                            }
                            success={
                              values.incident_report_times.length > 0 &&
                              !errors.incident_report_times
                            }
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>

                    <MDBox
                      sx={{ margin: "0 0 40px -40px" }}
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      {isLoading === false ? (
                        <MDButton
                          type="submit"
                          color="success"
                          sx={{ padding: "12px 82px" }}
                        >
                          Save
                        </MDButton>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default MetricsAndKPIs;
