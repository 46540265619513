import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextareaAutosize,
  Autocomplete,
} from "@mui/material";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createTaskListItem } from "features/company/taskListActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { getPrioritySymbol } from "../util";

const TaskListItemFormSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
  due_date: Yup.date().nullable(),
  responsible_user_id: Yup.string().nullable(),
  priority_level: Yup.string()
    .oneOf(["low", "medium", "high", "highest"], "Invalid priority level")
    .required("Priority Level is required."),
  notes: Yup.string().nullable(),
});

const CreateTaskListItemNewDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskList,
  taskListItems,
  taskListItemUsers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const [taskListItemList, setTaskListItemList] = useState(taskListItems);

   const priorityOptions = ["low", "medium", "high", "highest"].map(
     (priorityLevel) => ({
       label: priorityLevel,
       value: priorityLevel,
       icon: getPrioritySymbol(priorityLevel),
     })
   );

  useEffect(() => {
    if (taskListItems) {
      setTaskListItemList(taskListItems.filter((item) => item.id !== taskList?.id));
    }
  }, [taskListItems, taskList]);

  const onSubmitForm = async (values) => {
    const objData = {
      title: values.title,
      description: values.description,
      due_date: values.due_date,
      priority_level: values.priority_level,
      responsible_user_id: values.responsible_user_id,
      notes: values.notes,
    };

    setIsLoading(true);
    dispatch(createTaskListItem({ objUser, objData, taskListId: taskList.id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          onSubmit(response.data);
        } else {
          showMsg("error", `Failed to create task: ${response?.data?.message || "An unexpected error occurred."}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", rejectedValueOrSerializedError?.response?.data?.message || "Failed to create task. Please try again.");
        console.error(rejectedValueOrSerializedError);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Task</DialogTitle>
      <Formik
        initialValues={{
          title: "",
          description: "",
          due_date: "",
          priority_level: "",
          responsible_users_ids: [],
        }}
        validationSchema={TaskListItemFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form id="TaskForm" autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Title"
                    name="title"
                    value={values.title}
                    placeholder="Task Title"
                    error={errors.title && touched.title}
                    success={values.title?.length > 0 && !errors.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="date"
                    label="Due Date"
                    name="due_date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.due_date}
                    error={errors.due_date && touched.due_date}
                    success={values.due_date?.length > 0 && !errors.due_date}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={
                      taskListItemUsers
                        ? taskListItemUsers.map((user) => ({
                            label: `${user.name} ${user.last_name}`,
                            value: user.id,
                          }))
                        : []
                    }
                    value={
                      taskListItemUsers
                        ? taskListItemUsers
                            .map((user) => ({
                              label: `${user.name} ${user.last_name}`,
                              value: user.id,
                            }))
                            .filter((option) =>
                              values.responsibleUsers?.some(
                                (user) => user.id === option.value
                              )
                            )
                        : []
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    } // Add this line
                    onChange={(event, newValue) =>
                      setFieldValue(
                        "responsibleUsers",
                        newValue.map((option) => ({
                          id: option.value,
                          name: option.label,
                        }))
                      )
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Responsible Users"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disableClearable
                    options={priorityOptions}
                    value={
                      priorityOptions.find(
                        (option) => option.value === values.priority_level
                      ) || null
                    }
                    onChange={(event, value) =>
                      setFieldValue("priority_level", value?.value || "")
                    }
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Priority Level"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={option.icon}
                          alt={`${option.label} priority`}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        {option.label}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextareaAutosize
                    name="description"
                    label="Description"
                    minRows={2}
                    placeholder="Description"
                    style={{
                      width: "100%",
                      padding: "8px",
                      resize: "vertical",
                      border: "2px solid #e7edee",
                      marginTop: "10px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    value={values.notes}
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    fullWidth={true}
                    variant={"standard"}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  <MDButton type="submit" color="success">
                    Create
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner size="lg" />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateTaskListItemNewDialog;
