// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { useEffect, useState } from "react";
// formik components
import { Form, Formik } from "formik";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import EditCompanyUserFormikWrapper from "./EditCompanyUserFormikWrapper";
// import EditCompanyUserErrorsBlock from "./EditCompanyUserErrorsBlock";
import { fetchCompanyUserById, updateCompanyUser } from "features/company/companyUsersActions";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
const _objInitialValues = {
  txtName: "",
  txtLastName: "",
  txtEmail: "",
  txtPhoneNumber: "",
  txtRole: "",
};
const EditCompanyUserJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("Name is required."),
  //txtLastName: Yup.string().required("Last name is required."),
  txtEmail: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  txtPhoneNumber: Yup.string()
    .min(6, "Phone number is too short!(min 6 characters are required)")
    .required("Phone Number is required."),
  txtRole: Yup.string().required("Role is required."),
});
const EditCompanyUserForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [objCompanyUser, setObjCompanyUser] = useState({});
  const [objStates, setObjStates] = useState(objUsStates);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyUser, setIsLoadingCompanyUser] = useState(false);
  const [objEditCompanyUserErrors, setObjEditCompanyUserErrors] = useState({});
  const userCanEditHourlyRate = objUser.user.role.name === "admin" || objUser.user.role.name === "company_user";

  useEffect(() => {
    // setObjVerifiyEmailAddressErrors({});
    setIsLoadingCompanyUser(true);
    dispatch(fetchCompanyUserById({ objUser, id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingCompanyUser(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        setObjCompanyUser(response.data);
        let txtPhoneNumber = "";
        if (response.data.phone === null) {
          txtPhoneNumber = "";
        } else {
          txtPhoneNumber = response.data.phone;
        }
        const __ObjInitialValues = {
          txtName: response.data.name,
          txtLastName: response.data?.last_name,
          txtEmail: response.data.email,
          txtPhoneNumber,
          txtRole: response.data.role,
          txtHourlyRate: response.data.hourly_rate,
        };
        setObjInitialValues(__ObjInitialValues);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingCompanyUser(false);
        // showMsg("error", "Please correct the errors.");
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate("/msp-users");
        }
      });
  }, []);
  const onSubmitForm = async (values) => {
    const objPostData = {
      id,
      name: values.txtName,
      last_name: values.txtLastName,

      // address1: values.txtLastName,
      // address2: values.txtEmail,
      phone: values.txtPhoneNumber,
      role: values.txtRole,
      hourly_rate: values.txtHourlyRate,
    };
    setObjEditCompanyUserErrors({});
    setIsLoading(true);
    dispatch(updateCompanyUser({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        showMsg("success", "You have updated user data successfully.");
        navigate(`/msp-users`);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Error updating user";
        showMsg("error", errorMessage);
        if (rejectedValueOrSerializedError?.response?.data?.errors) {
          setObjEditCompanyUserErrors(rejectedValueOrSerializedError.response.data.errors);
        }
      });
  };

  if (
    isLoadingCompanyUser ||
    Object.keys(objCompanyUser).length === 0 ||
    Object.keys(objInitialValues).length === 0 ||
    objInitialValues.txtName === ""
  ) {
    return <LoadingSpinner subClass="text-center" color="success" size="lg" />;
  }
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={EditCompanyUserJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <MDTypography variant="h5">
                Edit {objCompanyUser.name} Information
              </MDTypography>
            </MDBox>
            <Form id={"EditCompanyUser"} autoComplete="off" pb={3} px={3}>
              <Grid container spacing={3}>
                <EditCompanyUserFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={objStates}
                  userCanEditHourlyRate={userCanEditHourlyRate}
                />

                {/* {Object.keys(objEditCompanyUserErrors).length > 0 ? (
                <Grid container justifyContent="space-between" pl={5} pr={5} pt={0} pb={5}>
                  <EditCompanyUserErrorsBlock
                    objEditCompanyUserErrors={objEditCompanyUserErrors}
                  />
                </Grid>
              ) : null} */}

                <MDBox
                  sx={{ margin: "0 0 40px -40px" }}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {isLoading === false ? (
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="success"
                      sx={{ padding: "12px 82px" }}
                    >
                      Save
                    </MDButton>
                  ) : (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  )}
                </MDBox>
              </Grid>
            </Form>
          </Card>
        )}
    </Formik>
  );
};

export default EditCompanyUserForm;
