import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListSubheader from "@mui/material/ListSubheader";
import Icon from "@mui/material/Icon";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { loadCampaign, resendCampaign } from "features/company/policiesActions";
import { Typography } from "@mui/material";

function ShowCampaignDialog({ open, onClose, objUser, clientId, campaignId }) {
  const [campaignData, setCampaignData] = useState(null);
  const [sendingTo, setSendingTo] = useState([]);
  // if at any point you want to do server side rendering, remove the noSsr prop
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    setCampaignData(null);
    if (campaignId === 0) return;
    dispatch(
      loadCampaign({
        objUser,
        clientId,
        campaignId,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        response.data.adopters_status.forEach(
          (a) => (a.name = a.first_name + " " + a.last_name)
        );
        response.data.adopters_adopted = response.data.adopters_status.filter(
          (a) => a.is_adopted
        );
        response.data.adopters_not_adopted =
          response.data.adopters_status.filter((a) => !a.is_adopted);

        setCampaignData(response.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        setCampaignData(null);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message || "Failed to load campaign data. Please try again"
        );
        onClose();
      });
  }, [objUser, campaignId]);

  const resendNotif = (contactId) => () => {
    setSendingTo([...sendingTo, contactId]);
    dispatch(
      resendCampaign({
        objUser,
        clientId,
        campaignId,
        contactId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showMsg("success", "Notification resent successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            "Failed to resend notification. Please try again."
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() =>
        setSendingTo((oldValue) => oldValue.filter((el) => el !== contactId))
      );
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 2 }}>Adoption Campaign Status</DialogTitle>
      {campaignData !== null ? (
        <DialogContent>
          <MDBox mb={1.5}>
            <MDInput
              value={campaignData.campaign_name}
              variant="standard"
              fullWidth
              label="Campaign Name"
              disabled={true}
            />
          </MDBox>
          <MDBox mb={1.5}>
            <MDInput
              value={campaignData.adoption_status}
              variant="standard"
              label="Campaign Status"
              disabled={true}
            />
          </MDBox>
          <div style={{ marginTop: "20px" }}>
            <small>
              {campaignData.adopters_adopted.length} /{" "}
              {campaignData.adopters_status.length} Adopted
            </small>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
                "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              {campaignData.adoption_status === "Adopted" || (
                <li>
                  <ul>
                    <ListSubheader sx={{ lineHeight: 1 }}>
                      Not adopted
                      {campaignData.reminder_time ? (
                        <Typography
                          component="div"
                          variant="body2"
                          color="text.secundary"
                        >
                          <small>
                            Will be automatically reminded every
                            {campaignData.reminder_time > 1
                              ? ` ${campaignData.reminder_time} days`
                              : "day"}
                          </small>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </ListSubheader>
                    {campaignData.adopters_not_adopted.map((contact) => (
                      <ListItem
                        key={`${contact.id}`}
                        secondaryAction={
                          !sendingTo.includes(contact.id) ? (
                            <MDButton
                              type="button"
                              variant="outlined"
                              color="dark"
                              sx={{
                                padding: { xs: "0 2px", sm: "12px 12px" },
                                marginRight: "5px",
                                border: { xs: "none", sm: "1px solid" },
                              }}
                              onClick={resendNotif(contact.id)}
                            >
                              <Icon>send</Icon>
                              Resend
                            </MDButton>
                          ) : (
                            <div style={{ marginRight: "10px" }}>
                              <LoadingSpinner
                                subClass="text-center"
                                color="success"
                                size="md"
                              />
                            </div>
                          )
                        }
                      >
                        {smallScreen ? (
                          ""
                        ) : (
                          <ListItemAvatar>
                            <Avatar>
                              <Icon>close</Icon>
                            </Avatar>
                          </ListItemAvatar>
                        )}
                        <ListItemText
                          primary={`${contact.name}`}
                          secondary={
                            smallScreen ? "" : <small>{contact.email}</small>
                          }
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              )}
              <li>
                <ul>
                  <ListSubheader>Adopted</ListSubheader>
                  {campaignData.adopters_adopted.map((contact) => (
                    <ListItem key={`${contact.id}`}>
                      {smallScreen ? (
                        ""
                      ) : (
                        <ListItemAvatar>
                          <Avatar>
                            <Icon>check</Icon>
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={`${contact.name}`}
                        secondary={
                          smallScreen ? "" : <small>{contact.email}</small>
                        }
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            </List>
          </div>
        </DialogContent>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
      <DialogActions>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default ShowCampaignDialog;
