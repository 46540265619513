import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import { fetchGlobalTemplate } from "features/company/superAdminActions";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showMsg } from "utils/general";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import EditTemplateDraft from "./EditTemplateDraft";

const EditTemplate = (props) => {
  const navigate = useNavigate();

  const { objUser } = props;
  const dispatch = useDispatch();

  let { templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [objTemplate, setObjTemplate] = useState({});
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadTemplate();
  }, []);

  const loadTemplate = async () => {
    setIsLoading(true);
    dispatch(fetchGlobalTemplate({ objUser, templateId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        try {
          if (response.status !== 201) {
            throw new Error("Get template request failed.");
          }

          if (
            typeof response.data === "undefined" ||
            typeof response.data.id === "undefined"
          ) {
            throw new Error("Template is not valid");
          }
          setObjTemplate(response.data);
        } catch (error) {
          console.error("Fetch template error:", error);
          showMsg("error", error?.message || "Failed to load template. Please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate(`/templates`);
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      {/* <Card> */}
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <Card mt={4}>
            <MDBox p={2}>
              <MDBox>
                <EditTemplateDraft
                  loadTemplate={loadTemplate}
                  objUser={objUser}
                  objTemplate={objTemplate}
                />
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default EditTemplate;
