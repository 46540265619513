import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";
import PolicyNameBtnTr from "views/pages/Dashboard/AssetLists/components/PolicyNameBtnTr";
import PolicyActionBtnTr from "views/pages/Dashboard/Policies/components/PolicyActionBtnTr";
import usePolicyPDFDownloader from "views/pages/Dashboard/Policies/CreatePolicyDocument/usePolicyPDFDownloader";
import { showMsg } from "utils/general";
import { unwrapResult } from "@reduxjs/toolkit";
import { deletePolicy } from "features/company/policiesActions";
import { getPolicies } from "features/company/policiesActions";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import PoliciesDatatable from "../../../components/PoliciesDatatable";
import { standardDateFormat } from "utils/general";

const AssetLists = (props) => {
  const { objUser } = props;
  const [isLoadingClientPolicy, setIsLoadingClientPolicy] = useState(false);
  const { downloadPDF, isLoadingPDF } = usePolicyPDFDownloader();

  const [policiesData, setPoliciesData] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickClientName = (value) => (objClientData) => {
    routeDictionary[objClientData.element.id] = value;
    navigate(`/asset-lists/${objClientData.element.id}`, {
      state: { routeDictionary },
    });
  };

  const btnOnClickNewPolicy = () => {
    navigate(`/assets/create-list`);
  };

  useEffect(() => {
    fetchPolicies(perPage, page);
  }, []);

  const getCurrentRMFList = (rmfsArray) => {
    return rmfsArray
      .filter((item) => {
        return item.selected === 1;
      })
      .map((item) => {
        return item.rmf.name;
      })
      .join(", ");
  };

  const fetchPolicies = (_per_page, _page) => {
    setIsLoadingClientPolicy(true);
    dispatch(getPolicies({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientPolicy(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objAssetLists = response.data.policies.filter((policy) => {
          return policy.assets_type_imported != null;
        });
        for (let index = 0; index < objAssetLists.length; index++) {
          const element = objAssetLists[index];
          const objTr = {};
          objTr.title = element.title;
          objTr.obj_user = objUser;
          objTr.category = element.category
            ? element.category.display_name ?? ""
            : "";
          objTr.current_rmfs = getCurrentRMFList(
            element.riskmanagementframeworks
          );
          objTr.date_created = standardDateFormat(element.created_at);
          objTr.creator_name = element.creator
            ? element.creator.name + " " + element.creator.last_name ?? ""
            : "";
          objTr.status = element?.latest_document?.state;
          objTr.element = element;
          objTr.user = props.objUser;
          objRows.push(objTr);
        }

        setTotalCount(objAssetLists.length);
        setPoliciesData(objRows);
        setTemplateList(response.data.templates);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientPolicy(false);
        console.log(rejectedValueOrSerializedError);
      });
  };

  const deletePolicyFunction = (policy, setIsLoading, onSubmit) => async (values) => {
      const objPostData = {
        name: values.txtName,
        reason: values.txtReason,
      };
  
      console.log(policy);
      let objUser = policy.user;
      let policyId = policy.element.id;
  
      setIsLoading(true);
      dispatch(deletePolicy({ objUser, policyId, objPostData }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsLoading(false);
          const response = originalPromiseResult;
          showMsg("success", "Policy deleted successfully");
          onSubmit(response.data);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsLoading(false);
          showMsg(
            "error",
            rejectedValueOrSerializedError?.response?.data?.message ||
              `Failed to delete policy. ${rejectedValueOrSerializedError?.response?.data?.message || "Please try again."}`
          );
          console.log(
            "rejectedValueOrSerializedError",
            rejectedValueOrSerializedError
          );
        });
    };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="right"
          justifyContent="right"
          mb={2}
        >
          <DashboardActionButton
            action={btnOnClickNewPolicy}
            btnText="Add Asset List"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card>
          {isLoadingClientPolicy === false &&
          policiesData.length > 0 &&
          totalCount > 0 ? (
            <PoliciesDatatable
              table={{ rows: policiesData }}
              objUser={objUser}
              NameBtnComponentFunction={(data, row) => (
                <PolicyNameBtnTr
                  value={data}
                  row={row}
                  onButtonClick={btnOnClickClientName(data)}
                />
              )}
              ActionBtnComponentFunction={(data, row) => (
                <PolicyActionBtnTr value={data} row={row} downloadPDF={downloadPDF} isLoadingPDF={isLoadingPDF} deletePolicy={deletePolicyFunction}/>
              )}
            />
          ) : (
            <Fragment>
              {isLoadingClientPolicy ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Client does not have any asset lists.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssetLists;
