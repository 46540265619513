/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const fetchClientContactsById = createAsyncThunk(
  "company/fetchClientContactsById",
  async (values, thunkApi) => {
    try {
      const {
        _per_page,
        _page,
        allow_msp_contacts = false,
        is_contacts_page = false,
        objUser,
      } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/contacts?per_page=${_per_page}&page=${_page}&allow_msp_contacts=${allow_msp_contacts}&is_contacts_page=${is_contacts_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearClientContactsById = createAsyncThunk(
  "client/clearClientContactsById",
  async (values, thunkApi) => {
    try {
      const res = {
        data: {
          status: "success",
          data: {
            current_page: 1,
            data: [],
            first_page_url: "",
            from: null,
            last_page: 1,
            last_page_url: "",
            next_page_url: null,
            path: "",
            per_page: 10,
            prev_page_url: null,
            to: null,
            total: 0,
          },
          isAuth: true,
          error: false,
        },
        status: 200,
      };
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const storeClientContactByClientId = createAsyncThunk(
  "client/storeClientContactByClientId",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/contacts/`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const importClientContactsByClientId = createAsyncThunk(
  "client/importClientContactsByClientId",
  async (values, thunkApi) => {
    try {
      const { objUser, importArray } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/contacts/import`,
        { importArray: importArray }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateClientContactByClientId = createAsyncThunk(
  "client/updateClientContactByClientId",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/contacts/${objPostData.id}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchClientContactByContactId = createAsyncThunk(
  "company/fetchClientContactByContactId",
  async (values, thunkApi) => {
    try {
      const { objUser, client_contact_id } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/contacts/${client_contact_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteClientContactByContactId = createAsyncThunk(
  "company/deleteClientContactByContactId",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/contacts/${objPostData.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteClientContactByContactIdfromStore = createAsyncThunk(
  "client/deleteClientContactByContactIdfromStore",
  async (values, thunkApi) => {
    try {
      return values;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const inviteClientContact = createAsyncThunk(
  "clients/inviteContact",
  async (values, thunkApi) => {
    try {
      const { objUser, contactId, risk_register_access, assessment_access } =
        values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/invite-user`,
        { contact_id: contactId, risk_register_access, assessment_access }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendInviteClientContact = createAsyncThunk(
  "clients/resendInviteClientContact",
  async (values, thunkApi) => {
    try {
      const { objUser, contactId } = values;

      const res = await axios.post(
        ` ${BASE_ENDPOINT}/${getCompanyID(objUser)}/reinvite-user`,
        { contact_id: contactId }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**bulk actions */
export const bulkDeleteSelectedContacts = createAsyncThunk(
  "company/bulkDeleteSelectedContacts",
  async (values, thunkApi) => {
    try {
      const { objUser, contactsSelectedForBulkAction, id } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/contacts/bulk-delete`,
        { contacts: contactsSelectedForBulkAction }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
