import { Fragment, useEffect, useState } from "react";

// react-router-dom components
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import List from "@mui/material/List";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "./SidenavCollapse";
import SidenavItem from "./SidenavItem";
import SidenavList from "./SidenavList";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import "./styles/AutoCompleteStyles.css";

import { fetchDisplayLogo } from "../../../features/company/CompanyConfigActions";
import { updateCurrentClientSelected } from "../../../features/company/companyUsersActions";
import { unwrapResult } from "@reduxjs/toolkit";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import { capitalizeFirstLetter } from "helpers/String/StringHelpers";
function RenderAllRoutes(props) {
  const {
    routes,
    objUser,
    userFullName,
    collapseName,
    openCollapse,
    setOpenCollapse,
    renderCollapse,
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = props;
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      mustBeSuperAdmin,
      mustBeCompanyAdmin,
      hide,
      mustBeCompanyAdminOrCompanyUser,
      mustBeCompanyAdminOrCompanyUserorAssessmentClient,
      mustBeCompanyAdminOrCompanyUserorRiskRegisterClient,
      mustBeCompanyAdminOrCompanyUserOrAudience,
      clientPermissionRequired,
      mustBeClientUser,
      mustBePeerGroupAdmin,
      cannotBeAccessedBy,
      canBeAccessedBy,
      textColor,
      newTab,
    }) => {
      let returnValue;
      let isBlockedOnClient = false;
      if (hide) {
        return false;
      }
      if (
        clientPermissionRequired &&
        !clientPermissionRequired.some((item) =>
          objUser.user.client_feature_access.includes(item)
        )
      ) {
        isBlockedOnClient = true;
      }
      // if (
      //   name == "Trust Center" &&
      //   process.env.REACT_APP_BASE_URL === "https://go.compliancerisk.io"
      // ) {
      //   return false;
      // }
      if (
        clientPermissionRequired &&
        !clientPermissionRequired.some((item) =>
          objUser.user.client_feature_access.includes(item)
        ) &&
        objUser.user.role.name !== "admin" &&
        objUser.user.role.name !== "company_user"
      ) {
        return false;
      }
      if (
        mustBeCompanyAdminOrCompanyUserorRiskRegisterClient === true &&
        objUser.user?.role.name !== "admin" &&
        objUser.user?.role.name !== "company_user" &&
        objUser.user?.user_permissions?.risk_register_access !== 1
      ) {
        return false;
      }

      // if (
      //   name == "Projects Center" &&
        
      //   objUser.user?.is_beta_tester != 1
      // ) {
      //   return false;
      // }

      // if (
      //   name == "SAT Scorecard" &&
      //   process.env.REACT_APP_BASE_URL === "https://go.compliancerisk.io"
      // ) {
      //   return false;
      // }
      if (
        mustBeCompanyAdminOrCompanyUserorAssessmentClient === true &&
        objUser.user?.role.name !== "admin" &&
        objUser.user?.role.name !== "company_user" &&
        objUser.user?.user_permissions?.assessment_access !== 1
      ) {
        return false;
      }
      if (
        mustBeCompanyAdminOrCompanyUser === true &&
        key === "templates" &&
        objUser.user?.role.name !== "admin" &&
        objUser.user?.role.name !== "company_user"
      ) {
        return false;
      }
      if (
        mustBeCompanyAdmin === true &&
        key === "templates" &&
        objUser.user?.role.name !== "admin"
      ) {
        return false;
      }
      if (mustBeSuperAdmin === true && objUser.user.is_super_admin !== 1) {
        return false;
      }
      if (
        mustBeCompanyAdminOrCompanyUser === true &&
        (objUser.user?.role.name === "client_user" ||
          objUser.user?.role.name === "auditor")
      ) {
        return false;
      }
      if (
        mustBeCompanyAdmin === true &&
        (objUser.user?.role.name === "company_user" ||
          objUser.user?.role.name === "client_user" ||
          objUser.user?.role.name === "auditor")
      ) {
        return false;
      }
      if (mustBeClientUser && objUser.user.role.name !== "client_user") {
        return false;
      }
      if (
        mustBePeerGroupAdmin &&
        objUser.user.role.name !== "peer_group_admin"
      ) {
        return false;
      }

      if (
        cannotBeAccessedBy &&
        cannotBeAccessedBy?.includes(objUser.user.role.name)
      ) {
        return false;
      }

      if (
        canBeAccessedBy &&
        !canBeAccessedBy?.includes(objUser.user.role.name)
      ) {
        return false;
      }
      if (type === "collapse") {
        if (href) {
          let _name = name;
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            ></Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key} target={newTab && "_blank"}>
              <SidenavCollapse
                name={name}
                icon={!isBlockedOnClient ? icon : <VisibilityOffIcon />}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          let _name = name;
          returnValue = (
            <SidenavCollapse
              key={key}
              name={_name}
              icon={!isBlockedOnClient ? icon : <VisibilityOffIcon />}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );
  return <Fragment>{renderRoutes}</Fragment>;
}
function Sidenav({ color, brand, brandName = "", routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const [myRoutes, setMyRoutes] = useState(routes);
  //checking for client related routes
  const [clientRouteLogo, setClientRouteLogo] = useState(null); //if this is set, then it will have priority over every other logo
  const pathNameArray = pathname.split("/");
  const dispatchClientLogoFetch = useDispatch();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  /**logos */
  useEffect(() => {
    if (objUser.user.role.name === "peer_group_admin") {
      let tempRoutes = routes.filter((route) => {
        return (
          route.key === "peer-group" ||
          route.key === "post-config" ||
          route.key === "logout" ||
          route.key === "support"
        );
      });

      setMyRoutes(tempRoutes);
    }
    const possibleClientId = pathNameArray[2];
    dispatchClientLogoFetch(fetchDisplayLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setClientRouteLogo(response.data.display_logo);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
    //}
  }, [pathname]);

  //check logo here
  if (
    //at some point, the clientroutelogo will have a value but we wont necessarily be in a client specific route
    // as a result, we need to double check that we are in a client specific route before displaying the client logo
    clientRouteLogo
  ) {
    brand = clientRouteLogo;
  } else if (
    objUser?.user?.company?.logo &&
    objUser?.user?.company?.logo !== null
  ) {
    brand = objUser?.user?.company?.logo;
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const logoBackgroundValue = darkMode ? "#151d31" : "#06253d";

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          hello
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(
      ({
        name,
        collapse,
        route,
        href,
        mustBeCompanyAdmin,
        mustBeCompanyAdminOrCompanyUser,
        mustBeCompanyAdminOrCompanyUserorAssessmentClient,
        mustBeCompanyAdminOrCompanyUserorRiskRegisterClient,
        mustBeCompanyAdminOrCompanyUserOrAudience,
        clientPermissionRequired,
        key,
        hide,
      }) => {
        let returnValue;
        if (hide) {
          return false;
        }
        if (
          clientPermissionRequired &&
          !clientPermissionRequired.some((item) =>
            objUser.user.client_feature_access.includes(item)
          ) &&
          objUser.user.role.name !== "admin" &&
          objUser.user.role.name !== "company_user"
        ) {
          return false;
        }
        // if (
        //   name == "Trust Center" &&
        //   process.env.REACT_APP_BASE_URL === "https://go.compliancerisk.io"
        // ) {
        //   return false;
        // }
        if (collapse) {
          if (
            collapse.mustBeCompanyAdminOrCompanyUser == true &&
            (objUser.user.role.name == "client_user" ||
              objUser.user.role.name == "auditor")
          ) {
            return false;
          }
          if (
            collapse.mustBeCompanyAdmin == true &&
            (objUser.user.role.name == "company_user" ||
              objUser.user.role.name == "client_user" ||
              objUser.user.role.name == "auditor")
          ) {
            return false;
          }
          if (
            mustBeCompanyAdminOrCompanyUserorAssessmentClient === true &&
            objUser.user.role.name !== "admin" &&
            objUser.user.role.name !== "company_user" &&
            objUser.user?.user_permissions?.assessment_access !== 1
          ) {
            return false;
          }
          if (
            mustBeCompanyAdminOrCompanyUserorRiskRegisterClient === true &&
            objUser.user.role.name !== "admin" &&
            objUser.user.role.name !== "company_user" &&
            objUser.user?.user_permissions?.risk_register_access !== 1
          ) {
            return false;
          }
          returnValue = (
            <SidenavItem
              key={key}
              sx={{ backgroundColor: "#000" }}
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          if (
            mustBeCompanyAdminOrCompanyUser == true &&
            (objUser.user.role.name == "client_user" ||
              objUser.user.role.name == "auditor")
          ) {
            return false;
          }
          if (
            mustBeCompanyAdmin == true &&
            (objUser.user.role.name == "company_user" ||
              objUser.user.role.name == "client_user" ||
              objUser.user.role.name == "auditor")
          ) {
            return false;
          }
          if (
            mustBeCompanyAdminOrCompanyUserorAssessmentClient === true &&
            objUser.user.role.name !== "admin" &&
            objUser.user.role.name !== "company_user" &&
            objUser.user?.user_permissions?.assessment_access !== 1
          ) {
            return false;
          }
          if (
            mustBeCompanyAdminOrCompanyUserorRiskRegisterClient === true &&
            objUser.user.role.name !== "admin" &&
            objUser.user.role.name !== "company_user" &&
            objUser.user?.user_permissions?.risk_register_access !== 1
          ) {
            return false;
          }
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      }
    );

  let userFullName = "Guest";

  if (objUser !== undefined && Object.keys(objUser).length > 0) {
    if (objUser.authorized) {
      let last_name = "";
      if (
        objUser.user.last_name !== null &&
        objUser.user.last_name !== undefined
      ) {
        last_name = objUser.user.last_name;
      }
      userFullName = objUser.user.name + " " + last_name;
    }
  }

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      className="no-print"
    >
      <MDBox
        textAlign="center"
        sx={{
          backgroundColor: logoBackgroundValue,
          height: "75px",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={0}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {/* logo checks logic */}
          {brand && (
            <MDBox
              component="img"
              src={brand}
              alt="Brand"
              sx={{ maxHeight: "55px", maxWidth: "260px" }}
            />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox py={2}>
        <List>
          <RenderAllRoutes
            routes={myRoutes}
            objUser={objUser}
            userFullName={userFullName}
            collapseName={collapseName}
            openCollapse={openCollapse}
            setOpenCollapse={setOpenCollapse}
            renderCollapse={renderCollapse}
            miniSidenav={miniSidenav}
            transparentSidenav={transparentSidenav}
            whiteSidenav={whiteSidenav}
            darkMode={darkMode}
          />
        </List>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
