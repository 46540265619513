// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import polygonLogo from "assets/images/icons/logo.png";
import { Fragment, useState } from "react";
import { resendVerificationEmail } from "features/user/userActions";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

const EmailVerificationSection = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [objRsendEmailVerificationErrors, setObjRsendEmailVerificationErrors] =
    useState({});
  const btnOnClickResentVerificationEamil = (event) => {
    event.preventDefault();
    const objPostData = {};
    setObjRsendEmailVerificationErrors({});
    setIsLoading(true);
    dispatch(resendVerificationEmail(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg(
            "success",
            "A verification email has been sent to you successfully."
          );
        } else {
          showMsg("error", `Email verification failed. Server responded with status: ${response?.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "There was an error sending the verification email. Please try again later.";
        showMsg("error", errorMessage);
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if(objErrors) {
        setObjRsendEmailVerificationErrors(objErrors);
        }
      });
  };
  return (
    <Fragment>
      <Card sx={{ marginBottom: "2rem" }}>
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          p="5rem 1rem"
        >
          <MDBox
            sx={{
              width: 200,
              height: 150,
            }}
            mb={5}
            component="img"
            src={polygonLogo}
            alt="Compliance Scorecard logo"
          />
          <MDTypography
            fontSize={{ xs: "2rem", sm: "3rem" }}
            fontWeight="medium"
            color="black"
            mt={1}
          >
            Check Your Email!
          </MDTypography>
          <MDTypography
            fontSize={{ xs: "1rem", sm: "1.5rem" }}
            fontWeight="regular"
            color="black"
            mt={3}
          >
            We sent an account verification email to {objUser.user.email}.
          </MDTypography>
          <MDTypography
            fontSize={{ xs: "1rem", sm: "1.5rem" }}
            fontWeight="regular"
            color="black"
          >
            Please click the link in the email in order to verify your account
            and continue.
          </MDTypography>
          <MDTypography
            fontSize={{ xs: "1rem", sm: "1.5rem" }}
            fontWeight="regular"
            color="black"
          >
            You can close this page now.
          </MDTypography>
        </MDBox>
      </Card>
      <MDTypography
        fontSize={{ xs: "1rem", sm: "1.5rem" }}
        fontWeight="regular"
        color="black"
        textAlign="center"
        // mt="auto"
        mb={4}
      >
        Did not get the email?{" "}
        {isLoading === false ? (
          <MDTypography
            fontSize={{ xs: "1rem", sm: "1.5rem" }}
            component={Link}
            to="/"
            color="success"
            onClick={(e) => btnOnClickResentVerificationEamil(e)}
          >
            Click here
          </MDTypography>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
      </MDTypography>
    </Fragment>
  );
};

export default EmailVerificationSection;
