import { useEffect, useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { Fragment } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import "./CreatePolicyDocumentStepAwaitingAuthorizations.css";
import AuthorizationsTable from "../AuthorizationsTable";

import BackToDraftDialog from "../../BackToDraftDialog";
import { documentUpdateState } from "features/company/policiesActions";
// /* eslint-enable */

const CreatePolicyStepAwaitingAuthorizations = (props) => {
  const { objUser, objPolicyDocument, loadPolicyDocument, setNextDisabled } =
    props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openBackToDraftDialog, setOpenBackToDraftDialog] = useState(false);
  const allowedStatuses = ["Pending Approval", "Authorized", "Published"];
  let allowedStep = false;

  if (
    typeof objPolicyDocument.state !== "undefined" &&
    allowedStatuses.includes(objPolicyDocument.state)
  ) {
    allowedStep = true;
  }

  useEffect(() => {
    const nextStates = ["Authorized", "Published"];
    if (
      typeof objPolicyDocument.state === "undefined" ||
      !nextStates.includes(objPolicyDocument.state) ||
      objPolicyDocument.is_max_major_version == false
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, []);

  const [tableData, approvers, showApprover] = useMemo(() => {
    const tableData = [];
    const approvers = [];
    let showApprover = true;

    if (
      typeof objPolicyDocument.state !== "undefined" &&
      objPolicyDocument.state !== "In Review" &&
      typeof objPolicyDocument.reviewers !== "undefined"
    ) {
      if (
        typeof objPolicyDocument.reviewers.approval !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.approval) &&
        objPolicyDocument.reviewers.approval.length > 0
      ) {
        objPolicyDocument.reviewers.approval.forEach((element) => {
          approvers.push({
            id: element.id,
            name: element.label,
            status: element.status,
            status_color: element.status_color,
            reason: element.reason,
            show_reason: element.show_reason,
            type: "Approver",
          });
        });
      }

      if (
        typeof objPolicyDocument.reviewers.review !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.review) &&
        objPolicyDocument.reviewers.review.length > 0
      ) {
        objPolicyDocument.reviewers.review.forEach((element) => {
          let tmpObj = {};
          tmpObj.id = element.id;
          tmpObj.name = element.label;
          tmpObj.status = element.status;
          tmpObj.status_color = element.status_color;
          tmpObj.reason = element.reason;
          tmpObj.show_reason = element.show_reason;
          tmpObj.type = "Reviewer";
          tableData.push(tmpObj);

          if (element.status !== "approved") {
            showApprover = false;
          }
        });
      }

      if (
        typeof objPolicyDocument.reviewers.legal !== "undefined" &&
        Array.isArray(objPolicyDocument.reviewers.legal) &&
        objPolicyDocument.reviewers.legal.length > 0
      ) {
        objPolicyDocument.reviewers.legal.forEach((element) => {
          let tmpObj = {};
          tmpObj.id = element.id;
          tmpObj.name = element.label;
          tmpObj.status = element.status;
          tmpObj.status_color = element.status_color;
          tmpObj.reason = element.reason;
          tmpObj.show_reason = element.show_reason;
          tmpObj.type = "Legal Reviewer";
          tableData.push(tmpObj);

          if (element.status !== "approved") {
            showApprover = false;
          }
        });
      }

      if (objPolicyDocument.policy.policy_type == "asset") {
        showApprover = false;
      }
    }
    return [tableData, approvers, showApprover];
  }, [objPolicyDocument]);

  const onClickBackToDraft = () => {
    setOpenBackToDraftDialog(true);
  };

  const onCloseBackToDraftDialog = () => {
    setOpenBackToDraftDialog(false);
  };

  const submitBackToDraft = () => {
    setIsLoading(true);
    const params = {
      client_id: objPolicyDocument.policy.client_id ?? "",
      policy_id: objPolicyDocument.policy.id ?? "",
      policy_document_id: objPolicyDocument.id ?? "",
      state: "Draft",
    };
    dispatch(documentUpdateState({ objUser, params }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setOpenBackToDraftDialog(false);
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 200) {
          loadPolicyDocument();
        } else {
          showMsg(
            "error",
            `Failed to update document state. Server responded with status: ${response.status}`
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setOpenBackToDraftDialog(false);
        setIsLoading(false);
        showMsg(
          "error",
          rejectedValueOrSerializedError?.response?.data?.message ||
            "Failed to update document state. Please try again."
        );
      });
  };

  useEffect(() => {
    const reloadTimer = setInterval(() => {
      loadPolicyDocument();
    }, 30000);
    return () => clearInterval(reloadTimer);
  }, []);

  return (
    <MDBox>
      <BackToDraftDialog
        open={openBackToDraftDialog}
        onClose={onCloseBackToDraftDialog}
        submitBackToDraft={submitBackToDraft}
      />
      {isLoading === false ? (
        <Fragment>
          {allowedStep === true ? (
            <Fragment>
              <MDBox
                className="step-content-wrapper"
                textAlign="left"
                mx="auto"
                mt={4}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  columnSpacing={2}
                >
                  <Grid item xs={12} pt={0}>
                    <MDBox mb={0}>
                      <MDBox mb={0}>
                        <MDTypography variant="h5" fontWeight="bold">
                          Requested Authorizations
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        Waiting for all contacts to approve the policy document.
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox className="step-content-wrapper" textAlign="left" mt={3}>
                <AuthorizationsTable tableData={tableData} />

                {showApprover || <AuthorizationsTable tableData={approvers} />}

                {objPolicyDocument.has_reject ? (
                  <Fragment>
                    <Grid container justifyContent="start" mt={2} spacing={2}>
                      <Grid item xs={12} lg={12} pt={0}>
                        <MDBox mb={0}>
                          <MDTypography variant="body2" color="text">
                            This policy document version was not approved
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={12} pt={0}>
                        <MDBox mb={0}>
                          <MDButton
                            onClick={onClickBackToDraft}
                            // disabled={true}
                            type="button"
                            variant="gradient"
                            color="error"
                            sx={{ padding: "12px 20px" }}
                          >
                            Go back to Draft
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <span></span>
                )}
              </MDBox>
            </Fragment>
          ) : (
            <MDBox px={5} textAlign="left" mx="auto" mt={4}>
              <Grid container justifyContent="space-between" columnSpacing={2}>
                <Grid item xs={12} pt={0}>
                  <MDBox mb={0}>
                    <MDBox mb={0}>
                      <MDTypography variant="h5" fontWeight="bold">
                        Requested Authorizations
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="body2" color="text">
                      Please complete the document reviewers step first.
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </Fragment>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </MDBox>
  );
};

export default CreatePolicyStepAwaitingAuthorizations;
