import React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";

import { useDispatch } from "react-redux";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import { useCookies } from "react-cookie";
import DashboardActionButton from "views/components/DashboardActionButton";
import {
  fetchAllCompanies,
  fetchAllUnAssignedUsers,
} from "features/company/superAdminActions";
import MDInput from "components/MDInput";
import { reassignUserToCompany } from "features/company/superAdminActions";
import { fetchClientsForCompany } from "features/company/superAdminActions";

const ReassignToCompany = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingClients, setIsFetchingClients] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [totalCount, setTotalCount] = useState(0);
  const [isFixing, setIsFixing] = useState(false);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [companyKeyword, setCompanyKeyword] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [isClientUser, setIsClientUser] = useState(false);
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const loadUsers = () => {
    setIsLoading(true);
    dispatch(fetchAllUnAssignedUsers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setTotalCount(response.data.users.length);
        loadCompanies();
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load users. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadCompanies = () => {
    dispatch(fetchAllCompanies({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setCompanies(response.data.companies);
        setFilteredCompanies(response.data.companies);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load companies. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reassignUser = () => {
    if (isClientUser && !selectedClient) {
      showMsg("error", "Please select a client.");
      return;
    }

    let objPostData = {
      userId: selectedUsers[0].id,
      companyId: selectedCompany.id,
      clientId: selectedClient?.id,
    };
    dispatch(
      reassignUserToCompany({
        objPostData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        showMsg(
          "success",
          "User has been re-assigned to company successfully."
        );
        navigate("/super-admin/toolkit");
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to reassign user. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setIsFixing(false);
      });
  };

  const fetchClients = (_per_page = null, _page = null, company_id) => {
    setIsFetchingClients(true);
    dispatch(fetchClientsForCompany({ _per_page, _page, objUser, company_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingClients(false);
        const response = originalPromiseResult;
        setClients(response.data.clients);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingClients(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to fetch clients. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const checkIfUserIsSelected = (userId) => {
    let selectedUsersArray = [...selectedUsers];
    const userSelected = selectedUsersArray.find((user) => user.id === userId);

    return userSelected === undefined ? false : true;
  };

  const handleChecked = (user) => () => {
    let selectedUsersArray = [...selectedUsers];

    if (selectedUsersArray.length > 0) {
      if (selectedUsersArray[0].id === user.id) {
        selectedUsersArray.pop();
      } else {
        selectedUsersArray.pop();
        selectedUsersArray.push(user);
      }
    } else {
      selectedUsersArray.push(user);
    }

    setSelectedUsers(selectedUsersArray);
  };

  const handleCompanyChecked = (company) => () => {
    setSelectedCompany(company);
    setSelectedClient();
    fetchClients(null, null, company.id);
  };

  const handleKeyWordChange = (value) => {
    setKeyword(value);
  };

  const handleCompanyKeyWordChange = (value) => {
    setCompanyKeyword(value);
  };

  const filterUsers = (curUsers = []) => {
    // filter

    const filteredUsers = curUsers.filter((q) => {
      return (
        (q?.name?.toLowerCase() ?? "").includes(keyword.toLowerCase()) ||
        (q?.last_name?.toLowerCase() ?? "").includes(keyword.toLowerCase()) ||
        (q?.email?.toLowerCase() ?? "").includes(keyword.toLowerCase())
      );
    });
    return filteredUsers;
  };

  const filterCompanies = (curCompanies = []) => {
    const filteredCompanies = curCompanies.filter((q) => {
      return (q?.name?.toLowerCase() ?? "").includes(keyword.toLowerCase());
    });
    return filteredCompanies;
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadUsers();
  }, []);

  useEffect(() => {
    if (users.length == 0) {
      return;
    }
    const curUsers = [...users];
    const curCompanies = [...companies];
    const filteredUsers = filterUsers(curUsers);
    const filteredCompanies = filterCompanies(curCompanies);
    setFilteredUsers(filteredUsers);
    setFilteredCompanies(filteredCompanies);
  }, [keyword, companyKeyword]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      {isLoading ? (
        <Card>
          <LoadingSpinner color="success" size="lg" />
        </Card>
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={4}>
            {users.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Search user:
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={keyword ?? ""}
                    onChange={(e) => handleKeyWordChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            {users.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Search company:
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={companyKeyword ?? ""}
                    onChange={(e) => handleCompanyKeyWordChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {filteredUsers.map((user, i) => {
                    return (
                      <ListItem
                        key={user.id}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleChecked(user)}
                          dense
                        >
                          <ListItemIcon>
                            <Radio
                              edge="start"
                              checked={checkIfUserIsSelected(user.id)}
                              tabIndex={-1}
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={`${user.name} ${user.last_name ?? ""} (${
                              user.email
                            })`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {filteredCompanies.map((company, i) => {
                    return (
                      <ListItem
                        key={company.id}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleCompanyChecked(company)}
                          dense
                        >
                          <ListItemIcon>
                            <Radio
                              edge="start"
                              checked={selectedCompany?.id == company.id}
                              tabIndex={-1}
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={`${company.name}`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <MDTypography variant="h6" fontWeight="bold" textAlign="left">
                  Selected User
                </MDTypography>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 620,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {selectedUsers.map((user, i) => {
                    return (
                      <ListItem key={i} disablePadding>
                        <ListItemButton role={undefined} dense>
                          <ListItemText
                            id={i}
                            primary={`${user.name} ${user.last_name ?? ""} (${
                              user.email
                            })`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>

                {selectedCompany && (
                  <>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      textAlign="left"
                      mt={2}
                    >
                      Selected Company
                    </MDTypography>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 620,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      <ListItem disablePadding>
                        <ListItemButton role={undefined} dense>
                          <ListItemText
                            primary={`${selectedCompany?.name}`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </>
                )}

                {selectedCompany && (
                  <>
                    <MDBox display="flex">
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            setSelectedClient();
                            setIsClientUser(false);
                          }}
                        >
                          <ListItemIcon>
                            <Radio
                              checked={!isClientUser}
                              name="isClientUser"
                              inputProps={"aria-label"}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={"MSP User"}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => setIsClientUser(true)}>
                          <ListItemIcon>
                            <Radio
                              checked={isClientUser}
                              name="isClientUser"
                              inputProps={"aria-label"}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Client User"}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </MDBox>
                    {isClientUser && (
                      <>
                        <MDTypography
                          variant="body2"
                          fontWeight="bold"
                          textAlign="left"
                        >
                          Select a Client
                        </MDTypography>
                        {isFetchingClients ? (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="sm"
                          />
                        ) : (
                          <MDBox display="flex" px={"10px"}>
                            <Select
                              value={selectedClient}
                              sx={{
                                minWidth: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderWidth: "4px",
                              }}
                              onChange={(e) =>
                                setSelectedClient(e.target.value)
                              }
                            >
                              <MenuItem value="" disabled>
                                Select a Client
                              </MenuItem>
                              {clients.map((client) => (
                                <MenuItem key={client} value={client}>
                                  {client.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <UnfoldMoreIcon
                              sx={{
                                position: "relative",
                                right: "20px",
                                top: "10px",
                                pointerEvents: "none",
                              }}
                            />
                          </MDBox>
                        )}
                      </>
                    )}
                  </>
                )}

                {selectedUsers.length == 0 || !selectedCompany ? (
                  <>
                    {selectedUsers.length == 0 && (
                      <MDBox>No User selected</MDBox>
                    )}
                    {!selectedCompany && <MDBox>No Company selected</MDBox>}
                  </>
                ) : (
                  <>
                    {isFixing ? (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="sm"
                      />
                    ) : (
                      <DashboardActionButton
                        action={reassignUser}
                        btnText="Reassign"
                        btnIcon="update"
                        textColor="white"
                        bgColor="primary"
                        sx={{ marginTop: "0px !important" }}
                      />
                    )}
                  </>
                )}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default ReassignToCompany;
