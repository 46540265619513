/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";
import EssentialProviders from "context/essentialProviders";
import ClientContactActionBtnTr from "./ClientContactActionBtnTr";
import StatusTr from "./StatusTr";

import "views/components/DataTablesStyle/datatables.css";

import DT from "datatables.net-dt";
import DataTable from "datatables.net-react";
import "datatables.net-responsive-dt";
import "datatables.net-select-dt";

DataTable.use(DT);

const columns = [
  {
    orderable: false,
    render: DT.render.select(),
    data: "id",
  },
  { data: "firstname" },
  { data: "lastname" },
  { data: "email" },
  { name: "status", data: "member" },
  { name: "action", data: "id" },
];

const datatableOptions = {
  responsive: true,
  select: {
    style: "multi+shift",
    selector: "td:first-child",
  },
};

function ClientContactsDataByIdTable({
  objClientsContactsData,
  editTheClientContact,
  deleteTheClientContact,
  changeUserStatus,
  isDeletingClientContact,
  setIsDeletingClientContact,
  objSelectedClientContact,
  addToAudienceContact,
  inviteContact,
  resendInvite,
  isInvitingClientContact,
  clickContactKBS,
  setContactsSelectedForBulkAction,
  isClientUser,
  showInviteClientDialog,
  materialUIController,
}) {
  const handleSelectRow = (e, dt, type, indexes) => {
    const rowData = dt.rows(indexes).data().toArray();
    const selectedRows = rowData.map((row) => row.id);
    console.log("selectedRows", selectedRows);
    setContactsSelectedForBulkAction((currentSelection) => [
      ...currentSelection,
      ...selectedRows,
    ]);
  };

  const handleDeselectRow = (e, dt, type, indexes) => {
    const rowData = dt.rows(indexes).data().toArray();
    const deselectedRows = rowData.map((row) => row.id);
    console.log("deselectedRows", deselectedRows);
    setContactsSelectedForBulkAction((currentSelection) =>
      currentSelection.filter((id) => !deselectedRows.includes(id))
    );
  };
  
  const slots = {
    status: (data, row) => (
      <EssentialProviders materialUIController={materialUIController}>
        <StatusTr value={data} row={row} />
      </EssentialProviders>
    ),
    action: (data, row) => (
      <EssentialProviders materialUIController={materialUIController}>
        <ClientContactActionBtnTr
          value={data}
          row={row}
          objSelectedClientContact={objSelectedClientContact}
          editTheClientContact={editTheClientContact}
          deleteTheClientContact={deleteTheClientContact}
          changeUserStatus={changeUserStatus}
          isDeletingClientContact={isDeletingClientContact}
          setIsDeletingClientContact={setIsDeletingClientContact}
          addToAudienceContact={addToAudienceContact}
          inviteContact={inviteContact}
          resendInvite={resendInvite}
          showInviteClientDialog={showInviteClientDialog}
          isInvitingClientContact={isInvitingClientContact}
          clickContactKBS={clickContactKBS}
          isClientUser={isClientUser}
        />
      </EssentialProviders>
    ),
  };

  const data = useMemo(() => {
    const objRows = [];
    const objClientContactsList = objClientsContactsData;
    for (let index = 0; index < objClientContactsList.length; index++) {
      const element = objClientContactsList[index];
      const objTr = {};
      objTr.id = element.id;
      objTr.firstname = element.first_name;
      objTr.lastname = element.last_name;
      objTr.email = element.email;
      objTr.status = element?.user?.status ?? null;
      objTr.role = element?.user?.role?.name ?? null;
      objTr.member = element.user ? true : false;
      objTr.element = element;
      objRows.push(objTr);
    }
    return objRows;
  }, [objClientsContactsData]);

  return (
    <DataTable
      data={data}
      columns={columns}
      slots={slots}
      options={datatableOptions}
      className="display"
      onSelect={handleSelectRow}
      onDeselect={handleDeselectRow}
    >
      <thead>
        <tr>
          <th></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
    </DataTable>
  );
}

// Setting default values for the props of ClientContactsDataByIdTable
ClientContactsDataByIdTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [10, 25, 50, 100] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default ClientContactsDataByIdTable;
