import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
const PolicyNameBtnTr = (props) => {
  const { value, row, onButtonClick } = props;

  return (
    <MDButton variant="text" onClick={() => onButtonClick(row)}>
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default PolicyNameBtnTr;
