import MDBadge from "components/MDBadge";

const StatusTr = (props) => {
  const { value, row } = props;

  if (row.member === true) {
    if (row.role === "admin") {
      return <MDBadge badgeContent="MSP Admin" size="sm" color="dark" />;
    } else if (row.role === "company_user") {
      return <MDBadge badgeContent="MSP User" size="sm" color="dark" />;
    } else {
      return <MDBadge badgeContent="Client User" size="sm" color="success" />;
    }
  } else {
    return <MDBadge badgeContent="Contact" size="sm" color="info" />;
  }

};

export default StatusTr;
