import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { shareKnowledgeBase } from "features/company/knowledgeBaseActions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete, TextField } from "@mui/material";
const objInitialValues = {
  txtKBSExpiration: null,
};
const AddNewPolicyFormJWTSchema = Yup.object().shape({
  txtKBSExpiration: Yup.string().required("Expiration date is required."),
});

function CreateNewKBSDialog({ open, onClose, onSubmit, objUser, contactId }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    const objPostData = {
      expirationTime: values.txtKBSExpiration,
    };

    if (contactId !== null) {
      objPostData.contactId = contactId;
    }
    setIsLoading(true);
    dispatch(shareKnowledgeBase({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data);
        } else {
          showMsg("error", `Failed to share knowledge base. Server responded with status: ${response.status}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to share knowledge base. Please try again.";
        showMsg("error", errorMessage);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>
        Share Knowledge Base{contactId === null ? " with all contacts" : ""}
      </DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewPolicyFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="ShareKBSForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <DateTimePicker
                  label="Knowledge Base Expiration"
                  disablePast={true}
                  value={values.txtKBSExpiration}
                  onChange={(value) => {
                    setFieldValue("txtKBSExpiration", value);
                  }}
                  name="txtKBSExpiration"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />

                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtKBSExpiration" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Share
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default CreateNewKBSDialog;
