import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { update } from "../../../../../../../features/assessmentConfig/assessmentConfigActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
const AddNewDataFormJWTSchema = Yup.object().shape({
  txtAssessmentTypeName: Yup.string().required("The name is required."),
});

function EditDialog({ open, onClose, onSubmit, objUser, editDataDialogData }) {
  const [isLoading, setIsLoading] = useState(false);
  const objInitialValues = {
    txtAssessmentTypeId: editDataDialogData.assessmentTypeId,
    txtAssessmentTypeName: editDataDialogData.assessmentTypeName,
  };

  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtAssessmentTypeName,
      id: values.txtAssessmentTypeId,
    };

    setIsLoading(true);
    dispatch(update({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          showMsg("success", "Changes saved successfully");
          onSubmit(response);
        } else {
          throw new Error("Failed to update assessment type. Check your input and try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError.response?.data?.message || "An unexpected error occurred. Try again.";
        showMsg("error", errorMessage);
        console.log("Error details:", rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response?.data?.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach((error) => {
            showMsg("error", error);
          });
        }
      });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Edit Assessment Type</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewDataFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="EditCategoryForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <Field
                  type="text"
                  label="Name"
                  name="txtAssessmentTypeName"
                  value={values.txtAssessmentTypeName}
                  placeholder={"Name"}
                  error={
                    errors.txtAssessmentTypeName &&
                    touched.txtAssessmentTypeName
                  }
                  success={
                    values.txtAssessmentTypeName.length > 0 &&
                    !errors.txtAssessmentTypeName
                  }
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  autoFocus
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    <ErrorMessage name="txtCategoryName" />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Save Changes
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default EditDialog;
