import React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import { useCookies } from "react-cookie";
import DashboardActionButton from "views/components/DashboardActionButton";
import {
  fetchAllPlatformUsers,
  resetTwoFA,
} from "features/company/superAdminActions";
import MDInput from "components/MDInput";

const AssessmentEventFix = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [totalCount, setTotalCount] = useState(0);
  const [isFixing, setIsFixing] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const loadUsers = () => {
    setIsLoading(true);
    dispatch(fetchAllPlatformUsers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setTotalCount(response.data.users.length);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to load users. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reset2FA = (category) => {
    setIsFixing(true);
    let objPostData = {
      selectedUsersToReset: selectedUsers.map((user) => user.id),
    };
    dispatch(
      resetTwoFA({
        objPostData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        showMsg("success", "2FA has been successful reset for the user.");
        navigate("/super-admin/toolkit");
      })
      .catch((rejectedValueOrSerializedError) => {
        const errorMessage = rejectedValueOrSerializedError?.response?.data?.message || "Failed to reset 2FA. Please try again.";
        showMsg("error", errorMessage);
        console.error(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setIsFixing(false);
      });
  };

  const checkIfUserIsSelected = (userId) => {
    let selectedUsersArray = [...selectedUsers];
    const userSelected = selectedUsersArray.find((user) => user.id === userId);

    return userSelected === undefined ? false : true;
  };

  const handleChecked = (user) => () => {
    let selectedUsersArray = [...selectedUsers];

    if (selectedUsersArray.length > 0) {
      if (selectedUsersArray[0].id === user.id) {
        selectedUsersArray.pop();
      } else {
        selectedUsersArray.pop();
        selectedUsersArray.push(user);
      }
    } else {
      selectedUsersArray.push(user);
    }

    setSelectedUsers(selectedUsersArray);
  };

  const handleKeyWordChange = (value) => {
    setKeyword(value);
  };

  const filterUsers = (curUsers = []) => {
    const filteredUsers = curUsers.filter((q) => {
      return (
        (q?.name?.toLowerCase() ?? "").includes(keyword.toLowerCase()) ||
        (q?.last_name?.toLowerCase() ?? "").includes(keyword.toLowerCase()) ||
        (q?.email?.toLowerCase() ?? "").includes(keyword.toLowerCase())
      );
    });
    return filteredUsers;
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadUsers();
  }, []);

  useEffect(() => {
    if (users.length == 0) {
      return;
    }
    const curUsers = [...users];
    const filteredUsers = filterUsers(curUsers);
    setFilteredUsers(filteredUsers);
  }, [keyword]);

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />

      {isLoading ? (
        <Card>
          <LoadingSpinner color="success" size="lg" />
        </Card>
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={12}>
            {users.length > 0 && (
              <MDBox
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"baseline"}
              >
                <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                  <MDBox sx={{ minWidth: "max-content" }}>
                    <MDTypography variant="body2" fontWeight={"bold"}>
                      Search user:
                    </MDTypography>
                  </MDBox>

                  {/* Filter by Keyword */}
                  <MDInput
                    label="Keyword"
                    value={keyword ?? ""}
                    onChange={(e) => handleKeyWordChange(e.target.value)}
                    fullWidth={true}
                    variant={"standard"}
                    sx={{ marginLeft: "10px" }}
                  />
                </MDBox>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} lg={7}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 700,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {filteredUsers.map((user, i) => {
                    return (
                      <ListItem
                        key={user.id}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={handleChecked(user)}
                          dense
                        >
                          <ListItemIcon>
                            <Radio
                              edge="start"
                              checked={checkIfUserIsSelected(user.id)}
                              tabIndex={-1}
                              inputProps={{ "aria-labelledby": i }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={i}
                            primary={`${user.name} ${user.last_name ?? ""} (${
                              user.email
                            })`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={5}>
            <MDBox>
              <Card
                sx={{
                  padding: "10px",
                  textAlign: "center",
                  color: "text.main",
                }}
              >
                <MDTypography variant="h6" fontWeight="bold">
                  Selected User
                </MDTypography>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 620,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {selectedUsers.map((user, i) => {
                    return (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{ marginTop: "10px" }}
                      >
                        <ListItemButton
                          role={undefined}
                          dense
                        >
                          <ListItemText
                            id={i}
                            primary={`${user.name} ${user.last_name ?? ""} (${
                              user.email
                            })`}
                            sx={{ color: "text.main" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                {selectedUsers.length == 0 ? (
                  "No User selected"
                ) : (
                  <>
                    {isFixing ? (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="sm"
                      />
                    ) : (
                      <DashboardActionButton
                        action={reset2FA}
                        btnText="Reset 2FA"
                        btnIcon="refresh"
                        textColor="white"
                        bgColor="primary"
                        sx={{ marginTop: "0px !important" }}
                      />
                    )}
                  </>
                )}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default AssessmentEventFix;
