import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { createMergeTag, updateMergeTag } from "features/company/mergeTagsActions";

import * as Yup from "yup";
// formik components
import { Formik, Form } from "formik";
const objInitialValues = {
  txtName: "",
  txtDescription: "",
};
const AddNewPolicyFormJWTSchema = Yup.object().shape({
  txtName: Yup.string().required("The Name is required."),
});

function EditTagsDialog({
  open,
  onClose,
  onSubmit,
  objUser,
  mergeTag,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const editMode = typeof mergeTag !== "undefined" && mergeTag !== null;
  const onSubmitForm = async (values) => {
    const objPostData = {
      name: values.txtName,
      description: values.txtDescription,
    };

    let operation = createMergeTag;
    if (editMode) {
      objPostData.id = mergeTag.id;
      operation = updateMergeTag;
    }
    setIsLoading(true);
    dispatch(operation({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          onSubmit(response.data);
        } else {
          throw new Error("error", `Failed to save merge tag. Server responded with status code: ${response.status}.`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessage = rejectedValueOrSerializedError?.message || rejectedValueOrSerializedError?.response?.data?.message || `Failed to save merge tag. Server responded with status code: ${rejectedValueOrSerializedError?.response?.status}.`;
        showMsg("error", errorMessage);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };
  useEffect(() => {
    if (editMode) {
      objInitialValues.txtName = mergeTag.name;
      objInitialValues.txtDescription = mergeTag.description;
    } else {
      objInitialValues.txtName = "";
      objInitialValues.txtDescription = "";
    }
  }, [mergeTag]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>{editMode?"Edit":"Create"} Merge Tag</DialogTitle>
      <Formik
        initialValues={objInitialValues}
        validationSchema={AddNewPolicyFormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="ShareKBSForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="text"
                  label="Tag"
                  name="txtName"
                  value={values.txtName}
                  placeholder={"Merge Tag Name"}
                  error={errors.txtName && touched.txtName}
                  success={
                    values.txtName.length > 0 && !errors.txtName
                  }
                />
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="text"
                  label="Description"
                  name="txtDescription"
                  value={values.txtDescription}
                  placeholder={"Description"}
                  error={errors.txtDescription && touched.txtDescription}
                  success={
                    values.txtDescription.length > 0 && !errors.txtDescription
                  }
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {isLoading === false ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    {editMode?"Update":"Create"}
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default EditTagsDialog;
