import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/users/new-user/components/FormField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListSubheader from "@mui/material/ListSubheader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { loadCampaign, resendCampaign } from "features/company/policiesActions";
import {
  resendCampaignToAllNotAdopted,
  exportAdoptersListToCsv,
} from "features/company/adoptionCampaignActions";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardActionButton from "views/components/DashboardActionButton";
import { Chip, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { displayExceptionErrorMessage } from "utils/general";

function Index({ objUser }) {
  const [campaignData, setCampaignData] = useState(null);
  const [sendingTo, setSendingTo] = useState([]);
  const [sendingToAll, setSendingToAll] = useState(null);
  const [exportingToCsv, setExportingToCSv] = useState(null);

  const navigate = useNavigate();

  let { campaign_id } = useParams();
  const campaignId = campaign_id;

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setCampaignData(null);
    if (campaignId === 0) return;

    dispatch(loadCampaign({ objUser, campaignId }))
      .then(unwrapResult)
      .then((response) => {
        if (
          !response.data ||
          !response.data.policies ||
          response.data.policies.length === 0
        ) {
          throw new Error("No campaign data found");
        }

        const campaign = response.data;

        // Process adopters for each policy
        campaign.policies.forEach((policy) => {
          policy.adopters.forEach(
            (a) => (a.name = `${a.first_name} ${a.last_name}`)
          );

          policy.adopters_adopted = policy.adopters.filter((a) => a.is_adopted);
          policy.adopters_not_adopted = policy.adopters.filter(
            (a) => !a.is_adopted
          );
        });

        setCampaignData(campaign);
      })
      .catch((error) => {
        setCampaignData(null);
        displayExceptionErrorMessage(error);
        navigate(`/adoption-campaigns`);
      });
  }, [objUser, campaignId]);

  const resendNotif = (contactId) => () => {
    setSendingTo([...sendingTo, contactId]);
    dispatch(
      resendCampaign({
        objUser,
        campaignId,
        contactId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showMsg("success", "Notification resent successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message ??
            "Error sending notification. Try again later."
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() =>
        setSendingTo((oldValue) => oldValue.filter((el) => el !== contactId))
      );
  };

  const resendToAll = () => {
    setSendingToAll(true);
    dispatch(
      resendCampaignToAllNotAdopted({
        objUser,
        campaignId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        showMsg(
          "success",
          "Notification resent successfully to all contacts who haven't adopted"
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message ??
            "Error occurred while resending notification to all, Try again"
        );
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      })
      .finally(() => {
        setSendingToAll(false);
      });
  };

  const exportToCsv = (output) => {
    setExportingToCSv(true);
    dispatch(
      exportAdoptersListToCsv({
        objUser,
        campaignId,
        campaignData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setExportingToCSv(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${campaignData?.campaign_name} Adoption Report.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setExportingToCSv(false);
        showMsg("error", rejectedValueOrSerializedError.response?.data?.message || "An error occurred while trying to export adopters csv. Try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <Grid container width="100%" spacing={2}>
        <Grid item xs={12} lg={12}>
          <MDBox
            display="flex"
            gap=".6rem"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={2}
          >
            <MDTypography
              variant="h4"
              sx={{ paddingTop: "1.2rem", marginRight: "auto" }}
            >
              View Campaign{" "}
              {campaignData != null
                ? "(" + campaignData.campaign_name + ")"
                : ""}
            </MDTypography>

            {exportingToCsv ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="md"
              />
            ) : (
              <DashboardActionButton
                action={exportToCsv}
                btnText="Export To CSV"
                btnIcon="file_copy"
                textColor="white"
                bgColor="info"
              />
            )}

            {sendingToAll ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="md"
              />
            ) : (
              <DashboardActionButton
                action={resendToAll}
                btnText="Resend Email To All"
                btnIcon="send"
                textColor="white"
                bgColor="success"
                buttonDisabled={campaignData?.policies.every((policy) =>
                  policy.adopters.every((a) => a.is_adopted)
                )}
              />
            )}
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card
            sx={{
              minHeight: "300px",
              width: "100%",
              padding: "1rem",
            }}
          >
            {campaignData !== null ? (
              <>
                {/* Campaign Adoption Status */}
                {campaignData.adoption_status === "Draft" ? (
                  <Chip
                    label="Not Adopted"
                    color="primary"
                    sx={{ width: "10%" }}
                  />
                ) : (
                  <Chip
                    label="Adopted"
                    color="success"
                    sx={{ width: "10%", color: "white !important" }}
                  />
                )}
                <br />

                <MDBox>
                  <MDTypography color="dark" variant="h6">
                    {campaignData.policies.reduce(
                      (acc, policy) =>
                        acc +
                        policy.adopters.filter((a) => a.is_adopted).length,
                      0
                    )}{" "}
                    /{" "}
                    {campaignData.policies.reduce(
                      (acc, policy) => acc + policy.adopters.length,
                      0
                    )}{" "}
                    Adopted
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <Typography
                    component="div"
                    variant="body2"
                    color="text.secondary"
                  >
                    Contacts will be automatically reminded every{" "}
                    {campaignData.reminder_time > 1
                      ? ` ${campaignData.reminder_time} days`
                      : "day"}
                  </Typography>
                </MDBox>

                <div style={{ marginTop: "20px" }}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      position: "relative",
                      overflow: "auto",
                      "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                  >
                    {/* Loop through each policy */}
                    {campaignData.policies.map((policy) => (
                      <div key={policy.id} style={{ marginBottom: "20px" }}>
                        {/* Policy Title */}
                        <MDTypography color="dark" variant="h5" mb={1.5}>
                          {policy.title}
                        </MDTypography>

                        {/* Not Adopted List */}
                        {policy.adopters
                          .sort(
                            (a, b) =>
                              a.first_name.localeCompare(b.first_name) ||
                              a.last_name.localeCompare(b.last_name)
                          )
                          .some((a) => !a.is_adopted) && (
                          <>
                            <MDTypography color="primary" variant="h6" mb={1.3}>
                              Not Adopted
                            </MDTypography>
                            {policy.adopters
                              .filter((a) => !a.is_adopted)
                              .map((contact) => (
                                <ListItem
                                  key={contact.id}
                                  sx={{
                                    marginBottom: "1rem",
                                    border: "1px solid #eee",
                                    backgroundColor: "#eee",
                                    borderRadius: "10px",
                                    padding: "1rem",
                                  }}
                                  secondaryAction={
                                    !sendingTo.includes(contact.id) ? (
                                      <MDButton
                                        type="button"
                                        variant="outlined"
                                        color="dark"
                                        sx={{
                                          padding: {
                                            xs: "0 2px",
                                            sm: "12px 12px",
                                          },
                                          marginRight: "5px",
                                          border: {
                                            xs: "none",
                                            sm: "1px solid",
                                          },
                                        }}
                                        onClick={resendNotif(contact.id)}
                                      >
                                        <Icon>send</Icon>
                                        Resend
                                      </MDButton>
                                    ) : (
                                      <div style={{ marginRight: "10px" }}>
                                        <LoadingSpinner
                                          subClass="text-center"
                                          color="success"
                                          size="md"
                                        />
                                      </div>
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={`${contact.first_name} ${contact.last_name} (${contact.email})`}
                                    secondary={`Last Email Sent At: ${new Date(
                                      contact.email_sent_at
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}`}
                                    secondaryTypographyProps={{
                                      variant: "caption",
                                      color: "textSecondary",
                                      fontStyle: "italic",
                                    }}
                                  />
                                </ListItem>
                              ))}
                          </>
                        )}

                        {/* Adopted List */}
                        {policy.adopters
                          .sort(
                            (a, b) =>
                              a.first_name.localeCompare(b.first_name) ||
                              a.last_name.localeCompare(b.last_name)
                          )
                          .some((a) => a.is_adopted) && (
                          <>
                            <MDTypography color="success" variant="h6" mb={1.3}>
                              Adopted
                            </MDTypography>
                            {policy.adopters
                              .filter((a) => a.is_adopted)
                              .map((contact) => (
                                <ListItem
                                  key={contact.id}
                                  sx={{
                                    marginBottom: "1rem",
                                    border: "2px solid #eee",
                                    borderRadius: "10px",
                                    padding: "1rem",
                                  }}
                                >
                                  <Tooltip
                                    title={new Date(
                                      contact.adopted_at
                                    ).toString()}
                                  >
                                    <ListItemText
                                      primary={`${contact.first_name} ${contact.last_name} (${contact.email})`}
                                      secondary={`Adopted on: ${new Date(
                                        contact.adopted_at
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}`}
                                      secondaryTypographyProps={{
                                        variant: "caption",
                                        color: "textSecondary",
                                        fontStyle: "italic",
                                      }}
                                    />
                                  </Tooltip>
                                </ListItem>
                              ))}
                          </>
                        )}
                      </div>
                    ))}
                  </List>
                </div>
              </>
            ) : (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
export default Index;
