// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import { createApiKeys } from "features/api-keys/apiKeysActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import AddApiKeysFormErrorsBlock from "./AddApiKeysFormErrorsBlock";
import AddApiKeysFormFormikWrapper from "./AddApiKeysFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { textAlign } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// const objInitialValues = {
//   public_key: "",
//   private_key: "",
//   company_id: "",
// };
const AddApiKeysFormJWTSchema = Yup.object().shape({
  name: Yup.string().required("Token name is Required"),
  // expiry_date: Yup.string().optional(),
});
const AddApiKeysForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(false);
  const [objInitialValues, setObjInitialValues] = useState({
    name: "",
    expiry_date: null,
  });

  const [objAddApiKeysFormErrors, setObjAddApiKeysFormErrors] = useState({});
  const onSubmitForm = async (values) => {
    setObjAddApiKeysFormErrors({});
    setIsLoading(true);
    dispatch(createApiKeys(values))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        showMsg("success", "You have successfully create a new API token.");
        props.onSubmitNewToken(response);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const errorMessages = rejectedValueOrSerializedError?.response?.data?.errors || ["Failed to create API key. Please check your input and try again."];
        errorMessages.forEach(errorMessage => showMsg("error", errorMessage));
      });
  };

  return (
    <>
      {isLoadingKeys === false ? (
        <Formik
          initialValues={objInitialValues}
          validationSchema={AddApiKeysFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"SaveConnectWiseApiKeysForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AddApiKeysFormFormikWrapper
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </LocalizationProvider>

                  {Object.keys(objAddApiKeysFormErrors).length > 0 ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      pl={5}
                      pr={5}
                      pt={0}
                      pb={5}
                    >
                      <AddApiKeysFormErrorsBlock
                        objAddApiKeysFormErrors={objAddApiKeysFormErrors}
                      />
                    </Grid>
                  ) : null}

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isLoading === false ? (
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 20px", margin: "0px 5px" }}
                      >
                        Save
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
              {/* </MDBox> */}
            </Form>
          )}
        </Formik>
      ) : (
        <MDBox>
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
          <MDTypography sx={{ textAlign: "center" }}>Loading Keys</MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default AddApiKeysForm;
