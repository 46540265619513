import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDBox from "components/MDBox";


const data = [
  { type: "Mitigate", description: "Apply actions that reduce the threats, vulnerabilities, and impacts of a given risk to an acceptable level. Responses could include those that help prevent a loss (i.e., reducing the probability of occurrence or the likelihood that a threat event materializes or succeeds) or that help limit such a loss by decreasing the amount of damage and liability."},
  { type: "Transfer", description: "For cybersecurity risks that fall outside of tolerance levels, reduce them to an acceptable level by sharing a portion of the consequences with another party (e.g., cybersecurity insurance). While some of the financial consequences may be transferable, there are often consequences that cannot be transferred, like loss of customer trust."},
  { type: "Avoid", description: "Apply responses to ensure that the risk does not occur. Avoiding risk may be the best option if there is not a cost-effective method for reducing the cybersecurity risk to an acceptable level. The cost of the lost opportunity associated with such a decision should be considered as well."},
  { type: "Accept", description: "Accept cybersecurity risk within risk tolerance levels. No additional risk response action is needed except for monitoring."},
  { type: "Defer", description: "Delaying or postponing the decision or action to address the risk. This might be done for various reasons, such as insufficient information, lack of resources, prioritization:, or uncertainty."},
];

const RiskRegisterDecidedActionTooltipTable = () => {
  return (
    <TableContainer component={Paper}  >
      <Table sx={{ minWidth: 600 }} size="small">
        <MDBox component="thead">
          <TableRow>
            <TableCell sx={{ width: "10%", fontWeight: "bold", border: "1px solid #ccc", }}>Type</TableCell>
            <TableCell sx={{ width: "90%", fontWeight: "bold", border: "1px solid #ccc", }}>Description</TableCell>
          </TableRow>
        </MDBox>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell component="th" scope="row" sx={{ width: "10%",  border: "1px solid #ccc", }}>{row.type}</TableCell>
              <TableCell sx={{  width: "90%", border: "1px solid #ccc", }}>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RiskRegisterDecidedActionTooltipTable;
